<template>
    <div class="card">
        <div class="card-header">
            <h5>Employee Job Life (EJL)</h5>
        </div>
        <div class="card-body">
            <div v-if="loading" class="text-center py-4">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div v-else class="col-md-12 w-full">
                <!-- Employee Basic Info -->
                <div class="col-md-12">
                    <div class="mb-4">
                        <!-- Photo -->
                        <div class="employee-photo mb-3">
                            <img :src="employee.photoUrl || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsEJHmI0MlIGvH9CYkbsLEWQ5_ee8Qtl5V-Q&s'"
                                alt="Employee Photo" class="img-thumbnail" style="max-width: 200px">
                            <div class="mt-2">
                                <button class="btn btn-sm btn-outline-primary" 
                                        @click="downloadPhoto()"
                                        :disabled="!employee.photoUrl">
                                    <i class="bi bi-download"></i> Download Photo
                                </button>
                            </div>
                        </div>

                        <!-- Basic Details -->
                        <div class="employee-details">
                            <div class="mb-2">
                                <strong>Black listed:</strong>
                                {{ employee.blacklisted ? 'Yes' : 'No' }}
                            </div>
                            <div class="mb-2"><strong>Name:</strong> {{ employee.name }}</div>
                            <div class="mb-2"><strong>DOJ:</strong> {{ formatDate(employee.doj) }}</div>
                            <div class="mb-2"><strong>DOB:</strong> {{ formatDate(employee.dob) }}</div>
                            <div class="mb-2"><strong>Employee Code:</strong> {{ employee.employeeCode }}</div>
                            <div class="mb-2"><strong>Father's Name:</strong> {{ employee.fathersName }}</div>
                            <div class="mb-2"><strong>Education:</strong> {{ employee.education }}</div>
                            <div class="mb-2"><strong>Character:</strong> {{ employee.character }}</div>
                            <div class="mb-2"><strong>Medical Category:</strong> {{ employee.medicalCategory }}</div>
                            <div class="mb-2"><strong>Languages:</strong> {{ employee.languagesKnown?.join(', ') }}
                            </div>
                            <div class="mb-2"><strong>Gender:</strong> {{ employee.sex }}</div>

                            <!-- Permanent Address -->
                            <div class="mt-4">
                                <h6>Permanent Address</h6>
                                <div class="ms-3">
                                    <div>{{ employee.permanentAddress?.village }}</div>
                                    <div>{{ employee.permanentAddress?.postOffice }}</div>
                                    <div>{{ employee.permanentAddress?.district }}, {{ employee.permanentAddress?.state
                                        }}</div>
                                    <div>PIN: {{ employee.permanentAddress?.pin }}</div>
                                    <div>Phone: {{ employee.permanentAddress?.phoneNumber }}</div>
                                </div>
                            </div>

                            <!-- Family Details -->
                            <!-- <div class="mt-4">
                                <h6>Family Details</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Relationship</th>
                                                <th>Age</th>
                                                <th>Occupation</th>
                                                <th>Contact</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="member in employee.familyDetails" :key="member._id">
                                                <td>{{ member.name }}</td>
                                                <td>{{ member.relationship }}</td>
                                                <td>{{ member.age }}</td>
                                                <td>{{ member.occupation }}</td>
                                                <td>{{ member.contactNo }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> -->

                            <!-- Previous Names -->
                            <div v-if="employee.previousNames?.length" class="mt-4">
                                <h6>Previous Names</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Previous Name</th>
                                                <th>Changed Date</th>
                                                <th>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="name in employee.previousNames" :key="name._id">
                                                <td>{{ name.name }}</td>
                                                <td>{{ formatDate(name.changedDate) }}</td>
                                                <td>{{ name.reason }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- Document Downloads -->
                            <div class="document-downloads mt-4">
                                <h6>Documents</h6>
                                <div class="list-group">
                                    <div class="list-group-item">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>Aadhar Card {{ getDocumentStatus('aadharCard') }}</span>
                                            <button class="btn btn-sm btn-outline-primary" 
                                                    @click="downloadDocument('aadharCard')"
                                                    :disabled="!employee.documents.aadharCard?.url">
                                                <i class="bi bi-download"></i> Download
                                            </button>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>Pan Card {{ getDocumentStatus('panCard') }}</span>
                                            <button class="btn btn-sm btn-outline-primary" 
                                                    @click="downloadDocument('panCard')"
                                                    :disabled="!employee.documents.panCard?.url">
                                                <i class="bi bi-download"></i> Download
                                            </button>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>Voter Card {{ getDocumentStatus('voterCard') }}</span>
                                            <button class="btn btn-sm btn-outline-primary" 
                                                    @click="downloadDocument('voterCard')"
                                                    :disabled="!employee.documents.voterCard?.url">
                                                <i class="bi bi-download"></i> Download
                                            </button>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>Bank Passbook {{ getDocumentStatus('bankPassbook') }}</span>
                                            <button class="btn btn-sm btn-outline-primary" 
                                                    @click="downloadDocument('bankPassbook')"
                                                    :disabled="!employee.documents.bankPassbook?.url">
                                                <i class="bi bi-download"></i> Download
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4">
                                <h6>Uniform Details</h6>

                                <!-- Uniform Summary -->
                                <div class="mb-3">
                                    <div><strong>Last Issue Date:</strong> {{ formatDate(uniformData?.lastIssueDate) }}
                                    </div>
                                    <div><strong>Next Renewal Date:</strong> {{ formatDate(uniformData?.nextRenewalDate)
                                        }}</div>
                                </div>

                                <!-- Uniform Items Table -->
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="table-light">
                                            <tr>
                                                <th>Date</th>
                                                <th>Items</th>
                                                <th>Quantity</th>
                                                <th>Monthly Deduction</th>
                                                <th>Condition</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!uniformData?.uniformItems?.length">
                                                <td colspan="6" class="text-center">No uniform items found</td>
                                            </tr>
                                            <tr v-for="item in uniformData?.uniformItems" :key="item._id">
                                                <td>{{ formatDate(item.issueDate) }}</td>
                                                <td>{{ item.itemName }} ({{ item.size }})</td>
                                                <td>{{ item.quantity }}</td>
                                                <td>-</td>
                                                <td>{{ item.condition }}</td>
                                                <td>
                                                    <span :class="{
                                                        'badge bg-success': item.status === 'Issued',
                                                        'badge bg-warning': item.status === 'Pending',
                                                        'badge bg-danger': item.status === 'Returned'
                                                    }">
                                                        {{ item.status }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="mt-4">
                                <h6>Siddar Details</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="table-light">
                                            <tr>
                                                <th>Date of Siddar</th>
                                                <th>Type</th>
                                                <th>Vacant Type</th>
                                                <th>Reason</th>
                                                <th>Days Worked</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!siddarData?.length">
                                                <td colspan="6" class="text-center">No siddar records found</td>
                                            </tr>
                                            <tr v-for="record in siddarData" :key="record._id">
                                                <td>{{ formatDate(record.dateOfSiddar) }}</td>
                                                <td>{{ record.type }}</td>
                                                <td>{{ record.vacentType }}</td>
                                                <td>{{ record.reason }}</td>
                                                <td>{{ record.daysWorked }}</td>
                                                <td>
                                                    <span :class="{
                                                        'badge bg-warning': record.status === 'Pending',
                                                        'badge bg-success': record.status === 'Approved',
                                                        'badge bg-danger': record.status === 'Rejected'
                                                    }">
                                                        {{ record.status }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="mt-4">
                                <h6>Reinstate Details</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="table-light">
                                            <tr>
                                                <th>Date of Siddar</th>
                                                <th>Time of Siddar</th>
                                                <th>Siddar By</th>
                                                <th>Last Job Tenure</th>
                                                <th>Reason of Leaving</th>
                                                <th>Reason to Reinstate</th>
                                                <th>Date of Reinstate</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!reinstateData?.length">
                                                <td colspan="8" class="text-center">No reinstate records found</td>
                                            </tr>
                                            <tr v-for="record in reinstateData" :key="record._id">
                                                <td>{{ record.DateOfSiddar }}</td>
                                                <td>{{ record.TimeOfSiddar }}</td>
                                                <td>{{ record.siddarBy }}</td>
                                                <td>{{ record.lastJobTenure }} days</td>
                                                <td>{{ record.ReasonOfleaving }}</td>
                                                <td>{{ record.reasonToReinstate }}</td>
                                                <td>{{ formatDate(record.createdAt) }}</td>
                                                <td>
                                                    <span :class="{
                                                        'badge bg-warning': record.status === 'Pending',
                                                        'badge bg-success': record.status === 'Approved',
                                                        'badge bg-danger': record.status === 'Rejected'
                                                    }">
                                                        {{ record.status }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="mt-4">
                                <h6>Site Working Details</h6>
                                <div class="mb-2">
                                    <strong>Total Experience:</strong> {{ siteWorkingData?.totalExperience }}
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="table-light">
                                            <tr>
                                                <th>Duration</th>
                                                <th>Site</th>
                                                <th>Department</th>
                                                <th>Working Hours</th>
                                                <th>Performance</th>
                                                <th>Transfer Details</th>
                                                <th>Reliever Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!siteWorkingData?.records?.length">
                                                <td colspan="7" class="text-center">No working records found</td>
                                            </tr>
                                            <tr v-for="record in siteWorkingData?.records" :key="record._id">
                                                <td>
                                                    {{ formatDate(record.startDate) }} - 
                                                    {{ record.endDate ? formatDate(record.endDate) : 'Present' }}
                                                </td>
                                                <td>{{ record.siteName }}</td>
                                                <td>{{ record.departmentName }}</td>
                                                <td>{{ record.workingHours }}</td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        {{ record.performanceRating }}/5
                                                        <small class="ms-2 text-muted">{{ record.remarks }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="record.transferDetails?.transferredTo">
                                                        <small>
                                                            To: {{ record.transferDetails.transferredTo.siteName }}<br>
                                                            Date: {{ formatDate(record.transferDetails.transferredTo.transferDate) }}<br>
                                                            Reason: {{ record.transferDetails.transferredTo.reason }}
                                                        </small>
                                                    </div>
                                                    <span v-else>-</span>
                                                </td>
                                                <td>
                                                    <div v-if="record.relieverDetails">
                                                        <small>
                                                            Site: {{ record.relieverDetails.relieverSiteName }}<br>
                                                            Date: {{ formatDate(record.relieverDetails.relieverDate) }}<br>
                                                            Reason: {{ record.relieverDetails.reason }}
                                                        </small>
                                                    </div>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="mt-4">
                                <h6>Promotion Records</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="table-light">
                                            <tr>
                                                <th>Previous Position</th>
                                                <th>New Position</th>
                                                <th>Promotion Date</th>
                                                <th>Effective Date</th>
                                                <th>Type</th>
                                                <th>Approved By</th>
                                                <th>Status</th>
                                                <th>Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!promotionData?.promotions?.length">
                                                <td colspan="8" class="text-center">No promotion records found</td>
                                            </tr>
                                            <tr v-for="record in promotionData?.promotions" :key="record._id">
                                                <td class="position-details">
                                                    <div>
                                                        <strong>Title:</strong> {{ record.previousPosition.title }}
                                                    </div>
                                                    <div>
                                                        <strong>Dept:</strong> {{ record.previousPosition.department }}
                                                    </div>
                                                    <div>
                                                        <strong>Salary:</strong> ₹{{ record.previousPosition.salary }}
                                                    </div>
                                                </td>
                                                <td class="position-details">
                                                    <div>
                                                        <strong>Title:</strong> {{ record.newPosition.title }}
                                                    </div>
                                                    <div>
                                                        <strong>Dept:</strong> {{ record.newPosition.department }}
                                                    </div>
                                                    <div>
                                                        <strong>Salary:</strong> ₹{{ record.newPosition.salary }}
                                                    </div>
                                                </td>
                                                <td>{{ formatDate(record.promotionDate) }}</td>
                                                <td>{{ formatDate(record.effectiveDate) }}</td>
                                                <td>{{ record.promotionType }}</td>
                                                <td class="approver-details">
                                                    <div>{{ record.approvedBy.name }}</div>
                                                    <div class="text-muted">
                                                        {{ formatDate(record.approvedBy.date) }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <span :class="{
                                                        'badge bg-success': record.status === 'APPROVED',
                                                        'badge bg-warning': record.status === 'PENDING',
                                                        'badge bg-danger': record.status === 'REJECTED'
                                                    }">
                                                        {{ record.status }}
                                                    </span>
                                                </td>
                                                <td class="remarks-details">
                                                    <div><strong>Reason:</strong> {{ record.reason }}</div>
                                                    <div>{{ record.remarks }}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="mt-4">
                                <h6>Complaint Records</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="table-light">
                                            <tr>
                                                <th>Date</th>
                                                <th>Title</th>
                                                <th>Category</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>Attachments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!complaintData?.complaints?.length">
                                                <td colspan="6" class="text-center">No complaint records found</td>
                                            </tr>
                                            <tr v-for="complaint in complaintData?.complaints" :key="complaint._id">
                                                <td>{{ formatDate(complaint.dateSubmitted) }}</td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        {{ complaint.title }}
                                                        <span v-if="complaint.confidential" 
                                                              class="ms-2 badge bg-secondary" 
                                                              title="Confidential">
                                                            <i class="bi bi-lock-fill"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="badge bg-info">
                                                        {{ complaint.category }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <small>{{ complaint.description }}</small>
                                                </td>
                                                <td>
                                                    <span :class="{
                                                        'badge bg-warning': complaint.status === 'PENDING',
                                                        'badge bg-success': complaint.status === 'RESOLVED',
                                                        'badge bg-danger': complaint.status === 'REJECTED'
                                                    }">
                                                        {{ complaint.status }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <div v-if="complaint.attachments?.length" class="attachment-list">
                                                        <div v-for="file in complaint.attachments" 
                                                             :key="file._id" 
                                                             class="attachment-item">
                                                            <a :href="file.fileUrl" 
                                                               target="_blank" 
                                                               class="btn btn-sm btn-outline-secondary">
                                                                <i class="bi bi-file-earmark"></i>
                                                                {{ file.fileName }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="mt-4">
                                <h6>Penalty and Warning Slips</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="table-light">
                                            <tr>
                                                <th>Date</th>
                                                <th>Type</th>
                                                <th>Title</th>
                                                <th>Category</th>
                                                <th>Severity</th>
                                                <th>Penalty Details</th>
                                                <th>Status</th>
                                                <th>Attachments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!penaltyData?.data?.slips?.length">
                                                <td colspan="8" class="text-center">No penalty or warning records found</td>
                                            </tr>
                                            <tr v-for="slip in penaltyData?.data?.slips" :key="slip._id">
                                                <td>{{ formatDate(slip.dateIssued) }}</td>
                                                <td>
                                                    <span :class="{
                                                        'badge bg-danger': slip.type === 'PENALTY',
                                                        'badge bg-warning': slip.type === 'WARNING'
                                                    }">
                                                        {{ slip.type }}
                                                    </span>
                                                </td>
                                                <td>{{ slip.title }}</td>
                                                <td>
                                                    <span class="badge bg-info">{{ slip.category }}</span>
                                                </td>
                                                <td>
                                                    <span :class="{
                                                        'badge bg-danger': slip.severity === 'HIGH',
                                                        'badge bg-warning': slip.severity === 'MODERATE',
                                                        'badge bg-info': slip.severity === 'LOW'
                                                    }">
                                                        {{ slip.severity }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <small>
                                                        <div v-if="slip.penalty">
                                                            <strong>Type:</strong> {{ slip.penalty.type }}<br>
                                                            <strong>Amount:</strong> ₹{{ slip.penalty.amount }}<br>
                                                            <strong>Details:</strong> {{ slip.penalty.details }}
                                                        </div>
                                                    </small>
                                                </td>
                                                <td>
                                                    <div class="d-flex flex-column gap-1">
                                                        <small>
                                                            <strong>Acknowledged:</strong> 
                                                            {{ slip.acknowledgement.acknowledged ? 'Yes' : 'No' }}
                                                        </small>
                                                        <small>
                                                            <strong>Appeal:</strong> 
                                                            <span :class="{
                                                                'badge bg-secondary': slip.appealStatus === 'NONE',
                                                                'badge bg-warning': slip.appealStatus === 'PENDING',
                                                                'badge bg-success': slip.appealStatus === 'APPROVED',
                                                                'badge bg-danger': slip.appealStatus === 'REJECTED'
                                                            }">
                                                                {{ slip.appealStatus }}
                                                            </span>
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="slip.attachments?.length" class="attachment-list">
                                                        <div v-for="file in slip.attachments" 
                                                             :key="file._id" 
                                                             class="attachment-item">
                                                            <a :href="file.fileUrl" 
                                                               target="_blank" 
                                                               class="btn btn-sm btn-outline-secondary">
                                                                <i class="bi bi-file-earmark"></i>
                                                                {{ file.fileName }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { format, addMonths as dateAddMonths } from 'date-fns';
import { fetchGetApi } from '@/util/api';
import { useRoute } from 'vue-router';

const toast = useToast();

interface Document {
    type: string;
    status: 'pending' | 'uploaded' | 'verified';
    url?: string;
}

interface PenaltySlip {
    date: string;
    siteName: string;
    penaltySlip: string;
    amount: number;
    penaltyReason: string;
    warningSlip: string;
    warningReason: string;
    good: boolean;
}

interface Employee {
    id: number;
    photoUrl: string;
    name: string;
    doj: string;
    dob: string;
    siddarDate: string;
    reason: string;
    jobLeft: string;
    blacklisted: boolean;
    blacklistReason?: string;
    documents: {
        aadharCard?: { url: string };
        panCard?: { url: string };
        voterCard?: { url: string };
        bankPassbook?: { url: string };
    };
    documentsList: Document[];
    penaltySlips: PenaltySlip[];
    workingRecords: WorkingRecord[];
    reinstateRecords: ReinstateRecord[];
    reInstantDate: string;
    reInstantReason: string;
    complaints: Complaint[];
    uniformRecords: UniformRecord[];
    promotionRecords: PromotionRecord[];
    reinstateInfo: ReinstateInfo;
}

interface WorkingRecord {
    id: number;
    startDate: string;
    siteName: string;
    department: string;
    transferTo: string;
    relieverDate: string;
    relieverSiteName: string;
}

interface ReinstateRecord {
    id: number;
    doj: string;
    site: string;
    department: string;
    post: string;
    siddarTypeAndDate: string;
    reason: string;
    leftOn: string;
    siddarBy: string;
    reinstateBy: string;
}

interface Complaint {
    id: number;
    date: string;
    offenceCategory: string;
    subCategory: string;
    warningType: string;
    hasSlip: boolean;
    by: string;
}

interface UniformDeductions {
    month1: number;
    month2: number;
    month3: number;
}

interface UniformRecord {
    id: number;
    date: string;
    itemsIssued: string;
    amountTotal: number;
    deductions: UniformDeductions;
    badDebt: number;
    reason: string;
    uniformReturn: boolean;
    originalType: string;
    docReturnDate: string;
}

interface PromotionRecord {
    id: number;
    doj: string;
    siteName: string;
    department: string;
    post: string;
}

interface ReinstateInfo {
    by: string;
    date: string;
    siteName: string;
    reason: string;
}

// Add these interfaces for the API response
interface ApiResponse {
    success: boolean;
    data: EmployeeApiData;
}

interface EmployeeApiData {
    experience: {
        details: string;
        documents: any[];
    };
    legalInformation: {
        criminalOffence: {
            exists: boolean;
            cases: any[];
        };
        pendingProceedings: {
            exists: boolean;
            cases: any[];
        };
    };
    addressVerification: {
        familyVerification: Array<{
            memberName: string;
            relationship: string;
            contactNo: string;
            verifiedBy: any[];
            _id: string;
        }>;
        friendsVerification: Array<{
            memberName: string;
            relationship: string;
            contactNo: string;
            verifiedBy: any[];
            _id: string;
        }>;
    };
    documents: {
        signature?: DocumentFile;
        passportPhoto?: DocumentFile;
        panCard?: DocumentFile;
        aadharCard?: DocumentFile;
        voterCard?: DocumentFile;
        bankPassbook?: DocumentFile;
    };
    _id: string;
    employeePrejoinToken: string;
    employeeName: string;
    employeeCode: string;
    fathersName: string;
    dateOfBirth: string;
    education: string;
    character: string;
    medicalCategory: string;
    languagesKnown: string[];
    sex: string;
    permanentAddress: Address;
    localAddress: Address;
    currentPlace: string;
    familyDetails: FamilyMember[];
    previousNames: PreviousName[];
    employeeType: string;
    email: string;
    isUniformGiven: boolean;
    status: string;
    blacklisted: boolean;
    createdAt: string;
    updatedAt: string;
}

interface Address {
    village: string;
    postOffice: string;
    policeStation: string;
    district: string;
    state: string;
    pin: string;
    phoneNumber: string;
    alternatePhoneNumber: string;
    alternatePhoneRelation: string;
    _id: string;
}

interface FamilyMember {
    name: string;
    relationship: string;
    age: number;
    occupation: string;
    contactNo: string;
    _id: string;
}

interface PreviousName {
    name: string;
    changedDate: string;
    reason: string;
    _id: string;
}

interface DocumentFile {
    key: string;
    url: string;
    _id: string;
}

// Add interface for uniform data
interface UniformItem {
    itemName: string;
    size: string;
    quantity: number;
    issueDate: string;
    condition: string;
    status: string;
    _id: string;
}

interface UniformData {
    _id: string;
    employeeId: {
        _id: string;
        employeeName: string;
        employeeCode: string;
    };
    employeeName: string;
    departmentId: {
        _id: string;
        name: string;
    };
    siteId: {
        _id: string;
        siteName: string;
    };
    uniformItems: UniformItem[];
    status: string;
    lastIssueDate: string;
    nextRenewalDate: string;
    createdAt: string;
    updatedAt: string;
}

// Add this interface
interface SiddarRecord {
    _id: string;
    dateOfSiddar: string;
    type: string;
    vacentType: string;
    reason: string;
    daysWorked: number;
    status: string;
    statusOfSiddar: string;
    dressNotReturnedReason: string;
    deadlineToFillUp: string;
}

// Add this interface
interface ReinstateRecord {
    _id: string;
    empId: string;
    empName: string;
    lastSite: string;
    Department: string;
    Designation: string;
    ReasonOfleaving: string;
    DateOfSiddar: string;
    TimeOfSiddar: string;
    siddarByEmpId: string;
    siddarBy: string;
    lastJobTenure: string;
    numberOfReinstate: string;
    loseToCompany: string;
    reasonToReinstate: string;
    status: string;
    createdAt: string;
    updatedAt: string;
}

// Add these interfaces
interface TransferDetails {
    transferredTo: {
        siteId: string;
        siteName: string;
        transferDate: string;
        reason: string;
    };
}

interface RelieverDetails {
    relieverDate: string;
    relieverSiteId: string;
    relieverSiteName: string;
    reason: string;
}

// Add these interfaces
interface WorkingRecord {
    _id: string;
    startDate: string;
    endDate: string | null;
    siteId: string;
    siteName: string;
    departmentId: string;
    departmentName: string;
    salary: number;
    workingHours: string;
    performanceRating: number;
    remarks: string;
    transferDetails?: TransferDetails;
    relieverDetails?: RelieverDetails;
}

interface SiteWorkingData {
    _id: string;
    employeeId: string;
    employeeName: string;
    records: WorkingRecord[];
    totalExperience: string;
    createdAt: string;
    updatedAt: string;
}

// Add these interfaces
interface Position {
    title: string;
    department: string;
    salary: number;
}

interface Approver {
    name: string;
    date: string;
}

interface Promotion {
    _id: string;
    previousPosition: Position;
    newPosition: Position;
    approvedBy: Approver;
    promotionDate: string;
    effectiveDate: string;
    promotionType: string;
    reason: string;
    status: string;
    remarks: string;
}

interface PromotionData {
    _id: string;
    employeeId: string;
    employeeName: string;
    promotions: Promotion[];
    createdAt: string;
    updatedAt: string;
}

// Add these interfaces
interface Attachment {
    _id: string;
    fileName: string;
    fileUrl: string;
}

interface Complaint {
    _id: string;
    title: string;
    description: string;
    category: string;
    status: string;
    confidential: boolean;
    attachments: Attachment[];
    dateSubmitted: string
}

interface ComplaintData {
    _id: string;
    employeeId: string;
    employeeName: string;
    complaints: Complaint[];
    createdAt: string;
    updatedAt: string;
}

// Add these interfaces
interface PenaltyDetails {
    type: string;
    amount: number;
    details: string;
}

interface Acknowledgement {
    acknowledged: boolean;
    comments: string;
}

interface AppealDetails {
    date: string | null;
    description: string;
    decision: string;
    decidedBy: string | null;
}

interface PenaltyAttachment {
    _id: string;
    fileName: string;
    fileUrl: string;
    uploadDate: string;
}

interface PenaltySlip {
    _id: string;
    type: string;
    title: string;
    description: string;
    category: string;
    severity: string;
    dateIssued: string;
    issuedBy: string;
    penalty: PenaltyDetails;
    acknowledgement: Acknowledgement;
    appealDetails: AppealDetails;
    attachments: PenaltyAttachment[];
    appealStatus: string;
}

interface PenaltyData {
    status: string;
    data: {
        _id: string;
        employeeId: string;
        employeeName: string;
        slips: PenaltySlip[];
        createdAt: string;
        updatedAt: string;
    };
}

const baseUrl = process.env.VUE_APP_BASE_URL
// Update the setup script
const route = useRoute();
const employeeId = route.params.id as string;

// Modify the employee ref to include API data
const employee = ref<Employee>({
    id: 1,
    photoUrl: '',
    name: 'John Doe',
    doj: '2024-01-15',
    dob: '1990-05-20',
    siddarDate: '2024-02-01',
    reason: 'Regular employment',
    jobLeft: 'No',
    blacklisted: false,
    documents: {
        aadharCard: { url: '' },
        panCard: { url: '' },
        voterCard: { url: '' },
        bankPassbook: { url: '' }
    },
    documentsList: [
        { type: 'joiningForm', status: 'verified' },
        { type: 'declaration', status: 'verified' },
        { type: 'scans', status: 'verified' },
        { type: 'pvSent', status: 'verified' },
        { type: 'pvReturn', status: 'pending' },
        { type: 'esicDoc', status: 'uploaded' },
        { type: 'esicSigned', status: 'pending' },
        { type: 'resignationSlip', status: 'pending' }
    ],
    penaltySlips: [
        {
            date: '2024-02-15',
            siteName: 'Site A',
            penaltySlip: 'PS001',
            amount: 500,
            penaltyReason: 'Late arrival',
            warningSlip: 'WS001',
            warningReason: 'Repeated late arrivals',
            good: false
        }
    ],
    workingRecords: [
        {
            id: 1,
            startDate: '2024-01-15',
            siteName: 'Site 1',
            department: 'Security',
            transferTo: 'Site 2',
            relieverDate: '2024-02-01',
            relieverSiteName: 'Site 3'
        }
    ],
    reinstateRecords: [
        {
            id: 1,
            doj: '2024-01-15',
            site: 'Site A',
            department: 'Security',
            post: 'Guard',
            siddarTypeAndDate: 'Type A - 2024-02-01',
            reason: 'Performance',
            leftOn: '2024-01-30',
            siddarBy: 'John Manager',
            reinstateBy: 'Jane Supervisor'
        }
    ],
    reInstantDate: '2024-02-15',
    reInstantReason: 'Good past performance',
    complaints: [
        {
            id: 1,
            date: '2024-02-10',
            offenceCategory: 'Attendance',
            subCategory: 'Late arrival',
            warningType: 'Verbal',
            hasSlip: true,
            by: 'Site Supervisor'
        }
    ],
    uniformRecords: [
        {
            id: 1,
            date: '2024-01-15',
            itemsIssued: 'Uniform Set, Shoes',
            amountTotal: 2500,
            deductions: {
                month1: 800,
                month2: 800,
                month3: 900
            },
            badDebt: 0,
            reason: 'Regular issue',
            uniformReturn: false,
            originalType: 'Standard Issue',
            docReturnDate: '2024-04-15'
        }
    ],
    promotionRecords: [
        {
            id: 1,
            doj: '2024-01-15',
            siteName: 'Site A',
            department: 'Security',
            post: 'Senior Guard'
        }
    ],
    reinstateInfo: {
        by: 'John Manager',
        date: '2024-02-15',
        siteName: 'Site B',
        reason: 'Performance improvement'
    }
});

// Add loading state
const loading = ref(true);

// Add fetch function
const fetchEmployeeData = async () => {
    try {
        loading.value = true;
        const response = await fetchGetApi<ApiResponse>(`api/joining/employee/${employeeId}`);
        const apiData = response.data.data;

        employee.value = {
            // Basic Info
            photoUrl: apiData.documents.passportPhoto?.url || '',
            name: apiData.employeeName,
            employeeCode: apiData.employeeCode,
            fathersName: apiData.fathersName,
            dob: apiData.dateOfBirth,
            education: apiData.education,
            character: apiData.character,
            medicalCategory: apiData.medicalCategory,
            languagesKnown: apiData.languagesKnown,
            sex: apiData.sex,
            blacklisted: apiData.blacklisted,
            doj: apiData.createdAt,

            // Documents
            documents: {
                aadharCard: apiData.documents.aadharCard,
                panCard: apiData.documents.panCard,
                voterCard: apiData.documents.voterCard,
                bankPassbook: apiData.documents.bankPassbook,
            },

            // Address
            permanentAddress: apiData.permanentAddress,

            // Family Details
            familyDetails: apiData.familyDetails,

            // Previous Names
            previousNames: apiData.previousNames,

            // Initialize empty arrays for other sections that aren't in the API
            penaltySlips: [],
            workingRecords: [],
            reinstateRecords: [],
            complaints: [],
            uniformRecords: [],
            promotionRecords: []
        };

    } catch (error) {
        toast.error('Failed to fetch employee data');
        console.error('Error fetching employee data:', error);
    } finally {
        loading.value = false;
    }
};

// Add ref for uniform data
const uniformData = ref<UniformData | null>(null);

// Add function to fetch uniform data
const fetchUniformData = async () => {
    try {
        const response = await fetchGetApi(`api/uniform/employee/${employeeId}`);
        uniformData.value = response.data;
    } catch (error) {
        console.error('Error fetching uniform data:', error);
        toast.error('Failed to fetch uniform data');
    }
};

// Add this ref
const siddarData = ref<SiddarRecord[]>([]);

// Add this function to fetch siddar data
const fetchSiddarData = async () => {
    try {
        const response = await fetchGetApi(`api/siddar/get/employeeId/${employeeId}`);
        siddarData.value = response.data;
    } catch (error) {
        console.error('Error fetching siddar data:', error);
        toast.error('Failed to fetch siddar data');
    }
};

// Add this ref
const reinstateData = ref<ReinstateRecord[]>([]);

// Add this function to fetch reinstate data
const fetchReinstateData = async () => {
    try {
        const response = await fetchGetApi(`api/reinstate/get/${employeeId}`);
        reinstateData.value = response.data;
    } catch (error) {
        console.error('Error fetching reinstate data:', error);
        toast.error('Failed to fetch reinstate data');
    }
};

// Add this ref
const siteWorkingData = ref<SiteWorkingData | null>(null);

// Add this function to fetch site working data
const fetchSiteWorkingData = async () => {
    try {
        const response = await fetchGetApi(`api/siteWorkingRecord/employee/${employeeId}`);
        siteWorkingData.value = response.data;
    } catch (error) {
        console.error('Error fetching site working data:', error);
        toast.error('Failed to fetch site working data');
    }
};

// Add this ref
const promotionData = ref<PromotionData | null>(null);

// Add this function to fetch promotion data
const fetchPromotionData = async () => {
    try {
        const response = await fetchGetApi(`api/promotion-record/employee/${employeeId}`);
        promotionData.value = response.data;
    } catch (error) {
        console.error('Error fetching promotion data:', error);
        toast.error('Failed to fetch promotion data');
    }
};

// Add this ref
const complaintData = ref<ComplaintData | null>(null);

// Add this function to fetch complaint data
const fetchComplaintData = async () => {
    try {
        const response = await fetchGetApi(`api/complaint-record/employee/${employeeId}`);
        complaintData.value = response.data;
    } catch (error) {
        console.error('Error fetching complaint data:', error);
        toast.error('Failed to fetch complaint data');
    }
};

// Add this ref
const penaltyData = ref<PenaltyData | null>(null);

// Add this function to fetch penalty data
const fetchPenaltyData = async () => {
    try {
        const response = await fetchGetApi(`api/penalty-warning-slip/${employeeId}`);
        penaltyData.value = response.data;
    } catch (error) {
        console.error('Error fetching penalty data:', error);
        toast.error('Failed to fetch penalty and warning slip data');
    }
};

// Update onMounted to include the new fetch
onMounted(() => {
    fetchEmployeeData();
    fetchUniformData();
    fetchSiddarData();
    fetchReinstateData();
    fetchSiteWorkingData();
    fetchPromotionData();
    fetchComplaintData();
    fetchPenaltyData();
});

const formatDate = (date: string) => {
    if (!date) return '';
    return format(new Date(date), 'dd/MM/yyyy');
};

const getDocumentStatus = (docType: 'aadharCard' | 'panCard' | 'voterCard' | 'bankPassbook') => {
    return employee.value.documents[docType]?.url ? '(Available)' : '(Not Available)';
};

const downloadDocument = async (docType: 'aadharCard' | 'panCard' | 'voterCard' | 'bankPassbook') => {
    try {
        const docUrl = employee.value.documents[docType]?.url;
        if (docUrl) {
            window.open(docUrl, '_blank');
        } else {
            toast.error('Document not available');
        }
    } catch (error) {
        console.error(`Error downloading ${docType}:`, error);
        toast.error(`Failed to download ${docType}`);
    }
};

// Add this helper function
const addMonths = (date: string, months: number) => {
    if (!date) return '';
    return format(dateAddMonths(new Date(date), months), 'dd/MM/yyyy');
};

// Add this function in the script section
const downloadPhoto = async () => {
    try {
        if (employee.value.photoUrl) {
            window.open(employee.value.photoUrl, '_blank');
        } else {
            toast.error('Photo not available');
        }
    } catch (error) {
        console.error('Error downloading photo:', error);
        toast.error('Failed to download photo');
    }
};
</script>

<style scoped>
.card {
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.employee-photo img {
    max-width: 200px;
    height: auto;
    border-radius: 8px;
}

.document-downloads .list-group-item {
    cursor: pointer;
    transition: all 0.2s;
}

.document-downloads .list-group-item:hover:not(:disabled) {
    background-color: #f8f9fa;
}

.document-downloads .list-group-item:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.table th {
    background-color: #f8f9fa;
    white-space: nowrap;
    font-size: 0.875rem;
}

.table td {
    vertical-align: middle;
    font-size: 0.875rem;
}

.bi {
    font-size: 1.1rem;
}

.text-warning {
    color: #ffc107;
}

.text-info {
    color: #17a2b8;
}

.text-success {
    color: #28a745;
}

.table-secondary {
    background-color: #e2e3e5;
}

.form-check-inline {
    margin: 0;
    justify-content: center;
}

@media (max-width: 768px) {
    .card-body {
        padding: 1rem;
    }

    .table {
        font-size: 0.8rem;
    }

    .employee-photo img {
        max-width: 150px;
    }
}

/* Add these styles */
.table-light {
    background-color: #f8f9fa;
}

.badge {
    font-size: 0.875rem;
    padding: 0.25em 0.6em;
}

.table th {
    white-space: nowrap;
    font-weight: 600;
}

.table td {
    vertical-align: middle;
}

.table {
  font-size: 0.875rem;
}

.table th {
  white-space: nowrap;
  font-weight: 600;
  background-color: #f8f9fa;
  vertical-align: middle;
}

.table td {
  vertical-align: middle;
}

/* Make the table horizontally scrollable on mobile */
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px) {
  .table {
    font-size: 0.75rem;
  }
}

.badge {
    text-transform: capitalize;
}

.table td {
    vertical-align: middle;
}

.badge.bg-warning {
    color: #000;
}

.table td {
    font-size: 0.875rem;
    padding: 0.5rem;
}

.table th {
    font-size: 0.875rem;
    padding: 0.5rem;
    white-space: nowrap;
}

/* Add to existing styles */
.table small {
    font-size: 0.8rem;
    display: block;
    line-height: 1.4;
}

.text-muted {
    color: #6c757d !important;
}

/* Make the table more compact for better readability */
.table td, .table th {
    padding: 0.5rem;
    font-size: 0.875rem;
}

/* Ensure proper wrapping of longer content */
.table td {
    white-space: normal;
    min-width: 120px;
}

/* Add to existing styles */
.table small {
    font-size: 0.8rem;
    line-height: 1.4;
}

.table td small strong {
    color: #6c757d;
}

.badge {
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 0.25em 0.6em;
}

.table td {
    vertical-align: middle;
    padding: 0.75rem;
}

/* Ensure proper wrapping for salary and other details */
.table td small div {
    margin-bottom: 0.25rem;
}

/* Add to existing styles */
.attachment-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.attachment-item .btn {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    text-decoration: none;
    width: 100%;
    text-align: left;
}

.attachment-item .btn i {
    margin-right: 0.5rem;
}

.badge.bg-info {
    background-color: #17a2b8 !important;
    color: white;
}

.badge.bg-secondary {
    font-size: 0.7rem;
    padding: 0.2em 0.4em;
}

/* Ensure description doesn't break layout */
.table td small {
    display: block;
    max-height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.badge {
    text-transform: uppercase;
    font-size: 0.75rem;
}

.table td small {
    font-size: 0.8rem;
    line-height: 1.4;
}

.table td small strong {
    color: #6c757d;
}

.gap-1 {
    gap: 0.25rem !important;
}

.badge.bg-warning {
    color: #000;
}

.attachment-item .btn {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
}

.attachment-item .btn i {
    margin-right: 0.25rem;
}

/* Update the Promotion Records table section */
.table td {
    vertical-align: middle;
    padding: 0.75rem;
    white-space: normal;
}

.position-details div,
.approver-details div,
.remarks-details div {
    margin-bottom: 0.25rem;
    line-height: 1.4;
}

.position-details div:last-child,
.approver-details div:last-child,
.remarks-details div:last-child {
    margin-bottom: 0;
}

.position-details strong,
.remarks-details strong {
    color: #495057;
    font-weight: 600;
}

.text-muted {
    font-size: 0.875rem;
}

.badge {
    text-transform: uppercase;
    padding: 0.4em 0.6em;
}

/* Ensure table cells don't collapse */
.table td {
    min-width: 120px;
}

/* Make the table scroll horizontally on small screens */
.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.btn-outline-primary {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.btn-outline-primary:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.list-group-item .d-flex {
    gap: 1rem;
}

.bi-download {
    margin-right: 0.25rem;
}
</style>