import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row mb-4" }
const _hoisted_5 = { class: "col-md-4" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = ["value"]
const _hoisted_8 = {
  key: 0,
  class: "table-responsive"
}
const _hoisted_9 = { class: "table table-bordered" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "modal-dialog modal-lg" }
const _hoisted_12 = { class: "modal-content" }
const _hoisted_13 = { class: "modal-header" }
const _hoisted_14 = { class: "modal-title" }
const _hoisted_15 = { class: "modal-body" }
const _hoisted_16 = { class: "table-responsive" }
const _hoisted_17 = { class: "table table-bordered" }
const _hoisted_18 = { colspan: "5" }

import { ref, computed, watch, onMounted } from 'vue';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { useToast } from 'vue-toast-notification';
import { fetchGetApi } from '@/util/api';


export default /*@__PURE__*/_defineComponent({
  __name: 'stock-report',
  setup(__props) {

const toast = useToast();
const showModal = ref(false);
const selectedDepartment = ref<{ _id: string; name: string } | null>(null);
const selectedItem = ref<any>(null);
const selectedItemDetails = ref<any[]>([]);

const departments = ref<Array<{ _id: string; name: string }>>([]);

// Stock data organized by department
const stockData = ref<Array<{
    _id: string;
    itemName: string;
    totalQuantity: number;
    sizes: Array<{
        size: string;
        quantity: number;
        additionalPrice: number;
    }>;
}>>([]);

const baseUrl = process.env.VUE_APP_BASE_URL

// Add function to fetch departments
const fetchDepartments = async () => {
    try {
        const response = await fetchGetApi(`api/department/getAll`);
        departments.value = response.data;
    } catch (error) {
        toast.error('Failed to fetch departments');
        console.error(error);
    }
};

// Add function to fetch products
const fetchProducts = async (departmentId: string) => {
    try {
        const response = await fetchGetApi(`api/product/department/${departmentId}`);
        stockData.value = response.data.data;
    } catch (error) {
        toast.error('Failed to fetch products');
        console.error(error);
    }
};

// Update the computed property:
const filteredStockItems = computed(() => {
    if (!selectedDepartment.value) return [];
    return stockData.value.map(item => ({
        name: item.itemName,
        quantity: item.totalQuantity,
        _id: item._id,
        sizes: item.sizes
    }));
});

// Add watch for selectedDepartment:
watch(selectedDepartment, async (newDept) => {
    if (newDept?._id) {
        await fetchProducts(newDept._id);
    } else {
        stockData.value = [];
    }
});

// Add onMounted hook:
onMounted(async () => {
    await fetchDepartments();
});

const showDetailedStock = async (item: any) => {
    selectedItem.value = item;
    // Mock detailed data - replace with actual API call
    selectedItemDetails.value = [
        { 
            size: 'S', 
            quantity: 10, 
            condition: 'New', 
            location: 'Main Store',
            status: 'In Stock',
            lastUpdated: new Date() 
        },
        { 
            size: 'M', 
            quantity: 15, 
            condition: 'Used', 
            location: 'Main Store',
            status: 'In Stock',
            lastUpdated: new Date() 
        },
        { 
            size: 'L', 
            quantity: 20, 
            condition: 'New', 
            location: 'Site Store',
            status: 'Reserved',
            lastUpdated: new Date() 
        },
        { 
            size: 'XL', 
            quantity: 5, 
            condition: 'Damaged', 
            location: 'Main Store',
            status: 'In Laundry',
            lastUpdated: new Date() 
        }
    ];
    showModal.value = true;
};

const closeModal = () => {
    showModal.value = false;
    selectedItem.value = null;
};

const formatDate = (date: Date) => {
    return format(date, 'dd-MM-yyyy HH:mm');
};

const getConditionClass = (condition: string) => {
    const classes = {
        'New': 'badge bg-success',
        'Used': 'badge bg-warning',
        'Damaged': 'badge bg-danger'
    };
    return classes[condition as keyof typeof classes] || 'badge bg-secondary';
};

const getStatusClass = (status: string) => {
    const classes = {
        'In Stock': 'badge bg-success',
        'Reserved': 'badge bg-warning',
        'In Laundry': 'badge bg-info'
    };
    return classes[status as keyof typeof classes] || 'badge bg-secondary';
};

const getTotalQuantity = () => {
    return selectedItemDetails.value.reduce((total, item) => total + item.quantity, 0);
};

const downloadExcel = () => {
    if (!selectedDepartment.value) {
        toast.error('Please select a department first');
        return;
    }

    const worksheet = XLSX.utils.json_to_sheet(filteredStockItems.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${selectedDepartment.value.name} Stock`);
    XLSX.writeFile(workbook, `${selectedDepartment.value.name}_stock_report.xlsx`);
};

const downloadDetailedExcel = () => {
    if (!selectedItem.value) return;

    const worksheet = XLSX.utils.json_to_sheet(selectedItemDetails.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${selectedItem.value.name} Details`);
    XLSX.writeFile(workbook, `${selectedItem.value.name}_detailed_stock.xlsx`);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", { class: "card-header d-flex justify-content-between align-items-center" }, [
        _cache[1] || (_cache[1] = _createElementVNode("h5", null, "Stock Report - Department wise", -1)),
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: downloadExcel
        }, " Download Excel ")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, "Select Department", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "form-select",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedDepartment).value = $event))
              }, [
                _cache[2] || (_cache[2] = _createElementVNode("option", { value: null }, "Choose Department", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(departments.value, (dept) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: dept._id,
                    value: dept
                  }, _toDisplayString(dept.name), 9, _hoisted_7))
                }), 128))
              ], 512), [
                [_vModelSelect, selectedDepartment.value]
              ])
            ])
          ])
        ]),
        (selectedDepartment.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-muted mb-2" }, "(multiple selections can be done)", -1)),
              _createElementVNode("table", _hoisted_9, [
                _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "Name of the uniform parts"),
                    _createElementVNode("th", null, "Total Quantity")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredStockItems.value, (item) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: item.name
                    }, [
                      _createElementVNode("td", null, _toDisplayString(item.name), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("button", {
                          class: "btn btn-link",
                          onClick: ($event: any) => (showDetailedStock(item))
                        }, _toDisplayString(item.quantity), 9, _hoisted_10)
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (showModal.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["modal fade", { 'show': showModal.value }])
        }, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("h5", _hoisted_14, " Detailed Stock Report - " + _toDisplayString(selectedItem.value?.name) + " (" + _toDisplayString(selectedDepartment.value?.name) + ") ", 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn-close",
                  onClick: closeModal
                })
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("table", _hoisted_17, [
                    _cache[8] || (_cache[8] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Size"),
                        _createElementVNode("th", null, "Quantity"),
                        _createElementVNode("th", null, "Condition"),
                        _createElementVNode("th", null, "Location"),
                        _createElementVNode("th", null, "Last Updated"),
                        _createElementVNode("th", null, "Status")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedItemDetails.value, (detail) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: detail.size
                        }, [
                          _createElementVNode("td", null, _toDisplayString(detail.size), 1),
                          _createElementVNode("td", null, _toDisplayString(detail.quantity), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("span", {
                              class: _normalizeClass(getConditionClass(detail.condition))
                            }, _toDisplayString(detail.condition), 3)
                          ]),
                          _createElementVNode("td", null, _toDisplayString(detail.location), 1),
                          _createElementVNode("td", null, _toDisplayString(formatDate(detail.lastUpdated)), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("span", {
                              class: _normalizeClass(getStatusClass(detail.status))
                            }, _toDisplayString(detail.status), 3)
                          ])
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("tfoot", null, [
                      _createElementVNode("tr", null, [
                        _cache[7] || (_cache[7] = _createElementVNode("td", null, [
                          _createElementVNode("strong", null, "Total")
                        ], -1)),
                        _createElementVNode("td", _hoisted_18, [
                          _createElementVNode("strong", null, _toDisplayString(getTotalQuantity()), 1),
                          _cache[6] || (_cache[6] = _createTextVNode(" pieces "))
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", { class: "modal-footer" }, [
                _createElementVNode("button", {
                  class: "btn btn-secondary",
                  onClick: closeModal
                }, "Close"),
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  onClick: downloadDetailedExcel
                }, " Download Detailed Report ")
              ])
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})