import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'
import { showNotification, requestNotificationPermission, getNotificationPermission, isNotificationsSupported } from '@/utils/notifications'

export const useWorkApprovalStore = defineStore('workApproval', () => {
  const pendingCount = ref(0)
  const pendingFieldEmployeeDayCount = ref(0)
  const pendingMarketingKytCount = ref(0)
  const pendingLeaveCount = ref(0)
  const pendingCdwCount = ref(0)
  const baseUrl = process.env.VUE_APP_BASE_URL
  const refreshInterval = ref<number | null>(null)
  const isLoading = ref(false)
  const lastUpdateTime = ref<Date | null>(null)
  const error = ref<string | null>(null)
  const hasNotificationPermission = ref(false)
  const notificationStatus = ref<'unsupported' | 'default' | 'granted' | 'denied'>('default')

  // Initialize notification permission
  async function initializeNotifications() {
    try {
      // First check if notifications are supported
      if (!isNotificationsSupported()) {
        notificationStatus.value = 'unsupported'
        console.warn('Notifications are not supported in this browser')
        return false
      }

      // Check current permission status
      const currentPermission = getNotificationPermission()
      notificationStatus.value = currentPermission
      console.log('Current notification permission:', currentPermission)
      
      // If already granted, we're good
      if (currentPermission === 'granted') {
        hasNotificationPermission.value = true
        // Show a test notification to verify it works
        showNotification('Notifications Active', {
          body: 'You will receive notifications for new pending approvals',
          tag: 'notification-test',
          icon: '/src/assets/images/mass-logo.png',
          soundUntilClick: false // Don't use continuous sound for test notification
        })
        return true
      }

      // If denied, we can't do anything
      if (currentPermission === 'denied') {
        console.warn('Notification permission was denied')
        return false
      }

      // Request permission if not granted or denied
      console.log('Requesting notification permission...')
      const granted = await requestNotificationPermission()
      hasNotificationPermission.value = granted
      notificationStatus.value = getNotificationPermission()
      
      if (granted) {
        // Show welcome notification
        showNotification('Notifications Enabled', {
          body: 'You will now receive notifications for new pending approvals',
          tag: 'notification-enabled',
          icon: '/src/assets/images/mass-logo.png',
          soundUntilClick: false // Don't use continuous sound for welcome notification
        })
      } else {
        console.warn('Permission not granted after request')
      }

      return granted
    } catch (error) {
      notificationStatus.value = 'denied'
      hasNotificationPermission.value = false
      return false
    }
  }

  // Start auto-refresh when store is initialized
  function startAutoRefresh(intervalMs: number = 30000) {
    // Ensure interval is at least 1 second
    const safeInterval = Math.max(1000, intervalMs)
    
    if (refreshInterval.value) {
      clearInterval(refreshInterval.value)
    }
    
    // Request notification permission when starting auto-refresh
    initializeNotifications()
    
    // Initial fetch
    fetchPendingCount()
    
    // Set up periodic fetching
    refreshInterval.value = window.setInterval(() => {
      fetchPendingCount()
    }, safeInterval)

  }

  // Stop auto-refresh
  function stopAutoRefresh() {
    if (refreshInterval.value) {
      clearInterval(refreshInterval.value)
      refreshInterval.value = null
    }
  }

  async function fetchPendingCount() {
    try {
      isLoading.value = true
      error.value = null
      const response = await axios.get(`${baseUrl}api/admin/counts/all`)
      
      if (response.data.success) {
        const oldCounts = {
          work: pendingCount.value,
          fieldEmployee: pendingFieldEmployeeDayCount.value,
          marketing: pendingMarketingKytCount.value,
          leave: pendingLeaveCount.value,
          cdw: pendingCdwCount.value
        }

        // Update counts
        pendingCount.value = response.data.data.pendingWork.pendingWorkCount
        pendingFieldEmployeeDayCount.value = response.data.data.pendingFieldEmployeeDay.pendingFieldEmployeeDayCount
        pendingMarketingKytCount.value = response.data.data.pendingMarketingChangeAndTransfer.pendingMarketingChangeAndTransferCount
        pendingLeaveCount.value = response.data.data.pendingLeave.pendingLeaveCount
        pendingCdwCount.value = response.data.data.pendingCdw.pendingCdwCount

        // Check which counts increased
        const increases = {
          work: pendingCount.value > oldCounts.work,
          fieldEmployee: pendingFieldEmployeeDayCount.value > oldCounts.fieldEmployee,
          marketing: pendingMarketingKytCount.value > oldCounts.marketing,
          leave: pendingLeaveCount.value > oldCounts.leave,
          cdw: pendingCdwCount.value > oldCounts.cdw
        }

        // If any count increased and we have permission, show notification
        const hasIncreases = Object.values(increases).some(increased => increased)
        
        if (hasIncreases && hasNotificationPermission.value) {
          // Create notification message
          const newItems = []
          if (increases.work) newItems.push(`Work Approvals (${pendingCount.value})`)
          if (increases.fieldEmployee) newItems.push(`Field Employee Days (${pendingFieldEmployeeDayCount.value})`)
          if (increases.marketing) newItems.push(`Marketing KYT (${pendingMarketingKytCount.value})`)
          if (increases.leave) newItems.push(`Leaves (${pendingLeaveCount.value})`)
          if (increases.cdw) newItems.push(`CDW (${pendingCdwCount.value})`)

          const notificationMessage = `New items pending:\n${newItems.join('\n')}`
          
          // Force request permission if needed
          if (notificationStatus.value === 'default') {
            await initializeNotifications()
          }

          if (notificationStatus.value === 'granted') {
            showNotification('New Pending Approvals', {
              body: notificationMessage,
              tag: 'pending-approval-' + Date.now(), // Unique tag to allow multiple notifications
              icon: '/src/assets/images/mass-logo.png',
              soundUntilClick: true
            })
          }
        }

        lastUpdateTime.value = new Date()


      }
    } catch (err: any) {
      error.value = err?.message || 'Failed to fetch pending counts'
      pendingCount.value = 0
      pendingFieldEmployeeDayCount.value = 0
      pendingMarketingKytCount.value = 0
      pendingLeaveCount.value = 0
      pendingCdwCount.value = 0
    } finally {
      isLoading.value = false
    }
  }

  function setPendingCount(count: number) {
    const oldCount = pendingCount.value
    console.log('Setting pending count:', count)
    pendingCount.value = count || 0
    if (count > oldCount) {
      showNotification('New Pending Work Approvals', {
        body: `You have ${count} pending work approvals`,
        tag: 'pending-work',
        icon: '/src/assets/images/mass-logo.png',
        soundUntilClick: true
      })
      lastUpdateTime.value = new Date()
    }
  }

  function setPendingFieldEmployeeDayCount(count: number) {
    const oldCount = pendingFieldEmployeeDayCount.value
    console.log('Setting pending field employee day count:', count)
    pendingFieldEmployeeDayCount.value = count || 0
    if (count > oldCount) {
      showNotification('New Field Employee Days', {
        body: `You have ${count} pending field employee days`,
        tag: 'pending-field-employee',
        icon: '/src/assets/images/mass-logo.png',
        soundUntilClick: true
      })
      lastUpdateTime.value = new Date()
    }
  }

  function setPendingMarketingKytCount(count: number) {
    const oldCount = pendingMarketingKytCount.value
    console.log('Setting pending marketing KYT count:', count)
    pendingMarketingKytCount.value = count || 0
    if (count > oldCount) {
      showNotification('New Marketing KYT Items', {
        body: `You have ${count} pending marketing KYT items`,
        tag: 'pending-marketing',
        icon: '/src/assets/images/mass-logo.png',
        soundUntilClick: true
      })
      lastUpdateTime.value = new Date()
    }
  }

  function setPendingLeaveCount(count: number) {
    const oldCount = pendingLeaveCount.value
    console.log('Setting pending leave count:', count)
    pendingLeaveCount.value = count || 0
    if (count > oldCount) {
      showNotification('New Leave Requests', {
        body: `You have ${count} pending leave requests`,
        tag: 'pending-leave',
        icon: '/src/assets/images/mass-logo.png',
        soundUntilClick: true
      })
      lastUpdateTime.value = new Date()
    }
  }

  return {
    pendingCount,
    pendingFieldEmployeeDayCount,
    pendingMarketingKytCount,
    pendingLeaveCount,
    pendingCdwCount,
    fetchPendingCount,
    setPendingCount,
    setPendingFieldEmployeeDayCount,
    setPendingMarketingKytCount,
    setPendingLeaveCount,
    startAutoRefresh,
    stopAutoRefresh,
    isLoading,
    lastUpdateTime,
    error,
    hasNotificationPermission,
    initializeNotifications,
    notificationStatus
  }
}) 