<template>
    <Breadcrumbs main="Apps" title="Quotation" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <QuotationList />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const QuotationList = defineAsyncComponent(() => import("@/components/quotation/list.vue"))
</script>