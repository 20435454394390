<template>
    <Breadcrumbs main="Documents" title="Return Documents" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <return-document />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const ReturnDocument = defineAsyncComponent(() => import("@/components/document/return-document/return-document-main.vue"))
</script>