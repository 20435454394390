<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import * as XLSX from 'xlsx';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const getAuthToken = () => localStorage.getItem('token');
const baseUrl = process.env.VUE_APP_BASE_URL;

onMounted(async () => {
    try {
        const token = getAuthToken();
        const response = await fetch(`${baseUrl}api/operation/materialRequest/getAll`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();
        console.log('API Response:', data);

        if (data && data.success && data.data) {
            allData.value = data.data;
            console.log('Data loaded successfully:', allData.value);
            if (allData.value.length === 0) {
                toast.warning('No records found');
            }
        } else {
            console.log('Invalid response structure:', data);
            toast.error('Invalid data format received');
        }
    } catch (error) {
        console.error('API Error:', error);
        toast.error('Error fetching data');
    }
});

// ... pagination functions remain the same ...

// Add these refs for individual filters
const filters = ref({
    reqDate: '',
    site: '',
    manager: '',
    departments: '',
    expenses: '',
    category: '',
    subCategories: '',
    preferredDate: '',
    urgentReason: '',
    status: ''
});

const filteredData = computed(() => {
    const data = Array.isArray(allData.value) ? allData.value : [];
    
    const filtered = data.filter(item => {
        // Helper function to safely check if a string includes the query
        const includes = (str, query) => String(str || '').toLowerCase().includes(query.toLowerCase());
        
        // Check each filter
        const matchesReqDate = !filters.value.reqDate || 
            (item.reqDate && new Date(item.reqDate).toLocaleDateString().includes(filters.value.reqDate));
            
        const matchesSite = !filters.value.site || 
            includes(item.siteId?.siteName, filters.value.site);
            
        const matchesManager = !filters.value.manager || 
            includes(item.reqManagerId?.employeeName, filters.value.manager);
            
        const matchesDepartments = !filters.value.departments || 
            item.departments?.some(dept => includes(dept.name, filters.value.departments));
            
        const matchesExpenses = !filters.value.expenses || 
            includes(item.expenses, filters.value.expenses);
            
        const matchesCategory = !filters.value.category || 
            includes(item.category, filters.value.category);
            
        const matchesSubCategories = !filters.value.subCategories || 
            item.subCategories?.some(subCat => 
                includes(subCat.name, filters.value.subCategories) || 
                subCat.items?.some(subItem => includes(subItem.name, filters.value.subCategories))
            );
            
        const matchesPreferredDate = !filters.value.preferredDate || 
            (item.preferredDate && new Date(item.preferredDate).toLocaleDateString().includes(filters.value.preferredDate));
            
        const matchesUrgentReason = !filters.value.urgentReason || 
            includes(item.urgentReason, filters.value.urgentReason);
            
        const matchesStatus = !filters.value.status || 
            includes(item.status, filters.value.status);
        
        return matchesReqDate && matchesSite && matchesManager && matchesDepartments && 
               matchesExpenses && matchesCategory && matchesSubCategories && 
               matchesPreferredDate && matchesUrgentReason && matchesStatus;
    });
    
    return filtered.slice(
        (currentPage.value - 1) * elementsPerPage.value,
        currentPage.value * elementsPerPage.value
    );
});

function downloadExcel() {
    const worksheet = XLSX.utils.json_to_sheet(allData.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Material Requests");
    XLSX.writeFile(workbook, 'material-requests.xlsx');
}

function num_pages() {
    const query = filterQuery.value.toLowerCase().trim();
    const data = Array.isArray(allData.value) ? allData.value : [];
    
    if (!query) return Math.ceil(data.length / elementsPerPage.value);
    
    const filtered = data.filter(item => {
        const includes = (str) => String(str || '').toLowerCase().includes(query);
        
        return (
            includes(item.category) ||
            includes(item.status) ||
            includes(item.expenses) ||
            includes(item.urgentReason) ||
            (item.reqDate && new Date(item.reqDate).toLocaleDateString().includes(query)) ||
            (item.preferredDate && new Date(item.preferredDate).toLocaleDateString().includes(query)) ||
            includes(item.siteId?.siteName) ||
            includes(item.reqManagerId?.employeeName) ||
            item.departments?.some(dept => includes(dept.name)) ||
            item.subCategories?.some(subCat => 
                includes(subCat.name) || 
                subCat.items?.some(subItem => 
                    includes(subItem.name) ||
                    includes(subItem.size) ||
                    includes(subItem.quantity) ||
                    includes(subItem.empId?.employeeName)
                )
            )
        );
    });
    
    return Math.ceil(filtered.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

async function acceptRequest(id) {
    try {
        const token = getAuthToken();
        const response = await fetch(`${baseUrl}api/operation/materialRequest/accept/${id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();
        
        if (data.success) {
            toast.success('Material request accepted successfully');
            // Refresh the data
            const updatedItem = allData.value.find(item => item._id === id);
            if (updatedItem) {
                updatedItem.status = 'Approved';
            }
        } else {
            toast.error(data.message || 'Failed to accept request');
        }
    } catch (error) {
        console.error('Error accepting request:', error);
        toast.error('Error accepting request');
    }
}

async function rejectRequest(id) {
    try {
        const token = getAuthToken();
        const response = await fetch(`${baseUrl}api/operation/materialRequest/reject/${id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();
        
        if (data.success) {
            toast.success('Material request rejected successfully');
            // Refresh the data
            const updatedItem = allData.value.find(item => item._id === id);
            if (updatedItem) {
                updatedItem.status = 'Rejected';
            }
        } else {
            toast.error(data.message || 'Failed to reject request');
        }
    } catch (error) {
        console.error('Error rejecting request:', error);
        toast.error('Error rejecting request');
    }
}

// Add this function to clear all filters
function clearFilters() {
    filters.value = {
        reqDate: '',
        site: '',
        manager: '',
        departments: '',
        expenses: '',
        category: '',
        subCategories: '',
        preferredDate: '',
        urgentReason: '',
        status: ''
    };
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Material Request" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer" @submit.prevent>
                        <!-- Search and Download buttons -->
                

                        <!-- Filter section -->
                        <div class="mb-3">
                            <div class="row g-3">
                                <!-- Request Date Filter -->
                                <div class="col-md-2">
                                    <label class="form-label">Request Date</label>
                                    <input type="date" class="form-control" v-model="filters.reqDate">
                                </div>
                                
                                <!-- Site Filter -->
                                <div class="col-md-2">
                                    <label class="form-label">Site</label>
                                    <input type="text" class="form-control" v-model="filters.site" placeholder="Filter site...">
                                </div>
                                
                                <!-- Manager Filter -->
                                <div class="col-md-2">
                                    <label class="form-label">Manager</label>
                                    <input type="text" class="form-control" v-model="filters.manager" placeholder="Filter manager...">
                                </div>
                                
                                <!-- Status Filter -->
                                <div class="col-md-2">
                                    <label class="form-label">Status</label>
                                    <select class="form-select" v-model="filters.status">
                                        <option value="">All</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Approved">Approved</option>
                                        <option value="Rejected">Rejected</option>
                                    </select>
                                </div>
                                
                                <!-- Clear Filters Button -->
                                <div class="col-md-2 d-flex align-items-end">
                                    <button type="button" class="btn btn-secondary" @click="clearFilters">
                                        Clear Filters
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Table -->
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col">SL</th>
                                    <th class="sorting_asc" scope="col">Request Date</th>
                                    <th class="sorting_asc" scope="col">Site</th>
                                    <th class="sorting_asc" scope="col">Manager</th>
                                    <th class="sorting_asc" scope="col">Departments</th>
                                    <th class="sorting_asc" scope="col">Expenses</th>
                                    <th class="sorting_asc" scope="col">Category</th>
                                    <th class="sorting_asc" scope="col">Sub Categories</th>
                                    <th class="sorting_asc" scope="col">Preferred Date</th>
                                    <th class="sorting_asc" scope="col">Urgent Reason</th>
                                    <th class="sorting_asc" scope="col">Status</th>
                                    <th class="sorting_asc" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="!filteredData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="11" class="dataTables_empty">
                                        No matching records found
                                        <br>
                                        <small class="text-muted">Debug: Total records: {{ allData.length }}</small>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, index) in filteredData" :key="item._id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.reqDate ? new Date(item.reqDate).toLocaleDateString() : '-' }}</td>
                                    <td>{{ item.siteId?.siteName || '-' }}</td>
                                    <td>{{ item.reqManagerId?.employeeName || '-' }}</td>
                                    <td>
                                        <span v-for="(dept, idx) in item.departments" :key="dept._id">
                                            {{ dept.name }}{{ idx < item.departments.length - 1 ? ', ' : '' }}
                                        </span>
                                    </td>
                                    <td>{{ item.expenses || '-' }}</td>
                                    <td>{{ item.category || '-' }}</td>
                                    <td>
                                        <div v-if="item.subCategories?.length">
                                            <div v-for="subCat in item.subCategories" :key="subCat._id" class="mb-2">
                                                <strong>{{ subCat.name }}:</strong>
                                                <ul class="list-unstyled mb-1">
                                                    <li v-for="subItem in subCat.items" :key="subItem._id" class="small">
                                                        {{ subItem.name }} 
                                                        <br>
                                                        <small class="text-muted">
                                                            Qty: {{ subItem.quantity }}, 
                                                            Size: {{ subItem.size }},
                                                            For: {{ subItem.empId?.employeeName || 'N/A' }}
                                                        </small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <span v-else>-</span>
                                    </td>
                                    <td>{{ item.preferredDate ? new Date(item.preferredDate).toLocaleDateString() : '-' }}</td>
                                    <td>{{ item.urgentReason || '-' }}</td>
                                    <td>
                                        <span :class="{
                                            'badge rounded-pill bg-success': item.status === 'Approved',
                                            'badge rounded-pill bg-warning': item.status === 'Pending',
                                            'badge rounded-pill bg-danger': item.status === 'Rejected'
                                        }">{{ item.status || 'Unknown' }}</span>
                                    </td>
                                    <td>
                                        <div v-if="item.status === 'Pending'" class="d-flex gap-1">
                                            <button class="btn btn-primary" @click="acceptRequest(item._id)">Accept</button>
                                            <button class="btn btn-danger" @click="rejectRequest(item._id)">Reject</button>
                                        </div>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- Pagination -->
                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" 
                                v-for="i in num_pages()" 
                                :key="i" 
                                :class="[i == currentPage ? 'active' : '']" 
                                @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>