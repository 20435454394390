<script setup>
import { ref, computed, onMounted } from 'vue';
import { fetchGetApi } from "@/util/api";
import { useToast } from 'vue-toast-notification';
import { jsonToExcel } from "@/util/makeExcel";

// Filter refs
const siteFilter = ref('');
const designationFilter = ref('');
const areaFilter = ref('');
const competitorFilter = ref('');

// Data ref
const socData = ref([]);
const designations = computed(() => {
    // Get all designation strings and split them into individual designations
    const allDesignations = socData.value
        .map(item => item.designation.split(','))
        .flat()
        .map(d => d.trim())
        .filter(d => d); // Remove empty strings
    
    // Get unique designations
    return [...new Set(allDesignations)];
});

const competitors = computed(() => {
    // Extract all agency names from the formatted strings
    const allCompetitors = socData.value
        .map(item => {
            // Split by comma to get each service type's agency
            const parts = item.agencyName.split(',');
            return parts.map(part => {
                // Extract agency name after the colon
                const match = part.split(':')[1];
                return match ? match.trim() : '';
            });
        })
        .flat()
        .filter(name => name && name !== 'N/A'); // Remove empty strings and N/A

    // Get unique competitors
    return [...new Set(allCompetitors)].sort();
});

const $toast = useToast();

// Fetch data function
async function fetchSocData() {
    try {
        const response = await fetchGetApi('api/reports/soc-analysis');

        socData.value = response.data;
    } catch (error) {
        $toast.error('Failed to fetch SOC data');
        console.error('Error fetching SOC data:', error);
    }
}

// Fetch data on component mount
onMounted(() => {
    fetchSocData();
});

// Computed filtered data
const filteredData = computed(() => {
    return socData.value.filter(item => {
        const matchesSite = !siteFilter.value ||
            item.projectName.toLowerCase().includes(siteFilter.value.toLowerCase());
        const matchesDesignation = !designationFilter.value || 
            item.designation.toLowerCase().includes(designationFilter.value.toLowerCase());
        const matchesArea = !areaFilter.value ||
            item.area.toLowerCase().includes(areaFilter.value.toLowerCase());
        const matchesCompetitor = !competitorFilter.value || 
            item.agencyName.split(',').some(part => {
                const agencyName = part.split(':')[1]?.trim();
                return agencyName === competitorFilter.value;
            });

        return matchesSite && matchesDesignation && matchesArea && matchesCompetitor;
    });
});

function clearFilters() {
    siteFilter.value = '';
    designationFilter.value = '';
    areaFilter.value = '';
    competitorFilter.value = '';
}

function downloadExcel() {
    if (!filteredData.value.length) {
        $toast.error('No data available to download');
        return;
    }

    const dataToExport = filteredData.value.map((item, index) => ({
        'SL': item.SL || index + 1,
        'Date': item.date || 'N/A',
        'Report Type': item.reportType || 'N/A',
        'Reported By': item.reportedBy || 'N/A',
        'Area': item.area || 'N/A',
        'Project Name': item.projectName || 'N/A',
        'Mode of Service': item.modeOfService || 'N/A',
        'Designation': item.designation || 'N/A',
        'Service Wise Manpower': item.serviceWiseManpower || 'N/A',
        'Competitor Name': item.agencyName || 'N/A',
        'Total Manpower': item.totalManpower || 'N/A',
        'Working Hour': item.workingHour || 'N/A',
        'Minimum Wages': item.minimumWages || 'N/A',
        'Salary': item.salary || 'N/A',
        'Food': item.food || 'N/A',
        'Stay': item.stay || 'N/A',
        'ESIC/PF': item.esicPf || 'N/A',
        'Weekly Off': item.weeklyOff || 'N/A',
        'Agreement Duration': item.agreementDuration || 'N/A',
        'Special Mention': item.specialMention || 'N/A'
    }));

    const timestamp = new Date().toISOString().split('T')[0];
    jsonToExcel(dataToExport, `soc-analysis-report-${timestamp}.xlsx`);
    $toast.success('Excel file downloaded successfully');
}
</script>

<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <!-- Filters Section -->
                <div class="row mb-4">
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Site Filter</label>
                        <input type="text" class="form-control" v-model="siteFilter" placeholder="Filter by site">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Designation Filter</label>
                        <select class="form-select" v-model="designationFilter">
                            <option value="">All Designations</option>
                            <option v-for="designation in designations" 
                                    :key="designation" 
                                    :value="designation">
                                {{ designation }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Area Filter</label>
                        <input type="text" class="form-control" v-model="areaFilter" placeholder="Filter by area">
                    </div>
                    <div class="col-md-2 mb-2">
                        <label class="form-label">Competitor Filter</label>
                        <select class="form-select" v-model="competitorFilter">
                            <option value="">All Competitors</option>
                            <option v-for="competitor in competitors" 
                                    :key="competitor" 
                                    :value="competitor">
                                {{ competitor }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button class="btn btn-secondary" @click="clearFilters">Clear</button>
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button type="button" class="btn btn-primary" @click="downloadExcel" :disabled="!filteredData.length">
                            <i class="fas fa-download me-1"></i>
                            Download Excel
                        </button>
                    </div>
                </div>

                <!-- Table Section -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="width: 60px">Sl no</th>
                                <th style="width: 100px">Date</th>
                                <th style="width: 100px">Report type</th>
                                <th style="width: 120px">Reported by</th>
                                <th class="area-column" style="width: 150px">Area</th>
                                <th style="width: 150px">Project name</th>
                                <th class="mode-service-column" style="width: 180px">Mode of Service</th>
                                <th style="width: 150px">Designation</th>
                                <th style="width: 200px">Service wise manpower</th>
                                <th style="width: 180px">Competitor Name</th>
                                <th style="width: 100px">Total manpower</th>
                                <th style="width: 150px">Working hour</th>
                                <th style="width: 150px">Minimum wages</th>
                                <th style="width: 150px">Salary</th>
                                <th style="width: 150px">Food</th>
                                <th style="width: 150px">Stay</th>
                                <th style="width: 150px">ESIC/PF</th>
                                <th style="width: 150px">Weekly off</th>
                                <th style="width: 180px">Agreement Duration</th>
                                <th style="width: 180px">Special mention</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredData" :key="index">
                                <td>{{ item.SL }}</td>
                                <td>{{ item.date }}</td>
                                <td>{{ item.reportType }}</td>
                                <td>{{ item.reportedBy }}</td>
                                <td class="area-column">{{ item.area }}</td>
                                <td>{{ item.projectName }}</td>
                                <td class="mode-service-column">{{ item.modeOfService.split(', ').join('\n') }}</td>
                                <td>{{ item.designation }}</td>
                                <td>{{ item.serviceWiseManpower }}</td>
                                <td>{{ item.agencyName }}</td>
                                <td>{{ item.totalManpower }}</td>
                                <td>{{ item.workingHour }}</td>
                                <td>{{ item.minimumWages }}</td>
                                <td>{{ item.salary }}</td>
                                <td>{{ item.food }}</td>
                                <td>{{ item.stay }}</td>
                                <td>{{ item.esicPf }}</td>
                                <td>{{ item.weeklyOff }}</td>
                                <td>{{ item.agreementDuration }}</td>
                                <td>{{ item.specialMention }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.table {
    table-layout: fixed;
    width: 100%;
}

.table th,
.table td {
    vertical-align: middle;
    font-size: 0.875rem;
    padding: 0.5rem;
}

.table th.mode-service-column,
.table td.mode-service-column {
    width: 180px !important;
    max-width: 180px !important;
    min-width: 180px !important;
    white-space: pre-line !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}

.table th.area-column,
.table td.area-column {
    width: 150px !important;
    max-width: 150px !important;
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}

.form-label {
    font-size: 0.875rem;
    font-weight: 500;
}

.btn-xs {
    padding: 0.2rem 0.4rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
</style>