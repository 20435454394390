<!-- src/components/site-management/NewSiteForm.vue -->
<template>
    <div class="site-form-container">
        <h4 class="mb-4">Convert Site</h4>
        <form @submit.prevent="handleSubmit" class="needs-validation">
            <!-- Basic Site Information -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Basic Site Information</h5>
                </div>
                <div class="card-body">
                    <BasicSiteInfo v-model:formData="formData.basicInfo" @file-upload="handleFileUpload" />
                </div>
            </div>

            <!-- Workforce Details -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Workforce Details</h5>
                </div>
                <div class="card-body">
                    <WorkforceDetails v-model:formData="formData.workforce" />
                </div>
            </div>

            <!-- SOP Section -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Standard Operating Procedures</h5>
                </div>
                <div class="card-body">
                    <SopSection v-model:sops="formData.sops" />
                </div>
            </div>

            <!-- Work Order Section -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Work Order Documents</h5>
                </div>
                <div class="card-body">
                    <WorkOrderSection v-model:workOrders="formData.workOrders" />
                </div>
            </div>

            <!-- Client Requirements -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Client Requirements</h5>
                </div>
                <div class="card-body">
                    <ClientRequirements v-model:requirements="formData.clientRequirements" />
                </div>
            </div>

            <!-- Billing Details -->
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Billing Details</h5>
                </div>
                <div class="card-body">
                    <BillingDetails v-model:billing="formData.billing" />
                </div>
            </div>

            <!-- Submit Button -->
            <div class="text-end">
                <button type="submit" class="btn btn-primary" :disabled="isSubmitting">
                    {{ isSubmitting ? 'Converting Site...' : 'Convert Site' }}
                </button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, watch, computed } from 'vue';
import { useToast } from 'vue-toast-notification';
import BasicSiteInfo from './basic-site-info.vue';
import WorkforceDetails from './work-force-details.vue';
import SopSection from './sop-section.vue';
import WorkOrderSection from './work-order-section.vue';
import ClientRequirements from './client-requirements.vue';
import BillingDetails from './billing-details.vue';
import { useRouter } from 'vue-router';
import { fetchPostApi, fetchGetApi } from '@/util/api';
import { SubDesignationDetail, SOP } from '@/types/site';

const toast = useToast();
const isSubmitting = ref(false);
const router = useRouter();
const baseUrl = process.env.VUE_APP_BASE_URL;

interface WorkforceDetails {
    manpower: { male: number; female: number };
    weeklyOff: { male: boolean; female: boolean };
    workingHours: { male: number; female: number };
    esiEpf: { male: boolean; female: boolean };
    food: { male: boolean; female: boolean };
    accommodation: { male: boolean; female: boolean };
    deadline: { male: string; female: string };
    inHandSalary: { male: number; female: number };
    quotedRate: { male: number; female: number };
    serviceStartDate: { male: string; female: string };
}

interface Designation {
    designationName: string;
    details: WorkforceDetails;
}

interface Service {
    serviceName: string;
    designations: Designation[];
}

interface WorkforceData {
    totalManpower: number;
    workingPlace: string;
    services: Service[];
    subDesignationDetails: Record<string, WorkforceDetails>;
}

interface FormData {
    basicInfo: {
        siteId: string;
        siteName: string;
        quotation: {
            type: string;
            file: File | null;
        };
        workOrder: {
            type: string;
            file: File | null;
        };
        client: {
            name: string;
            designation: string;
            phone: string;
            altPhone: string;
            email: string;
        };
        otherContact: {
            name: string;
            designation: string;
            phone: string;
            altPhone: string;
            email: string;
        };
        officeAddress: string;
        siteAddress: string;
    };
    workforce: WorkforceData;
    sops: Array<{ question: string; answer: string }>;
    workOrders: File[];
    clientRequirements: {
        specificRequirements: string;
        serviceStartDate: {
            male: string;
            female: string;
        };
    };
    billing: {
        type: string;
        gstNumber: string;
    };
}

const formData = reactive<FormData>({
    basicInfo: {
        siteId: '',
        siteName: '',
        quotation: {
            type: '',
            file: null
        },
        workOrder: {
            type: '',
            file: null
        },
        client: {
            name: '',
            designation: '',
            phone: '',
            altPhone: '',
            email: ''
        },
        otherContact: {
            name: '',
            designation: '',
            phone: '',
            altPhone: '',
            email: ''
        },
        officeAddress: '',
        siteAddress: ''
    },
    workforce: {
        totalManpower: 0,
        workingPlace: '',
        services: [],
        subDesignationDetails: {}
    },
    sops: [],
    workOrders: [],
    clientRequirements: {
        specificRequirements: '',
        serviceStartDate: {
            male: '',
            female: ''
        }
    },
    billing: {
        type: '',
        gstNumber: ''
    }
});

watch(() => formData, (newVal) => {
    console.log('Form Data Updated:', newVal);
}, { deep: true });

const calculateTotalManpower = computed(() => {
    let total = 0;
    formData.workforce.services.forEach(service => {
        service.designations.forEach(designation => {
            const detailsKey = `${service.serviceName}-${designation.designationName}`;
            const details = formData.workforce.subDesignationDetails[detailsKey];
            if (details?.manpower) {
                total += (parseInt(details.manpower.male?.toString() || '0') || 0) +
                        (parseInt(details.manpower.female?.toString() || '0') || 0);
            }
        });
    });
    formData.workforce.totalManpower = total;
    return total;
});

const validateForm = () => {
    try {
        if (!formData.basicInfo.siteId) {
            toast.error('Site selection is required');
            return false;
        }
        
        if (!formData.basicInfo.officeAddress) {
            toast.error('Office address is required');
            return false;
        }
        
        if (!formData.basicInfo.siteAddress) {
            toast.error('Site address is required');
            return false;
        }

        if (!formData.workforce.workingPlace) {
            toast.error('Working place is required');
            return false;
        }

        if (!formData.workforce.services || formData.workforce.services.length === 0) {
            toast.error('At least one service with designations is required');
            return false;
        }

        // Check if each service has at least one designation with details
        for (const service of formData.workforce.services) {
            if (!service.designations || service.designations.length === 0) {
                toast.error(`Please select at least one designation for service: ${service.serviceName}`);
                return false;
            }

            // Check if each designation has details
            for (const designation of service.designations) {
                const detailsKey = `${service.serviceName}-${designation.designationName}`;
                const details = formData.workforce.subDesignationDetails[detailsKey];

                if (!details) {
                    toast.error(`Please fill in details for ${designation.designationName} under ${service.serviceName}`);
                    return false;
                }

                // Validate manpower details
                if (!details.manpower || 
                    (typeof details.manpower.male !== 'number' && typeof details.manpower.female !== 'number') || 
                    (details.manpower.male === 0 && details.manpower.female === 0)) {
                    toast.error(`Please specify manpower for ${designation.designationName} under ${service.serviceName}`);
                    return false;
                }

                // Validate working hours
                if (!details.workingHours || 
                    (details.workingHours.male === 0 && details.workingHours.female === 0)) {
                    toast.error(`Please specify working hours for ${designation.designationName} under ${service.serviceName}`);
                    return false;
                }

                // Validate service start date
                if (!details.serviceStartDate || 
                    (!details.serviceStartDate.male && !details.serviceStartDate.female)) {
                    toast.error(`Please specify service start date for ${designation.designationName} under ${service.serviceName}`);
                    return false;
                }
            }
        }

        if (!formData.billing.type) {
            toast.error('Billing type is required');
            return false;
        }

        return true;
    } catch (error) {
        console.error('Validation error:', error);
        toast.error('Error validating form data');
        return false;
    }
};

const handleSubmit = async () => {
    try {
        if (!validateForm()) {
            return;
        }

        isSubmitting.value = true;
        const submitFormData = new FormData();

        // Add site ID
        submitFormData.append('siteId', formData.basicInfo.siteId);

        // Basic Site Information
        submitFormData.append('officeAddress', formData.basicInfo.officeAddress);
        submitFormData.append('siteAddress', formData.basicInfo.siteAddress);
        submitFormData.append('quotationType', formData.basicInfo.quotation.type);
        submitFormData.append('workOrderType', formData.basicInfo.workOrder.type);

        // Client Information
        const clientData = {
            name: formData.basicInfo.client.name,
            designation: formData.basicInfo.client.designation,
            phone: formData.basicInfo.client.phone,
            email: formData.basicInfo.client.email,
            altPhone: formData.basicInfo.client.altPhone
        };
        submitFormData.append('client', JSON.stringify(clientData));

        // Contact Person Information (if provided)
        if (formData.basicInfo.otherContact.name) {
            const contactPersonData = {
                name: formData.basicInfo.otherContact.name,
                designation: formData.basicInfo.otherContact.designation,
                phone: formData.basicInfo.otherContact.phone,
                email: formData.basicInfo.otherContact.email,
                altPhone: formData.basicInfo.otherContact.altPhone
            };
            submitFormData.append('contactPerson', JSON.stringify(contactPersonData));
        }

        // Workforce Details with new structure
        const workforceData = {
            totalManpower: calculateTotalManpower.value,
            workingPlace: formData.workforce.workingPlace,
            services: formData.workforce.services.map(service => ({
                serviceName: service.serviceName,
                designations: service.designations.map(designation => {
                    const detailsKey = `${service.serviceName}-${designation.designationName}`;
                    const details = formData.workforce.subDesignationDetails[detailsKey];
                    
                    if (!details) {
                        console.warn(`Details not found for ${designation.designationName} under ${service.serviceName}`);
                        return {
                            designationName: designation.designationName,
                            details: {
                                manpower: { male: 0, female: 0 },
                                weeklyOff: { male: false, female: false },
                                workingHours: { male: 0, female: 0 },
                                esiEpf: { male: false, female: false },
                                food: { male: false, female: false },
                                accommodation: { male: false, female: false },
                                deadline: { male: '', female: '' },
                                inHandSalary: { male: 0, female: 0 },
                                quotedRate: { male: 0, female: 0 },
                                serviceStartDate: { male: '', female: '' }
                            }
                        };
                    }

                    return {
                        designationName: designation.designationName,
                        details: {
                            manpower: details.manpower || { male: 0, female: 0 },
                            weeklyOff: details.weeklyOff || { male: false, female: false },
                            workingHours: details.workingHours || { male: 0, female: 0 },
                            esiEpf: details.esiEpf || { male: false, female: false },
                            food: details.food || { male: false, female: false },
                            accommodation: details.accommodation || { male: false, female: false },
                            deadline: details.deadline || { male: '', female: '' },
                            inHandSalary: details.inHandSalary || { male: 0, female: 0 },
                            quotedRate: details.quotedRate || { male: 0, female: 0 },
                            serviceStartDate: details.serviceStartDate || { male: '', female: '' }
                        }
                    };
                })
            }))
        };
        submitFormData.append('workforce', JSON.stringify(workforceData));

        // SOPs
        const sopsData = formData.sops.map(sop => ({
            question: sop.question,
            answer: sop.answer
        }));
        submitFormData.append('sops', JSON.stringify(sopsData));

        // Client Requirements
        const clientRequirementsData = {
            specificRequirements: formData.clientRequirements.specificRequirements,
            serviceStartDate: formData.clientRequirements.serviceStartDate
        };
        submitFormData.append('clientRequirements', JSON.stringify(clientRequirementsData));

        // Billing Details
        const billingData = {
            type: formData.billing.type,
            gstNumber: formData.billing.gstNumber
        };
        submitFormData.append('billing', JSON.stringify(billingData));

        // File Uploads
        if (formData.basicInfo.quotation.file) {
            submitFormData.append('quotation', formData.basicInfo.quotation.file);
        }

        if (formData.basicInfo.workOrder.file) {
            submitFormData.append('workOrder', formData.basicInfo.workOrder.file);
        }

        // Additional Work Orders
        if (formData.workOrders.length > 0) {
            formData.workOrders.forEach(file => {
                submitFormData.append('additionalWorkOrders', file);
            });
        }

        // Make API call
        const response = await fetch(`${baseUrl}api/temp/add/site`, {
            method: 'POST',
            body: submitFormData,
            headers: {
                ...(localStorage.getItem('token') ? {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                } : {})
            }
        });

        const data = await response.json();

        if (data.success) {
            toast.success('Site converted successfully');
            router.push('/site-creation/list');
        } else {
            throw new Error(data.message || 'Error converting site');
        }
    } catch (error: any) {
        console.error('Error converting site:', error);
        toast.error(error.message || 'Error converting site');
    } finally {
        isSubmitting.value = false;
    }
};

const handleFileUpload = (type: string, file: File) => {
    if (type === 'quotation') {
        formData.basicInfo.quotation.file = file;
    } else if (type === 'workOrder') {
        formData.basicInfo.workOrder.file = file;
    }
};
</script>

<style scoped>
.site-form-container {
    padding: 20px;
}

.card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
}

.card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.btn-primary {
    min-width: 120px;
}

/* Add bold outlines to all form inputs */
:deep(input),
:deep(select),
:deep(textarea) {
    border: 2px solid #ced4da !important;
    outline: none !important;
}

:deep(input:focus),
:deep(select:focus),
:deep(textarea:focus) {
    border-color: #86b7fe !important;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}
</style>