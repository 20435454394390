<!-- src/components/site-management/sections/BasicSiteInfo.vue -->
<template>
    <div class="basic-info">
        <div class="row">
            <!-- Site Name Dropdown -->
            <div class="col-md-12 mb-3">
                <label class="form-label">Select Site*</label>
                <select class="form-select" v-model="selectedSiteId" required @change="handleSiteChange">
                    <option value="">Select Site</option>
                    <option 
                        v-for="site in nonConvertedSites" 
                        :key="site.id" 
                        :value="site.id"
                    >
                        {{ site.label }}
                    </option>
                </select>
            </div>

            <!-- Quotation -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Quotation Type*</label>
                <select class="form-select" v-model="localFormData.quotation.type" required>
                    <option value="">Select Type</option>
                    <option value="mail">Mail</option>
                    <option value="hardcopy">Hardcopy</option>
                </select>
                <div v-if="localFormData.quotation.type === 'hardcopy'" class="mt-2">
                    <input type="file" class="form-control" accept=".pdf" @change="handleQuotationUpload" />
                </div>
            </div>

            <!-- Work Order -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Work Order Type*</label>
                <select class="form-select" v-model="localFormData.workOrder.type" required>
                    <option value="">Select Type</option>
                    <option value="mail">Mail</option>
                    <option value="hardcopy">Hardcopy</option>
                </select>
                <div v-if="localFormData.workOrder.type === 'hardcopy'" class="mt-2">
                    <input type="file" class="form-control" accept=".pdf" @change="handleWorkOrderUpload" />
                </div>
            </div>

            <!-- Client Information -->
            <div class="col-12 mb-4">
                <h6>Client Information</h6>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Client Name*</label>
                        <input type="text" class="form-control" v-model="localFormData.client.name" required />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Client Designation*</label>
                        <input type="text" class="form-control" v-model="localFormData.client.designation" required />
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Phone Number*</label>
                        <input type="tel" class="form-control" v-model="localFormData.client.phone" required />
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Alternative Phone Number</label>
                        <input type="tel" class="form-control" v-model="localFormData.client.altPhone" />
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Email Address*</label>
                        <input type="email" class="form-control" v-model="localFormData.client.email" required />
                    </div>
                </div>
            </div>

            <!-- Other Important Person -->
            <div class="col-12 mb-4">
                <h6>Other Important Person Details</h6>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control" v-model="localFormData.otherContact.name" />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Designation</label>
                        <input type="text" class="form-control" v-model="localFormData.otherContact.designation" />
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Phone Number</label>
                        <input type="tel" class="form-control" v-model="localFormData.otherContact.phone" />
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Alternative Phone Number</label>
                        <input type="tel" class="form-control" v-model="localFormData.otherContact.altPhone" />
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Email Address</label>
                        <input type="email" class="form-control" v-model="localFormData.otherContact.email" />
                    </div>
                </div>
            </div>

            <!-- Addresses -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Office Address*</label>
                <textarea class="form-control" v-model="localFormData.officeAddress" rows="3" required></textarea>
            </div>

            <div class="col-md-6 mb-3">
                <label class="form-label">Site Address*</label>
                <textarea class="form-control" v-model="localFormData.siteAddress" rows="3" required></textarea>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch, ref, onMounted } from 'vue';
import { fetchGetApi } from '@/util/api';

interface Site {
    id: string;
    label: string;
}

interface BasicSiteInfoProps {
    formData: {
        siteId: string;
        siteName: string;
        quotation: {
            type: string;
            file: File | null;
        };
        workOrder: {
            type: string;
            file: File | null;
        };
        client: {
            name: string;
            designation: string;
            phone: string;
            altPhone: string;
            email: string;
        };
        otherContact: {
            name: string;
            designation: string;
            phone: string;
            altPhone: string;
            email: string;
        };
        officeAddress: string;
        siteAddress: string;
    };
}

const props = defineProps<BasicSiteInfoProps>();
const emit = defineEmits(['update:formData', 'file-upload']);

const localFormData = reactive({ ...props.formData });
const nonConvertedSites = ref<Site[]>([]);
const selectedSiteId = ref('');

const handleSiteChange = () => {
    const selectedSite = nonConvertedSites.value.find(site => site.id === selectedSiteId.value);
    if (selectedSite) {
        localFormData.siteId = selectedSite.id;
        localFormData.siteName = selectedSite.label;
        emit('update:formData', { ...localFormData });
    }
};

const fetchNonConvertedSites = async () => {
    try {
        const response = await fetchGetApi('api/temp/sites/non-converted');
        if (response?.data?.success) {
            nonConvertedSites.value = response.data.data;
        }
    } catch (error) {
        console.error('Error fetching non-converted sites:', error);
    }
};

onMounted(() => {
    fetchNonConvertedSites();
});

watch(localFormData, (newVal) => {
    emit('update:formData', { ...newVal });
}, { deep: true });

const handleQuotationUpload = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        localFormData.quotation.file = input.files[0];
        emit('file-upload', 'quotation', input.files[0]);
    }
};

const handleWorkOrderUpload = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        localFormData.workOrder.file = input.files[0];
        emit('file-upload', 'workOrder', input.files[0]);
    }
};
</script>