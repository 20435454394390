import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row mb-3" }
const _hoisted_8 = { class: "col-md-4" }
const _hoisted_9 = { class: "table-responsive" }
const _hoisted_10 = { class: "table table-hover" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["href"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  class: "modal fade",
  id: "detailsModal",
  tabindex: "-1"
}
const _hoisted_18 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_19 = { class: "modal-content" }
const _hoisted_20 = {
  key: 0,
  class: "modal-body"
}
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = { class: "mb-3" }
const _hoisted_23 = { class: "mb-3" }
const _hoisted_24 = { class: "mb-3" }

import { ref, computed, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { Modal } from 'bootstrap';
import Swal from 'sweetalert2';
import { fetchGetApi, fetchDeleteApi } from '@/util/api';

interface Submission {
    _id: string;
    name: string;
    email: string;
    subject: string;
    message: string;
    resume?: {
        key: string;
        url: string;
    };
    createdAt: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'join-us-submissions',
  setup(__props) {

const submissions = ref<Submission[]>([]);
const filterQuery = ref('');
const selectedSubmission = ref<Submission | null>(null);
//@ts-ignore
const modalInstance = ref<Modal | null>(null);
const toast = useToast();
const baseUrl = process.env.VUE_APP_BASE_URL;

const filteredSubmissions = computed(() => {
    const query = filterQuery.value.toLowerCase();
    return submissions.value.filter(submission => 
        submission.name.toLowerCase().includes(query) ||
        submission.email.toLowerCase().includes(query) ||
        submission.subject.toLowerCase().includes(query)
    );
});

onMounted(async () => {
    modalInstance.value = new Modal(document.getElementById('detailsModal') as HTMLElement);
    await fetchSubmissions();
});

async function fetchSubmissions() {
    try {
        const response = await fetchGetApi(`api/joinus`);
        if (response.data.success) {
            submissions.value = response.data.data;
        }
    } catch (error: any) {
        toast.error(error.response?.data?.message || 'Failed to fetch submissions');
    }
}

function viewDetails(submission: Submission) {
    selectedSubmission.value = submission;
    modalInstance.value?.show();
}

async function deleteSubmission(id: string) {
    try {
        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: "This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (confirmed.isConfirmed) {
            const response = await fetchDeleteApi(`api/joinus/${id}`);
            if (response.data.success) {
                toast.success('Submission deleted successfully');
                await fetchSubmissions();
            }
        }
    } catch (error: any) {
        toast.error(error.response?.data?.message || 'Failed to delete submission');
    }
}

function truncateText(text: string, length: number): string {
    return text.length > length ? text.substring(0, length) + '...' : text;
}

function formatDate(date: string): string {
    return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
}

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Join Us",
      title: "Submissions"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("h5", null, "Join Us Submissions")
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterQuery).value = $event)),
                      placeholder: "Search submissions..."
                    }, null, 512), [
                      [_vModelText, filterQuery.value]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("table", _hoisted_10, [
                    _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Name"),
                        _createElementVNode("th", null, "Email"),
                        _createElementVNode("th", null, "Subject"),
                        _createElementVNode("th", null, "Message"),
                        _createElementVNode("th", null, "Resume"),
                        _createElementVNode("th", null, "Submitted On"),
                        _createElementVNode("th", null, "Actions")
                      ])
                    ], -1)),
                    (!filteredSubmissions.value.length)
                      ? (_openBlock(), _createElementBlock("tbody", _hoisted_11, _cache[1] || (_cache[1] = [
                          _createElementVNode("tr", null, [
                            _createElementVNode("td", {
                              colspan: "7",
                              class: "text-center"
                            }, "No submissions found")
                          ], -1)
                        ])))
                      : (_openBlock(), _createElementBlock("tbody", _hoisted_12, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredSubmissions.value, (submission) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: submission._id
                            }, [
                              _createElementVNode("td", null, _toDisplayString(submission.name), 1),
                              _createElementVNode("td", null, _toDisplayString(submission.email), 1),
                              _createElementVNode("td", null, _toDisplayString(submission.subject), 1),
                              _createElementVNode("td", null, _toDisplayString(truncateText(submission.message, 50)), 1),
                              _createElementVNode("td", null, [
                                (submission.resume?.url)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      href: submission.resume.url,
                                      target: "_blank",
                                      class: "btn btn-primary btn-sm"
                                    }, " View Resume ", 8, _hoisted_13))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_14, "No resume"))
                              ]),
                              _createElementVNode("td", null, _toDisplayString(formatDate(submission.createdAt)), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("button", {
                                  class: "btn btn-info btn-sm me-2",
                                  onClick: ($event: any) => (viewDetails(submission))
                                }, " View ", 8, _hoisted_15),
                                _createElementVNode("button", {
                                  class: "btn btn-danger btn-sm",
                                  onClick: ($event: any) => (deleteSubmission(submission._id))
                                }, " Delete ", 8, _hoisted_16)
                              ])
                            ]))
                          }), 128))
                        ]))
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", { class: "modal-title" }, "Submission Details"),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal"
            })
          ], -1)),
          (selectedSubmission.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Name:", -1)),
                  _createTextVNode(" " + _toDisplayString(selectedSubmission.value.name), 1)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Email:", -1)),
                  _createTextVNode(" " + _toDisplayString(selectedSubmission.value.email), 1)
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Subject:", -1)),
                  _createTextVNode(" " + _toDisplayString(selectedSubmission.value.subject), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Message:", -1)),
                  _createElementVNode("p", null, _toDisplayString(selectedSubmission.value.message), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}
}

})