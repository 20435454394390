import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "add-machine" }
const _hoisted_2 = { class: "card mb-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "d-flex justify-content-end gap-2" }
const _hoisted_6 = { class: "mt-4" }
const _hoisted_7 = {
  key: 0,
  class: "text-center my-4"
}
const _hoisted_8 = {
  key: 1,
  class: "text-center my-4"
}
const _hoisted_9 = {
  key: 2,
  class: "table-responsive"
}
const _hoisted_10 = { class: "table table-hover" }
const _hoisted_11 = ["onClick", "disabled"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';


export default /*@__PURE__*/_defineComponent({
  __name: 'add-machine',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const siteId = route.params.siteId as string;
const baseUrl = process.env.VUE_APP_BASE_URL;

const machineName = ref('');
const machines = ref<any[]>([]);
const isLoading = ref(false);
const removingMachineId = ref<string | null>(null);

// Handle form submission
const handleSubmit = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/physical/register/machine/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                siteId: siteId,
                registerData: [{
                    registerName: machineName.value
                }]
            })
        });

        const result = await response.json();
        
        // Check if response status is in 200-299 range
        if (response.ok) {
            // Reset form first
            machineName.value = '';
            // Then fetch updated machines
            await fetchMachines();
            // Show success message
            await Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Machine added successfully',
                timer: 1500,
                showConfirmButton: false
            });
        } else {
            throw new Error(result.message || 'Failed to add machine');
        }
    } catch (error: any) {
        console.error('Error adding machine:', error);
        await Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message || 'Failed to add machine',
        });
    }
};

// Fetch machines list
const fetchMachines = async () => {
    isLoading.value = true;
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/physical/register/machine/get/${siteId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        
        const result = await response.json();
        
        if (response.ok && result.physicalRegister) {
            // Transform the data to only include necessary machine information
            machines.value = result.physicalRegister.registerData.map((machine: any) => ({
                _id: machine._id,
                registerName: machine.registerName
            }));
        } else {
            console.error('Invalid API response format:', result);
            machines.value = [];
        }
    } catch (error) {
        console.error('Error fetching machines:', error);
        machines.value = [];
    } finally {
        isLoading.value = false;
    }
};

// Remove machine
const removeMachine = async (machineId: string) => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You want to remove this machine?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
        cancelButtonText: 'Cancel'
    });

    if (!result.isConfirmed) return;
    
    removingMachineId.value = machineId;
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/physical/register/machine/${siteId}/${machineId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        
        const apiResult = await response.json();
        
        if (response.ok) {
            await fetchMachines();
            await Swal.fire({
                icon: 'success',
                title: 'Removed!',
                text: 'Machine has been removed successfully',
                timer: 1500,
                showConfirmButton: false
            });
        } else {
            throw new Error(apiResult.message || 'Failed to remove machine');
        }
    } catch (error: any) {
        console.error('Error removing machine:', error);
        await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.message || 'Failed to remove machine'
        });
    } finally {
        removingMachineId.value = null;
    }
};

onMounted(() => {
    fetchMachines();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("h3", null, "Add Machine")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _withModifiers(handleSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("label", { class: "form-label" }, "Machine Name", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((machineName).value = $event)),
              required: "",
              placeholder: "Enter machine name"
            }, null, 512), [
              [_vModelText, machineName.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).back()))
            }, " Cancel "),
            _cache[3] || (_cache[3] = _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary"
            }, " Add Machine ", -1))
          ])
        ], 32),
        _createElementVNode("div", _hoisted_6, [
          _cache[7] || (_cache[7] = _createElementVNode("h4", null, "Existing Machines", -1)),
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
                _createElementVNode("div", {
                  class: "spinner-border text-primary",
                  role: "status"
                }, [
                  _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                ], -1)
              ])))
            : (machines.value.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [
                  _createElementVNode("p", { class: "text-muted" }, "No machines found", -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("table", _hoisted_10, [
                    _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Machine Name"),
                        _createElementVNode("th", null, "Actions")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(machines.value, (machine) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: machine._id
                        }, [
                          _createElementVNode("td", null, _toDisplayString(machine.registerName), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("button", {
                              class: "btn btn-danger btn-sm",
                              onClick: ($event: any) => (removeMachine(machine._id)),
                              disabled: removingMachineId.value === machine._id
                            }, [
                              (removingMachineId.value === machine._id)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Removing... "))
                                : (_openBlock(), _createElementBlock("span", _hoisted_13, " Remove "))
                            ], 8, _hoisted_11)
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ]))
        ])
      ])
    ])
  ]))
}
}

})