<script lang="js" setup>
import { ref, onMounted, computed, watch } from "vue";
import { fetchGetApi } from "@/util/api";

const allData = ref([]);
const elementsPerPage = ref(10);
const currentPage = ref(1);
const totalPages = ref(1);
const selectedEmployee = ref('');
const showPhotoModal = ref(false);
const selectedPhoto = ref('');
const startDate = ref('');
const endDate = ref('');
const uniqueEmployees = computed(() => {
    const employees = new Set();
    allData.value.forEach(item => {
        employees.add(item.empId.employeeName);
    });
    return Array.from(employees);
});

// Updated filteredData computed property
const filteredData = computed(() => {
    return allData.value.map(item => ({
        ...item,
        location: item.location || { lat: '-', lng: '-' }
    }));
});

const openPhotoModal = (photoUrl) => {
    selectedPhoto.value = photoUrl;
    showPhotoModal.value = true;
};

const fetchData = () => {
    let url = `api/attendance/getAttendance?page=${currentPage.value}`;

    // Add date range parameters if they exist
    if (startDate.value) {
        url += `&startDate=${startDate.value}`;
    }
    if (endDate.value) {
        url += `&endDate=${endDate.value}`;
    }
    if (selectedEmployee.value) {
        url += `&employeeName=${encodeURIComponent(selectedEmployee.value)}`;
    }

    fetchGetApi(url).then((response) => {
        if (response?.data) {
            allData.value = response.data.data;
            if (response.data.pagination) {
                totalPages.value = response.data.pagination.totalPages;
            }
        }
    });
};

// Watch for changes in filters to trigger API call
watch([startDate, endDate, selectedEmployee], () => {
    currentPage.value = 1; // Reset to first page when filters change
    fetchData();
});

onMounted(async () => {
    fetchData();
});

// Pagination methods
const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        fetchData();
    }
};

const previousPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        fetchData();
    }
};

</script>

<template>
    <Breadcrumbs main="Apps" title="Attendance List" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="employee-select" class="col-form-label">Select Employee:</label>
                                <select id="employee-select" class="form-select" v-model="selectedEmployee">
                                    <option value="">All Employees</option>
                                    <option v-for="employee in uniqueEmployees" :key="employee" :value="employee">
                                        {{ employee }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="start-date" class="col-form-label">Start Date:</label>
                                <input type="date" id="start-date" class="form-control" v-model="startDate" />
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="end-date" class="col-form-label">End Date:</label>
                                <input type="date" id="end-date" class="form-control" v-model="endDate" />
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50px;">SL</th>
                                    <th scope="col">Employee Name</th>
                                    <th scope="col">Employee Code</th>
                                    <th scope="col">Entry Date</th>
                                    <th scope="col">Entry Time</th>
                                    <th scope="col">Exit Date</th>
                                    <th scope="col">Exit Time</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Photo</th>
                                </tr>
                            </thead>
                            <tbody v-if="!filteredData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="9" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="odd" v-for="(row, index) in filteredData" :key="row._id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ row.empId.employeeName }}</td>
                                    <td>{{ row.empId.employeeCode }}</td>
                                    <td>{{ new Date(row.entryDate).toLocaleString('en-IN', {
                                        timeZone: 'Asia/Kolkata',
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric'
                                    }) }}</td>
                                    <td>{{ row.entryTime ? new
                                        Date(`2000-01-01T${row.entryTime}`).toLocaleString('en-IN', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                        }) : '-' }}</td>
                                    <td>{{ row.exitDate ? new Date(row.exitDate).toLocaleString('en-IN', {
                                        timeZone: 'Asia/Kolkata',
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric'
                                    }) : '-' }}</td>
                                    <td>{{ row.exitTime ? new Date(row.exitDate).toLocaleString('en-IN', {
                                        timeZone: 'Asia/Kolkata',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true
                                    }) : '-' }}</td>
                                    <td>
                                        <div v-if="row.entryLocation && row.entryLocation.lat !== 'null'">
                                            <a :href="`https://www.google.com/maps?q=${row.entryLocation.lat},${row.entryLocation.lng}`"
                                                target="_blank" title="View Entry Location on Maps"
                                                class="btn btn-primary btn-sm">
                                                <i class="fa fa-map-marker"></i>
                                            </a>
                                        </div>
                                        <div v-else>
                                            <span class="text-muted">N/A</span>
                                        </div>
                                    </td>
                                    <td>
                                        <img :src="row.photo" alt="Entry Photo"
                                            style="width: 50px; height: 50px; object-fit: cover; cursor: pointer;"
                                            @click="openPhotoModal(row.photo)" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div>
                                Showing page {{ currentPage }} of {{ totalPages }}
                            </div>
                            <ul class="pagination justify-content-end pagination-primary">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" @click="previousPage" style="cursor: pointer;">Previous</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                    <a class="page-link" @click="nextPage" style="cursor: pointer;">Next</a>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- Photo Modal -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showPhotoModal }" style="background-color: rgba(0,0,0,0.5);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Attendance Photo</h5>
                    <button type="button" class="btn-close" @click="showPhotoModal = false"></button>
                </div>
                <div class="modal-body text-center">
                    <img :src="selectedPhoto" alt="Attendance Photo" style="max-width: 100%; max-height: 70vh;" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal {
    display: none;
}

.modal.d-block {
    display: block !important;
}
</style>
