<template>
    <Breadcrumbs main="Products" title="Products" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <ProductsList />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const ProductsList = defineAsyncComponent(() => import("@/components/products/products-list.vue"))
</script>