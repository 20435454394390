<template>
    <div class="panel-container">
        <div class="card">
            <div class="card-header">
                <h5>Laundry Report</h5>
            </div>
            <div class="card-body">
                <!-- Date Range Selection -->
                <div class="row mb-4">
                    <div class="col-md-3">
                        <label class="form-label">From Date</label>
                        <input type="date" class="form-control" v-model="dateRange.from">
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">To Date</label>
                        <input type="date" class="form-control" v-model="dateRange.to">
                    </div>
                </div>

                <!-- Report Table -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Items</th>
                                <th>Items got returned</th>
                                <th>Items rejected directly</th>
                                <th>Items sent to laundry</th>
                                <th>Items rejected after laundry</th>
                                <th>Cost of rejected items</th>
                                <th>Cost of rejected items after laundry</th>
                                <th>Total lose of returned uniform (Pcs)</th>
                                <th>Total lose of returned uniform (amt)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in filteredReportData" :key="index">
                                <td>{{ formatDate(record.date) }}</td>
                                <td>{{ record.items }}</td>
                                <td>{{ record.itemsReturned }}</td>
                                <td>{{ record.itemsRejectedDirectly }}</td>
                                <td>{{ record.itemsSentToLaundry }}</td>
                                <td>{{ record.itemsRejectedAfterLaundry }}</td>
                                <td>{{ record.costOfRejectedItems }}</td>
                                <td>{{ record.costOfRejectedItemsAfterLaundry }}</td>
                                <td>{{ record.totalLosePcs }}</td>
                                <td>{{ record.totalLoseAmount }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { format } from 'date-fns';

interface DateRange {
    from: string;
    to: string;
}

interface LaundryRecord {
    date: string;
    items: number;
    itemsReturned: number;
    itemsRejectedDirectly: number;
    itemsSentToLaundry: number;
    itemsRejectedAfterLaundry: number;
    costOfRejectedItems: number;
    costOfRejectedItemsAfterLaundry: number;
    totalLosePcs: number;
    totalLoseAmount: number;
}

const dateRange = ref<DateRange>({
    from: '',
    to: ''
});

// Dummy data
const reportData = ref<LaundryRecord[]>([
    {
        date: '2024-02-01',
        items: 100,
        itemsReturned: 80,
        itemsRejectedDirectly: 5,
        itemsSentToLaundry: 75,
        itemsRejectedAfterLaundry: 3,
        costOfRejectedItems: 2500,
        costOfRejectedItemsAfterLaundry: 1500,
        totalLosePcs: 8,
        totalLoseAmount: 4000
    },
    {
        date: '2024-02-02',
        items: 150,
        itemsReturned: 120,
        itemsRejectedDirectly: 8,
        itemsSentToLaundry: 112,
        itemsRejectedAfterLaundry: 5,
        costOfRejectedItems: 3200,
        costOfRejectedItemsAfterLaundry: 2000,
        totalLosePcs: 13,
        totalLoseAmount: 5200
    },
    {
        date: '2024-02-03',
        items: 80,
        itemsReturned: 65,
        itemsRejectedDirectly: 4,
        itemsSentToLaundry: 61,
        itemsRejectedAfterLaundry: 2,
        costOfRejectedItems: 1800,
        costOfRejectedItemsAfterLaundry: 1000,
        totalLosePcs: 6,
        totalLoseAmount: 2800
    }
]);

const formatDate = (date: string) => {
    return format(new Date(date), 'dd/MM/yyyy');
};

const filteredReportData = computed(() => {
    if (!dateRange.value.from || !dateRange.value.to) return reportData.value;
    
    return reportData.value.filter(record => {
        const recordDate = new Date(record.date);
        const fromDate = new Date(dateRange.value.from);
        const toDate = new Date(dateRange.value.to);
        return recordDate >= fromDate && recordDate <= toDate;
    });
});
</script>

<style scoped>
.panel-container {
    padding: 20px;
}

.card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table {
    font-size: 0.875rem;
}

.table th {
    white-space: nowrap;
    vertical-align: middle;
}

.table-secondary td {
    font-size: 0.8rem;
    color: #666;
}
</style>