import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  class: "nav nav-tabs",
  role: "tablist"
}
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "tab-content" }
const _hoisted_10 = { class: "row mt-4" }
const _hoisted_11 = { class: "col-md-12" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_14 = { class: "mt-3" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 1,
  class: "alert alert-danger mt-3"
}
const _hoisted_17 = {
  key: 2,
  class: "mt-3"
}
const _hoisted_18 = ["href", "download"]

import { ref } from 'vue';
import axios from 'axios';
import SingleImageProcessing from '@/components/image-processing/single-image-processing.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const activeTab = ref('bulk');
const fileName = ref<string>('');
const selectedFile = ref<File | null>(null);
const baseUrl = process.env.VUE_APP_BASE_URL;
const isUploading = ref<boolean>(false);
const uploadError = ref<string>('');
const downloadUrl = ref<string>('');

const onFileSelected = (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
        fileName.value = file.name;
        selectedFile.value = file;
    }
};

const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result as string;
            // Remove the data URL prefix (e.g., "data:application/zip;base64,")
            resolve(base64String.split(',')[1]);
        };
        reader.onerror = (error) => reject(error);
    });
};

const uploadFile = async () => {
    if (!selectedFile.value) return;
    const originalFileName = selectedFile.value.name; // Store original filename

    try {
        isUploading.value = true;
        uploadError.value = '';
        downloadUrl.value = '';

        // Convert file to base64
        const base64Content = await convertToBase64(selectedFile.value);

        // Prepare payload according to backend requirements
        const payload = {
            fileName: originalFileName,
            fileContent: base64Content,
            fileContentLength: base64Content.length
        };

        const response = await axios.post(`${baseUrl}api/util/upload/zip/file`, payload, {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });

        // Create a URL for the downloaded file
        const blob = new Blob([response.data], { type: 'application/zip' });
        downloadUrl.value = URL.createObjectURL(blob);

        // Keep the fileName for download
        fileName.value = originalFileName;
        selectedFile.value = null;
        const input = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (input) input.value = '';

    } catch (error: any) {
        uploadError.value = error.response?.data?.message || 'Error uploading file';
    } finally {
        isUploading.value = false;
    }
};

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Apps",
      title: "Image Processing"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("ul", _hoisted_6, [
                _createElementVNode("li", _hoisted_7, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link", { active: activeTab.value === 'bulk' }]),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (activeTab.value = 'bulk')),
                    href: "#bulk",
                    "data-bs-toggle": "tab",
                    role: "tab"
                  }, " Bulk Processing ", 2)
                ]),
                _createElementVNode("li", _hoisted_8, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link", { active: activeTab.value === 'single' }]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (activeTab.value = 'single')),
                    href: "#single",
                    "data-bs-toggle": "tab",
                    role: "tab"
                  }, " Single Image ", 2)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: _normalizeClass(["tab-pane fade", { 'show active': activeTab.value === 'bulk' }]),
                  id: "bulk",
                  role: "tabpanel"
                }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _cache[2] || (_cache[2] = _createElementVNode("label", null, "Upload ZIP File", -1)),
                        _createElementVNode("input", {
                          type: "file",
                          class: "form-control",
                          onChange: onFileSelected,
                          accept: ".zip"
                        }, null, 32)
                      ]),
                      (fileName.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, " Selected file: " + _toDisplayString(fileName.value), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("button", {
                          class: "btn btn-primary",
                          onClick: uploadFile,
                          disabled: !fileName.value || isUploading.value
                        }, _toDisplayString(isUploading.value ? 'Uploading...' : 'Upload File'), 9, _hoisted_15)
                      ]),
                      (uploadError.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(uploadError.value), 1))
                        : _createCommentVNode("", true),
                      (downloadUrl.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("a", {
                              href: downloadUrl.value,
                              class: "btn btn-success",
                              download: fileName.value
                            }, " Download Processed File ", 8, _hoisted_18)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["tab-pane fade", { 'show active': activeTab.value === 'single' }]),
                  id: "single",
                  role: "tabpanel"
                }, [
                  _createVNode(SingleImageProcessing)
                ], 2)
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})