<template>
    <div class="add-employee">
        <div class="card mb-4">
            <div class="card-header">
                <h3>Add Employee</h3>
            </div>
            <div class="card-body">
                <form @submit.prevent="handleSubmit">
                    <div class="mb-3">
                        <label class="form-label">Name</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="formData.name"
                            @input="searchEmployees"
                            required
                        >
                        <div v-if="searchResults.length > 0" class="search-results border rounded mt-1">
                            <div 
                                v-for="employee in searchResults" 
                                :key="employee._id"
                                class="p-2 cursor-pointer hover:bg-gray-100"
                                @click="selectEmployee(employee)"
                            >
                                {{ employee.employeeName }} ({{ employee.employeeCode }})
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Employee Type</label>
                        <select 
                            class="form-select" 
                            v-model="formData.employeeType"
                            required
                            disabled
                        >
                            <option value="">Select Employee Type</option>
                            <option value="Operation">Operation</option>
                        </select>
                    </div>
                    <div class="d-flex justify-content-end gap-2">
                        <button 
                            type="button" 
                            class="btn btn-secondary"
                            @click="router.back()"
                        >
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-primary">
                            Add Employee
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Existing Employees</h3>
                <div class="input-group w-auto">
                    <input 
                        type="text" 
                        class="form-control" 
                        v-model="searchQuery"
                        placeholder="Search employees..."
                    >
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Employee Code</th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="employee in filteredEmployees" :key="employee._id">
                                <td>{{ employee.employeeCode }}</td>
                                <td>{{ employee.employeeName }}</td>
                                <td>{{ employee.permanentAddress?.phoneNumber || 'N/A' }}</td>
                                <td>
                                    <button 
                                        class="btn btn-sm btn-danger d-flex align-items-center gap-1"
                                        @click="removeEmployeeWithLoading(employee._id)"
                                        :disabled="removingEmployeeId === employee._id"
                                    >
                                        <span v-if="removingEmployeeId === employee._id" class="spinner-border spinner-border-sm" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </span>
                                        <i v-else class="bi bi-trash"></i>
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="isLoading" class="text-center my-4">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div v-if="!isLoading && employees.length === 0" class="text-center my-4">
                    <p class="text-muted">No employees found</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';

const router = useRouter();
const route = useRoute();
const siteId = route.params.siteId as string;
const baseUrl = process.env.VUE_APP_BASE_URL;

const formData = ref({
    name: '',
    employeeType: '',
});

interface Employee {
    _id: string;
    employeeName: string;
    employeeCode: string;
    employeeType: string;
    permanentAddress?: {
        phoneNumber: string;
    };
}

const employees = ref<Employee[]>([]);
const isLoading = ref(false);
const searchQuery = ref('');
const searchResults = ref<Employee[]>([]);

// Add this ref for tracking removal loading state
const removingEmployeeId = ref<string | null>(null);

// Define the removeEmployeeWithLoading function
const removeEmployeeWithLoading = async (employeeId: string) => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You want to remove this employee from the site?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
        cancelButtonText: 'Cancel'
    });

    if (!result.isConfirmed) return;
    
    removingEmployeeId.value = employeeId;
    try {
        const response = await fetch(`${baseUrl}api/sites/remove/manager/${siteId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                managerIds: [employeeId]
            })
        });
        
        const apiResult = await response.json();
        
        if (apiResult.success) {
            await fetchEmployees();
            Swal.fire(
                'Removed!',
                'Employee has been removed from the site.',
                'success'
            );
        } else {
            Swal.fire(
                'Error!',
                apiResult.message || 'Failed to remove employee',
                'error'
            );
        }
    } catch (error) {
        console.error('Error removing employee:', error);
        Swal.fire(
            'Error!',
            'Failed to remove employee from site',
            'error'
        );
    } finally {
        removingEmployeeId.value = null;
    }
};

// Fetch employees list
const fetchEmployees = async () => {
    isLoading.value = true;
    try {
        const response = await fetch(`${baseUrl}api/sites/get/manager/${siteId}`);
        const result = await response.json();
        if (result.success) {
            employees.value = result.data.map((emp: any) => ({
                _id: emp._id,
                employeeName: emp.employeeName,
                employeeCode: emp.employeeCode,
                employeeType: emp.employeeType,
                permanentAddress: emp.permanentAddress
            }));
        }
    } catch (error) {
        console.error('Error fetching employees:', error);
    } finally {
        isLoading.value = false;
    }
};

// Filter employees based on search
const filteredEmployees = computed(() => {
    return employees.value.filter(employee => 
        employee.employeeName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        employee.employeeCode.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        employee.permanentAddress?.phoneNumber?.includes(searchQuery.value) || false
    );
});

// Remove employee
const removeEmployee = async (employeeId: string) => {
    if (!confirm('Are you sure you want to remove this employee?')) return;
    
    try {
        // Add your API call here
        console.log('Removing employee:', employeeId);
        await fetchEmployees(); // Refresh the list
    } catch (error) {
        console.error('Error removing employee:', error);
    }
};

// Update handleSubmit to send managerIds as an array
const handleSubmit = async () => {
    try {
        const response = await fetch(`${baseUrl}api/sites/add/manager/${siteId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                managerIds: [selectedEmployee.value._id] // Changed from employeeIds to managerIds and wrapped in array
            })
        });
        const result = await response.json();
        
        if (result.success) {
            await fetchEmployees();
            // Reset form
            formData.value = {
                name: '',
                employeeType: '',
            };
            selectedEmployee.value = null;
        } else {
            Swal.fire('Error', result.message, 'error'); // Better error handling with Swal
        }
    } catch (error) {
        console.error('Error adding employee:', error);
        Swal.fire('Error', 'Failed to add employee', 'error');
    }
};

// Add new ref to store selected employee
const selectedEmployee = ref<Employee | null>(null);

// Add new function to search employees
const searchEmployees = async () => {
    if (!formData.value.name) {
        searchResults.value = [];
        return;
    }
    
    try {
        const response = await fetch(`${baseUrl}api/joining/employees/type?type=Operation`);
        const result = await response.json();
        if (result.success) {
            searchResults.value = result.data.filter((emp: any) => 
                emp.employeeName.toLowerCase().includes(formData.value.name.toLowerCase())
            );
        }
    } catch (error) {
        console.error('Error searching employees:', error);
    }
};

// Update selectEmployee function
const selectEmployee = (employee: Employee) => {
    formData.value.name = employee.employeeName;
    formData.value.employeeType = employee.employeeType || 'Employee';
    selectedEmployee.value = employee; // Store the selected employee
    searchResults.value = []; // Clear search results
};

// Fetch employees when component is mounted
onMounted(() => {
    fetchEmployees();
});
</script>

<style scoped>
.table th {
    background-color: #f8f9fa;
}

.btn-danger {
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
}

.gap-1 {
    gap: 0.25rem;
}

.search-results {
    position: absolute;
    background: white;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.cursor-pointer {
    cursor: pointer;
}

.hover\:bg-gray-100:hover {
    background-color: #f3f4f6;
}
</style>