<script lang="js" setup>
import { ref, onMounted, watch } from "vue"
import Swal from 'sweetalert2'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { fetchGetApi, fetchPostApi } from '@/util/api';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"))
const elementsPerPage = ref(25)
const currentPage = ref(1)
const filterQuery = ref("")
const allData = ref([])
const toast = useToast()
const opsName = ref('')
const startDate = ref('')
const priorityFilter = ref('all')

onMounted(async () => {
    await fetchData()
})

async function fetchData() {
    const params = {
        page: currentPage.value,
        limit: elementsPerPage.value,
        search: filterQuery.value,
        opsName: opsName.value,
        date: startDate.value,
        priority: priorityFilter.value !== 'all' ? priorityFilter.value === 'priority' : undefined
    }

    try {
        const response = await fetchPostApi('api/kyt/get/kyts', params)
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data
        } else {
            toast.error('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        toast.error('Error fetching data')
    }
}

function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}

function get_rows() {
    return allData.value
}

function num_pages() {
    return Math.ceil(allData.value.length / elementsPerPage.value)
}

function change_page(page) {
    currentPage.value = page
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}
function openMap(lat, long) {
    if (typeof window !== 'undefined' && window.open) {
        window.open(`https://www.google.com/maps?q=${lat},${long}`);
    } else {
        console.error('Window object is not available.');
    }
}

watch([filterQuery, elementsPerPage, currentPage, opsName, startDate, priorityFilter], async () => {
    await fetchData()
})

async function togglePriority(id) {
    try {
        const response = await fetchPostApi('api/kyt/makePriority', { id });
        console.log('Priority API Response:', response);
        console.log('Response type:', typeof response);
        console.log('Response structure:', JSON.stringify(response, null, 2));

        // Check if response is a string (might be JSON string)
        if (typeof response === 'string') {
            try {
                response = JSON.parse(response);
            } catch (e) {
                console.error('Failed to parse response:', e);
            }
        }

        // More flexible success check
        if (response && (response.success === true || response.status === 200)) {
            // Update the local state
            const kytIndex = allData.value.findIndex(k => k._id === id);
            if (kytIndex !== -1) {
                allData.value = [...allData.value];
                allData.value[kytIndex].isPriority = !allData.value[kytIndex].isPriority;
            }
            toast.success('Priority status updated successfully');
        } else {
            console.error('API Response Error:', response);
            await fetchData();
        }
    } catch (error) {
        console.error('Error updating priority:', error);
        toast.error('An error occurred while updating priority');
        await fetchData();
    }
}



</script>
<template>
    <Breadcrumbs main="Apps" title="Operation KYTs" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-xs-12 col-sm-auto col-form-label">Date:</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" class="form-control" v-model="startDate" style="width: 150px;">
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Site name:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery"style="width: 150px;">
                            </div>
                            <div class="col-2">
                                <label for="table-complete-entries"
                                    class="col-3 col-sm-auto col-form-label">Operation Manager:</label>
                                <select id="table-complete-entries" class="form-select" name="entries"
                                    v-model="opsName">
                                    <option value="Kawsar">Kawsar</option>
                                    <option value="Tamanna">Tamanna</option>
                                </select>
                            </div>
                            <div class="col-2">
                                <label for="table-complete-entries"
                                    class="col-3 col-sm-auto col-form-label">Show:</label>
                                <select id="table-complete-entries" class="form-select" name="entries"
                                    v-model="elementsPerPage">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div class="col-2">
                                <label for="priority-filter" class="col-3 col-sm-auto col-form-label">Priority:</label>
                                <select id="priority-filter" class="form-select" v-model="priorityFilter">
                                    <option value="all">All</option>
                                    <option value="priority">Priority</option>
                                    <option value="non-priority">Non-Priority</option>
                                </select>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc text-nowrap" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Address</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Operation manager</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Work duration</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Travel duration</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Type of visit</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date-Time</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Check work</th>
                                    <th class="sorting_asc text-nowrap text-center" scope="col">Is Completed</th>
                                    <th class="sorting_asc text-nowrap" scope="col" style="text-align: center;">Priority</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="!get_rows().length">
                                <tr class="odd">
                                    <td valign="top" colspan="8" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="odd" v-for="(row, index) in get_rows()" :key="index">
                                    <td class="sorting_1" style="width: 10px;">{{ index + 1 }}</td>
                                    <td class="sorting_1">
                                        <h6>{{ row.siteName }}</h6>
                                    </td>
                                    <td class="sorting_1 text-nowrap">
                                        <a @click="openMap(row.siteLat, row.siteLong)" href="#">{{ row.siteAddress
                                            }}</a>

                                    </td>
                                    <td class="sorting_1">{{ row.employeeDetails?.name ?? '' }}</td>
                                    <td class="sorting_1">{{ row.workDuration ?? '' }}</td>
                                    <td class="sorting_1">{{ row.travelDuration ?? '' }}</td>
                                    <td class="sorting_1">{{ row.typeOfVisit ?? '' }}</td>
                                    
                                    <td class="sorting_1">{{ formatDate(row.date) + ' - ' + row.time }}</td>
                                    <td class=" sorting_1">View</td>
                                    <td class="sorting_1 text-center">
                                        <div type="button" class="btn" :class="{
                                            'btn-success': row.isCompleted,
                                            'btn-danger': !row.isCompleted,
                                        }" style="scale: 0.7;cursor: auto;">
                                            {{ row.isCompleted == true ? 'Completed' : 'Not Completed' }}
                                        </div>
                                        <!-- <button class="btn btn-pill btn-success btn-air-success"
                                v-if="row.isCompleted">Completed</button>
                            <button class="btn btn-pill btn-info btn-air-info" v-else>Not Completed</button> -->
                                    </td>
                                    <td class="sorting_1" style="text-align: center;">
                                        <button 
                                            @click.prevent="togglePriority(row._id)"
                                            class="btn"
                                            :class="{
                                                'btn-danger-gradien': row.isPriority,
                                                'btn-success-gradien': !row.isPriority
                                            }"
                                            style="scale: 0.7;">
                                            {{ row.isPriority ? 'Priority' : 'Non-Priority' }}
                                        </button>
                                    </td>
                                    <td>
                                        <!-- <a class="text-primary me-2" data-bs-toggle="modal" data-bs-target="#grid-modal"
                                title="Edit Role" style="cursor: pointer; font-size:20px;">
                                <i class="fas fa-eye"></i>
                            </a> -->

                                        <router-link :to="`view/${row._id}`" class="text-primary me-2" title="View"
                                            target="_blank" style="cursor: pointer;  font-size:20px;">
                                            <i class="fas fa-eye"></i>
                                        </router-link>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>

</template>
