const XLSX = require('xlsx');
const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');

// Correctly assign the VFS to pdfMake
pdfMake.vfs = pdfFonts.vfs;

/**
 * Converts JSON data to an Excel file and triggers a download.
 * @param {Array} jsonData - The JSON data to be converted to Excel.
 * @param {string} fileName - The name of the output Excel file.
 */
function jsonToExcel(jsonData, fileName) {
    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Get the range of the worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref']);

    // Apply auto filter to all columns
    worksheet['!autofilter'] = { ref: XLSX.utils.encode_range(range) };

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);

    console.log('Excel file created successfully.');
}

/**
 * Converts JSON data to a PDF file and triggers a download.
 * @param {Array} jsonData - The JSON data to be converted to PDF.
 * @param {string} fileName - The name of the output PDF file.
 */
function jsonToPdf(jsonData, fileName) {
    // Define the table headers
    const headers = Object.keys(jsonData[0]);

    // Define the table body
    const body = [headers.map(header => ({ text: header, style: 'tableHeader' }))];
    jsonData.forEach(row => {
        const dataRow = headers.map(header => ({ text: row[header] ? row[header].toString() : '', style: 'tableBody' }));
        body.push(dataRow);
    });

    // Define the document definition
    const docDefinition = {
        pageOrientation: 'landscape', // Use landscape orientation for better width handling
        content: [
            {
                table: {
                    headerRows: 1,
                    widths: headers.map(() => '*'), // Set widths to '*' for equal distribution
                    body: body
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#CCCCCC' : null; // Add background color to header row
                    }
                }
            }
        ],
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
                fillColor: '#CCCCCC'
            },
            tableBody: {
                fontSize: 11,
                color: 'black'
            }
        },
    };

    // Create the PDF and download it
    pdfMake.createPdf(docDefinition).download(fileName);

    console.log('PDF file created successfully.');
}

module.exports = { jsonToExcel, jsonToPdf };
