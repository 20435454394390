<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";
import { useWorkApprovalStore } from '@/store/workApproval';

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const workApprovalStore = useWorkApprovalStore();

const filterQuery = ref("");
const startDate = ref("");
const endDate = ref("");
const currentPage = ref(1);
const itemsPerPage = ref(10);
const toast = useToast();
const showModal = ref(false);
const selectedCdw = ref(null);

// Computed property for filtered and paginated data
const filteredData = computed(() => {
    let filtered = [...allData.value];

    // Search filter
    if (filterQuery.value) {
        filtered = filtered.filter(item =>
            item.siteId?.toLowerCase().includes(filterQuery.value.toLowerCase()) ||
            item.serviceId?.toLowerCase().includes(filterQuery.value.toLowerCase()) ||
            item.givenBy?.toLowerCase().includes(filterQuery.value.toLowerCase())
        );
    }

    // Date range filter
    if (startDate.value && endDate.value) {
        filtered = filtered.filter(item => {
            const itemDate = new Date(item.workDate);
            return itemDate >= new Date(startDate.value) &&
                itemDate <= new Date(endDate.value);
        });
    }

    return filtered;
});

const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * itemsPerPage.value;
    const end = start + itemsPerPage.value;
    return filteredData.value.slice(start, end);
});

const totalPages = computed(() =>
    Math.ceil(filteredData.value.length / itemsPerPage.value)
);

// Action handlers
const handleApprove = async (id) => {
    try {
        await fetchPostApi(`api/cdw/approve/${id}`);
        toast.success('CDW approved successfully');
        // Refresh data and counts
        await loadData();
        await workApprovalStore.fetchPendingCount();
    } catch (error) {
        toast.error('Failed to approve CDW');
    }
};

const handleReject = async (id) => {
    try {
        await fetchPostApi(`api/cdw/reject/${id}`);
        toast.success('CDW rejected successfully');
        // Refresh data and counts
        await loadData();
        await workApprovalStore.fetchPendingCount();
    } catch (error) {
        toast.error('Failed to reject CDW');
    }
};

const handleView = async (id) => {
    try {
        const response = await fetchGetApi(`api/cdw/${id}`);
        if (response && response.data) {
            selectedCdw.value = response.data;
            showModal.value = true;
        }
    } catch (error) {
        toast.error('Failed to fetch CDW details');
    }
};

const closeModal = () => {
    showModal.value = false;
    selectedCdw.value = null;
};

const loadData = async () => {
    try {
        const response = await fetchGetApi('api/cdw/getAllCdw');
        if (response) {
            const data = Array.isArray(response) ? response :
                (response.data ? response.data : []);

            allData.value = data.map(item => ({
                id: item.id,
                siteId: item.site,
                serviceId: item.service,
                givenBy: item.submittedBy,
                managerIds: item.managers || [],
                alertHigherAuthIds: item.alertAuthorities || [],
                categoryIds: item.categories?.map(cat => cat.category) || [],
                subCategoryIds: item.categories?.flatMap(cat => cat.subCategories) || [],
                wordDescription: item.wordDescription,
                solution: item.solution,
                workDate: item.workDate,
                status: (item.status?.toLowerCase() || 'pending'),
                approvedBy: item.approvedBy || '-',
                approvedAt: item.approvedAt || '-',
                // Add additional fields for modal view
                site: { name: item.site, address: '' },
                submittedBy: { name: item.submittedBy, code: '' },
                service: item.service,
                managers: item.managers?.map(m => ({ name: m, email: '' })) || [],
                alertAuthorities: item.alertAuthorities?.map(a => ({ name: a, email: '' })) || [],
                categories: item.categories || [],
                description: item.wordDescription,
                assigned: item.assigned || [],
                categoriesWithSubs: item.categories?.map(cat => ({
                    category: cat.category,
                    subCategories: cat.subCategories || []
                })) || [],
            }));

            // Debug log
            console.log('Processed data:', allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error loading data:', error);
        console.log('Response type:', typeof response);
        console.log('Raw response:', response);
        toast.error('Error loading data');
    }
};

onMounted(async () => {
    await loadData();
    await workApprovalStore.fetchPendingCount();
});

</script>

<template>
    <Breadcrumbs main="Apps" title="CDW" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive product-table custom-scrollbar">
                    <div class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-md-3">
                                <label class="col-form-label">Search:</label>
                                <input type="text" class="form-control" v-model="filterQuery">
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-5">
                                        <label class="col-form-label">Start Date:</label>
                                        <input type="date" class="form-control" v-model="startDate">
                                    </div>
                                    <div class="col-md-5">
                                        <label class="col-form-label">End Date:</label>
                                        <input type="date" class="form-control" v-model="endDate">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc" scope="col">Site ID</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Given By</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Managers</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Higher Auth</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        <strong>Categories</strong> <br>
                                        <small>Sub Categories</small>
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Problems</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Work Description</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Solution</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Work Date</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Approved By</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Approved At</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody v-if="!paginatedData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="14" class="dataTables_empty">
                                        No matching records found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="odd" v-for="(row, index) in paginatedData" :key="row.id">
                                    <td class="sorting_1" style="width: 10px;">{{ index + 1 }}</td>
                                    <td class="sorting_1">{{ row.siteId }}</td>
                                    <td class="sorting_1">{{ row.serviceId }}</td>
                                    <td class="sorting_1">
                                        {{ row.givenBy }}
                                    </td>
                                    <td class="sorting_1">
                                        <ul>
                                            <li v-for="manager in row.managerIds" :key="manager">{{ manager }}</li>
                                        </ul>
                                    </td>
                                    <td class="sorting_1">
                                        <ul>
                                            <li v-for="auth in row.alertHigherAuthIds" :key="auth">{{ auth }}</li>
                                        </ul>
                                    </td>
                                    <td class="sorting_1">
                                        <div v-for="catGroup in row.categoriesWithSubs" :key="catGroup.category"
                                            class="mb-2">
                                            <strong>{{ catGroup.category }}</strong>
                                            <ul class="list-unstyled ms-3 small">
                                                <li v-for="subCat in catGroup.subCategories" :key="subCat"
                                                    class="text-muted">
                                                    - {{ subCat }}
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td class="sorting_1">
                                        <div v-for="catGroup in row.categories" :key="catGroup.category" class="mb-2">
                                            <strong>{{ catGroup.category }}:</strong>
                                            <p class="text-muted mb-0 small">{{ catGroup.problemController || 'N/A' }}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="sorting_1">{{ row.wordDescription }}</td>
                                    <td class="sorting_1">{{ row.solution }}</td>
                                    <td class="sorting_1 text-nowrap">
                                        {{ row.workDate && row.workDate.length > 16 ?
                                            row.workDate.substring(0, 16) : row.workDate }}
                                    </td>
                                    <td class="sorting_1">{{ row.approvedBy }}</td>
                                    <td class="sorting_1 text-nowrap">
                                        {{ row.approvedAt && row.approvedAt !== '-' && row.approvedAt.length > 16 ? 
                                            row.approvedAt.substring(0, 16) : row.approvedAt }}
                                    </td>
                                    <td class="text-center">
                                        <button type="button" @click="handleView(row.id)"
                                            class="btn btn-primary btn-sm me-2" title="View">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                        <template v-if="row.status === 'pending'">
                                            <button type="button" @click="handleApprove(row.id)"
                                                class="btn btn-success btn-sm me-2" title="Approve">
                                                <i class="fas fa-check"></i>
                                            </button>
                                            <button type="button" @click="handleReject(row.id)"
                                                class="btn btn-danger btn-sm" title="Reject">
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </template>
                                        <template v-else>
                                            <span
                                                :class="`badge bg-${row.status === 'approved' ? 'success' : 'danger'}`">
                                                {{ row.status.charAt(0).toUpperCase() + row.status.slice(1) }}
                                            </span>
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div class="dataTables_info">
                                Showing {{ ((currentPage - 1) * itemsPerPage) + 1 }} to
                                {{ Math.min(currentPage * itemsPerPage, filteredData.length) }} of
                                {{ filteredData.length }} entries
                            </div>
                            <ul class="pagination">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" @click="currentPage--"
                                        :disabled="currentPage === 1">Previous</a>
                                </li>
                                <li class="page-item" v-for="page in totalPages" :key="page"
                                    :class="{ active: page === currentPage }">
                                    <a class="page-link" @click="currentPage = page">{{ page }}</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                    <a class="page-link" @click="currentPage++"
                                        :disabled="currentPage === totalPages">Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" :class="{ 'show d-block': showModal }" tabindex="-1" v-if="showModal && selectedCdw">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">CDW Details</h5>
                    <button type="button" class="btn-close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <strong>Site Name:</strong>
                            <p>{{ selectedCdw.site.name }}</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <strong>Submitted By:</strong>
                            <p>{{ selectedCdw.submittedBy.name }}</p>
                        </div>
                        <div class="col-md-6">
                            <strong>Service:</strong>
                            <p>{{ selectedCdw.service }}</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <strong>Managers:</strong>
                            <ul class="list-unstyled">
                                <li v-for="manager in selectedCdw.managers" :key="manager.name">
                                    {{ manager.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <strong>Alert Authorities:</strong>
                            <ul class="list-unstyled">
                                <li v-for="auth in selectedCdw.alertAuthorities" :key="auth.name">
                                    {{ auth.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <strong>Categories:</strong>
                            <div v-for="cat in selectedCdw.categories" :key="cat.category" class="mb-2">
                                <p class="mb-1"><strong>{{ cat.category }}:</strong></p>
                                <ul class="list-unstyled ms-3">
                                    <li v-for="subCat in cat.subCategories" :key="subCat">
                                        - {{ subCat }}
                                    </li>
                                </ul>
                                <p class="text-muted mb-0" v-if="cat.problemController">
                                    Problem: {{ cat.problemController }}
                                </p>
                                <div v-if="cat.imageUrl" class="mt-2">
                                    <p class="mb-1">Receipt:</p>
                                    <img :src="cat.imageUrl" alt="Receipt" class="img-fluid" style="max-width: 200px;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <strong>Description:</strong>
                            <p>{{ selectedCdw.description }}</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <strong>Solution:</strong>
                            <p>{{ selectedCdw.solution }}</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <strong>Work Date:</strong>
                            <p>{{ selectedCdw.workDate }}</p>
                        </div>
                        <div class="col-md-6">
                            <strong>Status:</strong>
                            <span :class="`badge bg-${selectedCdw.status === 'Approved' ? 'success' :
                                selectedCdw.status === 'Rejected' ? 'danger' : 'warning'}`">
                                {{ selectedCdw.status }}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <strong>Approved By:</strong>
                            <p>{{ selectedCdw.approvedBy || '-' }}</p>
                        </div>
                        <div class="col-md-6">
                            <strong>Approved At:</strong>
                            <p>{{ selectedCdw.approvedAt || '-' }}</p>
                        </div>
                    </div>
                    <div class="row mb-3" v-if="selectedCdw.assigned && selectedCdw.assigned.length">
                        <div class="col-12">
                            <strong>Assigned Employees:</strong>
                            <div class="table-responsive mt-2">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Employee ID</th>
                                            <th>Sub Category</th>
                                            <th>Action</th>
                                            <th>Photo</th>
                                            <th>Slip</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="assign in selectedCdw.assigned" :key="assign._id">
                                            <td>{{ assign.employee }}</td>
                                            <td>{{ assign.subCategory }}</td>
                                            <td>
                                                <span
                                                    :class="`badge bg-${assign.action === 'penalty' ? 'danger' : 'success'}`">
                                                    {{ assign.action }}
                                                </span>
                                            </td>
                                            <td>
                                                <a v-if="assign.photo" :href="assign.photo" target="_blank">View
                                                    Photo</a>
                                                <span v-else>No photo</span>
                                            </td>
                                            <td>
                                                <a v-if="assign.slip" :href="assign.slip" target="_blank">View Slip</a>
                                                <span v-else>No slip</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showModal"></div>
</template>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
}

.modal {
    z-index: 1050;
}

.list-unstyled {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.badge {
    text-transform: capitalize;
}

.table {
    margin-bottom: 0;
}

.modal-body {
    max-height: 70vh;
    overflow-y: auto;
}

.sorting_1 ul {
    margin-bottom: 0.5rem;
}

.sorting_1 ul:last-child {
    margin-bottom: 0;
}

.sorting_1 strong {
    color: #333;
    font-size: 0.9rem;
}

.sorting_1 .small {
    font-size: 0.85rem;
}

.text-muted.small {
    font-size: 0.85rem;
    margin-left: 0.5rem;
}
</style>
