<template>
    <Breadcrumbs main="Apps" title="Rate Charts" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <RateChartList />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const RateChartList = defineAsyncComponent(() => import("@/components/ratechart/ratechart.vue"))
</script>