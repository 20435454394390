<template>
    <div class="employee-details">
        <div class="search-section">
            <button class="back-btn ml-4 mb-3" @click="handleBack">
                ← Back to Sites
            </button>
            <div class="search-inputs">
                <div class="date-range">
                    <label>Search by date</label>
                    <div class="d-flex align-items-center">
                        <input type="date" class="form-control" v-model="searchByDateFrom">
                        <span class="mx-2">to</span>
                        <input type="date" class="form-control" v-model="searchByDateTo">
                    </div>
                </div>
                <div class="employee-search">
                    <label>Search by emp.name</label>
                    <input type="text" class="form-control" v-model="searchByEmployee"
                        placeholder="Search employee name...">
                </div>
            </div>
            <button class="download-btn" @click="downloadExcel">Download excel</button>
        </div>

        <div class="table-responsive">
            <table class="esic-table">
                <thead>
                    <tr>
                        <th>SL No</th>
                        <th>Employee Code</th>
                        <th>Employee Name</th>
                        <th>Department</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loading">
                        <td colspan="5" class="text-center">Loading...</td>
                    </tr>
                    <tr v-else-if="filteredEmployees.length === 0">
                        <td colspan="5" class="text-center">No employees found</td>
                    </tr>
                    <tr v-else v-for="(employee, index) in filteredEmployees" :key="employee.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ employee.code }}</td>
                        <td>{{ employee.name }}</td>
                        <td>{{ employee.department }}</td>
                        <td>
                            <button class="btn btn-primary" @click="viewEmployee(employee.id)">
                                View
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="showModal" class="modal-overlay">
            <div class="modal-content">
                <div class="modal-header">
                    <h3>Employee Details</h3>
                    <button class="close-btn" @click="showModal = false">&times;</button>
                </div>
                <div class="modal-body" v-if="selectedEmployee">
                    <div class="detail-group">
                        <h4>Basic Information</h4>
                        <p><strong>Employee Code:</strong> {{ selectedEmployee.employeeCode }}</p>
                        <p><strong>Name:</strong> {{ selectedEmployee.employeeName }}</p>
                        <p><strong>Father's Name:</strong> {{ selectedEmployee.fathersName }}</p>
                        <p><strong>Date of Birth:</strong> {{ formatDate(selectedEmployee.dateOfBirth) }}</p>
                        <p><strong>Email:</strong> {{ selectedEmployee.email }}</p>
                        <p><strong>Employee Type:</strong> {{ selectedEmployee.employeeType }}</p>
                    </div>

                    <div class="detail-group">
                        <h4>Status Information</h4>
                        <p><strong>Status:</strong> {{ selectedEmployee.status }}</p>
                        <p><strong>Blacklisted:</strong> {{ selectedEmployee.blacklisted ? 'Yes' : 'No' }}</p>
                        <p><strong>Uniform Given:</strong> {{ selectedEmployee.isUniformGiven ? 'Yes' : 'No' }}</p>
                    </div>

                    <div class="detail-group" v-if="selectedEmployee.chaseList">
                        <h4>Chase List Information</h4>
                        <p><strong>Chase Status:</strong> {{ selectedEmployee.chaseList.status }}</p>
                        <p><strong>Days Remaining:</strong> {{ selectedEmployee.chaseList.daysRemaining }}</p>
                        <p><strong>Type:</strong> {{ selectedEmployee.chaseList.type }}</p>
                    </div>

                    <div class="detail-group" v-if="selectedEmployee.legalInformation">
                        <h4>Legal Information</h4>
                        <p><strong>Criminal Offence:</strong> {{ selectedEmployee.legalInformation.criminalOffence.exists ? 'Yes' : 'No' }}</p>
                        <p><strong>Pending Proceedings:</strong> {{ selectedEmployee.legalInformation.pendingProceedings.exists ? 'Yes' : 'No' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import * as XLSX from 'xlsx'
import { useRouter } from 'vue-router'

const router = useRouter()
const siteId = router.currentRoute.value.params.id

const baseUrl = process.env.VUE_APP_BASE_URL

const employees = ref([])
const loading = ref(false)
const searchByDateFrom = ref('')
const searchByDateTo = ref('')
const searchByEmployee = ref('')

const selectedSiteId = ref(null)
const handleBack = () => {
    router.push('/pf-esic-details/list')
}

const filteredEmployees = computed(() => {
    let filtered = [...employees.value]

    if (searchByEmployee.value) {
        filtered = filtered.filter(emp =>
            emp.name.toLowerCase().includes(searchByEmployee.value.toLowerCase())
        )
    }

    if (searchByDateFrom.value && searchByDateTo.value) {
        filtered = filtered.filter(emp => {
            const empDate = new Date(emp.joiningDate)
            return empDate >= new Date(searchByDateFrom.value) &&
                empDate <= new Date(searchByDateTo.value)
        })
    }

    return filtered
})

const showModal = ref(false)
const selectedEmployee = ref(null)

const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString()
}

const viewEmployee = async (employeeId) => {
    try {
        const response = await fetch(`${baseUrl}api/joining/employee/${employeeId}`)
        const result = await response.json()
        
        if (result.success) {
            selectedEmployee.value = result.data
            showModal.value = true
        } else {
            console.error('Failed to fetch employee details')
        }
    } catch (error) {
        console.error('Error fetching employee details:', error)
    }
}

const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(employees.value)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Employee Details')
    XLSX.writeFile(workbook, 'employee_details.xlsx')
}

const fetchEmployees = async () => {
    loading.value = true
    try {
        const response = await fetch(`${baseUrl}api/joining/employees/site/${siteId}`)
        const result = await response.json()
        
        if (result.success) {
            employees.value = result.data.map(emp => ({
                id: emp._id,
                code: emp.employeeCode,
                name: emp.employeeName,
                department: emp.department?.name || 'N/A',
                joiningDate: emp.createdAt
            }))
        } else {
            console.error('Failed to fetch employees')
        }
    } catch (error) {
        console.error('Error fetching employees:', error)
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    fetchEmployees()
})
</script>

<style scoped>
.employee-details {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-section {
    padding: 20px;
    border-bottom: 1px solid #eee;
}

.search-inputs {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.date-range,
.employee-search {
    flex: 1;
}

.date-range label,
.employee-search label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

.d-flex {
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-control {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.form-control:focus {
    border-color: #4318FF;
    outline: none;
    box-shadow: 0 0 0 2px rgba(67, 24, 255, 0.1);
}

.download-btn {
    padding: 8px 16px;
    background-color: #4318FF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
}

.download-btn:hover {
    background-color: #3311cc;
}

.table-responsive {
    overflow-x: auto;
    padding: 0 20px 20px;
}

.esic-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 20px 0;
}

.esic-table th,
.esic-table td {
    padding: 12px 16px;
    text-align: left;
    border: 1px solid #eee;
}

.esic-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
}

.text-center {
    text-align: center;
}

.esic-table tbody tr:hover {
    background-color: #f8f9fa;
}

.mx-2 {
    margin: 0 8px;
}

.btn {
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-primary {
    background-color: #4318FF;
    color: white;
    border: none;
}

.btn-primary:hover {
    background-color: #3311cc;
}

.back-btn {
    padding: 8px 16px;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}

.back-btn:hover {
    background-color: #cfcece;
}

.ml-4 {
    margin-left: 1.5rem;  /* 24px */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-header {
    padding: 1rem;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-body {
    padding: 1rem;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666;
}

.close-btn:hover {
    color: #333;
}

.detail-group {
    margin-bottom: 1.5rem;
}

.detail-group h4 {
    color: #333;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.5rem;
}

.detail-group p {
    margin: 0.5rem 0;
    color: #666;
}

.detail-group strong {
    color: #333;
}
</style>