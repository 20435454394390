<template>
    <div class="analysis-section mt-4">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Analysis</h6>
                        <div class="mt-3">
                            <p><strong>Total Margin:</strong> {{ totalMargin }}</p>
                            <p><strong>Total Stock:</strong> {{ totalStock }}</p>
                            <p><strong>Total Revenue Raised:</strong> {{ totalRevenue }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Graph</h6>
                        <canvas ref="chartCanvas"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import Chart from 'chart.js/auto'

const chartCanvas = ref<HTMLCanvasElement | null>(null)

// These would typically come from props or a store
const totalMargin = computed(() => 'Total2 - Total1')
const totalStock = computed(() => 'Total1')
const totalRevenue = computed(() => 'B of sales')

onMounted(() => {
    if (chartCanvas.value) {
        new Chart(chartCanvas.value, {
            type: 'bar',
            data: {
                labels: ['Purchased', 'In Stock', 'Returned', 'Sold', 'Recovered', 'Bad Debt'],
                datasets: [{
                    label: 'Uniform Account Analysis',
                    data: [300, 200, 100, 250, 180, 50],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        })
    }
})
</script>