<template>
    <Breadcrumbs main="Join Us" title="Submissions" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Join Us Submissions</h5>
                        </div>
                        <div class="card-body">
                            <!-- Search Section -->
                            <div class="row mb-3">
                                <div class="col-md-4">
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        v-model="filterQuery" 
                                        placeholder="Search submissions..."
                                    >
                                </div>
                            </div>

                            <!-- Submissions Table -->
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Subject</th>
                                            <th>Message</th>
                                            <th>Resume</th>
                                            <th>Submitted On</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!filteredSubmissions.length">
                                        <tr>
                                            <td colspan="7" class="text-center">No submissions found</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="submission in filteredSubmissions" :key="submission._id">
                                            <td>{{ submission.name }}</td>
                                            <td>{{ submission.email }}</td>
                                            <td>{{ submission.subject }}</td>
                                            <td>{{ truncateText(submission.message, 50) }}</td>
                                            <td>
                                                <a 
                                                    v-if="submission.resume?.url" 
                                                    :href="submission.resume.url" 
                                                    target="_blank"
                                                    class="btn btn-primary btn-sm"
                                                >
                                                    View Resume
                                                </a>
                                                <span v-else>No resume</span>
                                            </td>
                                            <td>{{ formatDate(submission.createdAt) }}</td>
                                            <td>
                                                <button 
                                                    class="btn btn-info btn-sm me-2"
                                                    @click="viewDetails(submission)"
                                                >
                                                    View
                                                </button>
                                                <button 
                                                    class="btn btn-danger btn-sm"
                                                    @click="deleteSubmission(submission._id)"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Details Modal -->
    <div class="modal fade" id="detailsModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Submission Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body" v-if="selectedSubmission">
                    <div class="mb-3">
                        <strong>Name:</strong> {{ selectedSubmission.name }}
                    </div>
                    <div class="mb-3">
                        <strong>Email:</strong> {{ selectedSubmission.email }}
                    </div>
                    <div class="mb-3">
                        <strong>Subject:</strong> {{ selectedSubmission.subject }}
                    </div>
                    <div class="mb-3">
                        <strong>Message:</strong>
                        <p>{{ selectedSubmission.message }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { Modal } from 'bootstrap';
import Swal from 'sweetalert2';
import { fetchGetApi, fetchDeleteApi } from '@/util/api';

interface Submission {
    _id: string;
    name: string;
    email: string;
    subject: string;
    message: string;
    resume?: {
        key: string;
        url: string;
    };
    createdAt: string;
}

const submissions = ref<Submission[]>([]);
const filterQuery = ref('');
const selectedSubmission = ref<Submission | null>(null);
//@ts-ignore
const modalInstance = ref<Modal | null>(null);
const toast = useToast();
const baseUrl = process.env.VUE_APP_BASE_URL;

const filteredSubmissions = computed(() => {
    const query = filterQuery.value.toLowerCase();
    return submissions.value.filter(submission => 
        submission.name.toLowerCase().includes(query) ||
        submission.email.toLowerCase().includes(query) ||
        submission.subject.toLowerCase().includes(query)
    );
});

onMounted(async () => {
    modalInstance.value = new Modal(document.getElementById('detailsModal') as HTMLElement);
    await fetchSubmissions();
});

async function fetchSubmissions() {
    try {
        const response = await fetchGetApi(`api/joinus`);
        if (response.data.success) {
            submissions.value = response.data.data;
        }
    } catch (error: any) {
        toast.error(error.response?.data?.message || 'Failed to fetch submissions');
    }
}

function viewDetails(submission: Submission) {
    selectedSubmission.value = submission;
    modalInstance.value?.show();
}

async function deleteSubmission(id: string) {
    try {
        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: "This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (confirmed.isConfirmed) {
            const response = await fetchDeleteApi(`api/joinus/${id}`);
            if (response.data.success) {
                toast.success('Submission deleted successfully');
                await fetchSubmissions();
            }
        }
    } catch (error: any) {
        toast.error(error.response?.data?.message || 'Failed to delete submission');
    }
}

function truncateText(text: string, length: number): string {
    return text.length > length ? text.substring(0, length) + '...' : text;
}

function formatDate(date: string): string {
    return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
}
</script>