<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Percentage Settings</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6" v-for="(setting, index) in settingsData" :key="index">
                <div class="mb-3">
                  <label class="form-label">{{ formatLabel(setting.label) }}</label>
                  <input :type="setting.type" class="form-control" v-model="setting.value"
                    :placeholder="setting.placeholder" :min="setting.type === 'number' ? 1 : undefined"
                    :max="setting.type === 'number' ? 20 : undefined" @change="setting.changed = true" />
                </div>
              </div>
            </div>
            <div class="text-end mt-4">
              <button class="btn btn-primary" @click="saveSettings" :disabled="isLoading || !hasChanges">
                <span v-if="isLoading">
                  <i class="fa fa-spinner fa-spin me-2"></i>Saving...
                </span>
                <span v-else>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { fetchGetApi, fetchPostApi } from '@/util/api'
import { useToast } from 'vue-toast-notification'

const toast = useToast()
const baseUrl = process.env.VUE_APP_BASE_URL
const isLoading = ref(false)

interface Setting {
  key: string
  label: string
  value: string | number
  type: string
  placeholder: string
  changed: boolean
  originalValue?: string | number
}

const settingsData = ref<Setting[]>([])

const hasChanges = computed(() => {
  return settingsData.value.some(setting => setting.changed)
})

const formatLabel = (label: string) => {
  return label.replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())
}

const getSettings = async () => {
  try {
    const settingKeys = ['basicPercentage', 'hraPercentage', 'esicPercentage', 'epfPercentage']
    const settings = []

    for (const key of settingKeys) {
      const response = await fetchGetApi(`api/settings/get/key/${key}`)
      if (response.data) {
        settings.push({
          key: key,
          label: key,
          value: response.data.value || '',
          type: key === 'siteIdLength' ? 'number' : 'text',
          placeholder: `Enter ${key}`,
          changed: false,
          originalValue: response.data.value || ''
        })
      }
    }

    settingsData.value = settings
  } catch (error) {
    console.error('Error fetching settings:', error)
    toast.error('Failed to load settings')
  }
}

const saveSettings = async () => {
  try {
    isLoading.value = true
    const changedSettings = settingsData.value.filter(setting => setting.changed)

    for (const setting of changedSettings) {
      await fetchPostApi(`api/settings/update`, {
        key: setting.key,
        value: setting.value
      })
      setting.changed = false
      setting.originalValue = setting.value
    }

    toast.success('GST setting saved successfully')
  } catch (error) {
    console.error('Error saving GST setting:', error)
    toast.error('Failed to save GST setting')
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getSettings()
})
</script>

<style scoped>
.card {
  margin-bottom: 1.5rem;
}

.form-control {
  border: 1px solid #dee2e6;
  padding: 0.5rem;
}

.form-control:focus {
  border-color: #3e5fce;
  box-shadow: none;
}
</style>