<template>
    <div class="card">
        <div class="card-body">
            <form @submit.prevent="handleSubmit">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <button type="button" class="btn btn-secondary" @click="handleBack">
                        <i class="fa fa-arrow-left me-2"></i>
                        Back to List
                    </button>
                </div>

                <div class="row">
                    <!-- Picture Upload -->
                    <div class="col-md-3 text-center mb-4">
                        <div class="picture-container">
                            <img :src="previewUrl || '/path/to/default-avatar.png'"
                                class="rounded-circle profile-pic mb-2" alt="Employee Picture">
                        </div>
                    </div>

                    <!-- Form Fields -->
                    <div class="col-md-9">
                        <div class="row">
                            <!-- Name -->
                            <div class="col-md-6 mb-3">
                                <label class="form-label">Name</label>
                                <input type="text" class="form-control" :value="employee.name" readonly>
                            </div>

                            <!-- Employee Code -->
                            <div class="col-md-6 mb-3">
                                <label class="form-label">Employee Code</label>
                                <input type="text" class="form-control" :value="employee.code" readonly>
                            </div>

                            <!-- Site Name -->
                            <div class="col-md-6 mb-3">
                                <label class="form-label">Site Name</label>
                                <input type="text" class="form-control" :value="employee.siteName" readonly>
                            </div>

                            <!-- Department -->
                            <div class="col-md-6 mb-3">
                                <label class="form-label">Department</label>
                                <input type="text" class="form-control" :value="employee.department" readonly>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Uniform Items Selection -->
                <div class="mt-4">
                    <h5 class="mb-3">Select Uniform Items</h5>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Item Name</th>
                                    <th>Size</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in selectedItems" :key="index">
                                    <td>
                                        <select class="form-select" v-model="item.productId"
                                            @change="handleProductChange(index)">
                                            <option value="">Select Item</option>
                                            <option v-for="product in uniformProducts" :key="product._id"
                                                :value="product._id">
                                                {{ product.itemName }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-select" v-model="item.size"
                                            @change="handleSizeChange(index)" :disabled="!item.productId">
                                            <option value="">Select Size</option>
                                            <option v-for="sizeOption in getAvailableSizes(item.productId)"
                                                :key="sizeOption.size" :value="sizeOption.size"
                                                :disabled="sizeOption.quantity === 0">
                                                {{ sizeOption.size }} ({{ sizeOption.quantity }} available)
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        Rs. {{ getItemPrice(item) }}
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" v-model="item.quantity" min="1"
                                            :max="getMaxQuantity(item)" :disabled="!item.size">
                                    </td>
                                    <td>
                                        Rs. {{ calculateItemTotal(item) }}
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-danger btn-sm" @click="removeItem(index)">
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="4" class="text-end"><strong>Grand Total:</strong></td>
                                    <td colspan="2"><strong>Rs. {{ calculateGrandTotal() }}</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <button type="button" class="btn btn-secondary btn-sm mt-2" @click="addItem">
                        Add Item
                    </button>
                </div>

                <!-- Submit Button -->
                <div class="text-end mt-4">
                    <button type="submit" class="btn btn-primary">
                        Issue Uniform
                    </button>
                </div>
            </form>
        </div>
    </div>
    <finance-details-modal v-if="showFinanceModal" :employee="employee" :items="selectedItems"
        :is-unavailable="isItemsUnavailable" @close="showFinanceModal = false" @order-placed="handleOrderPlaced" />
</template>

<script lang="ts" setup>
import { ref, defineAsyncComponent, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useRouter, useRoute } from 'vue-router';
import { fetchGetApi, fetchPostApi } from '@/util/api';
const FinanceDetailsModal = defineAsyncComponent(() => import('./finance-details-modal.vue'));
const baseUrl = process.env.VUE_APP_BASE_URL;
const router = useRouter();
const route = useRoute();

const handleBack = () => {
    router.push('/uniform/issue');
};

interface Employee {
    name: string;
    code: string;
    siteName: string;
    department: string;
    picture?: string;
}

interface UniformItem {
    productId: string;
    name: string;
    size: string;
    quantity: number;
    basePrice: number;
    additionalPrice: number;
}

interface SizeQuantity {
    size: string;
    quantity: number;
    additionalPrice: number;
}

interface UniformProduct {
    _id: string;
    itemName: string;
    department: {
        _id: string;
        name: string;
    };
    basePrice: number;
    hasSizes: boolean;
    sizes: SizeQuantity[];
    totalQuantity: number;
    status: string;
    minStockThreshold: number;
}

// Sample employee data
const employee = ref<Employee>({
    name: '',
    code: '',
    siteName: '',
    department: '',
    picture: '',
    _id: '',
    departmentId: '',
    siteId: ''
});

const toast = useToast();
const pictureFile = ref<File | null>(null);
const previewUrl = ref<string>('');
const showFinanceModal = ref(false);
const isItemsUnavailable = ref(true);

// Uniform items data
const uniformItems = ref<string[]>([]);
const uniformProducts = ref<UniformProduct[]>([]);
const selectedItems = ref<UniformItem[]>([
    {
        productId: '',
        name: '',
        size: '',
        quantity: 1,
        basePrice: 0,
        additionalPrice: 0
    }
]);

const getAvailableSizes = (productId: string) => {
    const product = uniformProducts.value.find(p => p._id === productId);
    return product?.hasSizes ? product.sizes : [];
};

const fetchUniformItems = async (departmentId: string) => {
    try {
        const response = await fetchGetApi(`api/product/department/${departmentId}`);
        if (response.data.success) {
            uniformProducts.value = response.data.data;
        } else {
            throw new Error('Failed to fetch uniform items');
        }
    } catch (error: any) {
        console.error('Error fetching uniform items:', error);
        toast.error(error.message || 'Failed to fetch uniform items');
    }
};

const fetchEmployeeDetails = async (employeeCode: string) => {
    try {
        const response = await fetchGetApi(`api/joining/employee/code/${employeeCode}`);

        if (response.data.success) {
            const data = response.data.data;
            employee.value = {
                name: data.employeeName || '',
                code: data.employeeCode || '',
                siteName: data.site?.siteName || '',
                department: data.department?.name || '',
                picture: data.picture || 'https://png.pngtree.com/png-vector/20230831/ourmid/pngtree-man-avatar-image-for-profile-png-image_9197908.png',
                _id: data._id || '',
                departmentId: data.department?._id || '',
                siteId: data.site?._id || ''
            };
            previewUrl.value = employee.value.picture || 'https://png.pngtree.com/png-vector/20230831/ourmid/pngtree-man-avatar-image-for-profile-png-image_9197908.png';

            // Fetch uniform items based on department ID
            if (data.department?._id) {
                await fetchUniformItems(data.department._id);
            }
        } else {
            throw new Error('Failed to fetch employee details');
        }
    } catch (error: any) {
        console.error('Error fetching employee details:', error);
        toast.error(error.message || 'Failed to fetch employee details');
        router.push('/uniform/issue');
    }
};

onMounted(() => {
    const employeeCode = route.query.employeeCode as string;
    if (!employeeCode) {
        toast.error('Employee code is required');
        router.push('/uniform/issue');
        return;
    }
    fetchEmployeeDetails(employeeCode);
});

const handlePictureUpload = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
        pictureFile.value = input.files[0];
        previewUrl.value = URL.createObjectURL(input.files[0]);
    }
};

const addItem = () => {
    selectedItems.value.push({
        productId: '',
        name: '',
        size: '',
        quantity: 1,
        basePrice: 0,
        additionalPrice: 0
    });
};

const removeItem = (index: number) => {
    selectedItems.value.splice(index, 1);
};

const handleProductChange = (index: number) => {
    const item = selectedItems.value[index];
    const product = uniformProducts.value.find(p => p._id === item.productId);

    if (product) {
        item.name = product.itemName;
        item.basePrice = product.basePrice;
        item.size = '';
        item.quantity = 1;
        item.additionalPrice = 0;
    }
};

const handleSizeChange = (index: number) => {
    const item = selectedItems.value[index];
    const product = uniformProducts.value.find(p => p._id === item.productId);

    if (product && product.hasSizes) {
        const selectedSize = product.sizes.find(s => s.size === item.size);
        if (selectedSize) {
            item.additionalPrice = selectedSize.additionalPrice;
        }
    }
};

const getItemPrice = (item: UniformItem) => {
    const totalPrice = item.basePrice + item.additionalPrice;
    return totalPrice.toFixed(2);
};

const calculateItemTotal = (item: UniformItem) => {
    const price = parseFloat(getItemPrice(item));
    return (price * item.quantity).toFixed(2);
};

const calculateGrandTotal = () => {
    return selectedItems.value.reduce((total, item) => {
        return total + (parseFloat(calculateItemTotal(item)) || 0);
    }, 0).toFixed(2);
};

const getMaxQuantity = (item: UniformItem) => {
    if (!item.productId || !item.size) return 0;

    const product = uniformProducts.value.find(p => p._id === item.productId);
    if (!product) return 0;

    if (product.hasSizes) {
        const sizeInfo = product.sizes.find(s => s.size === item.size);
        return sizeInfo?.quantity || 0;
    }

    return product.totalQuantity;
};

const handleSubmit = () => {
    console.log('Submit clicked'); // Debug log

    // Validate selections
    if (!selectedItems.value.every(item => item.productId && item.quantity > 0)) {
        toast.error('Please fill in all item details');
        return;
    }

    // Check if any items are unavailable
    isItemsUnavailable.value = selectedItems.value.some(item => {
        const maxQty = getMaxQuantity(item);
        return maxQty < item.quantity;
    });

    console.log('Showing modal:', showFinanceModal.value); // Debug log
    showFinanceModal.value = true;
    console.log('Modal shown:', showFinanceModal.value); // Debug log
};

const handleOrderPlaced = () => {
    showFinanceModal.value = false;
    toast.success('Order placed successfully');
    router.push('/uniform/issue');
};
</script>

<style scoped>
.profile-pic {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.picture-container {
    position: relative;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}
</style>