<template>
    <Breadcrumbs main="Users" title="Leaves" />
    <div class="container-fluid">
        <div class="user-profile">
            <div class="row">
                <profileHeader />
                <div class="col-xl-12">
                    <div class="default-according style-1 job-accordion">
                        <div class="row">
                            <defaultAccording />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent, provide, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { fetchGetApi } from '@/util/api';

const route = useRoute();
const id = ref(route.params.id);

const profileHeader = defineAsyncComponent(() => import("@/components/leaves/profile/profileHeader.vue"));
const defaultAccording = defineAsyncComponent(() => import("@/components/common/defaultAccording.vue"));

const baseUrl = process.env.VUE_APP_BASE_URL;

const leaveData = ref(null);

provide('leaveData', leaveData);

onMounted(async () => {
    const ID = id.value;
    await getLeaveById(ID);
});

const token = localStorage.getItem('token')


async function getLeaveById(ID: string) {
    try {
        const response = await fetch(`${baseUrl}api/leave/get/${ID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        leaveData.value = data;
        console.log(data);
    } catch (error) {
        console.error("Failed to fetch leave data:", error);
    }
}
</script>