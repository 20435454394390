<script setup>
import { ref, computed, onMounted } from 'vue';
import { jsonToExcel } from "@/util/makeExcel";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

// Filter refs
const nameFilter = ref('All');
const fromDate = ref('');
const toDate = ref('');
const serviceFilter = ref('All');
const baseUrl = process.env.VUE_APP_BASE_URL;

// Data refs
const competitorData = ref([]);
const summary = ref({
    totalReports: 0,
    totalProjects: 0,
    totalManpower: 0,
    averageManpower: 0
});

// Add these new refs after existing refs
const services = ref(['All']);
const competitors = ref(['All']);

const toast = useToast();

// Fetch data from API
async function fetchData() {
    try {
        const response = await fetch(`${baseUrl}api/competitor-report`);
        const result = await response.json();
        if (result.success) {
            competitorData.value = result.data;
            summary.value = result.summary;
            
            // Extract unique modes of service
            const uniqueServices = new Set();
            result.data.forEach(item => {
                const modes = item.modeOfService.split(',').map(s => s.trim());
                modes.forEach(mode => {
                    if (mode && mode !== 'Not specified') uniqueServices.add(mode);
                });
            });
            services.value = ['All', ...Array.from(uniqueServices)].filter(Boolean);

            // Extract unique competitor names
            const uniqueCompetitors = new Set(result.data.map(item => item.competitorName));
            competitors.value = ['All', ...Array.from(uniqueCompetitors)].filter(Boolean);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        services.value = ['All'];
        competitors.value = ['All'];
    }
}

// Load data on component mount
onMounted(async () => {
    try {
        await fetchData();
        serviceFilter.value = 'All';
    } catch (error) {
        console.error('Error during initialization:', error);
        toast.error('Error loading data');
    }
});

// Computed filtered data
const filteredData = computed(() => {
    return competitorData.value.filter(item => {
        const matchesName = nameFilter.value === 'All' || 
            item.competitorName === nameFilter.value;
        
        // Fix date comparison by normalizing dates to start/end of day
        const itemDate = new Date(item.date);
        itemDate.setHours(0, 0, 0, 0);

        let fromDateObj = null;
        let toDateObj = null;

        if (fromDate.value) {
            fromDateObj = new Date(fromDate.value);
            fromDateObj.setHours(0, 0, 0, 0);
        }
        if (toDate.value) {
            toDateObj = new Date(toDate.value);
            toDateObj.setHours(23, 59, 59, 999);
        }

        const matchesDateRange = (!fromDateObj || itemDate >= fromDateObj) &&
            (!toDateObj || itemDate <= toDateObj);
            
        const matchesService = serviceFilter.value === 'All' || 
            (item.modeOfService && 
             item.modeOfService.split(',').map(s => s.trim())
                .includes(serviceFilter.value));

        return matchesName && matchesDateRange && matchesService;
    });
});

function clearFilters() {
    nameFilter.value = 'All';
    fromDate.value = '';
    toDate.value = '';
    serviceFilter.value = 'All';
}

function downloadExcel() {
    const dataToExport = filteredData.value.map((item) => ({
        'Sl no': item.srNo || '',
        'Date': item.date || '',
        'Project Name': item.projectName || '',
        'Competitor Name': item.competitorName || '',
        'Area': item.area || '',
        'Mode of Service': item.modeOfService || '',
        'Designation': item.designation || '',
        'Service wise manpower': item.serviceWiseManpower || '',
        'Total manpower': item.totalManpower || '',
        'Working hour': item.workingHour || '',
        'Minimum wages': item.minimumWages || '',
        'Salary': item.salary || '',
        'Food': item.food || '',
        'Stay': item.stay || '',
        'ESIC/PF': item.esicPf || '',
        'Weekly off': item.weeklyOff || '',
        'Agreement Duration': item.agreementDuration || '',
        'Special mention': item.specialMention || ''
    }));

    const timestamp = new Date().toISOString().split('T')[0];
    jsonToExcel(dataToExport, `competitor-analysis-report-${timestamp}.xlsx`);
    toast.success('Excel file downloaded successfully');
}
</script>

<template>
    <div class="container-fluid">
        <!-- Summary Section -->
        <div class="row mb-4">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Total Reports</h6>
                        <h3>{{ summary.totalReports }}</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Total Projects</h6>
                        <h3>{{ summary.totalProjects }}</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Total Manpower</h6>
                        <h3>{{ summary.totalManpower }}</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Average Manpower</h6>
                        <h3>{{ summary.averageManpower }}</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <!-- Filters Section -->
                <div class="row mb-4">
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Competitor Name</label>
                        <select 
                            class="form-select" 
                            v-model="nameFilter"
                        >
                            <option 
                                v-for="competitor in competitors" 
                                :key="competitor" 
                                :value="competitor"
                            >
                                {{ competitor }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Date Range</label>
                        <div class="d-flex gap-2">
                            <input 
                                type="date" 
                                class="form-control" 
                                v-model="fromDate"
                                placeholder="From"
                            >
                            <input 
                                type="date" 
                                class="form-control" 
                                v-model="toDate"
                                placeholder="To"
                            >
                        </div>
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Service</label>
                        <select 
                            class="form-select" 
                            v-model="serviceFilter"
                        >
                            <option 
                                v-for="service in services" 
                                :key="service" 
                                :value="service"
                            >
                                {{ service }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button 
                            class="btn btn-secondary" 
                            @click="clearFilters"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button type="button" class="btn btn-primary" @click="downloadExcel">
                            <i class="fas fa-download me-1"></i>
                            Download
                        </button>
                    </div>
                </div>

                <!-- Table Section -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="width: 60px">Sl no</th>
                                <th style="width: 100px">Date</th>
                                <th style="width: 150px">Project name</th>
                                <th style="width: 150px">Competitor Name</th>
                                <th style="width: 120px">Area</th>
                                <th style="width: 180px">Mode of Service</th>
                                <th style="width: 120px">Designation</th>
                                <th style="width: 150px">Service wise<br>manpower</th>
                                <th style="width: 100px">Total<br>manpower</th>
                                <th style="width: 120px">Working hour</th>
                                <th style="width: 90px">Minimum<br>wages</th>
                                <th style="width: 90px">Salary</th>
                                <th style="width: 90px">Food</th>
                                <th style="width: 90px">Stay</th>
                                <th style="width: 90px">ESIC/PF</th>
                                <th style="width: 90px">Weekly off</th>
                                <th style="width: 150px">Agreement<br>Duration</th>
                                <th style="width: 80px">Special<br>mention</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in filteredData" :key="item.srNo">
                                <td class="text-wrap">{{ item.srNo }}</td>
                                <td class="text-wrap">{{ item.date }}</td>
                                <td class="text-wrap">{{ item.projectName }}</td>
                                <td class="text-wrap">{{ item.competitorName }}</td>
                                <td class="text-wrap">{{ item.area }}</td>
                                <td class="text-wrap">{{ item.modeOfService }}</td>
                                <td class="text-wrap">{{ item.designation }}</td>
                                <td class="text-wrap">{{ item.serviceWiseManpower }}</td>
                                <td class="text-wrap">{{ item.totalManpower }}</td>
                                <td class="text-wrap">{{ item.workingHour }}</td>
                                <td class="text-wrap">{{ item.minimumWages }}</td>
                                <td class="text-wrap">{{ item.salary }}</td>
                                <td class="text-wrap">{{ item.food }}</td>
                                <td class="text-wrap">{{ item.stay }}</td>
                                <td class="text-wrap">{{ item.esicPf }}</td>
                                <td class="text-wrap">{{ item.weeklyOff }}</td>
                                <td class="text-wrap">{{ item.agreementDuration }}</td>
                                <td class="text-wrap">{{ item.specialMention }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.table {
    table-layout: fixed;
    width: 100%;
}

.table th,
.table td {
    vertical-align: middle;
    font-size: 0.875rem;
    padding: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table td.text-wrap {
    white-space: normal;
    word-wrap: break-word;
}

.form-label {
    font-size: 0.875rem;
    font-weight: 500;
}

.card {
    margin-bottom: 1rem;
}

.card-title {
    color: #6c757d;
    margin-bottom: 0.5rem;
}

.card h3 {
    margin-bottom: 0;
    color: #2c3e50;
}

.form-select {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    background-color: #fff;
}

.form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #212529;
}

.gap-2 {
    gap: 0.5rem;
}

.d-flex {
    display: flex;
}

.d-flex input[type="date"] {
    width: 50%;
}
</style>