<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                    <h5>NFD Analysis</h5>
                    <span>View total follow-ups by each employee</span>
                </div>
                <button class="btn btn-primary" @click="downloadExcel">
                    <i class="fas fa-download me-1"></i>
                    Download Excel
                </button>
            </div>
            <div class="card-body">
                <div v-if="isLoading" class="text-center py-5">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div v-else class="table-responsive product-table custom-scrollbar">
                    <!-- Filters Section -->
                    <div class="row mb-4">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="form-label">Search Employee</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="filters.search" 
                                    placeholder="Search by name or code..."
                                >
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="form-label">Sort By</label>
                                <select class="form-select" v-model="filters.sortBy">
                                    <option value="name">Employee Name</option>
                                    <option value="code">Employee Code</option>
                                    <option value="followups">Total Follow-ups</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="form-label">Sort Order</label>
                                <select class="form-select" v-model="filters.sortOrder">
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="form-label">Min Follow-ups</label>
                                <input 
                                    type="number" 
                                    class="form-control" 
                                    v-model="filters.minFollowups" 
                                    placeholder="Minimum follow-ups..."
                                >
                            </div>
                        </div>
                    </div>

                    <!-- Summary Card -->
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <strong>Total Employees:</strong>
                                    {{ totalEmployees }}
                                </div>
                                <div class="col">
                                    <strong>Filtered Employees:</strong>
                                    {{ filteredEmployees.length }}
                                </div>
                                <div class="col">
                                    <strong>Total Follow-ups:</strong>
                                    {{ totalFollowups }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <table class="display table-striped table-hover table-bordered table" id="basic-1">
                        <thead>
                            <tr>
                                <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                <th class="sorting_asc text-nowrap" scope="col">Employee Name</th>
                                <th class="sorting_asc text-nowrap" scope="col">Employee Code</th>
                                <th class="sorting_asc text-nowrap" scope="col">Phone Number</th>
                                <th class="sorting_asc text-nowrap" scope="col">Total Follow-ups</th>
                                <th class="sorting_asc text-nowrap" scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!filteredEmployees.length">
                                <td colspan="6" class="text-center">No data available</td>
                            </tr>
                            <tr v-for="(employee, index) in filteredEmployees" :key="employee._id">
                                <td>{{ ((currentPage - 1) * itemsPerPage) + index + 1 }}</td>
                                <td>{{ employee.employeeName }}</td>
                                <td>{{ employee.employeeCode }}</td>
                                <td>{{ employee.phoneNumber }}</td>
                                <td>{{ employee.totalFollowUps }}</td>
                                <td>
                                    <button 
                                        class="btn btn-primary btn-sm" 
                                        @click="viewFollowups(employee)"
                                        title="View Follow-ups"
                                    >
                                        <i class="fa fa-eye"></i> View Follow-ups
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Pagination -->
                    <div class="d-flex justify-content-between align-items-center mt-4">
                        <div>
                            <select class="form-select" v-model="itemsPerPage">
                                <option :value="10">10 per page</option>
                                <option :value="25">25 per page</option>
                                <option :value="50">50 per page</option>
                                <option :value="100">100 per page</option>
                            </select>
                        </div>
                        <ul class="pagination mb-0">
                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                <button class="page-link" @click="currentPage--" :disabled="currentPage === 1">
                                    Previous
                                </button>
                            </li>
                            <li 
                                v-for="page in totalPages" 
                                :key="page" 
                                class="page-item"
                                :class="{ active: currentPage === page }"
                            >
                                <button class="page-link" @click="currentPage = page">{{ page }}</button>
                            </li>
                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                <button class="page-link" @click="currentPage++" :disabled="currentPage === totalPages">
                                    Next
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref, computed, watch, onMounted } from 'vue';
import * as XLSX from 'xlsx';

export default {
    name: 'NFDAnalysis',
    setup() {
        const employees = ref([]);
        const totalEmployees = ref(0);
        const isLoading = ref(true);
        const currentPage = ref(1);
        const itemsPerPage = ref(10);

        const filters = ref({
            search: '',
            sortBy: 'name',
            sortOrder: 'asc',
            minFollowups: ''
        });

        const filteredEmployees = computed(() => {
            let filtered = [...employees.value];

            // Apply search filter
            if (filters.value.search) {
                const searchTerm = filters.value.search.toLowerCase();
                filtered = filtered.filter(emp => 
                    emp.employeeName.toLowerCase().includes(searchTerm) ||
                    emp.employeeCode.toLowerCase().includes(searchTerm)
                );
            }

            // Apply minimum followups filter
            if (filters.value.minFollowups) {
                filtered = filtered.filter(emp => 
                    emp.totalFollowUps >= parseInt(filters.value.minFollowups)
                );
            }

            // Apply sorting
            filtered.sort((a, b) => {
                let compareA, compareB;
                
                switch (filters.value.sortBy) {
                    case 'name':
                        compareA = a.employeeName.toLowerCase();
                        compareB = b.employeeName.toLowerCase();
                        break;
                    case 'code':
                        compareA = a.employeeCode.toLowerCase();
                        compareB = b.employeeCode.toLowerCase();
                        break;
                    case 'followups':
                        compareA = a.totalFollowUps;
                        compareB = b.totalFollowUps;
                        break;
                    default:
                        return 0;
                }

                if (filters.value.sortOrder === 'asc') {
                    return compareA > compareB ? 1 : -1;
                } else {
                    return compareA < compareB ? 1 : -1;
                }
            });

            return filtered;
        });

        const totalPages = computed(() => {
            return Math.ceil(filteredEmployees.value.length / itemsPerPage.value);
        });

        const totalFollowups = computed(() => {
            return filteredEmployees.value.reduce((sum, emp) => sum + emp.totalFollowUps, 0);
        });

        const paginatedEmployees = computed(() => {
            const start = (currentPage.value - 1) * itemsPerPage.value;
            const end = start + itemsPerPage.value;
            return filteredEmployees.value.slice(start, end);
        });

        const fetchEmployeeFollowups = async () => {
            try {
                isLoading.value = true;
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.VUE_APP_BASE_URL}api/employees/with-followups`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.data.success) {
                    employees.value = response.data.data.employees;
                    totalEmployees.value = response.data.data.totalEmployees;
                }
            } catch (error) {
                console.error('Error fetching employee followups:', error);
            } finally {
                isLoading.value = false;
            }
        };

        const downloadExcel = () => {
            const data = filteredEmployees.value.map((employee, index) => ({
                'SL': index + 1,
                'Employee Name': employee.employeeName,
                'Employee Code': employee.employeeCode,
                'Phone Number': employee.phoneNumber,
                'Total Follow-ups': employee.totalFollowUps
            }));

            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Employee Followups');
            XLSX.writeFile(wb, 'employee-followups-report.xlsx');
        };

        const viewFollowups = (employee) => {
            // Navigate to employee's followups detail page
            window.open(`/reports/project/${employee._id}`, '_blank');
        };

        // Reset to first page when filters change
        watch([filters, itemsPerPage], () => {
            currentPage.value = 1;
        });

        onMounted(() => {
            fetchEmployeeFollowups();
        });

        return {
            employees,
            totalEmployees,
            isLoading,
            filters,
            currentPage,
            itemsPerPage,
            filteredEmployees,
            totalPages,
            totalFollowups,
            paginatedEmployees,
            downloadExcel,
            viewFollowups
        };
    }
};
</script>

<style scoped>
.table th {
    white-space: nowrap;
    background-color: #f8f9fa;
}

.custom-scrollbar {
    overflow-x: auto;
}

.card-body {
    padding: 1.25rem;
}

.form-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.pagination {
    margin-bottom: 0;
}

.page-link {
    cursor: pointer;
}

.page-item.active .page-link {
    background-color: #7366ff;
    border-color: #7366ff;
}
</style> 