<template>
    <Breadcrumbs main="Front Office" title="Joining" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <joining-form />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const JoiningForm = defineAsyncComponent(() => import("@/components/front-office/joining/joining-form.vue"))
</script>