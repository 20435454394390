<template>
    <Breadcrumbs main="Employees" title="Locked Employees" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <locked-employees-list />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const LockedEmployeesList = defineAsyncComponent(() => import("@/components/locked-employees/locked-employees-list.vue"))
</script>