// Audio notification utility
let audioContext: AudioContext | null = null;
let audioBuffer: AudioBuffer | null = null;
let soundInterval: number | null = null;
let isPlaying = false;
let activeNotifications = new Set<string>(); // Track active notifications by their tags
let lastPlayTime = 0;
const PLAY_INTERVAL = 30000; // 3 seconds between plays

// Initialize audio context
export async function initializeAudio() {
    if (audioContext && audioBuffer) {
        // If already initialized, just resume if needed
        if (audioContext.state === 'suspended') {
            await audioContext.resume();
        }
        return;
    }

    try {
        // Create audio context
        audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        
        // Fetch and decode audio file
        const response = await fetch('/notification.mp3');
        if (!response.ok) {
            throw new Error(`Failed to fetch audio file: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        
        // Resume context if needed (some browsers start in suspended state)
        if (audioContext.state === 'suspended') {
            await audioContext.resume();
        }
        
        console.log('Audio initialized successfully, context state:', audioContext.state);
        return true;
    } catch (error) {
        console.error('Audio initialization error:', error);
        throw error; // Propagate error for better handling
    }
}

// Play the sound once
async function playSound() {
    try {
        if (!audioContext || !audioBuffer) {
            await initializeAudio();
        }

        const now = Date.now();
        if (now - lastPlayTime < PLAY_INTERVAL) {
            return; // Prevent too frequent plays
        }

        // Resume context if needed
        if (audioContext?.state === 'suspended') {
            await audioContext.resume();
        }

        // Create new buffer source
        const source = audioContext!.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext!.destination);
        source.start(0);
        lastPlayTime = now;

        console.log('Playing notification sound, active notifications:', Array.from(activeNotifications));
    } catch (error) {
        console.error('Error playing sound:', error);
    }
}

// Enhanced force stop with notification cleanup
function forceStopAllSounds() {
    console.log('Force stopping all sounds...');
    
    try {
        // Log notifications being cleared
        const notificationsToStop = Array.from(activeNotifications);
        console.log('Clearing notifications:', notificationsToStop);
        
        // Clear all notifications
        activeNotifications.clear();
        
        // Stop the interval
        if (soundInterval) {
            clearInterval(soundInterval);
            soundInterval = null;
        }
        
        // Reset playing state
        isPlaying = false;
        
        // Reset last play time
        lastPlayTime = 0;
        
        // Stop any currently playing audio
        if (audioContext) {
            if (audioContext.state === 'running') {
                audioContext.suspend();
            }
            // Optionally, close and nullify the context for a complete reset
            audioContext.close();
            audioContext = null;
            audioBuffer = null;
        }
        
        console.log('All sounds and notifications forcefully stopped');
    } catch (error) {
        console.error('Error while forcing sound stop:', error);
    }
}

// Enhanced start continuous sound with duplicate prevention
export function startContinuousSound(notificationTag?: string) {
    // Prevent duplicate notifications and handle pending approvals
    if (notificationTag) {
        // If it's a pending approval notification
        if (notificationTag.startsWith('pending-approval-')) {
            // Check if any pending approval notification exists
            const existingPendingApproval = Array.from(activeNotifications).find(tag => tag.startsWith('pending-approval-'));
            if (existingPendingApproval) {
                console.log('Pending approval notification already active:', existingPendingApproval);
                return;
            }
        } else if (activeNotifications.has(notificationTag)) {
            // For other notifications, check exact duplicates
            console.log('Notification already active:', notificationTag);
            return;
        }
    }
    
    console.log('Starting continuous sound for tag:', notificationTag);
    
    if (notificationTag) {
        // Clear any existing pending approval notifications before adding new one
        if (notificationTag.startsWith('pending-approval-')) {
            Array.from(activeNotifications)
                .filter(tag => tag.startsWith('pending-approval-'))
                .forEach(tag => activeNotifications.delete(tag));
        }
        
        activeNotifications.add(notificationTag);
        console.log('Active notifications:', Array.from(activeNotifications));
    }

    // Start playing if not already playing
    if (!isPlaying) {
        isPlaying = true;
        playSound(); // Play immediately

        // Set up interval
        if (!soundInterval) {
            soundInterval = window.setInterval(() => {
                if (activeNotifications.size > 0) {
                    playSound();
                } else {
                    stopContinuousSound();
                }
            }, PLAY_INTERVAL);
        }
    }
}

// Enhanced stop continuous sound
export function stopContinuousSound(notificationTag?: string) {
    console.log('Stopping sound for tag:', notificationTag);
    
    if (notificationTag) {
        // If stopping a pending approval, stop all pending approvals
        if (notificationTag.startsWith('pending-approval-')) {
            const pendingApprovals = Array.from(activeNotifications)
                .filter(tag => tag.startsWith('pending-approval-'));
            pendingApprovals.forEach(tag => {
                activeNotifications.delete(tag);
                console.log('Removed pending approval:', tag);
            });
        } else if (activeNotifications.has(notificationTag)) {
            activeNotifications.delete(notificationTag);
            console.log('Notification removed:', notificationTag);
        }
        console.log('Remaining active notifications:', Array.from(activeNotifications));
        
        // If there are still other notifications, keep playing
        if (activeNotifications.size > 0) {
            return;
        }
    } else {
        // If no tag provided, clear all notifications
        const clearedNotifications = Array.from(activeNotifications);
        activeNotifications.clear();
        console.log('Cleared all notifications:', clearedNotifications);
    }

    // Stop the interval if no more active notifications
    if (soundInterval) {
        clearInterval(soundInterval);
        soundInterval = null;
    }
    isPlaying = false;
    
    // Suspend audio context
    if (audioContext?.state === 'running') {
        audioContext.suspend();
    }
}

// Check if a notification is active
export function isNotificationActive(tag: string): boolean {
    return activeNotifications.has(tag);
}

// Get count of active notifications
export function getActiveNotificationCount(): number {
    return activeNotifications.size;
}

// Add event listeners for page interactions
export function initializeInteractionHandlers() {
    const interactionEvents = [
        'click',
        'touchstart',
        'keydown',
        'mousemove',
        'scroll',
        'mousedown',
        'focus',
        'visibilitychange'
    ];
    
    const handleInteraction = (event: Event) => {
        // Don't handle interactions if no sounds are playing
        if (!(getActiveNotificationCount() > 0 || isPlaying || soundInterval)) {
            return;
        }

        console.log('User interaction detected:', event.type);
        console.log('Active notifications before stop:', Array.from(activeNotifications));
        
        // Force stop all sounds
        forceStopAllSounds();
        
        // Also suspend audio context to ensure sound stops
        if (audioContext?.state === 'running') {
            audioContext.suspend().catch(err => {
                console.error('Error suspending audio context:', err);
            });
        }
    };

    // Clean up existing listeners
    const cleanup = () => {
        interactionEvents.forEach(event => {
            document.removeEventListener(event, handleInteraction, true);
            window.removeEventListener(event, handleInteraction, true);
        });
    };

    // Add new listeners
    const initialize = () => {
        cleanup(); // Clean up first to avoid duplicates
        
        interactionEvents.forEach(event => {
            // Add to document
            document.addEventListener(event, handleInteraction, { 
                capture: true,  // Capture events in the capture phase
                passive: true   // Performance optimization
            });
            
            // Also add to window for events that might not bubble to document
            window.addEventListener(event, handleInteraction, { 
                capture: true,
                passive: true
            });
        });
        
        console.log('Interaction handlers initialized for events:', interactionEvents);
    };

    // Initialize immediately
    initialize();

    // Return cleanup function for component unmounting
    return cleanup;
}

// Enhanced notification interaction handler
export function handleNotificationInteraction(notificationTag: string) {
    console.log('Notification interaction detected:', notificationTag);
    forceStopAllSounds();
} 