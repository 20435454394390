<template>
    <Breadcrumbs main="Deduction Sheet" title="Deduction Sheet" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <deduction-sheet-main />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const DeductionSheetMain = defineAsyncComponent(() => import("@/components/deduction-sheet/deduction-sheet-main.vue"))
</script>   