<template>
  <div v-if="store.notificationStatus !== 'granted'" class="notification-permission">
    <div class="alert" :class="alertClass" role="alert">
      <template v-if="store.notificationStatus === 'unsupported'">
        <i class="fa fa-exclamation-triangle"></i>
        Your browser doesn't support notifications
      </template>
      
      <template v-else-if="store.notificationStatus === 'denied'">
        <i class="fa fa-bell-slash"></i>
        Notifications are blocked. Please enable them in your browser settings to receive updates.
      </template>
      
      <template v-else>
        <i class="fa fa-bell"></i>
        Enable notifications to stay updated about new pending approvals
        <button class="btn btn-sm btn-primary ms-2" @click="enableNotifications">
          Enable Notifications
        </button>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useWorkApprovalStore } from '@/store/workApproval'
import { initializeAudio } from '@/utils/audio'

const store = useWorkApprovalStore()

const alertClass = computed(() => {
  switch (store.notificationStatus) {
    case 'unsupported':
      return 'alert-warning'
    case 'denied':
      return 'alert-danger'
    default:
      return 'alert-info'
  }
})

async function enableNotifications() {
  try {
    // Initialize audio first since we have user interaction
    await initializeAudio()
    console.log('Audio initialized successfully')
    
    // Then enable notifications
    const granted = await store.initializeNotifications()
    console.log('Notifications initialized:', granted)
    
    if (!granted) {
      console.warn('Failed to get notification permission')
    }
  } catch (error) {
    console.error('Error enabling notifications:', error)
  }
}

// Try to initialize audio on component mount
initializeAudio().catch(error => {
  console.warn('Initial audio initialization failed:', error)
})
</script>

<style scoped>
.notification-permission {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1050;
  max-width: 400px;
}

.alert {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.alert i {
  margin-right: 8px;
}

.btn {
  white-space: nowrap;
}
</style> 