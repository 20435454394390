<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">NFD Analysis</h4>
          </div>
          <div class="card-body">
            <!-- Filters Section -->
            <div class="row mb-3">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label">Search Site</label>
                  <input type="text" v-model="searchQuery" class="form-control" placeholder="Search sites...">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label">Employee Name</label>
                  <input type="text" v-model="employeeNameFilter" class="form-control"
                    placeholder="Search by employee...">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label">NFD Date Range</label>
                  <div class="d-flex gap-2">
                    <input type="date" v-model="nfdStartDate" class="form-control">
                    <input type="date" v-model="nfdEndDate" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-md-3 d-flex align-items-end">
                <button class="btn btn-secondary me-2" @click="resetFilters">
                  Reset Filters
                </button>
              </div>
            </div>

            <!-- Entries Per Page Section -->
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="d-flex align-items-center">
                <span class="me-2">Show</span>
                <select v-model="perPage" class="form-select" style="width: auto;">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <span class="ms-2">entries</span>
              </div>
            </div>

            <!-- Table Section -->
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Site Name</th>
                    <th>Employee Name</th>
                    <th>Type</th>
                    <th>Last Meeting Report</th>
                    <th>Percentage</th>
                    <th>Total Followups</th>
                    <!-- <th>NFD</th> -->
                    <th>NFD</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="site in paginatedSites" :key="site._id">
                    <td>{{ site.siteName }}</td>
                    <td>{{ site.latestFollowup?.empName || '-' }}</td>
                    <td>{{ site.latestFollowup?.type || '-' }}</td>
                    <td>
                      <ul class="list-unstyled mb-0" v-if="site.latestFollowup?.meetingReport?.length">
                        <li v-for="(report, index) in site.latestFollowup.meetingReport" :key="index">
                          {{ report }}
                        </li>
                      </ul>
                      <span v-else>-</span>
                    </td>
                    <td>{{ site.latestFollowup?.percentage || 0 }}%</td>
                    <td>{{ site.totalFollowups }}</td>
                    <!-- <td>{{ formatNFDDate(site.latestFollowup?.NFD) || '-' }}</td> -->
                    <td>{{ formatMainNFDDate(site.mainNFD) || '-' }}</td>
                    <td>
                      <button class="btn btn-sm btn-primary" @click="openNFDModal(site)" v-if="site.latestFollowup">
                        Edit NFD
                      </button>
                    </td>
                  </tr>
                  <tr v-if="paginatedSites.length === 0">
                    <td colspan="8" class="text-center">No matching records found</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination Section -->
            <div class="d-flex justify-content-between align-items-center mt-3">
              <div>
                Showing {{ startIndex + 1 }} to {{ endIndex }} of {{ filteredSites.length }} entries
              </div>
              <nav aria-label="Page navigation">
                <ul class="pagination mb-0">
                  <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <a class="page-link" href="#" @click.prevent="currentPage = 1">First</a>
                  </li>
                  <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <a class="page-link" href="#" @click.prevent="currentPage--">Previous</a>
                  </li>
                  <li v-for="page in displayedPages" :key="page" class="page-item"
                    :class="{ active: currentPage === page }">
                    <a class="page-link" href="#" @click.prevent="currentPage = page">{{ page }}</a>
                  </li>
                  <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <a class="page-link" href="#" @click.prevent="currentPage++">Next</a>
                  </li>
                  <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <a class="page-link" href="#" @click.prevent="currentPage = totalPages">Last</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- NFD Update Modal -->
    <div class="modal fade" id="nfdModal" tabindex="-1" ref="nfdModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update NFD Date</h5>
            <button type="button" class="btn-close" @click="closeNFDModal"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="form-label">Next Followup Date (NFD)</label>
              <input type="datetime-local" class="form-control" v-model="finalNFDDate" required>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeNFDModal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="updateNFD" :disabled="!isNFDUpdateValid">Update
              NFD</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import { onMounted, onBeforeUnmount } from 'vue'

export default {
  name: 'NFDAnalysis',
  setup() {
    const router = useRouter()
    return { router }
  },
  data() {
    return {
      sites: [],
      loading: false,
      error: null,
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
      employeeNameFilter: '',
      nfdStartDate: '',
      nfdEndDate: '',
      selectedSite: null,
      finalNFDDate: '',
      modal: null
    }
  },
  computed: {
    filteredSites() {
      let filtered = this.sites

      // Site name search
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase()
        filtered = filtered.filter(site =>
          site.siteName.toLowerCase().includes(query)
        )
      }

      // Employee name filter
      if (this.employeeNameFilter) {
        const empQuery = this.employeeNameFilter.toLowerCase()
        filtered = filtered.filter(site =>
          site.latestFollowup?.empName?.toLowerCase().includes(empQuery)
        )
      }

      // NFD date range filter
      if (this.nfdStartDate || this.nfdEndDate) {
        filtered = filtered.filter(site => {
          if (!site.latestFollowup?.NFD) return false

          const nfdDate = this.parseNFDDate(site.latestFollowup.NFD)
          if (!nfdDate) return false

          // Get only the date part for comparison (removing time)
          const nfdDateOnly = new Date(nfdDate.getFullYear(), nfdDate.getMonth(), nfdDate.getDate())

          if (this.nfdStartDate && this.nfdEndDate) {
            const startDate = new Date(this.nfdStartDate)
            const endDate = new Date(this.nfdEndDate)

            // Set to start of day
            const startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
            // Set to end of day
            const endDateOnly = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())

            // If same date selected in both start and end
            if (this.nfdStartDate === this.nfdEndDate) {
              return nfdDateOnly.getTime() === startDateOnly.getTime()
            }

            return nfdDateOnly >= startDateOnly && nfdDateOnly <= endDateOnly
          } else if (this.nfdStartDate) {
            // For single start date, show only that date's records
            const startDate = new Date(this.nfdStartDate)
            const startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
            return nfdDateOnly.getTime() === startDateOnly.getTime()
          } else if (this.nfdEndDate) {
            // For single end date, show only that date's records
            const endDate = new Date(this.nfdEndDate)
            const endDateOnly = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
            return nfdDateOnly.getTime() === endDateOnly.getTime()
          }

          return true
        })
      }

      return filtered
    },
    totalPages() {
      return Math.ceil(this.filteredSites.length / this.perPage)
    },
    startIndex() {
      return (this.currentPage - 1) * this.perPage
    },
    endIndex() {
      return Math.min(this.startIndex + this.perPage, this.filteredSites.length)
    },
    paginatedSites() {
      return this.filteredSites.slice(this.startIndex, this.endIndex)
    },
    displayedPages() {
      const range = 2
      let start = Math.max(1, this.currentPage - range)
      let end = Math.min(this.totalPages, this.currentPage + range)

      if (this.currentPage <= range) {
        end = Math.min(1 + range * 2, this.totalPages)
      } else if (this.currentPage > this.totalPages - range) {
        start = Math.max(this.totalPages - range * 2, 1)
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i)
    },
    isNFDUpdateValid() {
      return this.finalNFDDate
    }
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}api/admin/kyts/followups`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        if (response.data.success) {
          this.sites = response.data.data.kyts.map(site => ({
            ...site,
            latestFollowup: site.latestFollowup ? {
              ...site.latestFollowup,
              empName: site.latestFollowup.empName === 'N/A' ? site.empName : site.latestFollowup.empName
            } : null
          }))
        }
      } catch (err) {
        this.error = err.message
        console.error('Error fetching NFD analysis data:', err)
      } finally {
        this.loading = false
      }
    },
    goToProjectReport(siteId) {
      this.router.push({
        name: 'project-report',
        params: { id: siteId }
      })
    },
    parseNFDDate(nfdString) {
      if (!nfdString) return null

      // Remove any extra spaces and normalize dashes
      nfdString = nfdString.trim().replace(/–/g, '-')

      const formats = [
        // Format: "2025-01-24 6:00 PM"
        (str) => {
          const match = str.match(/^(\d{4})-(\d{2})-(\d{2})\s+(\d{1,2}):(\d{2})\s+(AM|PM)$/)
          if (match) {
            const [_, year, month, day, hour, minute, period] = match
            let hours = parseInt(hour)
            if (period === 'PM' && hours < 12) hours += 12
            if (period === 'AM' && hours === 12) hours = 0
            return new Date(year, parseInt(month) - 1, day, hours, parseInt(minute))
          }
          return null
        },
        // Format: "16-02-2025 - 11:20 PM"
        (str) => {
          const parts = str.split('-').map(s => s.trim())
          const dateParts = parts.slice(0, 3)
          const timeParts = parts.slice(3).join('-').trim().split(' ')
          if (dateParts.length !== 3 || timeParts.length !== 2) return null

          const [day, month, year] = dateParts
          const [time, period] = timeParts
          const [hour, minute] = time.split(':')
          let hours = parseInt(hour)
          if (period === 'PM' && hours < 12) hours += 12
          if (period === 'AM' && hours === 12) hours = 0
          return new Date(year, month - 1, day, hours, parseInt(minute))
        },
        // Format: "2025-02-16T11:22:00"
        (str) => {
          if (str.includes('T')) {
            return new Date(str)
          }
          return null
        },
        // Format: "MM/DD/YYYY HH:mm AM/PM"
        (str) => {
          const [datePart, timePart, period] = str.split(' ')
          if (!datePart || !timePart) return null
          const [month, day, year] = datePart.split('/')
          const [hour, minute] = timePart.split(':')
          let hours = parseInt(hour)
          if (period === 'PM' && hours < 12) hours += 12
          if (period === 'AM' && hours === 12) hours = 0
          return new Date(year, month - 1, day, hours, parseInt(minute))
        }
      ]

      for (const format of formats) {
        try {
          const date = format(nfdString)
          if (date && !isNaN(date.getTime())) {
            return date
          }
        } catch (e) {
          continue
        }
      }

      // If none of the formats work, try parsing directly
      const directParse = new Date(nfdString)
      if (!isNaN(directParse.getTime())) {
        return directParse
      }

      return null
    },
    formatNFDDate(nfdString) {
      if (!nfdString) return null

      const date = this.parseNFDDate(nfdString)
      if (!date) return nfdString // Return original if parsing fails

      // Format: "YYYY-MM-DD HH:mm:ss.SSS"
      return date.toISOString().replace('T', ' ').slice(0, -1)
    },
    formatMainNFDDate(dateString) {
      if (!dateString) return null;

      let date;
      
      // Handle different formats
      if (dateString.includes('–')) {
        // Format: "DD-MM-YYYY – HH:mm A"
        return dateString; // Already in correct format
      } else if (dateString.includes('.000')) {
        // Format: "YYYY-MM-DD HH:mm:ss.000"
        date = new Date(dateString);
      } else {
        // Try to parse DD-MM-YYYY format
        const [day, month, year] = dateString.split('-');
        if (day && month && year) {
          date = new Date(year, month - 1, day);
        } else {
          // Try direct parsing as last resort
          date = new Date(dateString);
        }
      }

      if (!date || isNaN(date.getTime())) return dateString;

      // Format the date parts
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      
      // Format time
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';
      
      // Convert to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert 0 to 12
      hours = hours.toString().padStart(2, '0');

      // Final format: "DD-MM-YYYY – HH:mm A"
      return `${day}-${month}-${year} – ${hours}:${minutes} ${period}`;
    },
    resetFilters() {
      this.searchQuery = ''
      this.employeeNameFilter = ''
      this.nfdStartDate = ''
      this.nfdEndDate = ''
      this.currentPage = 1
    },
    openNFDModal(site) {
      this.selectedSite = site
      this.resetNFDForm()
      if (!this.modal) {
        this.modal = new Modal(this.$refs.nfdModal)
      }
      this.modal.show()
    },
    closeNFDModal() {
      if (this.modal) {
        this.modal.hide()
      }
      this.resetNFDForm()
    },
    resetNFDForm() {
      this.finalNFDDate = ''
    },
    async updateNFD() {
      try {
        console.log('Selected Site:', this.selectedSite)
        console.log('Final NFD Date:', this.finalNFDDate)

        if (!this.selectedSite?._id) {
          throw new Error('Site ID is missing')
        }
        if (!this.selectedSite?.latestFollowup?._id) {
          throw new Error('Followup ID is missing')
        }
        if (!this.finalNFDDate) {
          throw new Error('NFD date is missing')
        }

        this.loading = true
        const payload = {
          kytId: this.selectedSite._id.toString(),
          siteId: this.selectedSite.siteUID,
          oldNFD: this.selectedSite.latestFollowup.NFD,
          newNFD: new Date(this.finalNFDDate).toISOString()
        }

        console.log('Sending payload:', payload)

        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}api/admin/followup/update/nfd`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )

        if (response.data.success) {
          await this.fetchData()
          this.closeNFDModal()
          alert('NFD updated successfully')
        } else {
          throw new Error(response.data.message)
        }
      } catch (err) {
        console.error('Error updating NFD:', err)
        const errorMessage = err.response?.data?.message || err.message || 'Error updating NFD'
        console.error('Error details:', errorMessage)
        alert(errorMessage)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    searchQuery() {
      this.currentPage = 1
    },
    employeeNameFilter() {
      this.currentPage = 1
    },
    nfdStartDate() {
      this.currentPage = 1
    },
    nfdEndDate() {
      this.currentPage = 1
    },
    perPage() {
      this.currentPage = 1
    }
  },
  mounted() {
    this.fetchData()
  },
  beforeUnmount() {
    if (this.modal) {
      this.modal.dispose()
    }
  }
}
</script>

<style scoped>
.table th {
  background-color: #f8f9fa;
  white-space: nowrap;
}

.table td {
  vertical-align: middle;
}

.progress {
  margin-bottom: 0;
}

.list-unstyled {
  margin-bottom: 0;
}

.list-unstyled li {
  margin-bottom: 4px;
}

.list-unstyled li:last-child {
  margin-bottom: 0;
}

.pagination {
  margin-bottom: 0;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.form-group {
  margin-bottom: 0;
}

.form-label {
  margin-bottom: 0.5rem;
}

.modal-header .btn-close {
  margin: 0;
  padding: 1rem;
}

.btn-group {
  width: 100%;
}

.btn-group .btn {
  flex: 1;
}
</style>
