<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { Modal } from 'bootstrap';
import { jsonToExcel } from "@/util/makeExcel";

const route = useRoute();
const toast = useToast();
const allData = ref([]);
const isLoading = ref(false);
const showReport = ref(false);
const selectedSocDetails = ref(null);
const socModal = ref(null);
const selectedCriteriaDetails = ref(null);
const criteriaModal = ref(null);
const totalVisits = ref(0);
const totalCalls = ref(0);
const dateOfInception = ref(null);
const siteName = ref(null);
const router = useRouter();
const visitingCard = ref(null);
const otherDocuments = ref([]);
const imageModal = ref(null);
const currentImage = ref(null);
const imageModalTitle = ref('');
const phoneNo = ref(null);
const selectedQuotations = ref(null);
const selectedProposals = ref(null);
const quotationModal = ref(null);
const proposalModal = ref(null);
// Get route params
const areaId = route.params.areaId;
const siteId = route.params.siteId;

// Area and site info
const areaInfo = ref(null);
const siteInfo = ref(null);

// Add this computed property after the existing refs
const groupedSocDetails = computed(() => {
    if (!selectedSocDetails.value) return [];

    // Remove duplicate entries based on service and modeOfService combination
    return selectedSocDetails.value.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.service === item.service &&
            t.modeOfService === item.modeOfService &&
            t.competitorName === item.competitorName
        ))
    );
});

const sortedData = computed(() => {
    if (!allData.value) return [];
    return [...allData.value].sort((a, b) => {
        const dateA = new Date(a.dateAndTime.split(',')[0].split('/').reverse().join('/') + ',' + a.dateAndTime.split(',')[1]);
        const dateB = new Date(b.dateAndTime.split(',')[0].split('/').reverse().join('/') + ',' + b.dateAndTime.split(',')[1]);
        return dateB - dateA;
    });
});

onMounted(async () => {
    console.log('Component mounted with params:', { areaId, siteId });
    await Promise.all([
        getAreaInfo(),
        getData()
    ]);
});

async function getAreaInfo() {
    try {
        const response = await fetchGetApi(`api/area/get/name/${areaId}`);
        if (response?.data?.success) {
            areaInfo.value = response.data.data;
        }
    } catch (error) {
        console.error('Error fetching area info:', error);
        toast.error('Error loading area information');
    }
}

function getData() {
    isLoading.value = true;
    return fetchGetApi(`api/project-report?areaId=${areaId}&siteId=${siteId}`)
        .then((response) => {
            const data = response.data || response;
            console.log('Raw API response:', data);

            if (data?.success) {
                allData.value = data.data;
                totalVisits.value = data.totalVisits;
                totalCalls.value = data.totalCalls;
                siteName.value = data.siteName;
                dateOfInception.value = data.dateOfInception;
                visitingCard.value = data.visiting_card;
                otherDocuments.value = data.other_documents || [];
                phoneNo.value = data.phoneNo;
                showReport.value = true;
            } else {
                console.error('Invalid response:', data);
                toast.error('Failed to fetch project report data');
                allData.value = [];
                totalVisits.value = 0;
                totalCalls.value = 0;
                dateOfInception.value = null;
                visitingCard.value = null;
                otherDocuments.value = [];
                showReport.value = false;
            }
        })
        .catch((error) => {
            console.error('Error fetching project report:', error);
            toast.error('Error loading project report');
            allData.value = [];
            totalVisits.value = 0;
            totalCalls.value = 0;
            dateOfInception.value = null;
            visitingCard.value = null;
            otherDocuments.value = [];
            showReport.value = false;
        })
        .finally(() => {
            isLoading.value = false;
        });
}

function formatDate(dateString) {
    if (!dateString) return 'N/A';
    try {
        // Check if date is in DD/MM/YYYY, HH:MM:SS am/pm format
        const ddmmyyyyTimePattern = /^\d{1,2}\/\d{1,2}\/\d{4},\s\d{1,2}:\d{2}:\d{2}\s[ap]m$/;
        if (ddmmyyyyTimePattern.test(dateString)) {
            return dateString; // Return as is
        }

        const ddmmyyyyPattern = /^\d{2}-\d{2}-\d{4}$/;
        if (ddmmyyyyPattern.test(dateString)) {
            return dateString; // Return as is
        }

        // Check if the date is already in DD-MM-YYYY – HH:MM AM/PM format
        if (dateString.includes('–')) {
            return dateString; // Return as is since it's already formatted
        }

        // Otherwise handle standard date string format
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'N/A';

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        return 'N/A';
    }
}

function showSocModal(details) {
    selectedSocDetails.value = details;
    if (!socModal.value) {
        socModal.value = new Modal(document.getElementById('soc-modal'));
    }
    socModal.value.show();
}

function showCriteriaModal(criteria) {
    selectedCriteriaDetails.value = criteria;
    if (!criteriaModal.value) {
        criteriaModal.value = new Modal(document.getElementById('criteria-modal'));
    }
    criteriaModal.value.show();
}

function showImage(image, title) {
    currentImage.value = image;
    imageModalTitle.value = title;
    if (!imageModal.value) {
        imageModal.value = new Modal(document.getElementById('image-modal'));
    }
    imageModal.value.show();
}

function showOtherDocuments() {
    currentImage.value = otherDocuments.value;
    imageModalTitle.value = 'Other Documents';
    if (!imageModal.value) {
        imageModal.value = new Modal(document.getElementById('image-modal'));
    }
    imageModal.value.show();
}

function navigateToKytReport(project, type) {
    router.push({
        path: '/reports/marketing-kyt-change-transfer',
        query: {
            projectId: project._id,
            siteName: siteName.value
        }
    });
}

function downloadExcel() {
    if (!allData.value.length) {
        toast.error('No data available to download');
        return;
    }

    const dataToExport = allData.value.map((item, index) => ({
        'SL': index + 1,
        'Percentage': item.percentage !== 'N/A' ? `${item.percentage}%` : 'N/A',
        'Type Call/Visit': item.typeCallVisit || 'N/A',
        'Date and Time': formatDate(item.dateAndTime),
        'Manager Name': item.managerName || 'N/A',
        'Person Met': item.personMetAndDesignation || 'N/A',
        'Services Required': item.servicesRequiredAndManpower || 'N/A',
        'Mode of Service': item.modeOfService || 'N/A',
        'Competitor Name': item.competitorName || 'N/A',
        'SOC Report': item.socReportSubmitted?.length > 0 ? 'Yes' : 'No',
        'Meeting Report': item.meetingReport !== 'N/A' ? item.meetingReport.split(',').filter(report => report.trim()).join(', ') : 'N/A',
        'Quotation': item.quotation || 'N/A',
        'Proposal': item.proposal || 'N/A',
        'NFD': formatDate(item.NFD),
        'KYT Change/Transfer': item.KYTChangeTransfer === 'Yes' ? 'Yes' : 'No'
    }));

    const timestamp = new Date().toISOString().split('T')[0];
    jsonToExcel(dataToExport, `project-report-direct-${siteName.value || 'unknown'}-${timestamp}.xlsx`);
    toast.success('Excel file downloaded successfully');
}

function showQuotationModal(files) {
    selectedQuotations.value = files;
    if (!quotationModal.value) {
        quotationModal.value = new Modal(document.getElementById('quotation-modal'));
    }
    quotationModal.value.show();
}

function showProposalModal(files) {
    selectedProposals.value = files;
    if (!proposalModal.value) {
        proposalModal.value = new Modal(document.getElementById('proposal-modal'));
    }
    proposalModal.value.show();
}

function formatDateTime(dateStr) {
    if (!dateStr) return 'N/A';
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return 'N/A';
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Project report" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div v-if="isLoading" class="text-center py-5">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div v-else-if="showReport" class="table-responsive product-table custom-scrollbar">
                    <!-- Site Name Header -->
                    <div class="d-flex align-items-center mb-4">
                        <h4 class="fw-bold mb-0">Site Name: {{ siteName || 'N/A' }}</h4>
                    </div>

                    <!-- Document Buttons -->
                    <div class="mb-4">
                        <button v-if="visitingCard" @click="showImage(visitingCard, 'Visiting Card')"
                            class="btn btn-primary me-2">
                            <i class="fa fa-id-card me-2"></i>View Visiting Card
                        </button>
                        <button v-if="otherDocuments.length" @click="showOtherDocuments()" class="btn btn-info me-2">
                            <i class="fa fa-file me-2"></i>View Other Documents
                        </button>
                        <button type="button" class="btn btn-primary" @click="downloadExcel"
                            :disabled="!allData.length">
                            <i class="fas fa-download me-1"></i>
                            Download Excel
                        </button>
                    </div>

                    <!-- Summary Card -->
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <strong>Area:</strong> {{ areaInfo?.areaName || 'N/A' }}
                                </div>
                                <div class="col">
                                    <strong>Site:</strong> {{ siteName || 'N/A' }}
                                </div>
                                <div class="col">
                                    <strong>Date of Inception:</strong>
                                    {{ dateOfInception || 'N/A' }}
                                </div>
                                <div class="col">
                                    <strong>Total Visits:</strong>
                                    {{ totalVisits || 0 }}
                                </div>
                                <div class="col">
                                    <strong>Total Calls:</strong>
                                    {{ totalCalls || 0 }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Data Table -->
                    <table class="display table-striped table-hover table-bordered table" id="basic-1">
                        <thead>
                            <tr>
                                <th class="sorting_asc" scope="col" style="width: 50px;">Sl no</th>
                                <th class="sorting_asc" scope="col">Percentage</th>
                                <th class="sorting_asc text-nowrap" scope="col">Type call/visit</th>
                                <th class="sorting_asc text-nowrap" scope="col">Date and time</th>
                                <th class="sorting_asc text-nowrap" scope="col">NFD</th>

                                <th class="sorting_asc text-nowrap" scope="col">Manager name</th>
                                <th class="sorting_asc text-nowrap" scope="col">Person met and designation</th>
                                <th class="sorting_asc text-nowrap" scope="col">Meeting report</th>
                                <th class="sorting_asc text-nowrap" scope="col">Services required and manpower</th>
                                <th class="sorting_asc text-nowrap" scope="col">Mode of service</th>
                                <th class="sorting_asc text-nowrap" scope="col">Competitor name</th>
                                <th class="sorting_asc text-nowrap" scope="col">SOC report submitted</th>
                                <th class="sorting_asc text-nowrap" scope="col">Criteria</th>
                                <th class="sorting_asc" scope="col">Quotation</th>
                                <th class="sorting_asc text-nowrap" scope="col">Proposal</th>
                                <th class="sorting_asc text-nowrap" scope="col">KYT change/transfer</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in sortedData" :key="item.slNo">
                                <td>{{ item.slNo }}</td>
                                <td>{{ item.percentage !== 'N/A' ? `${item.percentage}%` : 'N/A' }}</td>
                                <td>{{ item.typeCallVisit }}</td>
                                <td>{{ formatDate(item.dateAndTime) }}</td>
                                <td>{{ formatDate(item.NFD) }}</td>

                                <td>{{ item.managerName }}</td>
                                <td>
                                    {{ item.personMetAndDesignation }}
                                    <br v-if="phoneNo">
                                    <span v-if="phoneNo" class="text-muted">Contact: {{ phoneNo }}</span>
                                </td>
                                <td>
                                    <div v-if="item.meetingReport !== 'N/A'" class="text-wrap"
                                        style="min-width: 600px; max-width: 700px; white-space: normal; display: block;">
                                        {{item.meetingReport.split(',').filter(report => report.trim()).join(', ')}}
                                    </div>
                                    <span v-else>N/A</span>
                                </td>
                                <td>{{ item.servicesRequiredAndManpower }}</td>
                                <td>{{ item.modeOfService }}</td>
                                <td>{{ item.competitorName }}</td>
                                <td>
                                    <a v-if="item.socReportSubmitted?.length" href="#"
                                        @click.prevent="showSocModal(item.socReportSubmitted)" class="text-primary">
                                        Yes (click to view)
                                    </a>
                                    <span v-else>No</span>
                                </td>
                                <td>
                                    <a v-if="item.criteria?.length" href="#"
                                        @click.prevent="showCriteriaModal(item.criteria)" class="text-primary">
                                        Click to view
                                    </a>
                                    <span v-else>No criteria</span>
                                </td>
                                <td>
                                    <a v-if="item.quotation === 'Yes'" href="#"
                                        @click.prevent="showQuotationModal(item.quotationFile)" class="text-primary">
                                        Yes (click to view)
                                    </a>
                                    <span v-else>No</span>
                                </td>
                                <td>
                                    <a v-if="item.proposal === 'Yes'" href="#"
                                        @click.prevent="showProposalModal(item.proposalFile)" class="text-primary">
                                        Yes (click to view)
                                    </a>
                                    <span v-else>No</span>
                                </td>
                                <td>
                                    <button v-if="item.KYTChangeTransfer === 'Yes'"
                                        @click="navigateToKytReport(item, 'change')" class="text-primary">
                                        Yes (click to view)
                                    </button>
                                    <span v-else>No</span>
                                </td>
                            </tr>
                            <tr v-if="allData.length === 0">
                                <td colspan="16" class="text-center">No data available</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-else class="text-center py-5">
                    <h4 class="text-muted">No data available for the selected area and site</h4>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="soc-modal" tabindex="-1" aria-labelledby="socModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="socModalLabel">SOC Report Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedSocDetails">
                        <!-- Group by service and mode -->
                        <div v-for="(serviceGroup, index) in groupedSocDetails" :key="index" class="mb-4">
                            <div class="service-section border-bottom pb-3">
                                <h5 class="text-primary">
                                    Service: {{ serviceGroup.service }}
                                    <p v-if="serviceGroup.serviceName">
                                        <span class="badge bg-primary ms-2">{{ serviceGroup.serviceName }}</span>
                                    </p>
                                    <span class="badge text-dark bg-secondary ms-2">{{ serviceGroup.modeOfService }}</span>
                                </h5>

                                <!-- Service Basic Information -->
                                <div class="mb-4">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th style="width: 200px">Competitor Name</th>
                                                <td>{{ serviceGroup.competitorName || 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <th>Agreement Duration</th>
                                                <td>
                                                    <span
                                                        v-if="serviceGroup.agreementStarted && serviceGroup.agreementEnded">
                                                        {{ serviceGroup.agreementStarted }} to {{
                                                            serviceGroup.agreementEnded }}
                                                    </span>
                                                    <span v-else>N/A</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Total Manpower</th>
                                                <td>{{ serviceGroup.totalManpower || 0 }}</td>
                                            </tr>
                                            <tr>
                                                <th>Special Mentions</th>
                                                <td>{{ serviceGroup.specialMentions || 'N/A' }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <!-- SOC Details Table -->
                                <div v-if="serviceGroup.socDetails && serviceGroup.socDetails.length > 0">
                                    <h6 class="mt-3">Manpower Details</h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Designation</th>
                                                    <th>Count</th>
                                                    <th>Duration</th>
                                                    <th>Salary (₹)</th>
                                                    <th>Benefits</th>
                                                    <th>Min. Wages</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(soc, socIndex) in serviceGroup.socDetails" :key="socIndex">
                                                    <td>{{ soc.designation }}</td>
                                                    <td>{{ soc.manpowerCount }}</td>
                                                    <td>{{ soc.workDuration }} hours</td>
                                                    <td>₹{{ soc.salary }}</td>
                                                    <td>
                                                        <ul class="list-unstyled mb-0">
                                                            <li>
                                                                <i class="fa"
                                                                    :class="soc.isFoodProvided ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                                Food
                                                            </li>
                                                            <li>
                                                                <i class="fa"
                                                                    :class="soc.isStayProvided ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                                Stay
                                                            </li>
                                                            <li>
                                                                <i class="fa"
                                                                    :class="soc.isEsicPfProvided ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                                ESIC/PF
                                                            </li>
                                                            <li>
                                                                <i class="fa"
                                                                    :class="soc.isWeeklyOff ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                                Weekly Off
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <i class="fa"
                                                            :class="soc.isMinWages ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="criteria-modal" tabindex="-1" aria-labelledby="criteriaModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="criteriaModalLabel">Criteria Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedCriteriaDetails">
                        <div v-for="(item, index) in selectedCriteriaDetails" :key="index" class="mb-4">
                            <h6 class="border-bottom pb-2">{{ item.serviceName }}</h6>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Total Manpower</th>
                                        <td>{{ item.manPower }}</td>
                                    </tr>
                                    <tr>
                                        <th>Gender Distribution</th>
                                        <td>Male: {{ item.gender.male }}, Female: {{ item.gender.female }}</td>
                                    </tr>
                                    <tr>
                                        <th>Working Hours</th>
                                        <td>{{ item.workingHours }} hours</td>
                                    </tr>
                                    <tr>
                                        <th>Benefits</th>
                                        <td>
                                            <ul class="list-unstyled mb-0">
                                                <li>ESIC/PF: {{ item.epf }}</li>
                                                <li>Food: {{ item.food }}</li>
                                                <li>Stay: {{ item.stay }}</li>
                                                <li>Weekly Off: {{ item.weeklyOff }}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="image-modal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="imageModalLabel">{{ imageModalTitle }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- Single Image View -->
                    <div v-if="typeof currentImage === 'string'" class="text-center">
                        <img :src="currentImage" alt="Document" class="img-fluid">
                    </div>
                    <!-- Multiple Images View -->
                    <div v-else class="row g-3">
                        <div v-for="(image, index) in currentImage" :key="index" class="col-md-6">
                            <div class="card">
                                <img :src="image" :alt="'Document ' + (index + 1)" class="card-img-top">
                                <div class="card-footer text-center">
                                    Document {{ index + 1 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Quotation Modal -->
    <div class="modal fade" id="quotation-modal" tabindex="-1" aria-labelledby="quotationModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="quotationModalLabel">Quotation Files</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedQuotations && selectedQuotations.length > 0">
                        <div class="list-group">
                            <div v-for="(file, index) in selectedQuotations" :key="file._id"
                                class="list-group-item list-group-item-action">
                                <div class="d-flex w-100 justify-content-between align-items-center">
                                    <h6 class="mb-1">Quotation {{ index + 1 }}</h6>
                                    <small class="text-muted">Added on {{ formatDateTime(file.date) }}</small>
                                </div>
                                <div class="mt-2">
                                    <a v-for="(url, urlIndex) in file.urls" :key="urlIndex" :href="url" target="_blank"
                                        class="btn btn-primary btn-sm me-2">
                                        <i class="fa fa-download me-1"></i>
                                        Download File {{ urlIndex + 1 }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center py-3">
                        <p class="text-muted">No quotation files available</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Proposal Modal -->
    <div class="modal fade" id="proposal-modal" tabindex="-1" aria-labelledby="proposalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="proposalModalLabel">Proposal Files</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedProposals && selectedProposals.length > 0">
                        <div class="list-group">
                            <div v-for="(file, index) in selectedProposals" :key="file._id"
                                class="list-group-item list-group-item-action">
                                <div class="d-flex w-100 justify-content-between align-items-center">
                                    <h6 class="mb-1">Proposal {{ index + 1 }}</h6>
                                    <small class="text-muted">Added on {{ formatDateTime(file.date) }}</small>
                                </div>
                                <div class="mt-2">
                                    <a v-for="(url, urlIndex) in file.urls" :key="urlIndex" :href="url" target="_blank"
                                        class="btn btn-primary btn-sm me-2">
                                        <i class="fa fa-download me-1"></i>
                                        Download File {{ urlIndex + 1 }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center py-3">
                        <p class="text-muted">No proposal files available</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>