import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }

import UniformAccountTable from '@/components/uniform-account/uniform-account-table.vue'
import UniformAnalysis from '@/components/uniform-account/uniform-analysis.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'uniform-account',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Uniform",
      title: "Uniform Account"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h5", null, "Uniform Account Management"),
          _createElementVNode("span", null, "Track uniform inventory and transactions")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(UniformAccountTable),
          _createVNode(UniformAnalysis)
        ])
      ])
    ])
  ], 64))
}
}

})