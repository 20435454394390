<script lang="js" setup>
import { ref, computed, onMounted } from "vue";
import { fetchGetApi } from "@/util/api";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const viewSiddar = defineAsyncComponent(() => import("@/pages/operation/modal/viewSiddar.vue"));
const isModalVisible = ref(false);
const selectedItem = ref(null);

const getAllData = async () => {
    try {
        const response = await fetchGetApi('api/siddar/get/all');
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data');
        }
    } catch (error) {
        toast.error('Error fetching data');
    }
};

onMounted(async () => {
    await getAllData();
});

const changeStatus = async (id, newStatus) => {
    try {
        toast.info('Updating the status');
        const result = await Swal.fire({
            title: 'Change Status',
            text: `Are you sure you want to change the status to ${newStatus}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
        });

        if (result.isConfirmed) {
            await fetchGetApi(`api/siddar/status/${id}/${newStatus}`);
            await getAllData();
            toast.success('Status updated successfully');
        }
    } catch (error) {
        toast.error('Failed to update status');
    }
}

function downloadExcel() {
    const worksheet = XLSX.utils.json_to_sheet(allData.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Siddar Data");
    XLSX.writeFile(workbook, 'siddar_data.xlsx');
}

const filteredData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    return allData.value.filter(item =>
        item.siteName.toLowerCase().includes(query) ||
        item.department.toLowerCase().includes(query)
    );
});

const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return filteredData.value.slice(start, end);
});

function num_pages() {
    return Math.ceil(filteredData.value.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function showModal(item) {
    selectedItem.value = item;
    isModalVisible.value = true;
}

function hideModal() {
    isModalVisible.value = false;
    selectedItem.value = null;
}
</script>

<template>
    <Breadcrumbs main="Apps" title="SIDDAR" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search" class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm" v-model="filterQuery">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Designation</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Gender</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Days Worked</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Type of Leaving</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Type</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Vacant Type</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Reason</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Deadline</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in paginatedData" :key="item.id">
                                    <td>{{ (currentPage - 1) * elementsPerPage + index + 1 }}</td>
                                    <td>{{ item.siteName }}</td>
                                    <td>{{ item.department }}</td>
                                    <td>{{ item.designation }}</td>
                                    <td>{{ item.gender }}</td>
                                    <td>{{ item.daysWorked }}</td>
                                    <td>{{ item.typeOfLeaving }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.vacentType }}</td>
                                    <td>{{ item.reason }}</td>
                                    <td>{{ item.deadline }}</td>
                                    <td>
                                        <select 
                                            class="form-select" 
                                            :class="{
                                                'bg-warning': item.status === 'Pending',
                                                'bg-success text-white': item.status === 'Approved',
                                                'bg-danger text-white': item.status === 'Rejected'
                                            }"
                                            v-model="item.status"
                                            @change="changeStatus(item._id, item.status)"
                                        >
                                            <option value="Pending">Pending</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Rejected">Rejected</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <viewSiddar v-if="isModalVisible" :show="isModalVisible" :item="selectedItem" @close="hideModal" @refreshData="onMounted" />
                </div>
            </div>
        </div>
    </div>
</template>