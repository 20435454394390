<template>
  <div class="import-employee-container">
    <div class="card">
      <div class="card-header">
        <h3>Import Employees</h3>
      </div>
      <div class="card-body">
        <div class="upload-section">
          <div class="file-input-wrapper">
            <input
              type="file"
              ref="fileInput"
              @change="handleFileChange"
              accept=".xlsx,.xls"
              class="file-input"
            />
            <div class="selected-file" v-if="selectedFile">
              Selected file: {{ selectedFile.name }}
            </div>
          </div>
          <button
            class="btn btn-primary upload-btn"
            @click="handleUpload"
            :disabled="!selectedFile || isUploading"
          >
            <span v-if="isUploading">
              <i class="fas fa-spinner fa-spin"></i> Uploading...
            </span>
            <span v-else>Upload Employees</span>
          </button>
        </div>
        <div class="instructions mt-4">
          <h4>Instructions:</h4>
          <ul>
            <li>Please upload an Excel file (.xlsx or .xls)</li>
            <li>The Excel file should contain the following columns:
              <ul>
                <li>Employee Prejoin Token</li>
                <li>Employee Name</li>
                <li>Employee Code</li>
                <li>Father Name</li>
                <li>Date of Birth</li>
                <li>Department ID</li>
                <li>Site ID</li>
                <li>Employee Type</li>
                <li>Email</li>
                <li>Password</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import { useToast } from 'vue-toastification'

export default {
  name: 'ImportEmployee',
  setup() {
    const selectedFile = ref(null)
    const isUploading = ref(false)
    const toast = useToast()
    const fileInput = ref(null)

    const handleFileChange = (event) => {
      const file = event.target.files[0]
      if (file) {
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.ms-excel') {
          selectedFile.value = file
        } else {
          toast.error('Please select a valid Excel file (.xlsx or .xls)')
          event.target.value = ''
          selectedFile.value = null
        }
      }
    }

    const handleUpload = async () => {
      if (!selectedFile.value) return

      const formData = new FormData()
      formData.append('file', selectedFile.value)
      
      isUploading.value = true
      try {
        const response = await axios.post('/api/employees/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        
        toast.success('Employees imported successfully!')
        selectedFile.value = null
        if (fileInput.value) {
          fileInput.value.value = ''
        }
      } catch (error) {
        toast.error(error.response?.data?.message || 'Error importing employees')
      } finally {
        isUploading.value = false
      }
    }

    return {
      selectedFile,
      isUploading,
      fileInput,
      handleFileChange,
      handleUpload
    }
  }
}
</script>

<style scoped>
.import-employee-container {
  padding: 20px;
}

.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.card-header h3 {
  margin: 0;
  color: #333;
}

.card-body {
  padding: 20px;
}

.upload-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.file-input-wrapper {
  width: 100%;
  max-width: 400px;
}

.file-input {
  width: 100%;
  padding: 10px;
  border: 2px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.selected-file {
  margin-top: 10px;
  color: #666;
  font-size: 0.9em;
}

.upload-btn {
  min-width: 200px;
  padding: 10px 20px;
}

.instructions {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 4px;
}

.instructions h4 {
  color: #333;
  margin-bottom: 15px;
}

.instructions ul {
  padding-left: 20px;
}

.instructions li {
  margin-bottom: 8px;
  color: #666;
}
</style>
