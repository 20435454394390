import { onMounted, onUnmounted } from 'vue'
import { useWorkApprovalStore } from '@/store/workApproval'

export function useAutoRefresh(refreshIntervalMs?: number) {
  const store = useWorkApprovalStore()

  onMounted(() => {
    // Start auto-refresh when component is mounted
    store.startAutoRefresh(refreshIntervalMs)
  })

  onUnmounted(() => {
    // Clean up interval when component is unmounted
    store.stopAutoRefresh()
  })

  return {
    startAutoRefresh: store.startAutoRefresh,
    stopAutoRefresh: store.stopAutoRefresh
  }
} 