<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { fetchGetApi, fetchPatchApi } from '@/util/api';
const baseUrl = process.env.VUE_APP_BASE_URL;

const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterSite = ref("");
const filterEmployee = ref("");
const startDate = ref("");
const endDate = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/operation/get/all/exchanges').then((response) => {
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data');
        }
    });
});

function downloadExcel() {
    const dataToExport = allData.value.map(item => ({
        'Date': new Date(item.createdAt).toLocaleDateString(),
        'Employee Name': item.employeeName,
        'From Site': item.fromSiteName,
        'From Department': item.fromDepartmentName,
        'From Designation': item.fromDesignationName,
        'Working Hour': item.workingHour,
        'Salary': item.salary,
        'Under SIDDAR': item.isUnderSiddar ? 'Yes' : 'No',
        'To Site': item.toSiteName,
        'To Employee Name': item.toEmployeeName,
        'To Department': item.toDepartmentName,
        'To Designation': item.toDesignationName,
        'To Working Hour': item.toWorkingHour,
        'To Salary': item.toSalary,
        'Reason for Exchange': item.reasonForExchange,
        'Status': item.isRevoked ? 'Revoked' : (item.isRejected ? 'Rejected' : (item.isApproved ? 'Approved' : 'Pending')),
        'Action Date': item.revokedAt ? new Date(item.revokedAt).toLocaleString() : 
                      (item.rejectedAt ? new Date(item.rejectedAt).toLocaleString() : 
                      (item.approvedAt ? new Date(item.approvedAt).toLocaleString() : 'N/A'))
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Exchanges");
    XLSX.writeFile(workbook, 'exchanges.xlsx');
}

function num_pages() {
    const data = Array.isArray(allData.value) ? allData.value : [];
    
    const filteredLength = data.filter(item => {
        // Site name filtering - check both from and to sites
        const siteSearchTerm = filterSite.value.toLowerCase().trim();
        const matchesSite = !filterSite.value || 
            item.fromSiteName?.toLowerCase().includes(siteSearchTerm) ||
            item.toSiteName?.toLowerCase().includes(siteSearchTerm);
            
        // Employee name filtering - check both employees
        const employeeSearchTerm = filterEmployee.value.toLowerCase().trim();
        const matchesEmployee = !filterEmployee.value || 
            item.employeeName?.toLowerCase().includes(employeeSearchTerm) ||
            item.toEmployeeName?.toLowerCase().includes(employeeSearchTerm);
            
        // Date filtering
        const itemDate = new Date(item.createdAt);
        let matchesDate = true;
        
        if (startDate.value && endDate.value) {
            const start = new Date(startDate.value);
            const end = new Date(endDate.value);
            
            if (startDate.value === endDate.value) {
                const itemDateString = itemDate.toISOString().split('T')[0];
                matchesDate = itemDateString === startDate.value;
            } else {
                end.setDate(end.getDate() + 1);
                matchesDate = itemDate >= start && itemDate < end;
            }
        } else if (startDate.value) {
            matchesDate = itemDate >= new Date(startDate.value);
        } else if (endDate.value) {
            matchesDate = itemDate <= new Date(endDate.value);
        }
        
        return matchesSite && matchesEmployee && matchesDate;
    }).length;
    
    return Math.ceil(filteredLength / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const filteredData = computed(() => {
    const data = Array.isArray(allData.value) ? allData.value : [];
    
    return data.filter(item => {
        // Site name filtering - check both from and to sites
        const siteSearchTerm = filterSite.value.toLowerCase().trim();
        const matchesSite = !filterSite.value || 
            item.fromSiteName?.toLowerCase().includes(siteSearchTerm) ||
            item.toSiteName?.toLowerCase().includes(siteSearchTerm);
            
        // Employee name filtering - check both employees
        const employeeSearchTerm = filterEmployee.value.toLowerCase().trim();
        const matchesEmployee = !filterEmployee.value || 
            item.employeeName?.toLowerCase().includes(employeeSearchTerm) ||
            item.toEmployeeName?.toLowerCase().includes(employeeSearchTerm);
            
        // Date filtering
        const itemDate = new Date(item.createdAt);
        let matchesDate = true;
        
        if (startDate.value && endDate.value) {
            const start = new Date(startDate.value);
            const end = new Date(endDate.value);
            
            if (startDate.value === endDate.value) {
                const itemDateString = itemDate.toISOString().split('T')[0];
                matchesDate = itemDateString === startDate.value;
            } else {
                end.setDate(end.getDate() + 1);
                matchesDate = itemDate >= start && itemDate < end;
            }
        } else if (startDate.value) {
            matchesDate = itemDate >= new Date(startDate.value);
        } else if (endDate.value) {
            matchesDate = itemDate <= new Date(endDate.value);
        }
        
        return matchesSite && matchesEmployee && matchesDate;
    }).slice((currentPage.value - 1) * elementsPerPage.value, currentPage.value * elementsPerPage.value);
});

async function rejectExchange(exchangeId) {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to reject this exchange?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Reject',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const response = await fetchPatchApi(`api/operation/exchange/reject`, {
                exchangeId: exchangeId
            });

            if (response && response.data && response.data.exchange) {
                // Update the local data with all the response data
                const index = allData.value.findIndex(item => item._id === exchangeId);
                if (index !== -1) {
                    allData.value[index] = {
                        ...allData.value[index],
                        ...response.data.exchange
                    };
                }
                
                toast.success('Exchange rejected successfully');
            }
        }
    } catch (error) {
        toast.error('Failed to reject exchange: ' + (error.message || 'Unknown error'));
        console.error(error);
    }
}

async function approveExchange(exchangeId) {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to approve this exchange? This will update both employees\' positions.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Approve',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const response = await fetchPatchApi(`api/operation/exchange/approve`, {
                exchangeId: exchangeId
            });

            if (response && response.data && response.data.exchange) {
                // Update the local data with all the response data
                const index = allData.value.findIndex(item => item._id === exchangeId);
                if (index !== -1) {
                    allData.value[index] = {
                        ...allData.value[index],
                        ...response.data.exchange
                    };
                }
                
                toast.success('Exchange approved and employee details updated successfully');
            }
        }
    } catch (error) {
        toast.error('Failed to approve exchange: ' + (error.message || 'Unknown error'));
        console.error(error);
    }
}

async function revokeExchange(exchangeId) {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to revoke this exchange? This will revert both employees to their original positions.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Revoke',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const response = await fetchPatchApi(`api/operation/exchange/revoke`, {
                exchangeId: exchangeId
            });

            if (response && response.data && response.data.exchange) {
                // Update the local data with all the response data
                const index = allData.value.findIndex(item => item._id === exchangeId);
                if (index !== -1) {
                    allData.value[index] = {
                        ...allData.value[index],
                        ...response.data.exchange
                    };
                }
                
                toast.success('Exchange revoked successfully');
            }
        }
    } catch (error) {
        toast.error('Failed to revoke exchange: ' + (error.message || 'Unknown error'));
        console.error(error);
    }
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Exchange" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer" @submit.prevent>
                        <div class="mb-3 row justify-content-between align-items-end">
                            <div class="col-md-2">
                                <label class="form-label">Site Name</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="filterSite"
                                    placeholder="Search by site name"
                                >
                            </div>
                            <div class="col-md-2">
                                <label class="form-label">Employee Name</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="filterEmployee"
                                    placeholder="Search by employee name"
                                >
                            </div>
                            <div class="col-md-2">
                                <label class="form-label">Start Date</label>
                                <input 
                                    type="date" 
                                    class="form-control" 
                                    v-model="startDate"
                                >
                            </div>
                            <div class="col-md-2">
                                <label class="form-label">End Date</label>
                                <input 
                                    type="date" 
                                    class="form-control" 
                                    v-model="endDate"
                                    :min="startDate"
                                >
                            </div>
                            <div class="col-md-2 text-end">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">
                                    Download Excel
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc text-danger" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">Date</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">Employee Name</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">From Site</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">From Department</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">From Designation</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">Working Hour</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">Salary</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">Under SIDDAR</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">To Site</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">To Employee Name</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">To Department</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">To Designation</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">To Working Hour</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">To Salary</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">Reason for Exchange</th>
                                    <th class="sorting_asc text-danger text-nowrap" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="!filteredData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="13" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, index) in filteredData" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ new Date(item.createdAt).toLocaleDateString() }}</td>
                                    <td>{{ item.employeeName }}</td>
                                    <td>{{ item.fromSiteName }}</td>
                                    <td>{{ item.fromDepartmentName }}</td>
                                    <td>{{ item.fromDesignationName }}</td>
                                    <td>{{ item.workingHour }}</td>
                                    <td>{{ item.salary }}</td>
                                    <td>
                                        <span 
                                            class="badge"
                                            :class="item.isUnderSiddar ? 'bg-success' : 'bg-danger'"
                                        >
                                            {{ item.isUnderSiddar ? 'Yes' : 'No' }}
                                        </span>
                                    </td>
                                    <td>{{ item.toSiteName }}</td>
                                    <td>{{ item.toEmployeeName }}</td>
                                    <td>{{ item.toDepartmentName }}</td>
                                    <td>{{ item.toDesignationName }}</td>
                                    <td>{{ item.toWorkingHour }}</td>
                                    <td>{{ item.toSalary }}</td>
                                    <td>{{ item.reasonForExchange }}</td>
                                    <td>
                                        <div class="d-flex gap-2">
                                            <template v-if="!item.isApproved && !item.isRejected && !item.isRevoked">
                                                <button 
                                                    class="btn btn-sm btn-success"
                                                    @click="approveExchange(item._id)"
                                                >
                                                    Approve
                                                </button>
                                                <button 
                                                    class="btn btn-sm btn-danger"
                                                    @click="rejectExchange(item._id)"
                                                >
                                                    Reject
                                                </button>
                                            </template>

                                            <button 
                                                v-if="item.isApproved && !item.isRevoked && !item.isRejected"
                                                class="btn btn-sm btn-warning"
                                                @click="revokeExchange(item._id)"
                                            >
                                                Revoke
                                            </button>

                                            <span 
                                                v-if="item.isRevoked" 
                                                class="badge bg-secondary"
                                            >
                                                Revoked at {{ new Date(item.revokedAt).toLocaleString() }}
                                            </span>
                                            <span 
                                                v-else-if="item.isRejected" 
                                                class="badge bg-danger"
                                            >
                                                Rejected by {{ item.isRejectedBy }} at {{ new Date(item.rejectedAt).toLocaleString() }}
                                            </span>
                                            <span 
                                                v-else-if="item.isApproved" 
                                                class="badge bg-success"
                                            >
                                                Approved at {{ new Date(item.approvedAt).toLocaleString() }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>