<template>
    <Breadcrumbs main="Reports" title="Laundry Report" />
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <!-- Loading state -->
                <div v-if="loading" class="text-center py-4">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <!-- Data table -->
                <div v-else class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Batch Number</th>
                                <th>Date Sent</th>
                                <th>Vendor</th>
                                <th>Items</th>
                                <th>Expected Return</th>
                                <th>Actual Return</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="batch in laundryBatches" :key="batch._id">
                                <td>{{ batch.batchNumber }}</td>
                                <td>{{ formatDate(batch.createdAt) }}</td>
                                <td>{{ batch.vendorName }}</td>
                                <td>
                                    {{ batch.items.length }} items
                                    <button 
                                        class="btn btn-link btn-sm" 
                                        @click="viewItems(batch)"
                                    >
                                        View Details
                                    </button>
                                </td>
                                <td>{{ formatDate(batch.expectedReturnDate) }}</td>
                                <td>{{ batch.actualReturnDate ? formatDate(batch.actualReturnDate) : '-' }}</td>
                                <td>
                                    <span :class="getStatusClass(batch.status)">
                                        {{ batch.status }}
                                    </span>
                                </td>
                                <td>
                                    <button 
                                        v-if="batch.status === 'Sent'"
                                        class="btn btn-primary btn-sm"
                                        @click="receiveBatch(batch)"
                                    >
                                        Receive
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Items Modal -->
    <div class="modal fade" :class="{ 'show': showModal }" v-if="showModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Batch Details</h5>
                    <button 
                        type="button" 
                        class="btn-close" 
                        @click="showModal = false"
                    ></button>
                </div>
                <div class="modal-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Size</th>
                                <th>Employee</th>
                                <th>Department</th>
                                <th>Site</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in selectedBatch?.items" :key="item.uniformItemId">
                                <td>{{ item.itemName }}</td>
                                <td>{{ item.size }}</td>
                                <td>{{ item.employeeId?.employeeName }}</td>
                                <td>{{ item.departmentId?.name }}</td>
                                <td>{{ item.siteId?.siteName }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { fetchGetApi, fetchPutApi } from '@/util/api';

// Define interfaces
interface Employee {
    _id: string;
    employeeName: string;
    employeeCode: string;
}

interface Department {
    _id: string;
    name: string;
}

interface Site {
    _id: string;
    siteName: string;
}

interface LaundryItem {
    uniformItemId: string;
    itemName: string;
    size: string;
    employeeId: Employee;
    departmentId: Department;
    siteId: Site;
}

interface LaundryBatch {
    _id: string;
    batchNumber: string;
    items: LaundryItem[];
    vendorName: string;
    expectedReturnDate: string;
    actualReturnDate?: string;
    status: 'Sent' | 'Received';
    remarks?: string;
    createdAt: string;
    updatedAt: string;
}

const toast = useToast();
const loading = ref(true);
const laundryBatches = ref<LaundryBatch[]>([]);
const showModal = ref(false);
const selectedBatch = ref<LaundryBatch | null>(null);

const baseUrl = process.env.VUE_APP_BASE_URL

// Fetch laundry batches
const fetchLaundryBatches = async (): Promise<void> => {
    try {
        loading.value = true;
        const response = await fetchGetApi<{ success: boolean; data: LaundryBatch[] }>(
            `api/laundry/batches`
        );
        console.log('Laundry data:', response.data);
        laundryBatches.value = response.data.data;
    } catch (error) {
        console.error('Error fetching laundry batches:', error);
        toast.error('Failed to fetch laundry data');
    } finally {
        loading.value = false;
    }
};

// Helper functions
const formatDate = (dateString: string): string => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString();
};

const getStatusClass = (status: LaundryBatch['status']): Record<string, boolean> => {
    return {
        'badge rounded-pill bg-warning': status === 'Sent',
        'badge rounded-pill bg-success': status === 'Received'
    };
};

const viewItems = (batch: LaundryBatch): void => {
    selectedBatch.value = batch;
    showModal.value = true;
};

const receiveBatch = async (batch: LaundryBatch): Promise<void> => {
    try {
        await fetchPutApi(`api/laundry/batch/${batch.batchNumber}/receive`);
        toast.success('Batch received successfully');
        await fetchLaundryBatches();
    } catch (error) {
        console.error('Error receiving batch:', error);
        toast.error('Failed to receive batch');
    }
};

// Lifecycle hooks
onMounted(() => {
    fetchLaundryBatches();
});
</script>

<style scoped>
.modal.show {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}

.badge {
    padding: 0.5em 1em;
}

.btn-link {
    text-decoration: none;
    padding: 0;
    margin-left: 0.5rem;
}

.table th {
    background-color: #f8f9fa;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
}

.modal-dialog {
    margin: 1.75rem auto;
}
</style>