<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);

// Computed property to format the data
const formattedData = computed(() => {
  return allData.value.map(item => ({
    id: item._id,
    siteName: item.siteId.siteName,
    siteAddress: item.siteId.siteAddress,
    contactPerson: item.siteId.contactPerson.name,
    designation: item.siteId.contactPerson.designation,
    phone: item.siteId.phoneNo,
    requestDate: new Date(item.requestCloseDate).toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }),
    status: item.status,
    operationId: item.operationId
  }));
});

const fetchData = async () => {
  try {
    const response = await fetchGetApi(`api/kyt/close/requests?page=${currentPage.value}`);
    if (response?.data) {
      allData.value = response.data;
      if (response.data.pagination) {
        totalPages.value = response.data.pagination.totalPages;
      }
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// Pagination methods
const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    fetchData();
  }
};

const previousPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    fetchData();
  }
};

// Add these new methods
const handleApprove = async (id) => {
  try {
    await fetchPostApi(`api/kyt/close/approve/${id}`);
    // Refresh the data after approval
    await fetchData();
  } catch (error) {
    console.error('Error approving request:', error);
  }
};

const handleReject = async (id) => {
  try {
    await fetchPostApi(`api/kyt/close/reject/${id}`);
    // Refresh the data after rejection
    await fetchData();
  } catch (error) {
    console.error('Error rejecting request:', error);
  }
};

onMounted(() => {
  fetchData();
});
</script>

<template>
  <Breadcrumbs main="Apps" title="KYT Close Requests" />
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Site Name</th>
                <th>Address</th>
                <th>Contact Person</th>
                <th>Designation</th>
                <th>Phone</th>
                <th>Request Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody v-if="!formattedData.length">
              <tr>
                <td colspan="8" class="text-center">No requests found</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="item in formattedData" :key="item.id">
                <td>{{ item.siteName }}</td>
                <td>{{ item.siteAddress }}</td>
                <td>{{ item.contactPerson }}</td>
                <td>{{ item.designation }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.requestDate }}</td>
                <td>
                  <span class="badge" :class="{
                    'bg-warning': item.status === 'pending',
                    'bg-success': item.status === 'approved',
                    'bg-danger': item.status === 'rejected'
                  }">
                    {{ item.status }}
                  </span>
                </td>
                <td>
                  <div class="btn-group" v-if="item.status === 'pending'">
                    <button 
                      class="btn btn-sm btn-success me-2" 
                      @click="handleApprove(item.id)"
                      title="Approve">
                      <i class="fa fa-check"></i>
                    </button>
                    <button 
                      class="btn btn-sm btn-danger" 
                      @click="handleReject(item.id)"
                      title="Reject">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="d-flex justify-content-between align-items-center mt-3">
            <div>
              Showing page {{ currentPage }} of {{ totalPages }}
            </div>
            <ul class="pagination justify-content-end">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" @click="previousPage" style="cursor: pointer;">Previous</a>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a class="page-link" @click="nextPage" style="cursor: pointer;">Next</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.badge {
  padding: 0.5em 1em;
  font-size: 0.875em;
  text-transform: capitalize;
}

.btn-group .btn {
  padding: 0.25rem 0.5rem;
}

.btn i {
  font-size: 0.875rem;
}
</style>
