import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row mb-4" }
const _hoisted_5 = { class: "col-md-4" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = { class: "col-md-4" }
const _hoisted_9 = {
  key: 0,
  class: "form-group"
}
const _hoisted_10 = {
  key: 0,
  class: "row mb-3"
}
const _hoisted_11 = { class: "col-md-6 d-flex align-items-center" }
const _hoisted_12 = { class: "form-check" }
const _hoisted_13 = { class: "col-md-6 text-end" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "table-responsive" }
const _hoisted_16 = { class: "table table-bordered" }
const _hoisted_17 = { class: "text-center" }
const _hoisted_18 = ["onUpdate:modelValue"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "modal-dialog" }
const _hoisted_21 = { class: "modal-content" }
const _hoisted_22 = { class: "modal-body" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "address-details mt-3" }
const _hoisted_25 = { class: "modal-dialog" }
const _hoisted_26 = { class: "modal-content" }
const _hoisted_27 = { class: "modal-body" }
const _hoisted_28 = { class: "form-group" }
const _hoisted_29 = { class: "mt-3" }
const _hoisted_30 = { class: "modal-footer" }
const _hoisted_31 = ["disabled"]

import { ref, computed, onMounted } from 'vue';
import { Modal } from 'bootstrap';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { fetchGetApi, fetchPostApi } from '@/util/api';

// TypeScript interfaces
interface Department {
    _id: string;
    name: string;
}

interface Site {
    _id: string;
    siteName: string;
}

interface Employee {
    _id: string;
    employeeCode: string;
    employeeName: string;
    fathersName: string;
    createdAt: string;
    department: Department;
    site: Site;
    village?: string;
    po?: string;
    ps?: string;
    district?: string;
    state?: string;
    pin?: string;
    selected?: boolean;
    joiningDate?: Date;
}

interface APIResponse {
    success: boolean;
    count: number;
    data: Employee[];
}

// State with types

export default /*@__PURE__*/_defineComponent({
  __name: 'pv-details',
  setup(__props) {

const startDate = ref<string>('');
const endDate = ref<string>('');
const searchName = ref<string>('');
const employees = ref<Employee[]>([]);
const showNameSearch = ref<boolean>(false);
const selectAll = ref<boolean>(false);
const journalNumber = ref<string>('');

// Modal refs and instances
const addressModalRef = ref<HTMLElement | null>(null);
const journalModalRef = ref<HTMLElement | null>(null);
const selectedEmployee = ref<Employee | null>(null);
//@ts-ignore
let modal: Modal | null = null;
//@ts-ignore
let journalModal: Modal | null = null;
const baseUrl = process.env.VUE_APP_BASE_URL;

// Initialize modals
onMounted(() => {
    if (addressModalRef.value && journalModalRef.value) {
        modal = new Modal(addressModalRef.value);
        journalModal = new Modal(journalModalRef.value);
    }
});

// Methods with type annotations
const viewAddress = (employee: Employee): void => {
    selectedEmployee.value = employee;
    modal?.show();
};

const closeModal = (): void => {
    modal?.hide();
    selectedEmployee.value = null;
};

const fetchEmployees = async (): Promise<void> => {
    try {
        if (!startDate.value || !endDate.value) {
            return;
        }
        
        const response = await fetchGetApi<APIResponse>(
           `api/employees/date-range?startDate=${startDate.value}&endDate=${endDate.value}`
        );
        
        if (response.data.success) {
            employees.value = response.data.data.map(emp => ({
                ...emp,
                selected: false,
                joiningDate: new Date(emp.createdAt)
            }));
            showNameSearch.value = true;
        }
    } catch (error) {
        console.error('Error fetching employees:', error);
    }
};

const toggleSelectAll = (): void => {
    employees.value.forEach(emp => {
        if (emp.selected !== undefined) {
            emp.selected = selectAll.value;
        }
    });
};

const updateSelectAll = (): void => {
    selectAll.value = employees.value.length > 0 && 
        employees.value.every(emp => emp.selected);
};

const selectedEmployees = computed((): Employee[] => {
    return employees.value.filter(emp => emp.selected);
});

const openJournalModal = (): void => {
    journalNumber.value = '';
    journalModal?.show();
};

const closeJournalModal = (): void => {
    journalModal?.hide();
    journalNumber.value = '';
};

const setJournalNumber = async (): Promise<void> => {
    try {
        const selectedIds = selectedEmployees.value.map(emp => emp._id);
        
        await fetchPostApi('api/employees/set-journal', {
            employeeIds: selectedIds,
            journalNumber: journalNumber.value
        });

        await fetchEmployees();
        closeJournalModal();
    } catch (error) {
        console.error('Error setting journal number:', error);
    }
};

const formatDate = (date: Date): string => {
    return format(date, 'dd-MM-yyyy');
};

const filteredEmployees = computed((): Employee[] => {
    if (!searchName.value) return employees.value;
    
    return employees.value.filter(emp => 
        emp.employeeName.toLowerCase().includes(searchName.value.toLowerCase())
    );
});

const downloadExcel = (): void => {
    const worksheet = XLSX.utils.json_to_sheet(filteredEmployees.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PV Details');
    XLSX.writeFile(workbook, 'pv_details.xlsx');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", { class: "card-header d-flex justify-content-between align-items-center" }, [
        _cache[5] || (_cache[5] = _createElementVNode("h5", { class: "mb-0" }, "PV Details", -1)),
        _createElementVNode("button", {
          class: "btn btn-dark",
          onClick: downloadExcel
        }, " Download excel ")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[7] || (_cache[7] = _createElementVNode("label", null, "Search by date", -1)),
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  type: "date",
                  class: "form-control",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((startDate).value = $event))
                }, null, 512), [
                  [_vModelText, startDate.value]
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "mx-2" }, "to", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "date",
                  class: "form-control",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((endDate).value = $event))
                }, null, 512), [
                  [_vModelText, endDate.value]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (showNameSearch.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", null, "Search by emp. Name", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((searchName).value = $event))
                  }, null, 512), [
                    [_vModelText, searchName.value]
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", { class: "col-md-4" }, [
            _createElementVNode("div", { class: "form-group" }, [
              _cache[9] || (_cache[9] = _createElementVNode("label", null, " ", -1)),
              _createElementVNode("button", {
                class: "btn btn-primary form-control",
                onClick: fetchEmployees
              }, " Search ")
            ])
          ])
        ]),
        (employees.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    class: "form-check-input",
                    id: "selectAll",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectAll).value = $event)),
                    onChange: toggleSelectAll
                  }, null, 544), [
                    [_vModelCheckbox, selectAll.value]
                  ]),
                  _cache[10] || (_cache[10] = _createElementVNode("label", {
                    class: "form-check-label",
                    for: "selectAll"
                  }, "Select All", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  onClick: openJournalModal,
                  disabled: !selectedEmployees.value.length
                }, " Set Journal Number ", 8, _hoisted_14)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("table", _hoisted_16, [
            _cache[11] || (_cache[11] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", { rowspan: "2" }, [
                  _createElementVNode("div", { class: "text-center" }, "Select")
                ]),
                _createElementVNode("th", { rowspan: "2" }, "SL NO."),
                _createElementVNode("th", { rowspan: "2" }, "Date of joining"),
                _createElementVNode("th", { rowspan: "2" }, "Employee Code"),
                _createElementVNode("th", { rowspan: "2" }, "NAME OF EMPLOYEES"),
                _createElementVNode("th", { rowspan: "2" }, "Father's Name"),
                _createElementVNode("th", { rowspan: "2" }, "Department"),
                _createElementVNode("th", { rowspan: "2" }, "Site"),
                _createElementVNode("th", { rowspan: "2" }, "Actions")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredEmployees.value, (employee, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", _hoisted_17, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      class: "form-check-input",
                      "onUpdate:modelValue": ($event: any) => ((employee.selected) = $event),
                      onChange: updateSelectAll
                    }, null, 40, _hoisted_18), [
                      [_vModelCheckbox, employee.selected]
                    ])
                  ]),
                  _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                  _createElementVNode("td", null, _toDisplayString(formatDate(employee.joiningDate)), 1),
                  _createElementVNode("td", null, _toDisplayString(employee.employeeCode), 1),
                  _createElementVNode("td", null, _toDisplayString(employee.employeeName), 1),
                  _createElementVNode("td", null, _toDisplayString(employee.fathersName), 1),
                  _createElementVNode("td", null, _toDisplayString(employee.department?.name), 1),
                  _createElementVNode("td", null, _toDisplayString(employee.site?.siteName), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("button", {
                      class: "btn btn-info btn-sm",
                      onClick: ($event: any) => (viewAddress(employee))
                    }, " View Address ", 8, _hoisted_19)
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: "modal fade",
      id: "addressModal",
      tabindex: "-1",
      ref_key: "addressModalRef",
      ref: addressModalRef
    }, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", { class: "modal-header" }, [
            _cache[12] || (_cache[12] = _createElementVNode("h5", { class: "modal-title" }, "Employee Address Details", -1)),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              onClick: closeModal
            })
          ]),
          _createElementVNode("div", _hoisted_22, [
            (selectedEmployee.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("h6", null, _toDisplayString(selectedEmployee.value.employeeName) + "'s Address", 1),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("p", null, [
                      _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Village:", -1)),
                      _createTextVNode(" " + _toDisplayString(selectedEmployee.value.village || '-'), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Post Office:", -1)),
                      _createTextVNode(" " + _toDisplayString(selectedEmployee.value.po || '-'), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Police Station:", -1)),
                      _createTextVNode(" " + _toDisplayString(selectedEmployee.value.ps || '-'), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[16] || (_cache[16] = _createElementVNode("strong", null, "District:", -1)),
                      _createTextVNode(" " + _toDisplayString(selectedEmployee.value.district || '-'), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[17] || (_cache[17] = _createElementVNode("strong", null, "State:", -1)),
                      _createTextVNode(" " + _toDisplayString(selectedEmployee.value.state || '-'), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[18] || (_cache[18] = _createElementVNode("strong", null, "PIN:", -1)),
                      _createTextVNode(" " + _toDisplayString(selectedEmployee.value.pin || '-'), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", { class: "modal-footer" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: closeModal
            }, "Close")
          ])
        ])
      ])
    ], 512),
    _createElementVNode("div", {
      class: "modal fade",
      id: "journalModal",
      tabindex: "-1",
      ref_key: "journalModalRef",
      ref: journalModalRef
    }, [
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", { class: "modal-header" }, [
            _cache[19] || (_cache[19] = _createElementVNode("h5", { class: "modal-title" }, "Set Journal Number", -1)),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              onClick: closeJournalModal
            })
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _cache[20] || (_cache[20] = _createElementVNode("label", null, "Journal Number", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((journalNumber).value = $event)),
                placeholder: "Enter journal number"
              }, null, 512), [
                [_vModelText, journalNumber.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("p", null, "Selected Employees: " + _toDisplayString(selectedEmployees.value.length), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: closeJournalModal
            }, "Cancel"),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: setJournalNumber,
              disabled: !journalNumber.value
            }, " Save ", 8, _hoisted_31)
          ])
        ])
      ])
    ], 512)
  ]))
}
}

})