<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { fetchGetApi } from "@/util/api";
import { useToast } from 'vue-toast-notification';
import { useRoute } from 'vue-router';
import { jsonToExcel } from "@/util/makeExcel";

const $toast = useToast();
const kytData = ref([]);
const loading = ref(false);

// Filter refs
const managerFilter = ref('');
const dateRangeStart = ref('');
const dateRangeEnd = ref('');
const typeFilter = ref('all'); // 'all', 'transfer', 'change'
const siteNameParam = ref('');

async function fetchData() {
    loading.value = true;
    try {
        // Fetch transfer KYTs
        const transferResponse = await fetchGetApi('api/kyt/transfer/get/all');

        const transferKyts = (transferResponse?.data || []).map(item => {
            const formatDate = (dateString) => {
                const date = new Date(dateString);
                // Convert to IST by adding 5 hours and 30 minutes
                date.setHours(date.getHours() + 5);
                date.setMinutes(date.getMinutes() + 30);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            };

            return {
                managerName: item.requestedBy || 'N/A',
                date: formatDate(item.createdAt),
                kytDate: formatDate(item.kytDate), // Use kytDate if available, fallback to createdAt
                type: 'Transfer',
                projectName: item.siteName || 'N/A',
                workDetails: `KYT Transfer Request`,
                reason: item.reason || 'N/A',
                newNFD: item.previousNFD || 'N/A',
                transferredTo: item.empId || 'N/A',
                status: item.status || 'Pending',
                approvedBy: item.approvedByName || 'N/A',
                previousNFD: item.previousNFD || 'N/A'
            };
        });

        // Fetch change KYTs
        const changeResponse = await fetchGetApi('api/kyt/getChangeKyts');

        const changeKyts = (changeResponse?.data || []).map(item => {
            const formatDate = (dateString) => {
                const date = new Date(dateString);
                // Convert to IST by adding 5 hours and 30 minutes
                date.setHours(date.getHours() + 5);
                date.setMinutes(date.getMinutes() + 30);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            };

            // For change KYTs, handle the date field which is already in DD-MM-YYYY format
            const parseFormattedDate = (dateString) => {
                if (!dateString) return 'N/A';
                // Check if date is already in DD-MM-YYYY format
                if (dateString.includes('-')) {
                    return dateString.split('-').join('/');
                }
                return formatDate(dateString);
            };

            return {
                managerName: item.empName || 'N/A',
                date: formatDate(item.createdAt),
                kytDate: formatDate(item.kytDate || item.createdAt), // Use kytDate if available, fallback to createdAt
                type: 'Change',
                projectName: item.siteName || 'N/A',
                workDetails: item.workDetails?.length ? item.workDetails.join(', ') : '-',
                reason: item.reason || 'N/A',
                newNFD: parseFormattedDate(item.date),
                transferredTo: '-',
                status: item.status || 'Pending',
                approvedBy: item.approvedByName || 'N/A',
                previousNFD: item.previousNFD || 'N/A'
            };
        });

        kytData.value = [...transferKyts, ...changeKyts];

        if (kytData.value.length === 0) {
            $toast.info('No KYT data found');
        }
    } catch (error) {
        console.error('Error fetching KYT data:', error);
        $toast.error('Failed to fetch KYT data: ' + (error.message || 'Unknown error'));
    } finally {
        loading.value = false;
    }
}

onMounted(() => {
    // Get parameters from route query parameters
    const route = useRoute();
    if (route.query.siteName) {
        siteNameParam.value = route.query.siteName;
    }
    if (route.query.type) {
        const queryType = route.query.type.toLowerCase();
        if (['all', 'transfer', 'change'].includes(queryType)) {
            typeFilter.value = queryType;
        }
    }
    fetchData();
});

// Computed filtered data
const filteredData = computed(() => {
    return kytData.value.filter(item => {
        const matchesManager = !managerFilter.value ||
            item.managerName.toLowerCase().includes(managerFilter.value.toLowerCase());

        // Add siteName filter
        const matchesSiteName = !siteNameParam.value ||
            item.projectName.toLowerCase() === siteNameParam.value.toLowerCase();

        // Parse the createdAt date and set to start of day for comparison
        const itemDate = new Date(item.kytDate.split('/').reverse().join('-'));
        itemDate.setHours(0, 0, 0, 0);

        let startDate = null;
        let endDate = null;

        if (dateRangeStart.value) {
            startDate = new Date(dateRangeStart.value);
            startDate.setHours(0, 0, 0, 0);
        }

        if (dateRangeEnd.value) {
            endDate = new Date(dateRangeEnd.value);
            endDate.setHours(23, 59, 59, 999);
        }

        const matchesDateRange = (!startDate || itemDate >= startDate) &&
            (!endDate || itemDate <= endDate);

        const matchesType = typeFilter.value === 'all' ||
            item.type.toLowerCase() === typeFilter.value.toLowerCase();

        return matchesManager && matchesDateRange && matchesType && matchesSiteName;
    });
});

function clearFilters() {
    managerFilter.value = '';
    dateRangeStart.value = '';
    dateRangeEnd.value = '';
    typeFilter.value = 'all';
    siteNameParam.value = '';
}

function validateDates() {
    if (dateRangeStart.value && dateRangeEnd.value) {
        const startDate = new Date(dateRangeStart.value);
        const endDate = new Date(dateRangeEnd.value);

        if (startDate > endDate) {
            $toast.error('Start date cannot be later than end date');
            dateRangeStart.value = '';
            dateRangeEnd.value = '';
            return false;
        }
    }
    return true;
}

// Add watch for date changes
watch([dateRangeStart, dateRangeEnd], () => {
    validateDates();
});

function viewWorkDetails(details) {
    console.log('Viewing work details:', details);
    // Implement modal or navigation to show full work details
}

function downloadExcel() {
    const dataToExport = filteredData.value.map((item, index) => ({
        'SL': index + 1,
        'Manager Name': item.managerName || 'N/A',
        'Date': item.date || 'N/A',
        'Previous NFD': item.previousNFD || 'N/A',
        'Type': item.type || 'N/A',
        'Name of Project/CDW': item.projectName || 'N/A',
        'Work Details': item.workDetails || 'N/A',
        'Reason': item.reason || 'N/A',
        'New NFD': item.newNFD || 'N/A',
        'Transferred To': item.transferredTo || 'N/A',
        'Approved By': item.approvedBy || 'N/A'
    }));

    const timestamp = new Date().toISOString().split('T')[0];
    jsonToExcel(dataToExport, `marketing-kyt-change-transfer-report-${timestamp}.xlsx`);
    $toast.success('Excel file downloaded successfully');
}
</script>

<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <!-- Title Section -->
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <h5 class="card-title">Marketing KYT Change/Transfer Report</h5>
                </div>

                <!-- Filters Section -->
                <div class="row mb-4">
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Manager Name</label>
                        <input type="text" class="form-control" v-model="managerFilter" placeholder="Filter by manager">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Date Range Start</label>
                        <input type="date" class="form-control" v-model="dateRangeStart">
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="form-label">Date Range End</label>
                        <input type="date" class="form-control" v-model="dateRangeEnd">
                    </div>
                    <div class="col-md-2 mb-2">
                        <label class="form-label">Type</label>
                        <select class="form-select" v-model="typeFilter">
                            <option value="all">All</option>
                            <option value="transfer">Transfer</option>
                            <option value="change">Change</option>
                        </select>
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button class="btn btn-secondary" @click="clearFilters">
                            Clear
                        </button>
                    </div>
                    <div class="col-md-1 mb-2 d-flex align-items-end">
                        <button type="button" class="btn btn-primary" @click="downloadExcel">
                            <i class="fas fa-download me-1"></i>
                            Download Excel
                        </button>
                    </div>
                </div>

                <!-- Loading State -->
                <div v-if="loading" class="text-center my-4">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <!-- No Data Message -->
                <div v-else-if="!filteredData.length" class="text-center my-4">
                    <p class="text-muted">No KYT data available</p>
                </div>

                <!-- Table Section -->
                <div class="table-responsive" v-else>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>SL No.</th>
                                <th>Manager name</th>
                                <th>Date</th>
                                <th>Previous NFD</th>
                                <th>Type (transfer/change)</th>
                                <th>Name of project/CDW</th>
                                <th>Details</th>
                                <th>Reason</th>
                                <th>New NFD</th>
                                <th>Transferred to</th>
                                <th>Approved by</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredData" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.managerName }}</td>
                                <td>{{ item.date }}</td>
                                <td>{{ item.previousNFD }}</td>
                                <td>
                                    <span :class="{
                                        'badge': true,
                                        'bg-primary': item.type === 'Transfer',
                                        'bg-success': item.type === 'Change'
                                    }">
                                        {{ item.type }}
                                    </span>
                                </td>
                                <td>{{ item.projectName }}</td>
                                <td>
                                    <span v-if="item.workDetails && item.workDetails.includes(',')" 
                                          class="text-wrap">{{ item.workDetails }}</span>
                                    <span v-else>{{ item.workDetails }}</span>
                                </td>
                                <td>{{ item.reason }}</td>
                                <td>{{ item.newNFD }}</td>
                                <td>{{ item.transferredTo }}</td>
                                <td>
                                    {{ item.approvedBy }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.btn-xs {
    padding: 0.2rem 0.4rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.table th,
.table td {
    vertical-align: middle;
    font-size: 0.875rem;
}

.form-label {
    font-size: 0.875rem;
    font-weight: 500;
}

.card-title {
    font-size: 1.1rem;
    font-weight: 500;
}

.badge {
    padding: 0.5em 0.8em;
    font-weight: 500;
    font-size: 0.75rem;
}
</style>