import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "site-list" }
const _hoisted_2 = { class: "card mb-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row g-3" }
const _hoisted_5 = { class: "col-md-4" }
const _hoisted_6 = { class: "col-md-3" }
const _hoisted_7 = { class: "row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "d-flex justify-content-between align-items-center mb-3" }
const _hoisted_11 = { class: "card-title mb-0 text-dark" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "site-details" }
const _hoisted_14 = { class: "text-dark" }
const _hoisted_15 = { class: "text-dark" }
const _hoisted_16 = {
  key: 0,
  class: "text-center my-4"
}
const _hoisted_17 = {
  key: 1,
  class: "text-center my-4"
}
const _hoisted_18 = {
  key: 0,
  class: "modal-wrapper"
}
const _hoisted_19 = {
  class: "modal fade show",
  tabindex: "-1",
  style: {"display":"block"}
}
const _hoisted_20 = { class: "modal-dialog modal-xl" }
const _hoisted_21 = { class: "modal-content" }
const _hoisted_22 = {
  key: 0,
  class: "modal-body"
}
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-md-6" }
const _hoisted_25 = { class: "col-md-6" }
const _hoisted_26 = { class: "row mt-4" }
const _hoisted_27 = { class: "col-12" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = {
  key: 0,
  class: "row mt-4"
}
const _hoisted_31 = { class: "col-12" }
const _hoisted_32 = { class: "card-body" }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { class: "mt-2" }
const _hoisted_36 = { class: "list-unstyled" }
const _hoisted_37 = { key: 0 }
const _hoisted_38 = { key: 1 }
const _hoisted_39 = { key: 2 }
const _hoisted_40 = { key: 3 }
const _hoisted_41 = {
  key: 1,
  class: "row mt-4"
}
const _hoisted_42 = { class: "col-12" }
const _hoisted_43 = { class: "card-body" }
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { key: 2 }
const _hoisted_47 = { key: 3 }
const _hoisted_48 = { class: "mt-2" }
const _hoisted_49 = { class: "list-unstyled" }
const _hoisted_50 = { key: 0 }
const _hoisted_51 = { key: 1 }
const _hoisted_52 = { key: 2 }
const _hoisted_53 = { key: 3 }
const _hoisted_54 = {
  key: 2,
  class: "row mt-3"
}
const _hoisted_55 = { class: "col-12" }
const _hoisted_56 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_57 = { class: "card-body" }
const _hoisted_58 = { key: 1 }

import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import Swal from 'sweetalert2';
import { fetchGetApi, fetchPostApi } from "@/util/api";
interface Quotation {
    type: string;
    file: string;
}

interface WorkOrder {
    type: string;
    file: string;
}

interface SubDesignationDetail {
    count: number;
    requirements: string;
    _id: string;
}

interface Workforce {
    totalManpower?: number;
    workingPlace?: string;
    designations: string[];
    subDesignationDetails?: {
        [key: string]: SubDesignationDetail;
    };
}

interface Client {
    name: string;
    designation: string;
    phone: string;
    altPhone?: string;
    email: string;
    _id: string;
}

interface SiteManager {
    name: string;
    _id: string;
}

interface Department {
    managerId: string[]; DepartmentId: string[];
    workers: string[];
    _id: string;
}

interface Site {
    _id: string;
    siteName: string;
    status: 'active' | 'inactive';
    client?: Client;
    siteAddress: string;
    officeAddress?: string;
    workforce: Workforce;
    quotation?: Quotation;
    workOrder?: WorkOrder;
    workOrders: string[];
    siteUID: string;
    websiteUrl?: string;
    siteLat: number;
    siteLong: number;
    contactPerson: {
        name: string;
        designation: string;
        email: string;
        _id: string;
    };
    Department: Department[];
    kytData?: {
        followUps: any[];
        socReport: any[];
        meetingReport: string[];
        requirements: any[];
    };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'site-list',
  setup(__props) {

const router = useRouter();
const isLoading = ref(false);
const searchQuery = ref('');
const filterStatus = ref('');
const toast = useToast();

const baseUrl = process.env.VUE_APP_BASE_URL;

// Dummy data
const sites = ref<Site[]>([]);

// Replace fetchSites with dummy data loader
const fetchSites = async () => {
    isLoading.value = true;
    try {
        const response = await fetchGetApi('api/temp/sites/new');
        if (response && response.data.success) {
            sites.value = response.data.data.sites;
        } else {
            console.error('Error in response:', response);
        }
    } catch (error) {
        console.error('Error fetching sites:', error);
    } finally {
        isLoading.value = false;
    }
};

// Filter and search computed property
const filteredSites = computed(() => {
    return sites.value.filter(site => {
        const matchesSearch = site.siteName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            site.client?.name.toLowerCase().includes(searchQuery.value.toLowerCase());
        const matchesStatus = !filterStatus.value || site.status === filterStatus.value;
        return matchesSearch && matchesStatus;
    });
});

const getStatusBadgeClass = (status: string) => {
    return {
        'badge rounded-pill': true,
        'bg-success': status === 'active',
        'bg-secondary': status === 'inactive'
    };
};

const viewSiteDetails = (siteId: string) => {
    router.push(`/sites/${siteId}`);
};

const editSite = (siteId: string) => {
    router.push(`/sites/${siteId}/edit`);
};

const createNewSite = () => {
    router.push('/site-creation/create');
};

const addSiteManager = (siteId: string) => {
    router.push(`/site-creation/add-site-manager/${siteId}`);
};

const addEmployee = (siteId: string) => {
    router.push(`/site-creation/add-employee/${siteId}`);
};

const selectedSite = ref<Site | null>(null);
const showModal = ref(false);

function handleCardClick(event: MouseEvent, site: Site) {
    const target = event.target as HTMLElement;
    if (target.closest('.card-footer') || target.closest('.dropdown')) {
        return;
    }
    openSiteDetails(site);
}

function openSiteDetails(site: Site) {
    selectedSite.value = site;
    showModal.value = true;
    document.body.style.overflow = 'hidden';
}

function closeModal() {
    showModal.value = false;
    selectedSite.value = null;
    document.body.style.overflow = '';
}

async function updateSiteStatus(siteId: string, newStatus: 'active' | 'inactive') {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to mark this site as ${newStatus}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const response = await fetchPostApi(`api/temp/update/site/status/${siteId}`, {
                status: newStatus
            });

            if (response && response.data.success) {
                sites.value = sites.value.map(site => {
                    if (site._id === siteId) {
                        return {
                            ...site,
                            status: newStatus
                        };
                    }
                    return site;
                });

                toast.success(`Site status updated to ${newStatus}`);
            } else {
                toast.error(response.data.message || 'Failed to update site status');
            }
        }
    } catch (error) {
        console.error('Error updating site status:', error);
        toast.error('Failed to update site status');
    }
}

async function editSiteName(site: Site) {
    try {
        const { value: newName } = await Swal.fire({
            title: 'Edit Site Name',
            input: 'text',
            inputValue: site.siteName,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Site name cannot be empty!';
                }
                return null;
            }
        });

        if (newName) {
            const response = await fetch(`${baseUrl}api/sites/${site._id}/editSiteName`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ newSiteName: newName })
            });

            if (response.ok) {
                // Update local state
                sites.value = sites.value.map(s =>
                    s._id === site._id ? { ...s, siteName: newName } : s
                );
                toast.success('Site name updated successfully');
            } else {
                toast.error('Failed to update site name');
            }
        }
    } catch (error) {
        console.error('Error updating site name:', error);
        toast.error('Failed to update site name');
    }
}

// Fetch sites when component is mounted
fetchSites();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "d-flex justify-content-between align-items-center mb-4" }, [
        _createElementVNode("h2", null, "New Sites")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
                placeholder: "Search sites..."
              }, null, 512), [
                [_vModelText, searchQuery.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("select", {
                class: "form-select",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filterStatus).value = $event))
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("option", { value: "" }, "All Status", -1),
                _createElementVNode("option", { value: "active" }, "Active", -1),
                _createElementVNode("option", { value: "inactive" }, "Inactive", -1)
              ]), 512), [
                [_vModelSelect, filterStatus.value]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredSites.value, (site) => {
          return (_openBlock(), _createElementBlock("div", {
            key: site._id,
            class: "col"
          }, [
            _createElementVNode("div", {
              class: "card h-100",
              onClick: ($event: any) => (handleCardClick($event, site))
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("h5", _hoisted_11, _toDisplayString(site.siteName), 1),
                  _createElementVNode("button", {
                    class: "btn btn-sm btn-outline-primary",
                    onClick: _withModifiers(($event: any) => (editSiteName(site)), ["stop"])
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "bi bi-pencil" }, null, -1),
                    _createTextVNode(" Edit ")
                  ]), 8, _hoisted_12)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("p", _hoisted_14, [
                    _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Site UID:", -1)),
                    _createTextVNode(" " + _toDisplayString(site.siteUID), 1)
                  ]),
                  _createElementVNode("p", _hoisted_15, [
                    _cache[5] || (_cache[5] = _createElementVNode("i", { class: "bi bi-geo-alt" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(site.siteAddress), 1)
                  ])
                ])
              ])
            ], 8, _hoisted_8)
          ]))
        }), 128))
      ]),
      (isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[6] || (_cache[6] = [
            _createElementVNode("div", {
              class: "spinner-border text-primary",
              role: "status"
            }, [
              _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (!isLoading.value && filteredSites.value.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[7] || (_cache[7] = [
            _createElementVNode("p", { class: "text-muted" }, "No sites found", -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    (showModal.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", { class: "modal-header" }, [
                  _cache[9] || (_cache[9] = _createElementVNode("h5", { class: "modal-title" }, "Site Details", -1)),
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn-close",
                    onClick: closeModal
                  })
                ]),
                (selectedSite.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          _cache[19] || (_cache[19] = _createElementVNode("h6", null, "Basic Information", -1)),
                          _createElementVNode("p", null, [
                            _cache[10] || (_cache[10] = _createElementVNode("strong", null, "Site Name:", -1)),
                            _createTextVNode(" " + _toDisplayString(selectedSite.value.siteName), 1)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[11] || (_cache[11] = _createElementVNode("strong", null, "Site UID:", -1)),
                            _createTextVNode(" " + _toDisplayString(selectedSite.value.siteUID), 1)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Status:", -1)),
                            _createElementVNode("span", {
                              class: _normalizeClass(getStatusBadgeClass(selectedSite.value.status))
                            }, _toDisplayString(selectedSite.value.status), 3)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Address:", -1)),
                            _createTextVNode(" " + _toDisplayString(selectedSite.value.siteAddress), 1)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Website:", -1)),
                            _createTextVNode(" " + _toDisplayString(selectedSite.value.websiteUrl || 'N/A'), 1)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Area:", -1)),
                            _createTextVNode(" " + _toDisplayString(selectedSite.value.area), 1)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Location:", -1)),
                            _createTextVNode(" " + _toDisplayString(selectedSite.value.siteLat) + ", " + _toDisplayString(selectedSite.value.siteLong), 1)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[17] || (_cache[17] = _createElementVNode("strong", null, "Created:", -1)),
                            _createTextVNode(" " + _toDisplayString(new Date(selectedSite.value.createdAt).toLocaleString()), 1)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Updated:", -1)),
                            _createTextVNode(" " + _toDisplayString(new Date(selectedSite.value.updatedAt).toLocaleString()), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          _cache[23] || (_cache[23] = _createElementVNode("h6", null, "Contact Person", -1)),
                          _createElementVNode("p", null, [
                            _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Name:", -1)),
                            _createTextVNode(" " + _toDisplayString(selectedSite.value.contactPerson.name), 1)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[21] || (_cache[21] = _createElementVNode("strong", null, "Designation:", -1)),
                            _createTextVNode(" " + _toDisplayString(selectedSite.value.contactPerson.designation), 1)
                          ]),
                          _createElementVNode("p", null, [
                            _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Email:", -1)),
                            _createTextVNode(" " + _toDisplayString(selectedSite.value.contactPerson.email), 1)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _cache[26] || (_cache[26] = _createElementVNode("h6", null, "Workforce Information", -1)),
                          (selectedSite.value.workforce.totalManpower)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_28, [
                                _cache[24] || (_cache[24] = _createElementVNode("strong", null, "Total Manpower:", -1)),
                                _createTextVNode(" " + _toDisplayString(selectedSite.value.workforce.totalManpower), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (selectedSite.value.workforce.designations?.length)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_29, [
                                _cache[25] || (_cache[25] = _createElementVNode("strong", null, "Designations:", -1)),
                                _createTextVNode(" " + _toDisplayString(selectedSite.value.workforce.designations.join(', ')), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      (selectedSite.value.kytData?.socReport?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                            _createElementVNode("div", _hoisted_31, [
                              _cache[39] || (_cache[39] = _createElementVNode("h6", null, "SOC Reports", -1)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedSite.value.kytData.socReport, (socGroup, groupIndex) => {
                                return (_openBlock(), _createElementBlock("div", { key: groupIndex }, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(socGroup, (soc, socIndex) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: socIndex,
                                      class: "card mb-3"
                                    }, [
                                      _createElementVNode("div", _hoisted_32, [
                                        _createElementVNode("p", null, [
                                          _cache[27] || (_cache[27] = _createElementVNode("strong", null, "Service:", -1)),
                                          _createTextVNode(" " + _toDisplayString(soc.service), 1)
                                        ]),
                                        _createElementVNode("p", null, [
                                          _cache[28] || (_cache[28] = _createElementVNode("strong", null, "Mode of Service:", -1)),
                                          _createTextVNode(" " + _toDisplayString(soc.modeOfService), 1)
                                        ]),
                                        _createElementVNode("p", null, [
                                          _cache[29] || (_cache[29] = _createElementVNode("strong", null, "Competitor:", -1)),
                                          _createTextVNode(" " + _toDisplayString(soc.competitorName), 1)
                                        ]),
                                        _createElementVNode("p", null, [
                                          _cache[30] || (_cache[30] = _createElementVNode("strong", null, "Total Manpower:", -1)),
                                          _createTextVNode(" " + _toDisplayString(soc.totalManpower), 1)
                                        ]),
                                        (soc.socDetails?.[0])
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_33, [
                                              _cache[31] || (_cache[31] = _createElementVNode("strong", null, "Work Duration:", -1)),
                                              _createTextVNode(" " + _toDisplayString(soc.socDetails[0].workDuration) + " hours", 1)
                                            ]))
                                          : _createCommentVNode("", true),
                                        (soc.socDetails?.[0])
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_34, [
                                              _cache[32] || (_cache[32] = _createElementVNode("strong", null, "Salary:", -1)),
                                              _createTextVNode(" ₹" + _toDisplayString(soc.socDetails[0].salary), 1)
                                            ]))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("p", null, [
                                          _cache[33] || (_cache[33] = _createElementVNode("strong", null, "Agreement Period:", -1)),
                                          _createTextVNode(" " + _toDisplayString(soc.agreementStarted) + " to " + _toDisplayString(soc.agreementEnded), 1)
                                        ]),
                                        _createElementVNode("div", _hoisted_35, [
                                          _cache[38] || (_cache[38] = _createElementVNode("p", { class: "mb-1" }, [
                                            _createElementVNode("strong", null, "Provisions:")
                                          ], -1)),
                                          _createElementVNode("ul", _hoisted_36, [
                                            (soc.socDetails?.[0])
                                              ? (_openBlock(), _createElementBlock("li", _hoisted_37, [
                                                  _createElementVNode("i", {
                                                    class: _normalizeClass(["bi", soc.socDetails[0].isFoodProvided ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger'])
                                                  }, null, 2),
                                                  _cache[34] || (_cache[34] = _createTextVNode(" Food"))
                                                ]))
                                              : _createCommentVNode("", true),
                                            (soc.socDetails?.[0])
                                              ? (_openBlock(), _createElementBlock("li", _hoisted_38, [
                                                  _createElementVNode("i", {
                                                    class: _normalizeClass(["bi", soc.socDetails[0].isStayProvided ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger'])
                                                  }, null, 2),
                                                  _cache[35] || (_cache[35] = _createTextVNode(" Stay"))
                                                ]))
                                              : _createCommentVNode("", true),
                                            (soc.socDetails?.[0])
                                              ? (_openBlock(), _createElementBlock("li", _hoisted_39, [
                                                  _createElementVNode("i", {
                                                    class: _normalizeClass(["bi", soc.socDetails[0].isEsicPfProvided ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger'])
                                                  }, null, 2),
                                                  _cache[36] || (_cache[36] = _createTextVNode(" ESIC/PF"))
                                                ]))
                                              : _createCommentVNode("", true),
                                            (soc.socDetails?.[0])
                                              ? (_openBlock(), _createElementBlock("li", _hoisted_40, [
                                                  _createElementVNode("i", {
                                                    class: _normalizeClass(["bi", soc.socDetails[0].isWeeklyOff ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger'])
                                                  }, null, 2),
                                                  _cache[37] || (_cache[37] = _createTextVNode(" Weekly Off"))
                                                ]))
                                              : _createCommentVNode("", true)
                                          ])
                                        ])
                                      ])
                                    ]))
                                  }), 128))
                                ]))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (selectedSite.value.kytData?.requirements?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                            _createElementVNode("div", _hoisted_42, [
                              _cache[49] || (_cache[49] = _createElementVNode("h6", null, "Requirements", -1)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedSite.value.kytData.requirements, (reqGroup, groupIndex) => {
                                return (_openBlock(), _createElementBlock("div", { key: groupIndex }, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reqGroup, (req, reqIndex) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: reqIndex,
                                      class: "card mb-3"
                                    }, [
                                      _createElementVNode("div", _hoisted_43, [
                                        (req.gender)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                              _cache[40] || (_cache[40] = _createElementVNode("p", null, [
                                                _createElementVNode("strong", null, "Gender Distribution:")
                                              ], -1)),
                                              _createElementVNode("ul", null, [
                                                _createElementVNode("li", null, "Male: " + _toDisplayString(req.gender.male), 1),
                                                _createElementVNode("li", null, "Female: " + _toDisplayString(req.gender.female), 1)
                                              ])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (req.serviceName)
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_45, [
                                              _cache[41] || (_cache[41] = _createElementVNode("strong", null, "Service:", -1)),
                                              _createTextVNode(" " + _toDisplayString(req.serviceName), 1)
                                            ]))
                                          : _createCommentVNode("", true),
                                        (req.manPower)
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_46, [
                                              _cache[42] || (_cache[42] = _createElementVNode("strong", null, "Manpower Required:", -1)),
                                              _createTextVNode(" " + _toDisplayString(req.manPower), 1)
                                            ]))
                                          : _createCommentVNode("", true),
                                        (req.workingHours)
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_47, [
                                              _cache[43] || (_cache[43] = _createElementVNode("strong", null, "Working Hours:", -1)),
                                              _createTextVNode(" " + _toDisplayString(req.workingHours), 1)
                                            ]))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("div", _hoisted_48, [
                                          _cache[48] || (_cache[48] = _createElementVNode("p", { class: "mb-1" }, [
                                            _createElementVNode("strong", null, "Provisions:")
                                          ], -1)),
                                          _createElementVNode("ul", _hoisted_49, [
                                            (req.food)
                                              ? (_openBlock(), _createElementBlock("li", _hoisted_50, _cache[44] || (_cache[44] = [
                                                  _createElementVNode("i", { class: "bi bi-check-circle-fill text-success" }, null, -1),
                                                  _createTextVNode(" Food")
                                                ])))
                                              : _createCommentVNode("", true),
                                            (req.stay)
                                              ? (_openBlock(), _createElementBlock("li", _hoisted_51, _cache[45] || (_cache[45] = [
                                                  _createElementVNode("i", { class: "bi bi-check-circle-fill text-success" }, null, -1),
                                                  _createTextVNode(" Stay")
                                                ])))
                                              : _createCommentVNode("", true),
                                            (req.epf)
                                              ? (_openBlock(), _createElementBlock("li", _hoisted_52, _cache[46] || (_cache[46] = [
                                                  _createElementVNode("i", { class: "bi bi-check-circle-fill text-success" }, null, -1),
                                                  _createTextVNode(" EPF")
                                                ])))
                                              : _createCommentVNode("", true),
                                            (req.weeklyOff)
                                              ? (_openBlock(), _createElementBlock("li", _hoisted_53, _cache[47] || (_cache[47] = [
                                                  _createElementVNode("i", { class: "bi bi-check-circle-fill text-success" }, null, -1),
                                                  _createTextVNode(" Weekly Off ")
                                                ])))
                                              : _createCommentVNode("", true)
                                          ])
                                        ])
                                      ])
                                    ]))
                                  }), 128))
                                ]))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (selectedSite.value.kytData)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                            _createElementVNode("div", _hoisted_55, [
                              _cache[55] || (_cache[55] = _createElementVNode("h6", null, "KYT Data", -1)),
                              (selectedSite.value.kytData.followUps.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                                    _cache[53] || (_cache[53] = _createElementVNode("strong", null, "Follow Ups:", -1)),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedSite.value.kytData.followUps, (followUp, index) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: index,
                                        class: "card mb-2"
                                      }, [
                                        _createElementVNode("div", _hoisted_57, [
                                          _createElementVNode("p", null, [
                                            _cache[50] || (_cache[50] = _createElementVNode("strong", null, "Type:", -1)),
                                            _createTextVNode(" " + _toDisplayString(followUp.isCall ? 'Call' : 'Visit'), 1)
                                          ]),
                                          _createElementVNode("p", null, [
                                            _cache[51] || (_cache[51] = _createElementVNode("strong", null, "Next Follow-up Date:", -1)),
                                            _createTextVNode(" " + _toDisplayString(new
                                                Date(followUp.NFD).toLocaleString()), 1)
                                          ]),
                                          _createElementVNode("p", null, [
                                            _cache[52] || (_cache[52] = _createElementVNode("strong", null, "Rating:", -1)),
                                            _createTextVNode(" " + _toDisplayString(followUp.rating) + "%", 1)
                                          ])
                                        ])
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (selectedSite.value.kytData.meetingReport.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                                    _cache[54] || (_cache[54] = _createElementVNode("strong", null, "Meeting Reports:", -1)),
                                    _createElementVNode("ul", null, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedSite.value.kytData.meetingReport, (report, index) => {
                                        return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(report), 1))
                                      }), 128))
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", { class: "modal-footer" }, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-secondary",
                    onClick: closeModal
                  }, "Close")
                ])
              ])
            ])
          ]),
          _cache[56] || (_cache[56] = _createElementVNode("div", { class: "modal-backdrop fade show" }, null, -1))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})