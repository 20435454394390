<template>
    <Breadcrumbs main="Apps" title="Employee Late Claims" />

    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive">
                    <div class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="status-select" class="col-form-label">Filter by Status:</label>
                                <select id="status-select" class="form-select" v-model="selectedStatus">
                                    <option value="">All Status</option>
                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="from-date" class="col-form-label">From Date:</label>
                                <input 
                                    type="date" 
                                    id="from-date" 
                                    class="form-control" 
                                    v-model="fromDate"
                                >
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="to-date" class="col-form-label">To Date:</label>
                                <input 
                                    type="date" 
                                    id="to-date" 
                                    class="form-control" 
                                    v-model="toDate"
                                >
                            </div>
                            <div class="col-xs-3 col-sm-auto d-flex align-items-end">
                                <button 
                                    type="button" 
                                    class="btn btn-light" 
                                    @click="clearFilters"
                                >
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="display table-striped table-hover table-bordered table" id="basic-1">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 50px;">SL</th>
                                        <th scope="col">Employee ID</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Reason</th>
                                        <th scope="col">Photo</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Approved/Rejected By</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody v-if="!filteredClaims.length">
                                    <tr class="odd">
                                        <td valign="top" colspan="8" class="dataTables_empty">No matching records found</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr class="odd" v-for="(claim, index) in filteredClaims" :key="claim._id">
                                        <td>{{ ((currentPage - 1) * elementsPerPage) + index + 1 }}</td>
                                        <td>{{ claim.employeeId.employeeName }}</td>
                                        <td>{{ formatDate(claim.date) }}</td>
                                        <td>{{ convertTo12HourFormat(claim.time) }}</td>
                                        <td>{{ claim.reason }}</td>
                                        <td>
                                            <img v-if="claim.photo" :src="claim.photo" alt="Late claim photo"
                                                style="width: 50px; height: 50px; object-fit: cover; cursor: pointer;"
                                                @click="openPhotoModal(claim.photo)" />
                                            <span v-else>No photo</span>
                                        </td>
                                        <td>
                                            <span :class="{
                                                'badge rounded-pill bg-success': claim.isApproved,
                                                'badge rounded-pill bg-danger': claim.isRejected,
                                                'badge rounded-pill bg-warning': !claim.isApproved && !claim.isRejected
                                            }">
                                                {{ getStatus(claim) }}
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="claim.approvedBy">{{ claim.approvedBy.name }}</span>
                                            <span v-else-if="claim.rejectedBy">{{ claim.rejectedBy.name }}</span>
                                            <span v-else>-</span>
                                        </td>
                                        <td>
                                            <div class="d-flex gap-2" v-if="!claim.isApproved && !claim.isRejected">
                                                <button
                                                    @click="showApproveConfirm(claim._id)"
                                                    class="btn btn-success btn-sm"
                                                    title="Approve"
                                                >
                                                    <i class="fa fa-check"></i>
                                                </button>
                                                <button
                                                    @click="showRejectConfirm(claim._id)"
                                                    class="btn btn-danger btn-sm"
                                                    title="Reject"
                                                >
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div>
                                Showing page {{ currentPage }} of {{ totalPages }}
                            </div>
                            <ul class="pagination justify-content-end pagination-primary">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" @click="previousPage" style="cursor: pointer;">Previous</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                    <a class="page-link" @click="nextPage" style="cursor: pointer;">Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Photo Modal -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showPhotoModal }" style="background-color: rgba(0,0,0,0.5);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Late Claim Photo</h5>
                    <button type="button" class="btn-close" @click="showPhotoModal = false"></button>
                </div>
                <div class="modal-body text-center">
                    <img :src="selectedPhoto" alt="Late Claim Photo" style="max-width: 100%; max-height: 70vh;" />
                </div>
            </div>
        </div>
    </div>

    <!-- Confirmation Modal -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showConfirmModal }" style="background-color: rgba(0,0,0,0.5);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm {{ confirmAction }}</h5>
                    <button type="button" class="btn-close" @click="closeConfirmModal"></button>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to {{ confirmAction.toLowerCase() }} this late claim?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeConfirmModal">Cancel</button>
                    <button 
                        type="button" 
                        :class="confirmAction === 'Approve' ? 'btn btn-success' : 'btn btn-danger'"
                        @click="confirmActionExecute"
                    >
                        {{ confirmAction }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref, computed, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import Breadcrumbs from '@/layout/breadCrumbs.vue';

export default {
    name: 'EmployeeLateClaim',
    components: {
        Breadcrumbs
    },
    setup() {
        const lateClaims = ref([]);
        const toast = useToast();
        const selectedStatus = ref('');
        const fromDate = ref('');
        const toDate = ref('');
        const showPhotoModal = ref(false);
        const selectedPhoto = ref('');
        const elementsPerPage = ref(30);
        const currentPage = ref(1);
        const totalPages = ref(1);
        const baseUrl = process.env.VUE_APP_BASE_URL;
        const showConfirmModal = ref(false);
        const confirmAction = ref('');
        const selectedClaimId = ref(null);

        const filteredClaims = computed(() => {
            let filtered = [...lateClaims.value];
            
            // Status filter
            if (selectedStatus.value) {
                filtered = filtered.filter(claim => {
                    if (selectedStatus.value === 'approved') return claim.isApproved;
                    if (selectedStatus.value === 'rejected') return claim.isRejected;
                    if (selectedStatus.value === 'pending') return !claim.isApproved && !claim.isRejected;
                    return true;
                });
            }

            // Date range filter
            if (fromDate.value) {
                const fromDateTime = new Date(fromDate.value);
                fromDateTime.setHours(0, 0, 0, 0);
                filtered = filtered.filter(item => {
                    const itemDate = new Date(item.date);
                    return itemDate >= fromDateTime;
                });
            }
            
            if (toDate.value) {
                const toDateTime = new Date(toDate.value);
                toDateTime.setHours(23, 59, 59, 999);
                filtered = filtered.filter(item => {
                    const itemDate = new Date(item.date);
                    return itemDate <= toDateTime;
                });
            }
            
            return filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
        });

        const fetchLateClaims = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${baseUrl}api/employee/late/get/all`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                lateClaims.value = data.data;
            } catch (error) {
                toast.error('Error fetching late claims');
                console.error('Error:', error);
            }
        };

        const closeConfirmModal = () => {
            showConfirmModal.value = false;
            confirmAction.value = '';
            selectedClaimId.value = null;
        };

        const showApproveConfirm = (id) => {
            confirmAction.value = 'Approve';
            selectedClaimId.value = id;
            showConfirmModal.value = true;
        };

        const showRejectConfirm = (id) => {
            confirmAction.value = 'Reject';
            selectedClaimId.value = id;
            showConfirmModal.value = true;
        };

        const confirmActionExecute = async () => {
            if (confirmAction.value === 'Approve') {
                await approveClaim(selectedClaimId.value);
            } else if (confirmAction.value === 'Reject') {
                await rejectClaim(selectedClaimId.value);
            }
            closeConfirmModal();
        };

        const approveClaim = async (id) => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${baseUrl}api/employee/late/approve/${id}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    toast.success('Claim approved successfully');
                    await fetchLateClaims();
                } else {
                    throw new Error(data.message || 'Failed to approve claim');
                }
            } catch (error) {
                toast.error(error.message || 'Error approving claim');
                console.error('Error:', error);
            }
        };

        const rejectClaim = async (id) => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${baseUrl}api/employee/late/reject/${id}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    toast.success('Claim rejected successfully');
                    await fetchLateClaims();
                } else {
                    throw new Error(data.message || 'Failed to reject claim');
                }
            } catch (error) {
                toast.error(error.message || 'Error rejecting claim');
                console.error('Error:', error);
            }
        };

        const formatDate = (date) => {
            return new Date(date).toLocaleString('en-IN', {
                timeZone: 'Asia/Kolkata',
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        };

        const convertTo12HourFormat = (time) => {
            try {
                // Check if time is in "H:mm:ss a" format
                if (time.toLowerCase().includes('am') || time.toLowerCase().includes('pm')) {
                    return time; // Return as is if already in 12-hour format
                }
                
                // Handle "HH:mm:ss" format
                const [hours, minutes] = time.split(':').map(Number);
                const period = hours >= 12 ? 'PM' : 'AM';
                let twelveHour = hours % 12;
                twelveHour = twelveHour ? twelveHour : 12;
                return `${String(twelveHour).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
            } catch (error) {
                console.error('Time conversion error:', error);
                return time;
            }
        };

        const getStatus = (claim) => {
            if (claim.isApproved) return 'Approved';
            if (claim.isRejected) return 'Rejected';
            return 'Pending';
        };

        const openPhotoModal = (photoUrl) => {
            selectedPhoto.value = photoUrl;
            showPhotoModal.value = true;
        };

        const clearFilters = () => {
            selectedStatus.value = '';
            fromDate.value = '';
            toDate.value = '';
        };

        const nextPage = () => {
            if (currentPage.value < totalPages.value) {
                currentPage.value++;
            }
        };

        const previousPage = () => {
            if (currentPage.value > 1) {
                currentPage.value--;
            }
        };

        onMounted(() => {
            fetchLateClaims();
        });

        return {
            lateClaims,
            filteredClaims,
            selectedStatus,
            fromDate,
            toDate,
            showPhotoModal,
            selectedPhoto,
            elementsPerPage,
            currentPage,
            totalPages,
            showConfirmModal,
            confirmAction,
            closeConfirmModal,
            showApproveConfirm,
            showRejectConfirm,
            confirmActionExecute,
            approveClaim,
            rejectClaim,
            formatDate,
            convertTo12HourFormat,
            getStatus,
            openPhotoModal,
            clearFilters,
            nextPage,
            previousPage
        };
    }
};
</script>

<style scoped>
.modal {
    display: none;
}

.modal.d-block {
    display: block !important;
}

.badge {
    font-size: 0.875em;
    padding: 0.5em 0.75em;
}

.modal-footer {
    padding: 1rem;
    border-top: 1px solid #dee2e6;
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: white;
}

.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}
</style>
