import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vModelRadio as _vModelRadio } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "return-docs-form" }
const _hoisted_5 = { class: "row mb-4" }
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  key: 0,
  class: "return-form-content"
}
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "declaration-text mb-4" }
const _hoisted_12 = { class: "text-decoration-underline px-2" }
const _hoisted_13 = { class: "doc-details mb-4" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-md-4" }
const _hoisted_16 = { class: "col-md-4" }
const _hoisted_17 = { class: "col-md-4" }
const _hoisted_18 = { class: "row mt-3" }
const _hoisted_19 = { class: "col-md-4" }
const _hoisted_20 = { class: "col-md-4" }
const _hoisted_21 = { class: "col-md-4" }
const _hoisted_22 = { class: "mt-3" }
const _hoisted_23 = { class: "table-responsive" }
const _hoisted_24 = { class: "table table-bordered" }
const _hoisted_25 = { class: "doc-photo-section mb-4" }
const _hoisted_26 = {
  key: 0,
  class: "upload-placeholder"
}
const _hoisted_27 = {
  key: 1,
  class: "preview-image"
}
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "signature-section mb-4" }
const _hoisted_30 = { class: "signature-options mb-3" }
const _hoisted_31 = { class: "form-check form-check-inline" }
const _hoisted_32 = { class: "form-check form-check-inline" }
const _hoisted_33 = {
  key: 0,
  class: "signature-pad"
}
const _hoisted_34 = {
  key: 1,
  class: "signature-upload"
}
const _hoisted_35 = {
  key: 0,
  class: "upload-placeholder"
}
const _hoisted_36 = {
  key: 1,
  class: "preview-image"
}
const _hoisted_37 = ["src"]
const _hoisted_38 = { class: "form-group mt-4" }
const _hoisted_39 = { class: "text-end mt-4" }
const _hoisted_40 = ["disabled"]

import { ref, computed, onMounted } from 'vue';
import { format } from 'date-fns';
import { useToast } from 'vue-toast-notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'return-docs',
  setup(__props) {

const toast = useToast();
const baseUrl = process.env.VUE_APP_BASE_URL;

// Document search
const docNumber = ref('');
const docData = ref<any>(null);

// File handling
const docInput = ref<HTMLInputElement | null>(null);
const docPhoto = ref<File | null>(null);
const docPhotoPreview = ref<string>('');

// Signature handling
const signatureType = ref('draw');
const signatureCanvas = ref<HTMLCanvasElement | null>(null);
const signatureInput = ref<HTMLInputElement | null>(null);
const signatureFile = ref<File | null>(null);
const signaturePreview = ref<string>('');
const isDrawing = ref(false);
const context = ref<CanvasRenderingContext2D | null>(null);
const hasSignature = ref(false);

// Add new refs
const remarks = ref('');

// Form validation
const isFormValid = computed(() => {
    const hasValidSignature = signatureType.value === 'draw' ? hasSignature.value : !!signatureFile.value;
    return docData.value && 
           docPhoto.value && 
           hasValidSignature;
});

// Search document
const searchDoc = async () => {
    try {
        const response = await fetch(`${baseUrl}api/document-custody/docket/${docNumber.value}`);
        const data = await response.json();
        
        if (data) {
            docData.value = data;
            toast.success('Document found');
        } else {
            throw new Error('Document not found');
        }
    } catch (error) {
        toast.error('Document not found');
        docData.value = null;
    }
};

// File handling methods
const triggerFileInput = (type: string) => {
    if (type === 'doc') {
        docInput.value?.click();
    } else if (type === 'signature') {
        signatureInput.value?.click();
    }
};

const handleFileChange = (event: Event, type: string) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
        const file = input.files[0];
        if (type === 'doc') {
            docPhoto.value = file;
            docPhotoPreview.value = URL.createObjectURL(file);
        } else if (type === 'signature') {
            signatureFile.value = file;
            signaturePreview.value = URL.createObjectURL(file);
        }
    }
};

const handleDrop = (event: DragEvent, type: string) => {
    const files = event.dataTransfer?.files;
    if (files && files[0]) {
        if (type === 'doc') {
            docPhoto.value = files[0];
            docPhotoPreview.value = URL.createObjectURL(files[0]);
        } else if (type === 'signature') {
            signatureFile.value = files[0];
            signaturePreview.value = URL.createObjectURL(files[0]);
        }
    }
};

const removeFile = (type: string) => {
    if (type === 'doc') {
        docPhoto.value = null;
        docPhotoPreview.value = '';
    } else if (type === 'signature') {
        signatureFile.value = null;
        signaturePreview.value = '';
    }
};

// Signature methods
const initializeCanvas = () => {
    if (signatureCanvas.value) {
        context.value = signatureCanvas.value.getContext('2d');
        if (context.value) {
            context.value.strokeStyle = '#000';
            context.value.lineWidth = 2;
        }
    }
};

const startDrawing = (event: MouseEvent) => {
    isDrawing.value = true;
    if (context.value) {
        const rect = signatureCanvas.value?.getBoundingClientRect();
        if (rect) {
            context.value.beginPath();
            context.value.moveTo(
                event.clientX - rect.left,
                event.clientY - rect.top
            );
        }
    }
    hasSignature.value = true;
};

const draw = (event: MouseEvent) => {
    if (!isDrawing.value) return;
    if (context.value && signatureCanvas.value) {
        const rect = signatureCanvas.value.getBoundingClientRect();
        context.value.lineTo(
            event.clientX - rect.left,
            event.clientY - rect.top
        );
        context.value.stroke();
    }
};

const stopDrawing = () => {
    isDrawing.value = false;
};

const clearSignature = () => {
    if (context.value && signatureCanvas.value) {
        context.value.clearRect(
            0,
            0,
            signatureCanvas.value.width,
            signatureCanvas.value.height
        );
        hasSignature.value = false;
    }
};

const formatDate = (date: Date) => {
    return format(date, 'dd-MM-yyyy');
};

const resetForm = () => {
    docNumber.value = '';
    docData.value = null;
    docPhoto.value = null;
    docPhotoPreview.value = '';
    signatureType.value = 'draw';
    signatureFile.value = null;
    signaturePreview.value = '';
    clearSignature();
    remarks.value = '';
};

const submitForm = async () => {
    if (!isFormValid.value) {
        toast.error('Please complete all required fields');
        return;
    }

    try {
        const formData = new FormData();
        formData.append('returnDate', new Date().toISOString());
        formData.append('remarks', remarks.value);

        // Append the document photo
        if (docPhoto.value) {
            formData.append('returnProofPhoto', docPhoto.value);
        }

        // Append the signature
        if (signatureType.value === 'draw' && signatureCanvas.value) {
            // Convert canvas to blob
            const signatureBlob = await new Promise(resolve => {
                signatureCanvas.value?.toBlob(resolve, 'image/png');
            });
            if (signatureBlob) {
                formData.append('returnSignature', signatureBlob, 'signature.png');
            }
        } else if (signatureFile.value) {
            formData.append('returnSignature', signatureFile.value);
        }

        // Log for debugging
        console.log('Document ID:', docData.value._id);

        // Send the document _id in the URL
        const response = await fetch(`${baseUrl}api/document-custody/return/${docData.value._id}`, {
            method: 'PUT',
            body: formData
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to process document return');
        }

        const result = await response.json();
        console.log('Return response:', result);

        toast.success('Documents returned successfully');
        resetForm();
    } catch (error) {
        console.error('Error:', error);
        toast.error(error.message || 'Error processing document return');
    }
};

onMounted(() => {
    initializeCanvas();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[37] || (_cache[37] = _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("h5", null, "Document Return Form")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label" }, "Docket Number", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((docNumber).value = $event)),
                  placeholder: "Enter docket number"
                }, null, 512), [
                  [_vModelText, docNumber.value]
                ])
              ])
            ]),
            _createElementVNode("div", { class: "col-md-6" }, [
              _createElementVNode("div", { class: "form-group" }, [
                _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, " ", -1)),
                _createElementVNode("button", {
                  class: "btn btn-primary d-block",
                  onClick: searchDoc
                }, " Search Document ")
              ])
            ])
          ]),
          (docData.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _cache[36] || (_cache[36] = _createElementVNode("h4", { class: "mb-4" }, "Docs Return Paper", -1)),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("p", null, [
                        _cache[16] || (_cache[16] = _createTextVNode(" I, hereby declare that I have received my documents on ")),
                        _createElementVNode("span", _hoisted_12, _toDisplayString(formatDate(new Date())), 1),
                        _cache[17] || (_cache[17] = _createTextVNode(". And hereby, I indemnify the company from any cause related to the documents. "))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _cache[26] || (_cache[26] = _createElementVNode("h6", null, "Document Details", -1)),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("p", null, [
                            _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Employee Code:", -1)),
                            _createTextVNode(" " + _toDisplayString(docData.value.employeeCode), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("p", null, [
                            _cache[19] || (_cache[19] = _createElementVNode("strong", null, "Employee Name:", -1)),
                            _createTextVNode(" " + _toDisplayString(docData.value.candidateName), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("p", null, [
                            _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Site Name:", -1)),
                            _createTextVNode(" " + _toDisplayString(docData.value.siteName), 1)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("p", null, [
                            _cache[21] || (_cache[21] = _createElementVNode("strong", null, "Designation:", -1)),
                            _createTextVNode(" " + _toDisplayString(docData.value.designation), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("p", null, [
                            _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Custodian:", -1)),
                            _createTextVNode(" " + _toDisplayString(docData.value.custodianName), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("p", null, [
                            _cache[23] || (_cache[23] = _createElementVNode("strong", null, "Status:", -1)),
                            _createTextVNode(" " + _toDisplayString(docData.value.status), 1)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _cache[25] || (_cache[25] = _createElementVNode("h6", null, "Documents in Custody", -1)),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("table", _hoisted_24, [
                            _cache[24] || (_cache[24] = _createElementVNode("thead", null, [
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", null, "Document Type"),
                                _createElementVNode("th", null, "Date Received"),
                                _createElementVNode("th", null, "Status")
                              ])
                            ], -1)),
                            _createElementVNode("tbody", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(docData.value.documentsInCustody, (doc) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: doc._id
                                }, [
                                  _createElementVNode("td", null, _toDisplayString(doc.documentType), 1),
                                  _createElementVNode("td", null, _toDisplayString(formatDate(new Date(doc.dateReceived))), 1),
                                  _createElementVNode("td", null, _toDisplayString(doc.status), 1)
                                ]))
                              }), 128))
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _cache[29] || (_cache[29] = _createElementVNode("h6", null, "Photo of Document", -1)),
                      _createElementVNode("div", {
                        class: "upload-box",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (triggerFileInput('doc'))),
                        onDragover: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
                        onDrop: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (handleDrop($event, 'doc')), ["prevent"]))
                      }, [
                        _createElementVNode("input", {
                          type: "file",
                          ref_key: "docInput",
                          ref: docInput,
                          class: "file-input",
                          accept: "image/*",
                          onChange: _cache[1] || (_cache[1] = ($event: any) => (handleFileChange($event, 'doc'))),
                          style: {"display":"none"}
                        }, null, 544),
                        (!docPhoto.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[27] || (_cache[27] = [
                              _createElementVNode("i", { class: "fas fa-upload" }, null, -1),
                              _createElementVNode("p", null, "Click or drag to upload document photo", -1)
                            ])))
                          : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                              _createElementVNode("img", {
                                src: docPhotoPreview.value,
                                alt: "Document Preview"
                              }, null, 8, _hoisted_28),
                              _createElementVNode("button", {
                                class: "remove-btn",
                                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (removeFile('doc')), ["stop"]))
                              }, _cache[28] || (_cache[28] = [
                                _createElementVNode("i", { class: "fas fa-times" }, null, -1)
                              ]))
                            ]))
                      ], 32)
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _cache[34] || (_cache[34] = _createElementVNode("h6", null, "Signature", -1)),
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-check-input",
                            type: "radio",
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((signatureType).value = $event)),
                            id: "drawSignature",
                            value: "draw"
                          }, null, 512), [
                            [_vModelRadio, signatureType.value]
                          ]),
                          _cache[30] || (_cache[30] = _createElementVNode("label", {
                            class: "form-check-label",
                            for: "drawSignature"
                          }, "Draw Signature", -1))
                        ]),
                        _createElementVNode("div", _hoisted_32, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-check-input",
                            type: "radio",
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((signatureType).value = $event)),
                            id: "uploadSignature",
                            value: "upload"
                          }, null, 512), [
                            [_vModelRadio, signatureType.value]
                          ]),
                          _cache[31] || (_cache[31] = _createElementVNode("label", {
                            class: "form-check-label",
                            for: "uploadSignature"
                          }, "Upload Signature", -1))
                        ])
                      ]),
                      (signatureType.value === 'draw')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                            _createElementVNode("canvas", {
                              ref_key: "signatureCanvas",
                              ref: signatureCanvas,
                              onMousedown: startDrawing,
                              onMousemove: draw,
                              onMouseup: stopDrawing,
                              onMouseleave: stopDrawing,
                              width: "400",
                              height: "200"
                            }, null, 544),
                            _createElementVNode("button", {
                              class: "btn btn-sm btn-secondary mt-2",
                              onClick: clearSignature
                            }, " Clear Signature ")
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_34, [
                            _createElementVNode("div", {
                              class: "upload-box",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (triggerFileInput('signature'))),
                              onDragover: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["prevent"])),
                              onDrop: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (handleDrop($event, 'signature')), ["prevent"]))
                            }, [
                              _createElementVNode("input", {
                                type: "file",
                                ref_key: "signatureInput",
                                ref: signatureInput,
                                class: "file-input",
                                accept: "image/*",
                                onChange: _cache[8] || (_cache[8] = ($event: any) => (handleFileChange($event, 'signature'))),
                                style: {"display":"none"}
                              }, null, 544),
                              (!signatureFile.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[32] || (_cache[32] = [
                                    _createElementVNode("i", { class: "fas fa-upload" }, null, -1),
                                    _createElementVNode("p", null, "Click or drag to upload signature image", -1)
                                  ])))
                                : (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                    _createElementVNode("img", {
                                      src: signaturePreview.value,
                                      alt: "Signature Preview"
                                    }, null, 8, _hoisted_37),
                                    _createElementVNode("button", {
                                      class: "remove-btn",
                                      onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (removeFile('signature')), ["stop"]))
                                    }, _cache[33] || (_cache[33] = [
                                      _createElementVNode("i", { class: "fas fa-times" }, null, -1)
                                    ]))
                                  ]))
                            ], 32)
                          ]))
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _cache[35] || (_cache[35] = _createElementVNode("label", { class: "form-label" }, "Remarks", -1)),
                      _withDirectives(_createElementVNode("textarea", {
                        class: "form-control",
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((remarks).value = $event)),
                        placeholder: "Enter any remarks about the document return",
                        rows: "3"
                      }, null, 512), [
                        [_vModelText, remarks.value]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("button", {
                        class: "btn btn-secondary me-2",
                        onClick: resetForm
                      }, " Reset "),
                      _createElementVNode("button", {
                        class: "btn btn-primary",
                        onClick: submitForm,
                        disabled: !isFormValid.value
                      }, " Submit Return Form ", 8, _hoisted_40)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})