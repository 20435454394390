<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/operation/get/marketing/lead/list').then((response) => {
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data');
        }
    });
});

const filteredData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    return allData.value.filter(item =>
        (item.assignedTo?.toLowerCase().includes(query) || false) ||
        (item.site?.toLowerCase().includes(query) || false)
    );
});

const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return filteredData.value.slice(start, end);
});

function num_pages() {
    return Math.ceil(filteredData.value.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function downloadExcel() {
    toast.info('Downloading Excel...');
    const worksheet = XLSX.utils.json_to_sheet(allData.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Marketing Leads");
    XLSX.writeFile(workbook, 'marketing_leads.xlsx');
    toast.success('Excel downloaded successfully');
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Marketing Lead"/>
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Assign<br>to</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site<br>name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Location</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name of<br>contact</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Phone<br>number</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Designation</th>  
                                    <th class="sorting_asc text-nowrap" scope="col">Email</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Images</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Department<br>details</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Manpower<br>requirement</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Description</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Ideal date and<br>time to visit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in paginatedData" :key="item._id">
                                    <td>{{ index + 1 + (currentPage - 1) * elementsPerPage }}</td>
                                    <td>{{ item.assignedTo || '-' }}</td>
                                    <td>{{ item.site || '-' }}</td>
                                    <td>{{ item.location || '-' }}</td>
                                    <td>{{ item.nameOfContact || '-' }}</td>
                                    <td>{{ item.phoneNumber || '-' }}</td>
                                    <td>{{ item.designation || '-' }}</td>
                                    <td>{{ item.email || '-' }}</td>
                                    <td>
                                        <img v-if="item.image" :src="item.image" alt=" Image" style="max-width: 100px; max-height: 100px;">
                                        <span v-else>No image</span>
                                    </td>
                                    <td>{{ item.departmentDetails?.map(dept => dept.department).join(', ') || '-' }}</td>
                                    <td>{{ item.manpowerReq || '-' }}</td>
                                    <td>{{ item.description || '-' }}</td>
                                    <td>{{ item.timeToVisit ? new Date(item.timeToVisit).toLocaleString() : '-' }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>