<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi } from "@/util/api";
import jsonToExcel from '@/util/makeExcel';
import jsonToPdf from '@/util/makeExcel';

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    getData();
});

function getData() {
    fetchGetApi('api/operation/get/report/sopKnowledge').then((response) => {
        if (response?.data?.details) {
            if (response.data.details.length > 0) {
                allData.value = response.data.details;
                console.log(allData.value);
            } else {
                toast.warning('No data available');
            }
        } else {
            toast.error('Invalid response format');
        }
    }).catch((error) => {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch data');
    });
}

function downloadExcel() {
    const exportData = allData.value.map(item => ({
        'Date': formatDate(item.format.createdAt),
        'All OK': item.format.allOk ? 'Yes' : 'No',
        'Log Number': item.format._id,
        'Site Name': item.siteName,
        'Problem': item.format.problem,
        'Sub Category': item.format.subCategory || '',
        'Solution': item.format.solution || '',
        'Team/Trainer': item.format.employees[0]?.employeeName || '',
        'Action': item.format.employees[0]?.action || '',
        'Slip': item.format.employees[0]?.uploadPhoto ? 'Available' : 'Not Available',
        'NFD': item.format.nfd || '',
        'Log Closed': item.format.issueExplain || '',
        'Employees Not Tested': item.format.employeesNotTested || ''
    }));
    
    jsonToExcel(exportData, 'sop-knowledge-report.xlsx');
}

function search() {
    // Implement the search functionality based on date range and selected sites
    toast.info('Search functionality will be implemented');
}

function formatDate(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString();
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(item =>
        item.siteName.toLowerCase().includes(query) ||
        item.format.problem.toLowerCase().includes(query) ||
        (item.format.solution && item.format.solution.toLowerCase().includes(query)) ||
        (item.format.category && item.format.category.toLowerCase().includes(query)) ||
        (item.format.subCategory && item.format.subCategory.toLowerCase().includes(query))
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const kypList = ref([]);
const startDate = ref("");
const endDate = ref("");
const sites = ref(["Site 11"]);
const selectedSite = ref([]);
</script>

<template>
    <Breadcrumbs main="Apps" title="SOP Knowledge Report" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-12 col-md-4">
                                <label class="col-form-label">Date range:</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" class="form-control" v-model="startDate">
                                    <span class="mx-1">to</span>
                                    <input type="date" class="form-control" v-model="endDate">
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4">
                                <label for="area-select" class="col-form-label">Search by area:</label>
                                <multiselect id="area-select" v-model="selectedSite" :options="sites" :multiple="true"
                                    :searchable="true" placeholder="Select areas" label="name" track-by="name"
                                    class="form-control"></multiselect>
                            </div>
                            <div class="col-xs-12 col-md-4 d-flex align-items-end">
                                <div class="d-flex gap-2">
                                    <button type="button" class="btn btn-primary" @click="search">
                                        <i class="fas fa-search me-1"></i> Search
                                    </button>
                                    <button type="button" class="btn btn-success" @click="downloadExcel">
                                        <i class="fas fa-file-excel me-1"></i> Download Excel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="display table-striped table-hover table-bordered table" id="basic-1">
                                <thead>
                                    <tr>
                                        <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                        <th class="sorting_asc text-nowrap" scope="col">Date</th>
                                        <th class="sorting_asc text-nowrap" scope="col">All OK</th>
                                        <th class="sorting_asc text-nowrap" scope="col">Log number</th>
                                        <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                        <th class="sorting_asc text-nowrap" scope="col">Problem</th>
                                        <th class="sorting_asc text-nowrap" scope="col">Sub Category</th>
                                        <th class="sorting_asc text-nowrap" scope="col">Employee Details</th>
                                        <th class="sorting_asc text-nowrap" scope="col">Solution</th>
                                        <th class="sorting_asc text-nowrap" scope="col">NFD</th>
                                        <th class="sorting_asc text-nowrap" scope="col">Log closed</th>
                                        <th class="sorting_asc text-nowrap" scope="col">Employees not tested</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(site, index) in allData" :key="site.format._id">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ formatDate(site.format.createdAt) }}</td>
                                        <td>{{ site.format.allOk ? 'Yes' : 'No' }}</td>
                                        <td>{{ site.format._id }}</td>
                                        <td>{{ site.siteName }}</td>
                                        <td>{{ site.format.problem }}</td>
                                        <td>{{ site.format.subCategory || 'N/A' }}</td>
                                        <td>
                                            <div class="employee-table-container">
                                                <table class="w-100 table-sm table-bordered">
                                                    <thead>
                                                        <tr class="bg-light">
                                                            <th class="text-nowrap px-2">Emp name</th>
                                                            <th class="text-nowrap px-2">Rating</th>
                                                            <th class="text-nowrap px-2">Slip</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="employee in site.format.employees" :key="employee._id">
                                                            <td class="px-2">{{ employee.employeeName }}</td>
                                                            <td class="px-2">{{ employee.action || 'N/A' }}</td>
                                                            <td class="px-2">
                                                                <a v-if="employee.uploadPhoto" :href="employee.uploadPhoto" 
                                                                   target="_blank" class="btn btn-sm btn-outline-primary">
                                                                   <i class="fas fa-eye me-1"></i>View
                                                                </a>
                                                                <span v-else>N/A</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                        <td>{{ site.format.solution || 'N/A' }}</td>
                                        <td>{{ site.format.nfd || 'N/A' }}</td>
                                        <td>{{ site.format.issueExplain || 'N/A' }}</td>
                                        <td>{{ site.format.employeesNotTested || 'None' }}</td>
                                    </tr>
                                    <tr v-if="!allData.length">
                                        <td colspan="12" class="text-center py-3">No data available</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div>
                                <select v-model="elementsPerPage" class="form-select form-select-sm">
                                    <option :value="10">10 per page</option>
                                    <option :value="25">25 per page</option>
                                    <option :value="50">50 per page</option>
                                    <option :value="100">100 per page</option>
                                </select>
                            </div>
                            <ul class="pagination py-2 justify-content-end pagination-primary mb-0">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" @click="previousPage">Previous</a>
                                </li>
                                <li class="page-item" v-for="i in num_pages()" :key="i"
                                    :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                    <a class="page-link">{{ i }}</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage >= num_pages() }">
                                    <a class="page-link" @click="nextPage">Next</a>
                                </li>
                            </ul>
                        </div>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.employee-table-container {
    max-width: 100%;
    overflow-x: auto;
}

@media (max-width: 767px) {
    .table-responsive {
        overflow-x: auto;
    }
    
    .table th,
    .table td {
        white-space: nowrap;
        min-width: 100px;
    }
    
    .d-flex.align-items-center {
        flex-direction: column;
        align-items: stretch !important;
    }
    
    .d-flex.align-items-center .mx-1 {
        margin: 0.5rem 0;
        text-align: center;
    }
}
</style>
