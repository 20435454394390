<script lang="js" setup>
import { ref, onMounted, Ref, computed } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'vue-chartjs';
import { jsonToExcel } from "@/util/makeExcel";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';


const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const managerNameFilter = ref("");
const toast = useToast();

const departmentName = ref('');
const departmentDescription = ref('');
const reports = ref([]);
const loading = ref(false);

const selectedMonth = ref('');
const selectedArea = ref('');
const selectedPercentageRange = ref('');
const areas = ref([]);
const months = ref([
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
]);
const percentageRanges = ref([
    { label: 'All', value: '' },
    { label: '0-25%', value: '0-25' },
    { label: '26-50%', value: '26-50' },
    { label: '51-75%', value: '51-75' },
    { label: '76-100%', value: '76-100' }
]);

const showGraph = ref(false);
const chartData = ref(null);
const selectedProjection = ref(null);
const ignoredProjects = ref([]);
const ignoredProjectsModal = ref(null);
const lastMeetingReportsModal = ref(null);
const selectedLastMeetingReports = ref([]);
const INITIAL_REPORTS_SHOWN = 2; // Number of reports to show initially

onMounted(async () => {
    getData();
});

// Helper function to get color based on project type
function getProjectTypeColor(type) {
    switch (type) {
        case 'Cardio':
            return 'rgb(255, 99, 132)'; // Red
        case 'Ascending':
            return 'rgb(75, 192, 75)';  // Green
        case 'Descending':
            return 'rgb(255, 159, 64)'; // Orange
        default: // Stagnant
            return 'rgb(75, 192, 192)'; // Teal
    }
}

// Helper function to get background color based on project type
function getProjectTypeBackgroundColor(type) {
    switch (type) {
        case 'Cardio':
            return 'rgba(255, 99, 132, 0.2)';
        case 'Ascending':
            return 'rgba(75, 192, 75, 0.2)';
        case 'Descending':
            return 'rgba(255, 159, 64, 0.2)';
        default: // Stagnant
            return 'rgba(75, 192, 192, 0.2)';
    }
}

function determineProjectType(followups) {
    if (!followups || followups.length < 2) return 'Stagnant';
    
    const sortedFollowups = followups.sort((a, b) => new Date(a.date) - new Date(b.date));
    const ratings = sortedFollowups.map(f => f.rating);
    const changes = [];
    
    // Calculate rating changes between consecutive followups
    for (let i = 1; i < ratings.length; i++) {
        changes.push(ratings[i] - ratings[i - 1]);
    }
    
    // Determine project type based on rating changes
    const hasIncrease = changes.some(change => change > 0);
    const hasDecrease = changes.some(change => change < 0);
    const isVolatile = changes.some((change, i) => {
        return i > 0 && Math.sign(change) !== Math.sign(changes[i - 1]);
    });
    
    if (isVolatile) {
        return 'Cardio';
    } else if (hasIncrease && !hasDecrease) {
        return 'Ascending';
    } else if (hasDecrease && !hasIncrease) {
        return 'Descending';
    }
    return 'Stagnant';
}

function handleProjectTypeClick(report) {
    selectedProjection.value = report;
    
    // Get all followups and determine project type
    const followups = report.allFollowups || [];
    const sortedFollowups = followups.sort((a, b) => new Date(a.date) - new Date(b.date));
    const projectType = determineProjectType(followups);
    
    // Format dates for better readability
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
            month: 'short', 
            day: 'numeric',
            year: 'numeric'
        });
    };

    // Format data for the graph
    chartData.value = {
        labels: sortedFollowups.map(f => formatDate(f.date)),
        datasets: [{
            label: `Progress Rating (%) - ${projectType}`,
            data: sortedFollowups.map(f => f.rating),
            borderColor: getProjectTypeColor(projectType),
            backgroundColor: getProjectTypeBackgroundColor(projectType),
            tension: 0.1,
            fill: true,
            pointRadius: 6,
            pointHoverRadius: 10,
            pointBackgroundColor: getProjectTypeColor(projectType),
            borderWidth: 2
        }]
    };

    showGraph.value = true;
}

function getData() {
    loading.value = true;
    const baseUrl = process.env.VUE_APP_BASE_URL;
    fetch(`${baseUrl}api/projection/report`)
        .then(response => response.json())
        .then((response) => {
            console.log('API Response:', response);
            if (response && response.success && response.data) {
                // Filter out projects with higher percentage that are ignored
                ignoredProjects.value = response.data
                    .filter(item => item.projectsWithHigherPercentageIgnored)
                    .map(item => ({
                        name: item.siteName,
                        percentage: item.lastPercentage
                    }));

                reports.value = response.data.map(item => {
                    const allFollowups = item.allFollowups || [];
                    return {
                        projection: item.siteName,
                        siteId: item.siteId,
                        areaId: item.areaId,
                        area: item.area,
                        lastPercentage: `${item.lastPercentage}%`,
                        employeeName: item.projections[0]?.employeeName || 'N/A',
                        projectType: determineProjectType(allFollowups), // Use frontend calculation
                        lastMeetingReport: Array.isArray(item.lastMeetingReport) ?
                            item.lastMeetingReport.filter(report => report.trim()) :
                            [],
                        totalFollowUp: `${item.totalFollowUp.currentMonth} in month, ${item.totalFollowUp.sinceInception} since inception`,
                        presentInProjection: item.projections.map(p => `${p.month} ${p.year}`).join(', ') || 'None',
                        manpowerServiceRequired: `${item.manpowerAndService.manpower} workers, ${item.manpowerAndService.services || 'No services'}`,
                        allFollowups: allFollowups
                    };
                });
                getUniqueAreas();
            } else {
                toast.error('Failed to fetch projection data');
            }
        })
        .catch(error => {
            console.error('Error details:', error);
            toast.error('Error fetching projection data');
        })
        .finally(() => {
            loading.value = false;
        });
}

function formatProjectType(type) {
    const types = [];
    if (type.cardio) types.push('Cardio');
    if (type.ascending) types.push('Ascending');
    if (type.descending) types.push('Descending');
    if (type.stagnant) types.push('Stagnant');
    return types.join(', ') || 'None';
}

function getUniqueAreas() {
    const uniqueAreas = new Set(reports.value.map(item => item.area));
    areas.value = Array.from(uniqueAreas);
}

const filteredReports = computed(() => {
    let filtered = reports.value;

    const query = filterQuery.value.toLowerCase();
    const managerQuery = managerNameFilter.value.toLowerCase();

    if (query) {
        filtered = filtered.filter(report =>
            report.projection.toLowerCase().includes(query) ||
            report.area.toLowerCase().includes(query)
        );
    }

    if (managerQuery) {
        filtered = filtered.filter(report =>
            report.employeeName.toLowerCase().includes(managerQuery)
        );
    }

    if (selectedMonth.value) {
        filtered = filtered.filter(report =>
            report.presentInProjection.includes(selectedMonth.value.substring(0, 3))
        );
    }

    if (selectedArea.value) {
        filtered = filtered.filter(report => report.area === selectedArea.value);
    }

    if (selectedPercentageRange.value) {
        const [min, max] = selectedPercentageRange.value.split('-').map(Number);
        filtered = filtered.filter(report => {
            const percentage = parseInt(report.lastPercentage);
            return percentage >= min && percentage <= max;
        });
    }

    return filtered;
});

function num_pages() {
    return Math.ceil(filteredReports.value.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDepartment(id);
        }
    })
}
const isEditt = ref(false);
const dataa = ref({});

const modal = ref();
function showModal(isEdit = false, data = {}) {
    if (isEdit && data) {
        isEditt.value = true;
        dataa.value = data;
        departmentName.value = data.name;
        departmentDescription.value = data.description;
    } else { isEditt.value = false; }
    modal.value = new Modal(document.getElementById('grid-modal'));
    modal.value.show();
}
function hideModal() {
    isEditt.value = false;
    dataa.value = {};
    departmentName.value = '';
    departmentDescription.value = '';

    modal.value.hide();
}
function deleteDepartment(id) {
    fetchGetApi('api/delete/category/' + id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}

function updateDepartment() {

    if (!departmentName.value) {
        toast.error('Department Name is required');
        return;
    }
    if (!departmentDescription.value) {
        toast.error('Department Description is required');
        return;
    }
    const data = {
        name: departmentName.value,
        description: departmentDescription.value
    }
    console.log(dataa.value);
    var url = isEditt.value ? 'api/category/edit/' + dataa.value._id : 'api/category/create';
    console.log(url);
    // return;
    fetchPostApi(url, data).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        hideModal();
        getData();
    });
}

function clearFilters() {
    filterQuery.value = '';
    managerNameFilter.value = '';
    selectedMonth.value = '';
    selectedArea.value = '';
    selectedPercentageRange.value = '';
}

function closeGraph() {
    showGraph.value = false;
    selectedProjection.value = null;
}

function openIgnoredProjectsModal(event) {
    event.preventDefault();

    if (!ignoredProjectsModal.value) {
        ignoredProjectsModal.value = new Modal(document.getElementById('ignored-projects-modal'));
    }
    ignoredProjectsModal.value.show();
}

function closeIgnoredProjectsModal() {
    if (ignoredProjectsModal.value) {
        ignoredProjectsModal.value.hide();
    }
}

function showLastMeetingReportsModal(reports) {
    selectedLastMeetingReports.value = reports.filter(report => report.trim()); // Filter out empty reports
    if (!lastMeetingReportsModal.value) {
        lastMeetingReportsModal.value = new Modal(document.getElementById('last-meeting-reports-modal'));
    }
    lastMeetingReportsModal.value.show();
}

function closeLastMeetingReportsModal() {
    if (lastMeetingReportsModal.value) {
        lastMeetingReportsModal.value.hide();
    }
}

function downloadExcel() {
    if (!filteredReports.value.length) {
        toast.error('No data available to download');
        return;
    }

    const dataToExport = filteredReports.value.map((report, index) => ({
        'SL': index + 1,
        'Projection': report.projection || 'N/A',
        'Area': report.area || 'N/A',
        'Manager': report.employeeName || 'N/A',
        'Last Percentage': report.lastPercentage || 'N/A',
        'Project Type': report.projectType || 'N/A',
        'Last Meeting Reports': Array.isArray(report.lastMeetingReport) ? report.lastMeetingReport.join('; ') : 'N/A',
        'Total Follow Up': report.totalFollowUp || 'N/A',
        'Present in Projection': report.presentInProjection || 'N/A',
        'Manpower and Service Required': report.manpowerServiceRequired || 'N/A'
    }));

    const timestamp = new Date().toISOString().split('T')[0];
    jsonToExcel(dataToExport, `projection-status-report-${timestamp}.xlsx`);
    toast.success('Excel file downloaded successfully');
}

</script>
<template>
    <Breadcrumbs main="Apps" title="Projection and status" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <div v-if="loading" class="text-center p-3">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <form v-else class="no-footer">
                        <div class="mb-3 row">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search" class="col-form-label">Project Name:</label>
                                <input id="table-complete-search" type="text" class="form-control" v-model="filterQuery"
                                    placeholder="Search projects...">
                            </div>

                            <div class="col-xs-3 col-sm-auto">
                                <label for="manager-search" class="col-form-label">Manager Name:</label>
                                <input id="manager-search" type="text" class="form-control" v-model="managerNameFilter"
                                    placeholder="Search by manager...">
                            </div>

                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-form-label">Month:</label>
                                <select class="form-select" v-model="selectedMonth">
                                    <option value="">All Months</option>
                                    <option v-for="month in months" :key="month" :value="month">
                                        {{ month }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-form-label">Area:</label>
                                <select class="form-select" v-model="selectedArea">
                                    <option value="">All Areas</option>
                                    <option v-for="area in areas" :key="area" :value="area">
                                        {{ area }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-form-label">Percentage Range:</label>
                                <select class="form-select" v-model="selectedPercentageRange">
                                    <option v-for="range in percentageRanges" :key="range.value" :value="range.value">
                                        {{ range.label }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary me-2" @click="getData">
                                    <i class="fas fa-search"></i> Search
                                </button>
                                <button class="btn btn-secondary me-2" @click="clearFilters">
                                    <i class="fas fa-times"></i> Clear Filters
                                </button>
                                <button type="button" class="btn btn-info" @click.prevent="openIgnoredProjectsModal">
                                    <i class="fas fa-eye"></i> View Ignored Projects
                                </button>
                                <button type="button" class="btn btn-primary ms-2" @click="downloadExcel"
                                    :disabled="!filteredReports.length">
                                    <i class="fas fa-download me-1"></i>
                                    Download Excel
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Projection</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Area</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Manager</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last percentage</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Project type</th>
                                    <th class="sorting_asc text-nowrap" scope="col" style="min-width: 500px;">Last
                                        meeting report</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Total follow up in month<br>
                                        Total follow up since inception
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Present in projection<br>
                                        for what all months
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Manpower and service<br>
                                        required and total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(report, index) in filteredReports" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <router-link :to="`/reports/project/${report.areaId}/${report.siteId}`"
                                            class="text-primary text-decoration-none">
                                            {{ report.projection }}
                                        </router-link>
                                    </td>
                                    <td>{{ report.area }}</td>
                                    <td>{{ report.employeeName }}</td>
                                    <td>{{ report.lastPercentage }}</td>
                                    <td>
                                        <a href="#" @click.prevent="handleProjectTypeClick(report)"
                                            class="text-primary text-decoration-none">
                                            {{ report.projectType }}
                                        </a>
                                    </td>
                                    <td>
                                        <template
                                            v-if="report.lastMeetingReport && report.lastMeetingReport.length > 0">
                                            {{ report.lastMeetingReport.slice(0, INITIAL_REPORTS_SHOWN).join(', ') }}
                                            <template v-if="report.lastMeetingReport.length > INITIAL_REPORTS_SHOWN">
                                                <br>
                                                <a href="#"
                                                    @click.prevent="showLastMeetingReportsModal(report.lastMeetingReport)"
                                                    class="text-primary">
                                                    View More ({{ report.lastMeetingReport.length -
                                                        INITIAL_REPORTS_SHOWN }} more)
                                                </a>
                                            </template>
                                        </template>
                                        <template v-else>
                                            No reports
                                        </template>
                                    </td>
                                    <td>{{ report.totalFollowUp }}</td>
                                    <td>{{ report.presentInProjection }}</td>
                                    <td>{{ report.manpowerServiceRequired }}</td>
                                </tr>
                                <tr v-if="filteredReports.length === 0">
                                    <td colspan="9" class="text-center">No matching records found</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>




        </div>
    </div>

    <div class="modal fade" id="grid-modal" tabindex="-1" role="dialog" aria-labelledby="grid-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button class="btn-close" type="button" @click="hideModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body grid-showcase">
                    <div class="container-fluid bd-example-row">
                        <div class="row">
                            <!-- Form for Department Name and Description -->
                            <form class="col-md-12">
                                <div class="mb-3">
                                    <label for="department-name" class="form-label">Department Name</label>
                                    <input type="text" class="form-control" id="department-name"
                                        v-model="departmentName">
                                </div>
                                <div class="mb-3">
                                    <label for="department-description" class="form-label">Department
                                        Description</label>
                                    <textarea class="form-control" id="department-description" rows="3"
                                        v-model="departmentDescription"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" @click="updateDepartment()" type="button">Save</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="graph-modal" tabindex="-1" :class="{ 'show d-block': showGraph }" v-if="showGraph">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Percentage Details for {{ selectedProjection?.projection }}
                    </h5>
                    <button type="button" class="btn-close" @click="closeGraph"></button>
                </div>
                <div class="modal-body">
                    <div v-if="chartData" style="height: 400px;">
                        <Line :data="chartData" :options="{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    max: 100
                                }
                            }
                        }" />
                    </div>
                    <div v-else class="text-center">
                        No percentage data available
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="ignored-projects-modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Projects with Higher Percentage (70-99%)</h5>
                    <button type="button" class="btn-close" @click="closeIgnoredProjectsModal"></button>
                </div>
                <div class="modal-body">
                    <div v-if="ignoredProjects.length > 0">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Project Name</th>
                                        <th>Percentage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(project, index) in ignoredProjects" :key="index">
                                        <td>{{ project.name }}</td>
                                        <td>{{ project.percentage }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        No ignored projects found
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeIgnoredProjectsModal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="last-meeting-reports-modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Last Meeting Reports</h5>
                    <button type="button" class="btn-close" @click="closeLastMeetingReportsModal"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedLastMeetingReports.length > 0">
                        <ul class="list-group">
                            <li v-for="(report, index) in selectedLastMeetingReports.filter(r => r.trim())" :key="index"
                                class="list-group-item">
                                {{ report }}
                            </li>
                        </ul>
                    </div>
                    <div v-else class="text-center">
                        No reports available
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeLastMeetingReportsModal">Close</button>
                </div>
            </div>
        </div>
    </div>

</template>