import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "workforce-details" }
const _hoisted_2 = { class: "row mb-4" }
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "col-md-6" }
const _hoisted_6 = {
  key: 0,
  class: "designation-selection mb-4"
}
const _hoisted_7 = { class: "multiselect-wrapper" }
const _hoisted_8 = { class: "selected-tags" }
const _hoisted_9 = {
  key: 0,
  class: "placeholder"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "options-dropdown"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["checked"]
const _hoisted_14 = {
  key: 0,
  class: "designation-selection mb-4"
}
const _hoisted_15 = { class: "form-label" }
const _hoisted_16 = { class: "multiselect-wrapper" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "selected-tags" }
const _hoisted_19 = {
  key: 0,
  class: "placeholder"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 0,
  class: "options-dropdown"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["checked"]

import { reactive, watch, ref, onMounted, computed } from 'vue';
import SubDesignationDetails from './sub-designation.vue';
import { fetchPostApi, fetchGetApi } from '@/util/api';
import axios from 'axios';



interface Designation {
    _id: string;
    name: string;
    image: string;
}

interface SubDesignation {
    _id: string;
    serviceId: string;
    name: string;
    description: string;
}

interface Service {
    _id: string;
    name: string;
    description?: string;
    // Add other service fields as needed
}


export default /*@__PURE__*/_defineComponent({
  __name: 'work-force-details',
  props: {
    formData: {}
  },
  emits: ['update:formData'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const localFormData = reactive({
    workingPlace: props.formData.workingPlace || '',
    services: [...(props.formData.services || [])],
    subDesignationDetails: { ...props.formData.subDesignationDetails }
});

const designationList = ref<Designation[]>([]);
const selectedDesignation = ref('');
const subDesignationList = ref<Record<string, SubDesignation[]>>({});
const selectedSubDesignations = ref<Record<string, string[]>>({});
const servicesList = ref<Service[]>([]);
const selectedServices = ref<string[]>([]);

const showCustomPosition = ref(false);
const customPositionName = ref('');

const showServiceDropdown = ref(false);
const showDesignationDropdown = ref<Record<string, boolean>>({});

const baseUrl = process.env.VUE_APP_BASE_URL;
const fetchDesignation = async () => {
    try {
        const response = await axios.get(`${baseUrl}api/designation/getAllServices`);
        const designations: Designation[] = response.data;
        designationList.value = designations;

        // Initialize empty records for each service
        const emptySubDesignations: Record<string, string[]> = {};
        const emptyDesignationList: Record<string, SubDesignation[]> = {};
        const emptyDropdownState: Record<string, boolean> = {};
        
        designations.forEach(d => {
            emptySubDesignations[d.name] = [];
            emptyDesignationList[d.name] = [];
            emptyDropdownState[d.name] = false;
        });
        
        selectedSubDesignations.value = emptySubDesignations;
        subDesignationList.value = emptyDesignationList;
        showDesignationDropdown.value = emptyDropdownState;
        
        // Restore previous selection if it exists
        if (props.formData.services && props.formData.services.length > 0) {
            selectedServices.value = props.formData.services.map(s => s.serviceName);
            // Fetch designations for each service
            for (const service of selectedServices.value) {
                const serviceObj = designations.find(d => d.name === service);
                if (serviceObj) {
                    await fetchSubDesignationByDesignationId(serviceObj._id, service);
                }
            }
        }
    } catch (error) {
        console.error("Error fetching designations:", error);
    }
};

const fetchSubDesignationByDesignationId = async (serviceId: string, serviceName: string) => {
    try {
        const response = await axios.get(`${baseUrl}api/designation/get/by/service/id/${serviceId}`);
        if (response.data) {
            subDesignationList.value[serviceName] = response.data;
        } else {
            console.error("Error in fetching designations");
            subDesignationList.value[serviceName] = [];
        }
    } catch (error) {
        console.error("Error fetching designations:", error);
        subDesignationList.value[serviceName] = [];
    }
};

const addCustomPosition = () => {
    if (customPositionName.value.trim()) {
        localFormData.subDesignationDetails[customPositionName.value] = {
            manpower: { male: 0, female: 0 },
            weeklyOff: { male: false, female: false },
            workingHours: { male: 0, female: 0 },
            esiEpf: { male: false, female: false },
            food: { male: false, female: false },
            accommodation: { male: false, female: false },
            deadline: { male: '', female: '' },
            inHandSalary: { male: 0, female: 0 },
            quotedRate: { male: 0, female: 0 },
            serviceStartDate: { male: '', female: '' }
        };
        customPositionName.value = '';
    }
};

const initializeSubDesignationDetails = (designations: string[]) => {
    const details: Record<string, any> = {};
    designations.forEach(designation => {
        if (!localFormData.subDesignationDetails[designation]) {
            details[designation] = {
                manpower: { male: 0, female: 0 },
                weeklyOff: { male: false, female: false },
                workingHours: { male: 0, female: 0 },
                esiEpf: { male: false, female: false },
                food: { male: false, female: false },
                accommodation: { male: false, female: false },
                deadline: { male: '', female: '' },
                inHandSalary: { male: 0, female: 0 },
                quotedRate: { male: 0, female: 0 },
                serviceStartDate: { male: '', female: '' }
            };
        } else {
            details[designation] = localFormData.subDesignationDetails[designation];
        }
    });
    return details;
};

const fetchServicesBySubDesignation = async (subDesignationId: string) => {
    try {
        const response = await axios.get(`${baseUrl}api/services/by-sub-designation/${subDesignationId}`);
        if (response.data.success) {
            return response.data.data;
        }
        return [];
    } catch (error) {
        console.error("Error fetching services:", error);
        return [];
    }
};

const toggleService = async (name: string) => {
    const index = selectedServices.value.indexOf(name);
    if (index === -1) {
        selectedServices.value.push(name);
        // Fetch designations for this service
        const selectedServiceObj = designationList.value.find(d => d.name === name);
        if (selectedServiceObj) {
            await fetchSubDesignationByDesignationId(selectedServiceObj._id, name);
        }
    } else {
        selectedServices.value.splice(index, 1);
        // Clean up related data
        delete selectedSubDesignations.value[name];
        delete subDesignationList.value[name];
    }
};

const removeService = (name: string) => {
    const index = selectedServices.value.indexOf(name);
    if (index !== -1) {
        // Remove service from selected services
        selectedServices.value.splice(index, 1);
        
        // Clean up related designations
        if (selectedSubDesignations.value[name]) {
            // Remove all subDesignationDetails for this service
            selectedSubDesignations.value[name].forEach(designation => {
                const detailsKey = `${name}-${designation}`;
                delete localFormData.subDesignationDetails[detailsKey];
            });
            delete selectedSubDesignations.value[name];
        }
        
        delete subDesignationList.value[name];
        
        // Update services array
        localFormData.services = localFormData.services.filter(s => s.serviceName !== name);
    }
};

const toggleDesignationDropdown = (service: string) => {
    showDesignationDropdown.value[service] = !showDesignationDropdown.value[service];
};

const toggleDesignation = (service: string, designation: string) => {
    if (!selectedSubDesignations.value[service]) {
        selectedSubDesignations.value[service] = [];
    }

    const index = selectedSubDesignations.value[service].indexOf(designation);
    if (index === -1) {
        selectedSubDesignations.value[service].push(designation);
        // Initialize the subDesignationDetails for this service-designation pair
        const detailsKey = `${service}-${designation}`;
        if (!localFormData.subDesignationDetails[detailsKey]) {
            localFormData.subDesignationDetails[detailsKey] = {
                manpower: { male: 0, female: 0 },
                weeklyOff: { male: false, female: false },
                workingHours: { male: 0, female: 0 },
                esiEpf: { male: false, female: false },
                food: { male: false, female: false },
                accommodation: { male: false, female: false },
                deadline: { male: '', female: '' },
                inHandSalary: { male: 0, female: 0 },
                quotedRate: { male: 0, female: 0 },
                serviceStartDate: { male: '', female: '' }
            };
        }
    } else {
        selectedSubDesignations.value[service].splice(index, 1);
        // Clean up the subDesignationDetails when removing a designation
        const detailsKey = `${service}-${designation}`;
        delete localFormData.subDesignationDetails[detailsKey];
    }
};

const removeDesignation = (service: string, designation: string) => {
    const index = selectedSubDesignations.value[service]?.indexOf(designation);
    if (index !== -1) {
        // Remove designation from selected designations
        selectedSubDesignations.value[service].splice(index, 1);
        
        // Clean up subDesignationDetails
        const detailsKey = `${service}-${designation}`;
        delete localFormData.subDesignationDetails[detailsKey];
        
        // Update services array
        const serviceIndex = localFormData.services.findIndex(s => s.serviceName === service);
        if (serviceIndex !== -1) {
            localFormData.services[serviceIndex].designations = 
                localFormData.services[serviceIndex].designations.filter(d => d.designationName !== designation);
        }
    }
};

watch(selectedSubDesignations, (newVal) => {
    const services: Array<{
        serviceName: string;
        designations: Array<{
            designationName: string;
            details: any;
        }>;
    }> = [];

    Object.entries(newVal).forEach(([service, designations]) => {
        if (designations && designations.length > 0) {
            const serviceDesignations = designations.map(designation => {
                const detailsKey = `${service}-${designation}`;
                const details = localFormData.subDesignationDetails[detailsKey];
                
                if (!details) {
                    // Initialize details if they don't exist
                    localFormData.subDesignationDetails[detailsKey] = {
                        manpower: { male: 0, female: 0 },
                        weeklyOff: { male: false, female: false },
                        workingHours: { male: 0, female: 0 },
                        esiEpf: { male: false, female: false },
                        food: { male: false, female: false },
                        accommodation: { male: false, female: false },
                        deadline: { male: '', female: '' },
                        inHandSalary: { male: 0, female: 0 },
                        quotedRate: { male: 0, female: 0 },
                        serviceStartDate: { male: '', female: '' }
                    };
                }

                return {
                    designationName: designation,
                    details: localFormData.subDesignationDetails[detailsKey]
                };
            });

            services.push({
                serviceName: service,
                designations: serviceDesignations
            });
        }
    });

    localFormData.services = services;
}, { deep: true });

const calculateTotalManpower = computed(() => {
    let total = 0;
    try {
        localFormData.services.forEach(service => {
            service.designations.forEach(designation => {
                const detailsKey = `${service.serviceName}-${designation.designationName}`;
                const details = localFormData.subDesignationDetails[detailsKey];
                if (details?.manpower) {
                    const male = Number(details.manpower.male) || 0;
                    const female = Number(details.manpower.female) || 0;
                    total += male + female;
                }
            });
        });
    } catch (error) {
        console.error('Error calculating total manpower:', error);
    }
    return total;
});

watch(localFormData, (newVal) => {
    const formattedData = {
        workingPlace: newVal.workingPlace,
        services: newVal.services,
        subDesignationDetails: newVal.subDesignationDetails,
        totalManpower: calculateTotalManpower.value
    };
    
    emit('update:formData', formattedData);
}, { deep: true });

onMounted(() => {
    fetchDesignation();

    document.addEventListener('click', (e) => {
        const target = e.target as HTMLElement;
        if (!target.closest('.multiselect-wrapper')) {
            showServiceDropdown.value = false;
            Object.keys(showDesignationDropdown.value).forEach(service => {
                showDesignationDropdown.value[service] = false;
            });
        }
    });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label" }, "Total Manpower", -1)),
        _createElementVNode("input", {
          type: "number",
          class: "form-control",
          value: calculateTotalManpower.value,
          readonly: ""
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "Working Place*", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localFormData.workingPlace) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, localFormData.workingPlace]
        ])
      ])
    ]),
    (designationList.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label" }, "Select Services*", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "selected-options",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (showServiceDropdown.value = !showServiceDropdown.value))
            }, [
              _createElementVNode("div", _hoisted_8, [
                (selectedServices.value.length === 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Choose Services "))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedServices.value, (service) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: service,
                    class: "selected-tag"
                  }, [
                    _createTextVNode(_toDisplayString(service) + " ", 1),
                    _createElementVNode("button", {
                      type: "button",
                      class: "remove-tag",
                      onClick: _withModifiers(($event: any) => (removeService(service)), ["stop"])
                    }, " × ", 8, _hoisted_10)
                  ]))
                }), 128))
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "dropdown-arrow" }, "▼", -1))
            ]),
            (showServiceDropdown.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(designationList.value, (service) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: service._id,
                      class: "option",
                      onClick: ($event: any) => (toggleService(service.name))
                    }, [
                      _createElementVNode("input", {
                        type: "checkbox",
                        checked: selectedServices.value.includes(service.name),
                        onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                      }, null, 8, _hoisted_13),
                      _createElementVNode("span", null, _toDisplayString(service.name), 1)
                    ], 8, _hoisted_12))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (selectedServices.value.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(selectedServices.value, (service) => {
          return (_openBlock(), _createElementBlock("div", {
            key: service,
            class: "service-section mb-4"
          }, [
            _createElementVNode("h6", null, _toDisplayString(service), 1),
            (subDesignationList.value[service]?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("label", _hoisted_15, "Select Designations for " + _toDisplayString(service) + "*", 1),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", {
                      class: "selected-options",
                      onClick: ($event: any) => (toggleDesignationDropdown(service))
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        (!selectedSubDesignations.value[service]?.length)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_19, " Choose Designations "))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedSubDesignations.value[service] || [], (designation) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: designation,
                            class: "selected-tag"
                          }, [
                            _createTextVNode(_toDisplayString(designation) + " ", 1),
                            _createElementVNode("button", {
                              type: "button",
                              class: "remove-tag",
                              onClick: _withModifiers(($event: any) => (removeDesignation(service, designation)), ["stop"])
                            }, " × ", 8, _hoisted_20)
                          ]))
                        }), 128))
                      ]),
                      _cache[8] || (_cache[8] = _createElementVNode("span", { class: "dropdown-arrow" }, "▼", -1))
                    ], 8, _hoisted_17),
                    (showDesignationDropdown.value[service])
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subDesignationList.value[service], (designation) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: designation._id,
                              class: "option",
                              onClick: ($event: any) => (toggleDesignation(service, designation.name))
                            }, [
                              _createElementVNode("input", {
                                type: "checkbox",
                                checked: selectedSubDesignations.value[service]?.includes(designation.name),
                                onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                              }, null, 8, _hoisted_23),
                              _createElementVNode("span", null, _toDisplayString(designation.name), 1)
                            ], 8, _hoisted_22))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (selectedSubDesignations.value[service]?.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(selectedSubDesignations.value[service], (designation) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: designation,
                    class: "designation-details-section mb-4"
                  }, [
                    _createElementVNode("h6", null, _toDisplayString(designation) + " Details", 1),
                    _createVNode(SubDesignationDetails, {
                      designation: designation,
                      details: localFormData.subDesignationDetails[`${service}-${designation}`],
                      "onUpdate:details": ($event: any) => ((localFormData.subDesignationDetails[`${service}-${designation}`]) = $event)
                    }, null, 8, ["designation", "details", "onUpdate:details"])
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}
}

})