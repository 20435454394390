<script setup>
import { ref, onMounted, computed } from 'vue';
import { useToast } from 'vue-toast-notification';
import { fetchGetApi } from "@/util/api";
const toast = useToast();
const designationName = ref('');
const designationDescription = ref('');
const selectedService = ref('');
const services = ref([]);
const designations = ref([]);
const showModal = ref(false);
const isLoading = ref(false);
const editingDesignation = ref(null);
const isEditMode = ref(false);
const searchQuery = ref('');

// Computed property for filtered designations
const filteredDesignations = computed(() => {
    const query = searchQuery.value.toLowerCase().trim();
    if (!query) return designations.value;

    return designations.value.filter(designation => {
        const serviceName = getServiceName(designation).toLowerCase();
        return designation.name.toLowerCase().includes(query) ||
            serviceName.includes(query) ||
            designation.description?.toLowerCase().includes(query);
    });
});

// Function to fetch designations
async function fetchDesignations() {
    isLoading.value = true;
    try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}api/designation/getAll`);
        if (!response.ok) throw new Error('Failed to fetch designations');
        designations.value = await response.json();
    } catch (error) {
        console.error('Error fetching designations:', error);
        toast.error(`Failed to fetch designations: ${error.message}`);
    } finally {
        isLoading.value = false;
    }
}

// Function to add a new designation
async function addDesignation() {
    try {
        if (!selectedService.value) {
            toast.error('Please select a service');
            return;
        }

        const response = await fetch(`${process.env.VUE_APP_BASE_URL}api/designation/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: designationName.value,
                description: designationDescription.value,
                serviceId: selectedService.value,
            }),
        });

        if (!response.ok) throw new Error('Failed to add designation');
        toast.success('Designation added successfully');
        designationName.value = '';
        designationDescription.value = '';
        selectedService.value = '';
        showModal.value = false;
        await fetchDesignations();
    } catch (error) {
        console.error('Error adding designation:', error);
        toast.error(`Failed to add designation: ${error.message}`);
    }
}

// Update the getServices function
async function getServices() {
    try {
        const response = await fetchGetApi('api/designation/getAllServices');
        console.log("response", response);
        services.value = response.data;
    } catch (error) {
        console.error('Error fetching services:', error);
        toast.error(`Failed to fetch services: ${error.message}`);
    }
}

// Add a helper function to get service name
function getServiceName(designation) {
    if (designation.serviceId?.name) {
        return designation.serviceId.name;
    }
    if (designation.service?.name) {
        return designation.service.name;
    }
    return 'N/A';
}

async function editDesignation() {
    try {
        if (!selectedService.value) {
            toast.error('Please select a service');
            return;
        }

        const response = await fetch(`${process.env.VUE_APP_BASE_URL}api/designation/edit/${editingDesignation.value._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: designationName.value,
                description: designationDescription.value,
                serviceId: selectedService.value,
            }),
        });

        if (!response.ok) throw new Error('Failed to update designation');
        toast.success('Designation updated successfully');
        resetForm();
        await fetchDesignations();
    } catch (error) {
        console.error('Error updating designation:', error);
        toast.error(`Failed to update designation: ${error.message}`);
    }
}

async function deleteDesignation(id) {
    if (!confirm('Are you sure you want to delete this designation?')) return;

    try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}api/designation/delete/${id}`, {
            method: 'DELETE',
        });

        if (!response.ok) throw new Error('Failed to delete designation');
        toast.success('Designation deleted successfully');
        await fetchDesignations();
    } catch (error) {
        console.error('Error deleting designation:', error);
        toast.error(`Failed to delete designation: ${error.message}`);
    }
}

function openEditModal(designation) {
    editingDesignation.value = designation;
    designationName.value = designation.name;
    designationDescription.value = designation.description;
    selectedService.value = designation.serviceId?._id || designation.service?._id;
    isEditMode.value = true;
    showModal.value = true;
}

function resetForm() {
    designationName.value = '';
    designationDescription.value = '';
    selectedService.value = '';
    showModal.value = false;
    isEditMode.value = false;
    editingDesignation.value = null;
}

onMounted(() => {
    fetchDesignations();
    getServices();
});
</script>

<template>
    <div class="container mx-auto p-4">
        <div class="d-flex justify-content-between align-items-center mb-4">
            <h2 class="mb-0">Designations List</h2>
            <button class="btn btn-primary" @click="showModal = true">
                <i class="fas fa-plus me-2"></i> Add Designation
            </button>
        </div>

        <!-- Search Input -->
        <div class="mb-4">
            <div class="input-group">
                <span class="input-group-text">
                    <i class="fas fa-search"></i>
                </span>
                <input type="text" class="form-control" v-model="searchQuery"
                    placeholder="Search by name, service, or description...">
            </div>
        </div>

        <!-- Loading State -->
        <div v-if="isLoading" class="text-center py-4">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- Designations List -->
        <div v-else class="card">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead class="table-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Service</th>
                            <th scope="col">Description</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Updated At</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="designations.length === 0">
                            <td colspan="7" class="text-center py-4 text-muted">
                                No designations found
                            </td>
                        </tr>
                        <tr v-for="(designation, index) in filteredDesignations" :key="designation._id">
                            <td>{{ index + 1 }}</td>
                            <td>{{ designation.name }}</td>
                            <td>{{ getServiceName(designation) }}</td>
                            <td>{{ designation.description }}</td>
                            <td>{{ new Date(designation.createdAt).toLocaleDateString() }}</td>
                            <td>{{ new Date(designation.updatedAt).toLocaleDateString() }}</td>
                            <td>
                                <div class="btn-group">
                                    <button class="btn btn-sm btn-primary" @click="openEditModal(designation)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button class="btn btn-sm btn-danger" @click="deleteDesignation(designation._id)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Improved Modal -->
        <div v-if="showModal" class="modal fade show" style="display: block;" tabindex="-1" role="dialog"
            @click.self="showModal = false">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ isEditMode ? 'Edit' : 'Add' }} Designation</h5>
                        <button type="button" class="btn-close" @click="resetForm" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="isEditMode ? editDesignation() : addDesignation()">
                            <div class="mb-3">
                                <label for="serviceSelect" class="form-label">Select Service</label>
                                <select id="serviceSelect" class="form-select" v-model="selectedService" required>
                                    <option value="">Choose a service...</option>
                                    <option v-for="service in services" :key="service._id" :value="service._id">
                                        {{ service.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="designationName" class="form-label">Designation Name</label>
                                <input type="text" id="designationName" class="form-control"
                                    v-model.trim="designationName" required>
                            </div>
                            <div class="mb-3">
                                <label for="designationDescription" class="form-label">Description</label>
                                <textarea id="designationDescription" class="form-control"
                                    v-model.trim="designationDescription" rows="3"></textarea>
                            </div>
                            <div class="d-flex justify-content-end gap-2">
                                <button type="button" class="btn btn-secondary" @click="resetForm">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary">
                                    {{ isEditMode ? 'Update' : 'Save' }} Designation
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    max-width: 500px;
}

.list-group-item:hover {
    background-color: #f8f9fa;
}

.btn-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    padding: 0.5rem;
    cursor: pointer;
    opacity: 0.5;
}

.btn-close:hover {
    opacity: 1;
}

/* Remove the separate modal-backdrop div as we're using background-color on modal */

.table th {
    font-weight: 600;
    background-color: #f8f9fa;
}

.table td {
    vertical-align: middle;
}

.btn-group .btn {
    padding: 0.25rem 0.5rem;
}

.table-responsive {
    overflow-x: auto;
}

.table {
    margin-bottom: 0;
}
</style>