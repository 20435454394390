<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5>Document Return Form</h5>
            </div>
            <div class="card-body">
                <div class="return-docs-form">
                    <div class="row mb-4">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Docket Number</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="docNumber"
                                    placeholder="Enter docket number"
                                >
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">&nbsp;</label>
                                <button 
                                    class="btn btn-primary d-block" 
                                    @click="searchDoc"
                                >
                                    Search Document
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="docData" class="return-form-content">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="mb-4">Docs Return Paper</h4>
                                
                                <div class="declaration-text mb-4">
                                    <p>
                                        I, hereby declare that I have received my documents on 
                                        <span class="text-decoration-underline px-2">{{ formatDate(new Date()) }}</span>. 
                                        And hereby, I indemnify the company from any cause related to the documents.
                                    </p>
                                </div>

                                <!-- Document Details -->
                                <div class="doc-details mb-4">
                                    <h6>Document Details</h6>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p><strong>Employee Code:</strong> {{ docData.employeeCode }}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p><strong>Employee Name:</strong> {{ docData.candidateName }}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p><strong>Site Name:</strong> {{ docData.siteName }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-4">
                                            <p><strong>Designation:</strong> {{ docData.designation }}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p><strong>Custodian:</strong> {{ docData.custodianName }}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p><strong>Status:</strong> {{ docData.status }}</p>
                                        </div>
                                    </div>
                                    
                                    <!-- Documents in Custody -->
                                    <div class="mt-3">
                                        <h6>Documents in Custody</h6>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Document Type</th>
                                                        <th>Date Received</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="doc in docData.documentsInCustody" :key="doc._id">
                                                        <td>{{ doc.documentType }}</td>
                                                        <td>{{ formatDate(new Date(doc.dateReceived)) }}</td>
                                                        <td>{{ doc.status }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <!-- Document Photo Upload -->
                                <div class="doc-photo-section mb-4">
                                    <h6>Photo of Document</h6>
                                    <div class="upload-box" 
                                         @click="triggerFileInput('doc')"
                                         @dragover.prevent
                                         @drop.prevent="handleDrop($event, 'doc')">
                                        <input 
                                            type="file"
                                            ref="docInput"
                                            class="file-input"
                                            accept="image/*"
                                            @change="handleFileChange($event, 'doc')"
                                            style="display: none"
                                        >
                                        <div v-if="!docPhoto" class="upload-placeholder">
                                            <i class="fas fa-upload"></i>
                                            <p>Click or drag to upload document photo</p>
                                        </div>
                                        <div v-else class="preview-image">
                                            <img :src="docPhotoPreview" alt="Document Preview">
                                            <button class="remove-btn" @click.stop="removeFile('doc')">
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- Signature Section -->
                                <div class="signature-section mb-4">
                                    <h6>Signature</h6>
                                    <div class="signature-options mb-3">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" v-model="signatureType" 
                                                   id="drawSignature" value="draw">
                                            <label class="form-check-label" for="drawSignature">Draw Signature</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" v-model="signatureType" 
                                                   id="uploadSignature" value="upload">
                                            <label class="form-check-label" for="uploadSignature">Upload Signature</label>
                                        </div>
                                    </div>

                                    <!-- Draw Signature -->
                                    <div v-if="signatureType === 'draw'" class="signature-pad">
                                        <canvas 
                                            ref="signatureCanvas"
                                            @mousedown="startDrawing"
                                            @mousemove="draw"
                                            @mouseup="stopDrawing"
                                            @mouseleave="stopDrawing"
                                            width="400"
                                            height="200"
                                        ></canvas>
                                        <button class="btn btn-sm btn-secondary mt-2" @click="clearSignature">
                                            Clear Signature
                                        </button>
                                    </div>

                                    <!-- Upload Signature -->
                                    <div v-else class="signature-upload">
                                        <div class="upload-box" 
                                             @click="triggerFileInput('signature')"
                                             @dragover.prevent
                                             @drop.prevent="handleDrop($event, 'signature')">
                                            <input 
                                                type="file"
                                                ref="signatureInput"
                                                class="file-input"
                                                accept="image/*"
                                                @change="handleFileChange($event, 'signature')"
                                                style="display: none"
                                            >
                                            <div v-if="!signatureFile" class="upload-placeholder">
                                                <i class="fas fa-upload"></i>
                                                <p>Click or drag to upload signature image</p>
                                            </div>
                                            <div v-else class="preview-image">
                                                <img :src="signaturePreview" alt="Signature Preview">
                                                <button class="remove-btn" @click.stop="removeFile('signature')">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Add remarks field -->
                                <div class="form-group mt-4">
                                    <label class="form-label">Remarks</label>
                                    <textarea 
                                        class="form-control" 
                                        v-model="remarks"
                                        placeholder="Enter any remarks about the document return"
                                        rows="3"
                                    ></textarea>
                                </div>

                                <div class="text-end mt-4">
                                    <button class="btn btn-secondary me-2" @click="resetForm">
                                        Reset
                                    </button>
                                    <button 
                                        class="btn btn-primary" 
                                        @click="submitForm" 
                                        :disabled="!isFormValid"
                                    >
                                        Submit Return Form
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { format } from 'date-fns';
import { useToast } from 'vue-toast-notification';

const toast = useToast();
const baseUrl = process.env.VUE_APP_BASE_URL;

// Document search
const docNumber = ref('');
const docData = ref<any>(null);

// File handling
const docInput = ref<HTMLInputElement | null>(null);
const docPhoto = ref<File | null>(null);
const docPhotoPreview = ref<string>('');

// Signature handling
const signatureType = ref('draw');
const signatureCanvas = ref<HTMLCanvasElement | null>(null);
const signatureInput = ref<HTMLInputElement | null>(null);
const signatureFile = ref<File | null>(null);
const signaturePreview = ref<string>('');
const isDrawing = ref(false);
const context = ref<CanvasRenderingContext2D | null>(null);
const hasSignature = ref(false);

// Add new refs
const remarks = ref('');

// Form validation
const isFormValid = computed(() => {
    const hasValidSignature = signatureType.value === 'draw' ? hasSignature.value : !!signatureFile.value;
    return docData.value && 
           docPhoto.value && 
           hasValidSignature;
});

// Search document
const searchDoc = async () => {
    try {
        const response = await fetch(`${baseUrl}api/document-custody/docket/${docNumber.value}`);
        const data = await response.json();
        
        if (data) {
            docData.value = data;
            toast.success('Document found');
        } else {
            throw new Error('Document not found');
        }
    } catch (error) {
        toast.error('Document not found');
        docData.value = null;
    }
};

// File handling methods
const triggerFileInput = (type: string) => {
    if (type === 'doc') {
        docInput.value?.click();
    } else if (type === 'signature') {
        signatureInput.value?.click();
    }
};

const handleFileChange = (event: Event, type: string) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
        const file = input.files[0];
        if (type === 'doc') {
            docPhoto.value = file;
            docPhotoPreview.value = URL.createObjectURL(file);
        } else if (type === 'signature') {
            signatureFile.value = file;
            signaturePreview.value = URL.createObjectURL(file);
        }
    }
};

const handleDrop = (event: DragEvent, type: string) => {
    const files = event.dataTransfer?.files;
    if (files && files[0]) {
        if (type === 'doc') {
            docPhoto.value = files[0];
            docPhotoPreview.value = URL.createObjectURL(files[0]);
        } else if (type === 'signature') {
            signatureFile.value = files[0];
            signaturePreview.value = URL.createObjectURL(files[0]);
        }
    }
};

const removeFile = (type: string) => {
    if (type === 'doc') {
        docPhoto.value = null;
        docPhotoPreview.value = '';
    } else if (type === 'signature') {
        signatureFile.value = null;
        signaturePreview.value = '';
    }
};

// Signature methods
const initializeCanvas = () => {
    if (signatureCanvas.value) {
        context.value = signatureCanvas.value.getContext('2d');
        if (context.value) {
            context.value.strokeStyle = '#000';
            context.value.lineWidth = 2;
        }
    }
};

const startDrawing = (event: MouseEvent) => {
    isDrawing.value = true;
    if (context.value) {
        const rect = signatureCanvas.value?.getBoundingClientRect();
        if (rect) {
            context.value.beginPath();
            context.value.moveTo(
                event.clientX - rect.left,
                event.clientY - rect.top
            );
        }
    }
    hasSignature.value = true;
};

const draw = (event: MouseEvent) => {
    if (!isDrawing.value) return;
    if (context.value && signatureCanvas.value) {
        const rect = signatureCanvas.value.getBoundingClientRect();
        context.value.lineTo(
            event.clientX - rect.left,
            event.clientY - rect.top
        );
        context.value.stroke();
    }
};

const stopDrawing = () => {
    isDrawing.value = false;
};

const clearSignature = () => {
    if (context.value && signatureCanvas.value) {
        context.value.clearRect(
            0,
            0,
            signatureCanvas.value.width,
            signatureCanvas.value.height
        );
        hasSignature.value = false;
    }
};

const formatDate = (date: Date) => {
    return format(date, 'dd-MM-yyyy');
};

const resetForm = () => {
    docNumber.value = '';
    docData.value = null;
    docPhoto.value = null;
    docPhotoPreview.value = '';
    signatureType.value = 'draw';
    signatureFile.value = null;
    signaturePreview.value = '';
    clearSignature();
    remarks.value = '';
};

const submitForm = async () => {
    if (!isFormValid.value) {
        toast.error('Please complete all required fields');
        return;
    }

    try {
        const formData = new FormData();
        formData.append('returnDate', new Date().toISOString());
        formData.append('remarks', remarks.value);

        // Append the document photo
        if (docPhoto.value) {
            formData.append('returnProofPhoto', docPhoto.value);
        }

        // Append the signature
        if (signatureType.value === 'draw' && signatureCanvas.value) {
            // Convert canvas to blob
            const signatureBlob = await new Promise(resolve => {
                signatureCanvas.value?.toBlob(resolve, 'image/png');
            });
            if (signatureBlob) {
                formData.append('returnSignature', signatureBlob, 'signature.png');
            }
        } else if (signatureFile.value) {
            formData.append('returnSignature', signatureFile.value);
        }

        // Log for debugging
        console.log('Document ID:', docData.value._id);

        // Send the document _id in the URL
        const response = await fetch(`${baseUrl}api/document-custody/return/${docData.value._id}`, {
            method: 'PUT',
            body: formData
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to process document return');
        }

        const result = await response.json();
        console.log('Return response:', result);

        toast.success('Documents returned successfully');
        resetForm();
    } catch (error) {
        console.error('Error:', error);
        toast.error(error.message || 'Error processing document return');
    }
};

onMounted(() => {
    initializeCanvas();
});
</script>

<style scoped>
.upload-box {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-box:hover {
    border-color: #666;
}

.preview-image {
    position: relative;
    max-width: 100%;
    height: auto;
}

.preview-image img {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
}

.remove-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.signature-pad {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
}

.signature-pad canvas {
    border: 1px solid #eee;
    width: 100%;
    height: 200px;
}

.doc-details {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
}

.table td, .table th {
    vertical-align: middle;
}

textarea.form-control {
    resize: vertical;
}
</style>