<template>
    <Breadcrumbs main="Joining" title="Site Joining" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <site-joining-main />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const SiteJoiningMain = defineAsyncComponent(() => import("@/components/joining/site-joining/site-joining-main.vue"))
</script>