<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <h5>General Settings</h5>
                    </div>
                    <div class="card-body">
                        <!-- Tabs Navigation -->
                        <ul class="nav nav-tabs border-tab nav-primary" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: activeTab === 'logo' }"
                                    @click="activeTab = 'logo'" href="javascript:void(0)">
                                    <i class="fa fa-image"></i>Logo
                                </a>
                            </li>
                            <li class="nav-item">

                                <a class="nav-link" :class="{ active: activeTab === 'site' }"
                                    @click="activeTab = 'site'" href="javascript:void(0)">
                                    <i class="fa fa-cog"></i>Site Settings
                                </a>
                            </li>

                            <li class="nav-item">

                                <a class="nav-link" :class="{ active: activeTab === 'gst' }" @click="activeTab = 'gst'"
                                    href="javascript:void(0)">
                                    <i class="fa fa-cog"></i>GST Settings
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: activeTab === 'percentage' }"
                                    @click="activeTab = 'percentage'" href="javascript:void(0)">
                                    <i class="fa fa-cog"></i>Percentage Settings
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: activeTab === 'contact' }"
                                    @click="activeTab = 'contact'" href="javascript:void(0)">
                                    <i class="fa fa-phone"></i>Contact Info
                                </a>
                            </li>
                        </ul>

                        <!-- Tabs Content -->
                        <div class="tab-content" id="tab-content">
                            <!-- Logo Tab -->
                            <div class="tab-pane fade" :class="{ 'active show': activeTab === 'logo' }" role="tabpanel">
                                <LogoSettings />
                            </div>

                            <!-- Site Settings Tab -->
                            <div class="tab-pane fade" :class="{ 'active show': activeTab === 'site' }" role="tabpanel">
                                <SiteId />
                            </div>


                            <div class="tab-pane fade" :class="{ 'active show': activeTab === 'gst' }" role="tabpanel">
                                <GST />
                            </div>

                            <div class="tab-pane fade" :class="{ 'active show': activeTab === 'percentage' }"
                                role="tabpanel">
                                <PercentageSettings />
                            </div>

                            <!-- Contact Info Tab -->
                            <div class="tab-pane fade" :class="{ 'active show': activeTab === 'contact' }"
                                role="tabpanel">
                                <h6>Contact Info Content</h6>
                                <!-- Add contact info content here -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import LogoSettings from './logo/logo.vue'
import SiteId from './siteId/siteId.vue'
import GST from './gst/gst.vue'
import PercentageSettings from './percentage/percentage.vue'
const activeTab = ref('logo')
</script>

<style scoped>
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 1.5rem;
}

.nav-tabs .nav-link {
    color: #6c757d;
    border: none;
    padding: 0.75rem 1.25rem;
    font-weight: 500;
    transition: all 0.3s ease;
    position: relative;
}

.nav-tabs .nav-link i {
    margin-right: 8px;
}

.nav-tabs .nav-link:hover {
    color: #3e5fce;
    border: none;
}

.nav-tabs .nav-link.active {
    color: #3e5fce;
    background: transparent;
    border: none;
}

.nav-tabs .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #3e5fce;
}

.tab-content {
    padding: 1.5rem 0;
}

.tab-pane {
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Primary color theme */
.nav-primary .nav-link.active {
    color: #3e5fce;
}

.nav-primary .nav-link:hover {
    color: #3e5fce;
}

.nav-primary .nav-link.active::after {
    background-color: #3e5fce;
}
</style>