<script lang="js" setup>
import { ref, onMounted, Ref, watch, computed } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";
import { jsonToExcel } from "@/util/makeExcel";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const departmentName = ref('');
const departmentDescription = ref('');
const isLoading = ref(false);

const projectName = ref('');
const managerName = ref('');

const phoneNo = ref(null);

const filteredData = computed(() => {
    if (!Array.isArray(allData.value)) return [];

    return allData.value.filter(item => {
        const matchesProject = !projectName.value ||
            item.details?.projectName?.toLowerCase().includes(projectName.value.toLowerCase());
        const matchesManager = !managerName.value ||
            item.details?.managerName?.toLowerCase().includes(managerName.value.toLowerCase());

        return matchesProject && matchesManager;
    });
});

onMounted(async () => {
    console.log('Component mounted');
    getAreas().catch(error => {
        console.error('Error in onMounted:', error);
    });
});

const areas = ref([]);
const sites = ref([]);
const selectedArea = ref('');
const selectedSite = ref('');
const showReport = ref(false);

const visitingCard = ref(null);
const otherDocuments = ref([]);
const imageModal = ref(null);
const currentImage = ref(null);
const imageModalTitle = ref('');

const selectedQuotations = ref(null);
const selectedProposals = ref(null);
const quotationModal = ref(null);
const proposalModal = ref(null);

function getAreas() {
    isLoading.value = true;
    return fetchGetApi('api/area/get/all/names')
        .then((response) => {
            const data = response.data || response;
            console.log('Area response:', data);

            if (data.success && data.data) {
                areas.value = data.data;
                console.log('Areas loaded:', areas.value.map(area => ({
                    id: area._id,
                    name: area.areaName,
                    pinCode: area.pinCode
                })));
            } else {
                console.error('Invalid area response:', data);
                toast.error('Failed to fetch areas');
                areas.value = [];
            }
        })
        .catch((error) => {
            console.error('Error fetching areas:', error);
            toast.error('Error loading areas');
            areas.value = [];
        })
        .finally(() => {
            isLoading.value = false;
        });
}

function getSitesByArea() {
    if (!selectedArea.value) {
        sites.value = [];
        return Promise.resolve();
    }

    isLoading.value = true;
    return fetchGetApi(`api/sites/byArea?areaId=${selectedArea.value}`)
        .then((response) => {
            const data = response.data || response;
            console.log('Sites response:', data);

            if (data.success && data.data) {
                sites.value = data.data.sites || [];
                console.log('Sites loaded:', sites.value);
            } else {
                console.error('Invalid sites response:', data);
                toast.error('Failed to fetch sites');
                sites.value = [];
            }
        })
        .catch((error) => {
            console.error('Error fetching sites:', error);
            toast.error('Error loading sites');
            sites.value = [];
        })
        .finally(() => {
            isLoading.value = false;
        });
}

function validateSelections() {
    if (!selectedArea.value) {
        toast.error('Please select an area first');
        return false;
    }
    if (!selectedSite.value) {
        toast.error('Please select a site first');
        return false;
    }
    showReport.value = true;
    return true;
}

function getData() {
    if (!selectedArea.value || !selectedSite.value) {
        return;
    }

    isLoading.value = true;
    fetchGetApi(`api/project-report?areaId=${selectedArea.value}&siteId=${selectedSite.value}`)
        .then((response) => {
            const data = response.data || response;
            console.log('Raw API response:', data);

            if (data?.success) {
                phoneNo.value = data.phoneNo;
                allData.value = data.data.map(item => ({
                    ...item,
                    details: {
                        percentage: item.percentage,
                        type: item.typeCallVisit,
                        dateTime: item.dateAndTime,
                        managerName: item.managerName,
                        personMet: {
                            name: item.personMetAndDesignation?.split(' - ')[0] || 'N/A',
                            designation: item.personMetAndDesignation?.split(' - ')[1] || 'N/A'
                        },
                        servicesAndManpower: parseServicesAndManpower(item.servicesRequiredAndManpower),
                        modeOfService: item.modeOfService,
                        competitorName: item.competitorName,
                        socReportSubmitted: item.socReportSubmitted?.length > 0,
                        competitorDetails: item.socReportSubmitted || [],
                        meetingReport: item.meetingReport?.split(', ').filter(Boolean) || [],
                        criteria: item.criteria || [],
                        quotation: { submitted: item.quotation === 'Yes' },
                        proposal: { submitted: item.proposal === 'Yes' },
                        NFD: item.NFD,
                        kytStatus: { isConverted: item.KYTChangeTransfer === 1 }
                    }
                }));

                reportSummary.value = {
                    dateOfInception: data.dateOfInception,
                    totalVisits: data.totalVisits,
                    totalCalls: data.totalCalls
                };

                visitingCard.value = data.visiting_card;
                otherDocuments.value = data.other_documents || [];
                showReport.value = true;
            } else {
                console.error('Invalid response:', data);
                toast.error('Failed to fetch project report data');
                allData.value = [];
                visitingCard.value = null;
                otherDocuments.value = [];
                showReport.value = false;
            }
        })
        .catch((error) => {
            console.error('Error fetching project report:', error);
            toast.error('Error loading project report');
            allData.value = [];
            visitingCard.value = null;
            otherDocuments.value = [];
            showReport.value = false;
        })
        .finally(() => {
            isLoading.value = false;
        });
}

function parseServicesAndManpower(servicesStr) {
    if (!servicesStr || servicesStr === 'N/A') return [];

    return servicesStr.split(', ').map(service => {
        const [serviceName, count] = service.split(': ');
        return {
            service: serviceName,
            count: parseInt(count) || 0
        };
    });
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(item => {
        // Check if item exists and has required properties
        if (!item || typeof item !== 'object') return false;

        // Get all searchable fields from the item
        const searchableFields = [
            item.details?.managerName,
            item.details?.personMet?.name,
            item.details?.type,
            item.details?.competitorName
        ].filter(Boolean); // Remove null/undefined values

        // Return true if any field includes the search query
        return searchableFields.some(field =>
            field.toLowerCase().includes(query)
        );
    });

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDepartment(id);
        }
    })
}
const isEditt = ref(false);
const dataa = ref({});

const modal = ref();
function showModal(isEdit = false, data = {}) {
    if (isEdit && data) {
        isEditt.value = true;
        dataa.value = data;
        departmentName.value = data.name;
        departmentDescription.value = data.description;
    } else { isEditt.value = false; }
    modal.value = new Modal(document.getElementById('grid-modal'));
    modal.value.show();
}
function hideModal() {
    isEditt.value = false;
    dataa.value = {};
    departmentName.value = '';
    departmentDescription.value = '';

    modal.value.hide();
}
function deleteDepartment(id) {
    fetchGetApi('api/delete/category/' + id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function formatDate(dateString) {
    if (!dateString || dateString === 'N/A') return 'N/A';

    // If date is already in DD/MM/YYYY format, return as is
    if (dateString.includes('/')) {
        return dateString;
    }

    // If date includes " – ", it's already formatted
    if (dateString.includes(' – ')) {
        return dateString;
    }

    try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'N/A';

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year}, ${hours}:${minutes}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        return 'N/A';
    }
}

function updateDepartment() {

    if (!departmentName.value) {
        toast.error('Department Name is required');
        return;
    }
    if (!departmentDescription.value) {
        toast.error('Department Description is required');
        return;
    }
    const data = {
        name: departmentName.value,
        description: departmentDescription.value
    }
    console.log(dataa.value);
    var url = isEditt.value ? 'api/category/edit/' + dataa.value._id : 'api/category/create';
    console.log(url);
    // return;
    fetchPostApi(url, data).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        hideModal();
        getData();
    });
}

const projects = ref([{ id: 1, name: 'Project A' },
{ id: 2, name: 'Project B' },
{ id: 3, name: 'Project C' },
]);
const selectedProject = ref('');

const mockData = ref([
    {
        slNo: 1,
        percentage: '70%',
        typeCall: 'Call',
        dateTime: '22.04.2024 05.00pm',
        managerName: 'Kanai Basfor',
        personMet: 'Mr.XYZ President',
        servicesRequired: 'Security Housekeeping',
        modeOfService: 'SG Sup HK Sup',
        competitorName: 'Omega Security service',
        socReport: true,
        meetingReport: ['XYZ', 'ABC'],
        nfd: '25.04.2024 12.00pm',
        kytStatus: true
    }
]);

watch(selectedArea, (newValue) => {
    selectedSite.value = '';
    sites.value = [];
    if (newValue) {
        getSitesByArea().catch(error => {
            console.error('Error in area watch:', error);
        });
    }
});

watch(areas, (newValue) => {
    console.log('Areas updated:', newValue);
}, { deep: true });

const selectedSocDetails = ref(null);
const socModal = ref(null);

function showSocModal(details) {
    selectedSocDetails.value = details;
    if (!socModal.value) {
        socModal.value = new Modal(document.getElementById('soc-modal'));
    }
    socModal.value.show();
}

const selectedCriteriaDetails = ref(null);
const criteriaModal = ref(null);

function showCriteriaModal(criteria) {
    selectedCriteriaDetails.value = criteria;
    if (!criteriaModal.value) {
        criteriaModal.value = new Modal(document.getElementById('criteria-modal'));
    }
    criteriaModal.value.show();
}

const reportSummary = ref({
    dateOfInception: null,
    totalVisits: 0,
    totalCalls: 0
});

const groupedSocDetails = computed(() => {
    if (!selectedSocDetails.value) return [];

    // Remove duplicate entries based on service and modeOfService combination
    return selectedSocDetails.value.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.service === item.service &&
            t.modeOfService === item.modeOfService &&
            t.competitorName === item.competitorName
        ))
    );
});

function showImage(image, title) {
    currentImage.value = image;
    imageModalTitle.value = title;
    if (!imageModal.value) {
        imageModal.value = new Modal(document.getElementById('image-modal'));
    }
    imageModal.value.show();
}

function showOtherDocuments() {
    currentImage.value = otherDocuments.value;
    imageModalTitle.value = 'Other Documents';
    if (!imageModal.value) {
        imageModal.value = new Modal(document.getElementById('image-modal'));
    }
    imageModal.value.show();
}

function downloadExcel() {
    if (!filteredData.value.length) {
        toast.error('No data available to download');
        return;
    }

    const dataToExport = filteredData.value.map((item, index) => ({
        'SL': index + 1,
        'Percentage': item.percentage !== 'N/A' ? `${item.percentage}%` : 'N/A',
        'Type Call/Visit': item.typeCallVisit || 'N/A',
        'Date and Time': formatDate(item.dateAndTime),
        'Manager Name': item.managerName || 'N/A',
        'Person Met': item.personMetAndDesignation || 'N/A',
        'Services Required': item.servicesRequiredAndManpower || 'N/A',
        'Mode of Service': item.modeOfService || 'N/A',
        'Competitor Name': item.competitorName || 'N/A',
        'SOC Report': item.socReportSubmitted?.length > 0 ? 'Yes' : 'No',
        'Meeting Report': item.meetingReport !== 'N/A' ? item.meetingReport.split(', ').filter(Boolean).join(', ') : 'N/A',
        'Quotation': item.quotation || 'N/A',
        'Proposal': item.proposal || 'N/A',
        'NFD': formatDate(item.NFD),
        'KYT Change/Transfer': item.KYTChangeTransfer === 'Yes' ? 'Yes' : 'No'
    }));

    const timestamp = new Date().toISOString().split('T')[0];
    const siteName = sites.value.find(s => s._id === selectedSite.value)?.siteName || 'unknown';
    jsonToExcel(dataToExport, `project-report-${siteName}-${timestamp}.xlsx`);
    toast.success('Excel file downloaded successfully');
}

function showQuotationModal(files) {
    selectedQuotations.value = files;
    if (!quotationModal.value) {
        quotationModal.value = new Modal(document.getElementById('quotation-modal'));
    }
    quotationModal.value.show();
}

function showProposalModal(files) {
    selectedProposals.value = files;
    if (!proposalModal.value) {
        proposalModal.value = new Modal(document.getElementById('proposal-modal'));
    }
    proposalModal.value.show();
}

function formatDateTime(dateStr) {
    if (!dateStr) return 'N/A';
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return 'N/A';
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}

</script>
<template>
    <Breadcrumbs main="Apps" title="Project report" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">

            <!-- Project name_______ -->
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="area-select" class="col-xs-12 col-sm-auto col-form-label">
                                    Area:
                                </label>
                                <select id="area-select" class="form-control" v-model="selectedArea"
                                    @change="getSitesByArea(); showReport = false" :disabled="isLoading">
                                    <option value="">{{ isLoading ? 'Loading areas...' : 'Select Area' }}</option>
                                    <option v-for="area in areas" :key="area._id" :value="area._id">
                                        {{ area.areaName }}{{ area.pinCode ? ` (${area.pinCode})` : '' }}
                                    </option>
                                </select>
                                <small class="text-muted" v-if="areas.length > 0">
                                    {{ areas.length }} areas loaded
                                </small>
                                <small class="text-danger" v-else>
                                    No areas loaded
                                </small>
                            </div>

                            <div class="col-xs-3 col-sm-auto">
                                <label for="site-select" class="col-xs-12 col-sm-auto col-form-label">
                                    Site:
                                </label>
                                <select id="site-select" class="form-control" v-model="selectedSite"
                                    @change="showReport = false" :disabled="!selectedArea || isLoading">
                                    <option value="">{{
                                        !selectedArea ? 'Select an area first' :
                                            isLoading ? 'Loading sites...' :
                                                'Select Site'
                                    }}</option>
                                    <option v-for="site in sites" :key="site._id" :value="site._id">
                                        {{ site.siteName }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-xs-3 col-sm-auto d-flex align-items-end">
                                <button class="btn btn-primary" @click.prevent="getData()"
                                    :disabled="isLoading || !selectedArea || !selectedSite">
                                    {{ isLoading ? 'Loading...' : 'Search' }}
                                </button>
                            </div>
                            <div class="col-xs-12 col-sm-auto d-flex align-items-end">
                                <button type="button" class="btn btn-primary" @click="downloadExcel"
                                    :disabled="!filteredData.length">
                                    <i class="fas fa-download me-1"></i>
                                    Download Excel
                                </button>
                            </div>
                        </div>

                        <div class="row card card-body">
                            <div class="table-responsive product-table custom-scrollbar">
                                <!-- Document Buttons - Moved outside form -->
                                <div v-if="showReport" class="mb-4">
                                    <button v-if="visitingCard"
                                        @click.prevent="showImage(visitingCard, 'Visiting Card')"
                                        class="btn btn-primary me-2">
                                        <i class="fa fa-id-card me-2"></i>View Visiting Card
                                    </button>
                                    <button v-if="otherDocuments.length" @click.prevent="showOtherDocuments()"
                                        class="btn btn-info">
                                        <i class="fa fa-file me-2"></i>View Other Documents
                                    </button>
                                </div>

                                <form class="no-footer" @submit.prevent>
                                    <div v-if="showReport" class="mb-3 row">
                                        <!-- Site Name Header -->
                                        <div class="d-flex align-items-center mb-4">
                                            <h4 class="fw-bold mb-0">Site Name: {{sites.find(s => s._id ===
                                                selectedSite)?.siteName || 'N/A'}}</h4>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col">
                                                            <strong>Area:</strong> {{selectedArea ? areas.find(a =>
                                                                a._id === selectedArea)?.areaName : 'N/A'}}
                                                        </div>
                                                        <div class="col">
                                                            <strong>Date of Inception:</strong>
                                                            {{ formatDate(reportSummary.dateOfInception) }}
                                                        </div>
                                                        <div class="col">
                                                            <strong>Total Visits:</strong>
                                                            {{ reportSummary.totalVisits }}
                                                        </div>
                                                        <div class="col">
                                                            <strong>Total Calls:</strong>
                                                            {{ reportSummary.totalCalls }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="!showReport" class="text-center py-5">
                                        <h4 class="text-muted">Please select an Area and Site to view the report</h4>
                                    </div>

                                    <div v-if="showReport" class="table-responsive product-table custom-scrollbar">
                                        <table class="display table-striped table-hover table-bordered table"
                                            id="basic-1">
                                            <thead>
                                                <tr>
                                                    <th class="sorting_asc" scope="col" style="width: 50px;">Sl no</th>
                                                    <th class="sorting_asc" scope="col">Percentage</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Type <br> call
                                                        <br>/visit
                                                    </th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Date and time</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">NFD</th>

                                                    <th class="sorting_asc text-nowrap" scope="col">Manager <br> name
                                                    </th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Person met <br> and
                                                        designation</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Meeting report </th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Services <br>
                                                        required
                                                        <br> and manpower
                                                    </th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Mode of <br>
                                                        service</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Competitor <br> name
                                                    </th>
                                                    <th class="sorting_asc text-nowrap" scope="col">SOC report <br>
                                                        submitted</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Criteria</th>
                                                    <th class="sorting_asc" scope="col">Quotation</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Proposal</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">KYT <br>
                                                        change/transfer
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in filteredData" :key="index">
                                                    <td>{{ item.slNo }}</td>
                                                    <td>{{ item.percentage !== 'N/A' ? `${item.percentage}%` : 'N/A' }}
                                                    </td>
                                                    <td>{{ item.typeCallVisit }}</td>
                                                    <td>{{ formatDate(item.dateAndTime) }}</td>
                                                    <td>{{ formatDate(item.NFD) }}</td>

                                                    <td>{{ item.managerName }}</td>
                                                    <td>
                                                        {{ item.personMetAndDesignation }}
                                                        <br v-if="phoneNo">
                                                        <span v-if="phoneNo" class="text-muted">Contact: {{ phoneNo
                                                            }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.meetingReport !== 'N/A'" class="text-wrap"
                                                            style="min-width: 600px; max-width: 700px; white-space: normal; display: block;">
                                                            {{ item.meetingReport.split(', ').filter(Boolean).join(', ')
                                                            }}
                                                        </span>
                                                        <span v-else>N/A</span>
                                                    </td>
                                                    <td>{{ item.servicesRequiredAndManpower }}</td>
                                                    <td>{{ item.modeOfService }}</td>
                                                    <td>{{ item.competitorName }}</td>
                                                    <td>
                                                        <a v-if="item.socReportSubmitted?.length > 0" href="#"
                                                            @click.prevent="showSocModal(item.socReportSubmitted)"
                                                            class="text-primary">
                                                            Yes (click to view)
                                                        </a>
                                                        <span v-else>No</span>
                                                    </td>
                                                    <td>
                                                        <a v-if="item.criteria?.length > 0" href="#"
                                                            @click.prevent="showCriteriaModal(item.criteria)"
                                                            class="text-primary">
                                                            Click to view
                                                        </a>
                                                        <span v-else>N/A</span>
                                                    </td>
                                                    <td>
                                                        <a v-if="item.quotation === 'Yes'" href="#"
                                                            @click.prevent="showQuotationModal(item.quotationFile)"
                                                            class="text-primary">
                                                            Yes (click to view)
                                                        </a>
                                                        <span v-else>No</span>
                                                    </td>
                                                    <td>
                                                        <a v-if="item.proposal === 'Yes'" href="#"
                                                            @click.prevent="showProposalModal(item.proposalFile)"
                                                            class="text-primary">
                                                            Yes (click to view)
                                                        </a>
                                                        <span v-else>No</span>
                                                    </td>
                                                    <td>
                                                        <router-link v-if="item.KYTChangeTransfer === 'Yes'"
                                                            :to="`/reports/marketing-kyt-change-transfer`"
                                                            class="text-primary">
                                                            Yes (click to view)
                                                        </router-link>
                                                        <span v-else>No</span>
                                                    </td>
                                                </tr>
                                                <tr v-if="filteredData.length === 0">
                                                    <td colspan="16" class="text-center">No data available</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>



        </div>
    </div>

    <div class="modal fade" id="grid-modal" tabindex="-1" role="dialog" aria-labelledby="grid-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button class="btn-close" type="button" @click="hideModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body grid-showcase">
                    <div class="container-fluid bd-example-row">
                        <div class="row">
                            <!-- Form for Department Name and Description -->
                            <form class="col-md-12">
                                <div class="mb-3">
                                    <label for="department-name" class="form-label">Department Name</label>
                                    <input type="text" class="form-control" id="department-name"
                                        v-model="departmentName">
                                </div>
                                <div class="mb-3">
                                    <label for="department-description" class="form-label">Department
                                        Description</label>
                                    <textarea class="form-control" id="department-description" rows="3"
                                        v-model="departmentDescription"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" @click="updateDepartment()" type="button">Save</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="soc-modal" tabindex="-1" aria-labelledby="socModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="socModalLabel">SOC Report Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedSocDetails">
                        <!-- Group by service and mode -->
                        <div v-for="(serviceGroup, index) in groupedSocDetails" :key="index" class="mb-4">
                            <div class="service-section border-bottom pb-3">
                                <h5 class="text-primary">
                                    Service: {{ serviceGroup.service }}
                                    <p v-if="serviceGroup.serviceName">
                                        <span class="badge bg-primary ms-2">{{ serviceGroup.serviceName }}</span>
                                    </p>
                                    <span class="badge text-dark bg-secondary ms-2">{{ serviceGroup.modeOfService }}</span>
                                </h5>

                                <!-- Service Basic Information -->
                                <div class="mb-4">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th style="width: 200px">Competitor Name</th>
                                                <td>{{ serviceGroup.competitorName || 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <th>Agreement Duration</th>
                                                <td>
                                                    <span
                                                        v-if="serviceGroup.agreementStarted && serviceGroup.agreementEnded">
                                                        {{ serviceGroup.agreementStarted }} to {{
                                                            serviceGroup.agreementEnded }}
                                                    </span>
                                                    <span v-else>N/A</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Total Manpower</th>
                                                <td>{{ serviceGroup.totalManpower || 0 }}</td>
                                            </tr>
                                            <tr>
                                                <th>Special Mentions</th>
                                                <td>{{ serviceGroup.specialMentions || 'N/A' }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <!-- SOC Details Table -->
                                <div v-if="serviceGroup.socDetails && serviceGroup.socDetails.length > 0">
                                    <h6 class="mt-3">Manpower Details</h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Designation</th>
                                                    <th>Count</th>
                                                    <th>Duration</th>
                                                    <th>Salary (₹)</th>
                                                    <th>Benefits</th>
                                                    <th>Min. Wages</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(soc, socIndex) in serviceGroup.socDetails" :key="socIndex">
                                                    <td>{{ soc.designation }}</td>
                                                    <td>{{ soc.manpowerCount }}</td>
                                                    <td>{{ soc.workDuration }} hours</td>
                                                    <td>₹{{ soc.salary }}</td>
                                                    <td>
                                                        <ul class="list-unstyled mb-0">
                                                            <li>
                                                                <i class="fa"
                                                                    :class="soc.isFoodProvided ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                                Food
                                                            </li>
                                                            <li>
                                                                <i class="fa"
                                                                    :class="soc.isStayProvided ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                                Stay
                                                            </li>
                                                            <li>
                                                                <i class="fa"
                                                                    :class="soc.isEsicPfProvided ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                                ESIC/PF
                                                            </li>
                                                            <li>
                                                                <i class="fa"
                                                                    :class="soc.isWeeklyOff ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                                Weekly Off
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <i class="fa"
                                                            :class="soc.isMinWages ? 'fa-check text-success' : 'fa-times text-danger'"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="criteria-modal" tabindex="-1" aria-labelledby="criteriaModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="criteriaModalLabel">Criteria Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedCriteriaDetails">
                        <div v-for="(item, index) in selectedCriteriaDetails" :key="index" class="mb-4">
                            <h6 class="border-bottom pb-2">{{ item.serviceName }}</h6>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Total Manpower</th>
                                        <td>{{ item.manPower }}</td>
                                    </tr>
                                    <tr>
                                        <th>Gender Distribution</th>
                                        <td>Male: {{ item.gender.male }}, Female: {{ item.gender.female }}</td>
                                    </tr>
                                    <tr>
                                        <th>Working Hours</th>
                                        <td>{{ item.workingHours }} hours</td>
                                    </tr>
                                    <tr>
                                        <th>Benefits</th>
                                        <td>
                                            <ul class="list-unstyled mb-0">
                                                <li>ESIC/PF: {{ item.epf === 'Yes' ? 'Yes' : 'No' }}</li>
                                                <li>Food: {{ item.food === 'Yes' ? 'Yes' : 'No' }}</li>
                                                <li>Stay: {{ item.stay === 'Yes' ? 'Yes' : 'No' }}</li>
                                                <li>Weekly Off: {{ item.weeklyOff === 'Yes' ? 'Yes' : 'No' }}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Image Modal -->
    <div class="modal fade" id="image-modal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="imageModalLabel">{{ imageModalTitle }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- Single Image View -->
                    <div v-if="typeof currentImage === 'string'" class="text-center">
                        <img :src="currentImage" alt="Document" class="img-fluid">
                    </div>
                    <!-- Multiple Images View -->
                    <div v-else class="row g-3">
                        <div v-for="(image, index) in currentImage" :key="index" class="col-md-6">
                            <div class="card">
                                <img :src="image" :alt="'Document ' + (index + 1)" class="card-img-top">
                                <div class="card-footer text-center">
                                    Document {{ index + 1 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Quotation Modal -->
    <div class="modal fade" id="quotation-modal" tabindex="-1" aria-labelledby="quotationModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="quotationModalLabel">Quotation Files</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedQuotations && selectedQuotations.length > 0">
                        <div class="list-group">
                            <div v-for="(file, index) in selectedQuotations" :key="file._id"
                                class="list-group-item list-group-item-action">
                                <div class="d-flex w-100 justify-content-between align-items-center">
                                    <h6 class="mb-1">Quotation {{ index + 1 }}</h6>
                                    <small class="text-muted">Added on {{ formatDateTime(file.date) }}</small>
                                </div>
                                <div class="mt-2">
                                    <a v-for="(url, urlIndex) in file.urls" :key="urlIndex" :href="url" target="_blank"
                                        class="btn btn-primary btn-sm me-2">
                                        <i class="fa fa-download me-1"></i>
                                        Download File {{ urlIndex + 1 }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center py-3">
                        <p class="text-muted">No quotation files available</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Proposal Modal -->
    <div class="modal fade" id="proposal-modal" tabindex="-1" aria-labelledby="proposalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="proposalModalLabel">Proposal Files</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedProposals && selectedProposals.length > 0">
                        <div class="list-group">
                            <div v-for="(file, index) in selectedProposals" :key="file._id"
                                class="list-group-item list-group-item-action">
                                <div class="d-flex w-100 justify-content-between align-items-center">
                                    <h6 class="mb-1">Proposal {{ index + 1 }}</h6>
                                    <small class="text-muted">Added on {{ formatDateTime(file.date) }}</small>
                                </div>
                                <div class="mt-2">
                                    <a v-for="(url, urlIndex) in file.urls" :key="urlIndex" :href="url" target="_blank"
                                        class="btn btn-primary btn-sm me-2">
                                        <i class="fa fa-download me-1"></i>
                                        Download File {{ urlIndex + 1 }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center py-3">
                        <p class="text-muted">No proposal files available</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

</template>