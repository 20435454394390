import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, unref as _unref, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "add-employee" }
const _hoisted_2 = { class: "card mb-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "search-results border rounded mt-1"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "d-flex justify-content-end gap-2" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header d-flex justify-content-between align-items-center" }
const _hoisted_11 = { class: "input-group w-auto" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = { class: "table-responsive" }
const _hoisted_14 = { class: "table table-hover" }
const _hoisted_15 = ["onClick", "disabled"]
const _hoisted_16 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status"
}
const _hoisted_17 = {
  key: 1,
  class: "bi bi-trash"
}
const _hoisted_18 = {
  key: 0,
  class: "text-center my-4"
}
const _hoisted_19 = {
  key: 1,
  class: "text-center my-4"
}

import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';
import { fetchGetApi } from '@/util/api';

interface Employee {
    _id: string;
    employeeName: string;
    employeeCode: string;
    employeeType: string;
    permanentAddress?: {
        village?: string;
        postOffice?: string;
        policeStation?: string;
        district?: string;
        state?: string;
        pin?: string;
        phoneNumber?: string;
        alternatePhoneNumber?: string;
        alternatePhoneRelation?: string;
        _id?: string;
    };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'add-employee',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const siteId = route.params.siteId as string;
const baseUrl = process.env.VUE_APP_BASE_URL;

const formData = ref({
    name: '',
    employeeType: '',
});

const employees = ref<Employee[]>([]);
const isLoading = ref(false);
const searchQuery = ref('');
const searchResults = ref<Employee[]>([]);

// Add this ref for tracking removal loading state
const removingEmployeeId = ref<string | null>(null);

// Define the removeEmployeeWithLoading function
const removeEmployeeWithLoading = async (employeeId: string) => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You want to remove this employee from the site?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
        cancelButtonText: 'Cancel'
    });

    if (!result.isConfirmed) return;
    
    removingEmployeeId.value = employeeId;
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/sites/remove/employee/${siteId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                employeeIds: employeeId
            })
        });
        
        const apiResult = await response.json();
        
        if (apiResult.success) {
            await fetchEmployees();
            Swal.fire(
                'Removed!',
                'Employee has been removed from the site.',
                'success'
            );
        } else {
            Swal.fire(
                'Error!',
                apiResult.message || 'Failed to remove employee',
                'error'
            );
        }
    } catch (error) {
        console.error('Error removing employee:', error);
        Swal.fire(
            'Error!',
            'Failed to remove employee from site',
            'error'
        );
    } finally {
        removingEmployeeId.value = null;
    }
};

// Fetch employees list
const fetchEmployees = async () => {
    isLoading.value = true;
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/sites/get/employee/${siteId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        
        const result = await response.json();
        console.log('API Response:', result);
        
        if (result.success && Array.isArray(result.data)) {
            employees.value = result.data.map((emp: any) => {
                console.log('Processing employee:', emp);
                return {
                    _id: emp._id,
                    employeeName: emp.employeeName,
                    employeeCode: emp.employeeCode,
                    employeeType: emp.employeeType,
                    permanentAddress: emp.permanentAddress || {}
                };
            });
            console.log('Mapped employees:', employees.value);
        } else {
            console.error('Invalid API response format:', result);
            employees.value = [];
        }
    } catch (error) {
        console.error('Error fetching employees:', error);
        employees.value = [];
    } finally {
        isLoading.value = false;
    }
};

// Filter employees based on search
const filteredEmployees = computed(() => {
    return employees.value.filter(employee => 
        employee.employeeName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        employee.employeeCode.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        employee.permanentAddress?.phoneNumber?.includes(searchQuery.value) || false
    );
});

// Remove employee
const removeEmployee = async (employeeId: string) => {
    if (!confirm('Are you sure you want to remove this employee?')) return;
    
    try {
        // Add your API call here
        console.log('Removing employee:', employeeId);
        await fetchEmployees(); // Refresh the list
    } catch (error) {
        console.error('Error removing employee:', error);
    }
};

// Update handleSubmit to call the API
const handleSubmit = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/sites/assign/employee/${siteId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                employeeIds: selectedEmployee.value._id // Add this ref
            })
        });
        const result = await response.json();
        
        if (result.success) {
            await fetchEmployees();
            // Reset form
            formData.value = {
                name: '',
                employeeType: '',
            };
            selectedEmployee.value = null; // Add this ref
        } else {
            alert(result.message);
        }
    } catch (error) {
        console.error('Error adding employee:', error);
        alert('Failed to add employee');
    }
};

// Add new ref to store selected employee
const selectedEmployee = ref<Employee | null>(null);

// Add new function to search employees
const searchEmployees = async () => {
    if (!formData.value.name) {
        searchResults.value = [];
        return;
    }
    
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/joining/employees/type?type=Employee`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const result = await response.json();
        if (result.success) {
            searchResults.value = result.data.filter((emp: any) => 
                emp.employeeName.toLowerCase().includes(formData.value.name.toLowerCase())
            );
        }
    } catch (error) {
        console.error('Error searching employees:', error);
    }
};

// Update selectEmployee function
const selectEmployee = (employee: Employee) => {
    formData.value.name = employee.employeeName;
    formData.value.employeeType = employee.employeeType || 'Employee';
    selectedEmployee.value = employee; // Store the selected employee
    searchResults.value = []; // Clear search results
};

// Fetch employees when component is mounted
onMounted(() => {
    fetchEmployees();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("h3", null, "Add Employee")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _withModifiers(handleSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label" }, "Name", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.name) = $event)),
              onInput: searchEmployees,
              required: ""
            }, null, 544), [
              [_vModelText, formData.value.name]
            ]),
            (searchResults.value.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchResults.value, (employee) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: employee._id,
                      class: "p-2 cursor-pointer hover:bg-gray-100",
                      onClick: ($event: any) => (selectEmployee(employee))
                    }, _toDisplayString(employee.employeeName) + " (" + _toDisplayString(employee.employeeCode) + ") ", 9, _hoisted_6))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label" }, "Employee Type", -1)),
            _withDirectives(_createElementVNode("select", {
              class: "form-select",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.employeeType) = $event)),
              required: "",
              disabled: ""
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("option", { value: "" }, "Select Employee Type", -1),
              _createElementVNode("option", { value: "Employee" }, "Employee", -1)
            ]), 512), [
              [_vModelSelect, formData.value.employeeType]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).back()))
            }, " Cancel "),
            _cache[7] || (_cache[7] = _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary"
            }, " Add Employee ", -1))
          ])
        ], 32)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Existing Employees", -1)),
        _createElementVNode("div", _hoisted_11, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((searchQuery).value = $event)),
            placeholder: "Search employees..."
          }, null, 512), [
            [_vModelText, searchQuery.value]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("table", _hoisted_14, [
            _cache[12] || (_cache[12] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Employee Code"),
                _createElementVNode("th", null, "Name"),
                _createElementVNode("th", null, "Phone Number"),
                _createElementVNode("th", null, "Actions")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredEmployees.value, (employee) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: employee._id
                }, [
                  _createElementVNode("td", null, _toDisplayString(employee.employeeCode), 1),
                  _createElementVNode("td", null, _toDisplayString(employee.employeeName), 1),
                  _createElementVNode("td", null, _toDisplayString(employee.permanentAddress?.phoneNumber || 'N/A'), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("button", {
                      class: "btn btn-sm btn-danger d-flex align-items-center gap-1",
                      onClick: ($event: any) => (removeEmployeeWithLoading(employee._id)),
                      disabled: removingEmployeeId.value === employee._id
                    }, [
                      (removingEmployeeId.value === employee._id)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _cache[10] || (_cache[10] = [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...", -1)
                          ])))
                        : (_openBlock(), _createElementBlock("i", _hoisted_17)),
                      _cache[11] || (_cache[11] = _createTextVNode(" Remove "))
                    ], 8, _hoisted_15)
                  ])
                ]))
              }), 128))
            ])
          ])
        ]),
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[13] || (_cache[13] = [
              _createElementVNode("div", {
                class: "spinner-border text-primary",
                role: "status"
              }, [
                _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
              ], -1)
            ])))
          : _createCommentVNode("", true),
        (!isLoading.value && employees.value.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[14] || (_cache[14] = [
              _createElementVNode("p", { class: "text-muted" }, "No employees found", -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})