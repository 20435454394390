import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = {
  key: 0,
  class: "text-center py-4"
}
const _hoisted_5 = {
  key: 1,
  class: "table-responsive"
}
const _hoisted_6 = { class: "table table-bordered" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "modal-dialog modal-lg" }
const _hoisted_10 = { class: "modal-content" }
const _hoisted_11 = { class: "modal-header" }
const _hoisted_12 = { class: "modal-body" }
const _hoisted_13 = { class: "table" }

import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { fetchGetApi, fetchPutApi } from '@/util/api';

// Define interfaces
interface Employee {
    _id: string;
    employeeName: string;
    employeeCode: string;
}

interface Department {
    _id: string;
    name: string;
}

interface Site {
    _id: string;
    siteName: string;
}

interface LaundryItem {
    uniformItemId: string;
    itemName: string;
    size: string;
    employeeId: Employee;
    departmentId: Department;
    siteId: Site;
}

interface LaundryBatch {
    _id: string;
    batchNumber: string;
    items: LaundryItem[];
    vendorName: string;
    expectedReturnDate: string;
    actualReturnDate?: string;
    status: 'Sent' | 'Received';
    remarks?: string;
    createdAt: string;
    updatedAt: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'laundry-report',
  setup(__props) {

const toast = useToast();
const loading = ref(true);
const laundryBatches = ref<LaundryBatch[]>([]);
const showModal = ref(false);
const selectedBatch = ref<LaundryBatch | null>(null);

const baseUrl = process.env.VUE_APP_BASE_URL

// Fetch laundry batches
const fetchLaundryBatches = async (): Promise<void> => {
    try {
        loading.value = true;
        const response = await fetchGetApi<{ success: boolean; data: LaundryBatch[] }>(
            `api/laundry/batches`
        );
        console.log('Laundry data:', response.data);
        laundryBatches.value = response.data.data;
    } catch (error) {
        console.error('Error fetching laundry batches:', error);
        toast.error('Failed to fetch laundry data');
    } finally {
        loading.value = false;
    }
};

// Helper functions
const formatDate = (dateString: string): string => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString();
};

const getStatusClass = (status: LaundryBatch['status']): Record<string, boolean> => {
    return {
        'badge rounded-pill bg-warning': status === 'Sent',
        'badge rounded-pill bg-success': status === 'Received'
    };
};

const viewItems = (batch: LaundryBatch): void => {
    selectedBatch.value = batch;
    showModal.value = true;
};

const receiveBatch = async (batch: LaundryBatch): Promise<void> => {
    try {
        await fetchPutApi(`api/laundry/batch/${batch.batchNumber}/receive`);
        toast.success('Batch received successfully');
        await fetchLaundryBatches();
    } catch (error) {
        console.error('Error receiving batch:', error);
        toast.error('Failed to receive batch');
    }
};

// Lifecycle hooks
onMounted(() => {
    fetchLaundryBatches();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Reports",
      title: "Laundry Report"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (loading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
                _createElementVNode("div", {
                  class: "spinner-border",
                  role: "status"
                }, [
                  _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                ], -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("table", _hoisted_6, [
                  _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "Batch Number"),
                      _createElementVNode("th", null, "Date Sent"),
                      _createElementVNode("th", null, "Vendor"),
                      _createElementVNode("th", null, "Items"),
                      _createElementVNode("th", null, "Expected Return"),
                      _createElementVNode("th", null, "Actual Return"),
                      _createElementVNode("th", null, "Status"),
                      _createElementVNode("th", null, "Actions")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(laundryBatches.value, (batch) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: batch._id
                      }, [
                        _createElementVNode("td", null, _toDisplayString(batch.batchNumber), 1),
                        _createElementVNode("td", null, _toDisplayString(formatDate(batch.createdAt)), 1),
                        _createElementVNode("td", null, _toDisplayString(batch.vendorName), 1),
                        _createElementVNode("td", null, [
                          _createTextVNode(_toDisplayString(batch.items.length) + " items ", 1),
                          _createElementVNode("button", {
                            class: "btn btn-link btn-sm",
                            onClick: ($event: any) => (viewItems(batch))
                          }, " View Details ", 8, _hoisted_7)
                        ]),
                        _createElementVNode("td", null, _toDisplayString(formatDate(batch.expectedReturnDate)), 1),
                        _createElementVNode("td", null, _toDisplayString(batch.actualReturnDate ? formatDate(batch.actualReturnDate) : '-'), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("span", {
                            class: _normalizeClass(getStatusClass(batch.status))
                          }, _toDisplayString(batch.status), 3)
                        ]),
                        _createElementVNode("td", null, [
                          (batch.status === 'Sent')
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "btn btn-primary btn-sm",
                                onClick: ($event: any) => (receiveBatch(batch))
                              }, " Receive ", 8, _hoisted_8))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ]))
        ])
      ])
    ]),
    (showModal.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["modal fade", { 'show': showModal.value }])
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "modal-title" }, "Batch Details", -1)),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn-close",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showModal.value = false))
                })
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("table", _hoisted_13, [
                  _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "Item"),
                      _createElementVNode("th", null, "Size"),
                      _createElementVNode("th", null, "Employee"),
                      _createElementVNode("th", null, "Department"),
                      _createElementVNode("th", null, "Site")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedBatch.value?.items, (item) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: item.uniformItemId
                      }, [
                        _createElementVNode("td", null, _toDisplayString(item.itemName), 1),
                        _createElementVNode("td", null, _toDisplayString(item.size), 1),
                        _createElementVNode("td", null, _toDisplayString(item.employeeId?.employeeName), 1),
                        _createElementVNode("td", null, _toDisplayString(item.departmentId?.name), 1),
                        _createElementVNode("td", null, _toDisplayString(item.siteId?.siteName), 1)
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
}

})