<template>
  <div class="panel-2-list">
    <h2>Candidates for Panel 2 Interview</h2>
    <table v-if="candidates.length" class="candidates-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Token</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="candidate in candidates" :key="candidate._id">
          <td>{{ candidate.employeeName }}</td>
          <td>{{ candidate.token }}</td>
          <td>
            <button @click="selectCandidate(candidate.token)" class="select-btn">
              Select
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else>No candidates available for Panel 2 interview.</p>
  </div>
</template>

<script>
import { fetchGetApi } from '@/util/api';

export default {
  data() {
    return {
      candidates: []
    };
  },
  methods: {
    async fetchCandidates() {
      try {
        const response = await fetchGetApi('api/prejoin/passed-panel1');
        if (response.data.success) {
          this.candidates = response.data.data;
        } else {
          console.error('Failed to fetch candidates');
        }
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
    },
    selectCandidate(token) {
      this.$router.push(`/interview/interview-process-panel2/${token}`);
    }
  },
  created() {
    this.fetchCandidates();
  }
};
</script>

<style scoped>
.panel-2-list {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.candidates-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.candidates-table th,
.candidates-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.candidates-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.select-btn {
  background-color: #4CAF50;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.select-btn:hover {
  background-color: #45a049;
}
</style>