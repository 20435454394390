<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import Multiselect from 'vue-multiselect';
import { jsonToExcel } from "@/util/makeExcel";

const allData = ref([]);
const elementsPerPage = ref(10);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

// Search and filter states
const startDate = ref('');
const endDate = ref('');
const selectedAreas = ref([]);
const areas = ref([]); // Will populate based on allData

// Add these refs after other refs
const sortBy = ref('visits'); // Default sort by visits
const sortDirection = ref('desc'); // Default sort direction

// Add this helper function after other refs
const getTotalVisits = (area) => {
    const visitsByEmployee = new Map();

    // Add marketing manager visits
    if (Array.isArray(area.MarketingManagerVisits)) {
        area.MarketingManagerVisits.forEach(visit => {
            const name = visit.employeeName || '-';
            visitsByEmployee.set(name, (visitsByEmployee.get(name) || 0) + 1);
        });
    }

    // Add follow-up visits
    if (Array.isArray(area.FollowUpVisits)) {
        area.FollowUpVisits.forEach(empVisits => {
            const name = empVisits.employeeName || '-';
            const visitsCount = empVisits.visits?.length || 0;
            visitsByEmployee.set(name, (visitsByEmployee.get(name) || 0) + visitsCount);
        });
    }

    // Return total visits across all employees
    return Array.from(visitsByEmployee.values()).reduce((sum, count) => sum + count, 0);
};

onMounted(async () => {
    await getData();
    populateAreas();
});

// Fetch data from API
async function getData() {
    try {
        const response = await fetchGetApi('api/operation/get/site/by/area');

        if (!response?.data?.data) {
            toast.error('Failed to fetch data');
            return;
        }

        allData.value = response.data.data;

        if (allData.value.length === 0) {
            toast.warning('No data available');
            return;
        }

    } catch (error) {
        console.error('API Error:', error);
        toast.error('An error occurred while fetching data');
    }
}

// Populate areas for the multiselect based on fetched data
function populateAreas() {
    areas.value = allData.value.map(area => ({
        name: area.Area
    }));
}

// Computed property for filtered data
const filteredData = computed(() => {
    let data = allData.value;

    // Filter by search query
    if (filterQuery.value) {
        const query = filterQuery.value.toLowerCase();
        data = data.filter(item => item.Area.toLowerCase().includes(query));
    }

    // Filter by selected areas
    if (selectedAreas.value.length > 0) {
        const selectedNames = selectedAreas.value.map(area => area.name.toLowerCase());
        data = data.filter(item => selectedNames.includes(item.Area.toLowerCase()));
    }

    // Filter by date range if needed (assuming you want to filter based on visit dates)
    if (startDate.value && endDate.value) {
        const start = new Date(startDate.value);
        const end = new Date(endDate.value);
        
        // Set time to start of day for start date and end of day for end date
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        data = data.filter(item => {
            // Helper function to check if a date string falls within range
            const isDateInRange = (dateStr) => {
                // Handle date format like "11-12-2024 – 02:36 PM"
                let visitDate;
                if (dateStr.includes('–')) {
                    const [datePart] = dateStr.split('–');
                    const [day, month, year] = datePart.trim().split('-');
                    visitDate = new Date(year, month - 1, day);
                } else {
                    visitDate = new Date(dateStr);
                }
                
                visitDate.setHours(0, 0, 0, 0); // Normalize time part
                
                // For same date comparison
                if (start.getTime() === end.getTime()) {
                    return visitDate.getTime() === start.getTime();
                }
                
                return visitDate >= start && visitDate <= end;
            };

            // Check marketing manager visits
            const hasMarketingVisits = item.MarketingManagerVisits?.some(visit => 
                isDateInRange(visit.date)
            );

            // Check follow-up visits
            const hasFollowUps = item.FollowUpVisits?.some(emp => 
                emp.visits?.some(visit => isDateInRange(visit.NFD))
            );

            // Check marketing manager calls
            const hasCalls = item.MarketingManagerCalls?.some(call => 
                isDateInRange(call.date)
            );

            return hasMarketingVisits || hasFollowUps || hasCalls;
        });
    }

    return data;
});

// Add these new computed properties after other computed properties
const totalVisitsForArea = computed(() => (area) => {
    const marketingVisits = area.VisitsByMarketingManagers?.reduce((sum, emp) => 
        sum + (emp.visits?.length || 0), 0) || 0;
    const followUpVisits = area.FollowUpVisits?.reduce((sum, emp) => 
        sum + (emp.visits?.length || 0), 0) || 0;
    return marketingVisits + followUpVisits;
});

// Update the sortFunctions object in the sortedData computed property
const sortedData = computed(() => {
    let data = [...filteredData.value];

    switch(sortBy.value) {
        case 'zeroVisits':
            data.sort((a, b) => {
                const aVisits = getTotalVisits(a);
                const bVisits = getTotalVisits(b);
                
                // If sorting by zero visits ascending
                if (sortDirection.value === 'asc') {
                    // Areas with zero visits come first
                    if (aVisits === 0 && bVisits !== 0) return -1;
                    if (aVisits !== 0 && bVisits === 0) return 1;
                } else {
                    // Areas with visits come first
                    if (aVisits === 0 && bVisits !== 0) return 1;
                    if (aVisits !== 0 && bVisits === 0) return -1;
                }
                
                // If both have visits or both don't have visits, sort by pincode
                return (a.AreaPinCode || '').localeCompare(b.AreaPinCode || '');
            });
            break;

        case 'visitCount':
            data.sort((a, b) => {
                const aVisits = getTotalVisits(a);
                const bVisits = getTotalVisits(b);
                
                // First sort by visit count
                if (aVisits !== bVisits) {
                    // For High to Low (desc), subtract a from b
                    // For Low to High (asc), subtract b from a
                    return sortDirection.value === 'desc' ? 
                        (bVisits - aVisits) : 
                        (aVisits - bVisits);
                }
                
                // If visit counts are equal, sort by pincode as secondary criteria
                return (a.AreaPinCode || '').localeCompare(b.AreaPinCode || '');
            });
            break;

        default:
            // Default sorting by pincode
            data.sort((a, b) => {
                return (a.AreaPinCode || '').localeCompare(b.AreaPinCode || '');
            });
            break;
    }

    return data;
});

// Replace the paginatedData computed property with this updated version
const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return sortedData.value.slice(start, end);
});

// Total number of pages
const totalPages = computed(() => {
    return Math.ceil(filteredData.value.length / elementsPerPage.value);
});

// Pagination methods
function change_page(page) {
    if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
    }
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
    }
}

function search(event) {
    event.preventDefault();
    currentPage.value = 1;
}

function formatArray(array, key1, key2 = null) {
    if (!Array.isArray(array) || array.length === 0 || array.every(item => item === null)) return '-';

    // Special handling for calls
    if (key1 === 'employeeName' && key2 === 'date') {
        // Group calls by employee
        const callsByEmployee = array.reduce((acc, call) => {
            const name = call.employeeName || 'Unassigned';
            if (!acc[name]) acc[name] = 0;
            acc[name]++;
            return acc;
        }, {});

        // Format as "employee: X calls"
        return Object.entries(callsByEmployee)
            .map(([name, count]) => `${name}: ${count} calls`)
            .join('\n');
    }

    // Special handling for NewProjects
    if (array[0] && typeof array[0] === 'object' && 'projectName' in array[0]) {
        return array.map(item => {
            const projectName = item.projectName || '-';
            const employeeName = item.employeeName ? ` (${item.employeeName})` : '';
            return `${projectName}${employeeName}`;
        }).join('\n');
    }

    // Handle arrays of strings or objects with key1
    return array
        .filter(item => item !== null)
        .map(item => {
            if (typeof item === 'object' && key1) {
                return item[key1] || '-';
            }
            return item;
        })
        .join(', ');
}

function formatDate(dateString) {
    if (!dateString) return '';

    // Handle date format like "11-12-2024 – 02:36 PM"
    if (dateString.includes('–')) {
        // Remove the en-dash and just use space
        return dateString.replace('–', '').trim();
    }

    // Handle standard date format
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid

    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
}

// Update the formatVisits function to only show marketing manager visits
function formatVisits(visitsData) {
    // If no visits data, return dash
    if (!Array.isArray(visitsData) || visitsData.length === 0) {
        return '-';
    }

    // Create a map to store visits per employee
    const visitsByEmployee = new Map();

    // Process marketing manager visits only
    visitsData.forEach(visit => {
        const name = visit.employeeName || '-';
        visitsByEmployee.set(name, (visitsByEmployee.get(name) || 0) + 1);
    });

    // Format as "employee: X visits"
    return Array.from(visitsByEmployee.entries())
        .map(([name, count]) => `${name}: ${count} visits`)
        .join('\n');
}

function toggleSort(column) {
    if (sortBy.value === column) {
        sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
    } else {
        sortBy.value = column;
        sortDirection.value = 'desc';
    }
}

function downloadExcel() {
    const dataToExport = sortedData.value.map((item, index) => ({
        'SL': (currentPage.value - 1) * elementsPerPage.value + index + 1,
        'Area Pincode': item.AreaPinCode || '',
        'Area': item.Area || '',
        'No. of Projects': item.NoOfProjects || 0,
        'Total Manpower': item.TotalNoOfManpower || 0,
        'Services Required': formatArray(item.ServiceRequired) || '',
        'Marketing Manager Visits': formatVisits(item.MarketingManagerVisits) || '',
        'Marketing Manager Calls': formatArray(item.MarketingManagerCalls, 'employeeName', 'date') || '',
        'New Projects Found': formatArray(item.NewProjects) || '',
        'Follow Up Visits': formatVisits(item.FollowUpVisits) || ''
    }));

    // Add a timestamp to the filename
    const timestamp = new Date().toISOString().split('T')[0];
    jsonToExcel(dataToExport, `area-analysis-report-${timestamp}.xlsx`);
    toast.success('Excel file downloaded successfully');
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Area analysis" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <!-- Area analysis, Date range, Search by area -->
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer" @submit="search">
                        <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-xs-12 col-sm-auto col-form-label">Date range:</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" class="form-control" v-model="startDate" style="width: 150px;">
                                    <span class="mx-1">to</span>
                                    <input type="date" class="form-control" v-model="endDate" style="width: 150px;">
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="area-select" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    area:</label>
                                <multiselect id="area-select" v-model="selectedAreas" :options="areas" :multiple="true"
                                    :searchable="true" placeholder="Select areas" label="name" track-by="name"
                                    class="form-control">
                                </multiselect>
                            </div>
                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" type="submit">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center gap-2">
                                <button 
                                    type="button" 
                                    class="btn btn-outline-primary" 
                                    :class="{ 'active': sortBy === 'zeroVisits' }"
                                    @click="() => {
                                        sortBy = 'zeroVisits';
                                        sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
                                    }">
                                    {{ sortBy === 'zeroVisits' && sortDirection === 'desc' ? 
                                        'Pin codes with visits' : 
                                        'Pin codes with zero visits' }}
                                </button>
                                <button 
                                    type="button" 
                                    class="btn btn-outline-primary" 
                                    :class="{ 'active': sortBy === 'visitCount' }"
                                    @click="() => {
                                        if (sortBy === 'visitCount') {
                                            // Toggle direction if already sorting by visit count
                                            sortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
                                        } else {
                                            // Default to desc (High to Low) when first clicking
                                            sortBy = 'visitCount';
                                            sortDirection = 'desc';
                                        }
                                    }">
                                    Pin codes by visits ({{ sortDirection === 'desc' ? 'High to Low' : 'Low to High' }})
                                </button>
                                <button type="button" class="btn btn-primary" @click="downloadExcel">
                                    <i class="fas fa-download me-1"></i>
                                    Download Excel
                                </button>
                            </div>
                        </div>
                        <!-- Optional: Add a search input if needed -->
                        <!-- <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                        </div> -->

                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50px;">SL</th>
                                    <th scope="col" @click="toggleSort('pincode')" style="cursor: pointer;">
                                        Pincodes/Area
                                        <i v-if="sortBy === 'pincode'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('projects')" style="cursor: pointer;">
                                        No. of project
                                        <i v-if="sortBy === 'projects'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('manpower')" style="cursor: pointer;">
                                        Total no<br>of manpower
                                        <i v-if="sortBy === 'manpower'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('services')" style="cursor: pointer;">
                                        Service required
                                        <i v-if="sortBy === 'services'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('visits')" style="cursor: pointer;">
                                        Visits by marketing managers
                                        <i v-if="sortBy === 'visits'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('calls')" style="cursor: pointer;">
                                        Calls
                                        <i v-if="sortBy === 'calls'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('newProjects')" style="cursor: pointer;">
                                        New project found visits
                                        <i v-if="sortBy === 'newProjects'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>
                                    <th scope="col" @click="toggleSort('followUps')" style="cursor: pointer;">
                                        Follow up visits
                                        <i v-if="sortBy === 'followUps'"
                                            :class="['fas', sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down']">
                                        </i>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in paginatedData" :key="item.Area">
                                    <td>{{ (currentPage - 1) * elementsPerPage + index + 1 }}</td>
                                    <td>{{ item.AreaPinCode }}<br>{{ item.Area }}</td>
                                    <td>
                                        <router-link v-if="item.NoOfProjects > 0"
                                            :to="`area/area-project-view/${item.id}`" class="text-primary me-2"
                                            title="View" target="_blank" style="cursor: pointer; font-size:14px;">
                                            {{ item.NoOfProjects }}
                                        </router-link>
                                        <span v-else>{{ item.NoOfProjects }}</span>
                                    </td>
                                    <td>{{ item.TotalNoOfManpower || 0 }}</td>
                                    <td>{{ formatArray(item.ServiceRequired) }}</td>
                                    <td class="whitespace-pre-line">
                                        {{ formatVisits(item.MarketingManagerVisits) }}
                                    </td>
                                    <td class="whitespace-pre-line">
                                        {{ formatArray(item.MarketingManagerCalls, 'employeeName', 'date') }}
                                    </td>
                                    <td class="whitespace-pre-line">{{ formatArray(item.NewProjects) }}</td>
                                    <td class="whitespace-pre-line">
                                        {{ formatVisits(item.FollowUpVisits) }}
                                    </td>
                                </tr>
                                <tr v-if="paginatedData.length === 0">
                                    <td colspan="10" class="text-center">No data available</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <!-- Pagination -->
                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" :class="{ disabled: currentPage === 1 }" @click="previousPage">
                        <a class="page-link" href="#">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in totalPages" :key="i" :class="{ active: i === currentPage }"
                        @click="change_page(i)">
                        <a class="page-link" href="#">{{ i }}</a>
                    </li>
                    <li class="page-item" :class="{ disabled: currentPage === totalPages }" @click="nextPage">
                        <a class="page-link" href="#">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
.whitespace-pre-line {
    white-space: pre-line;
}

table td {
    vertical-align: top;
    padding: 8px;
}

/* Add these to your existing styles */
th {
    position: relative;
    padding-right: 20px !important;
}

th i {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8em;
}

th:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* Add to your existing styles */
.text-primary {
    color: #7366ff;
    font-size: 0.85em;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer:hover {
    text-decoration: underline;
}

/* Update the table cell styles */
table td {
    vertical-align: top;
    padding: 8px;
    max-width: 250px; /* Default max width for cells */
    overflow: hidden;
}

/* Add specific width for No. of Projects column */
table td:nth-child(3) {
    max-width: 50px; /* Reduced width for No. of Projects column */
    width: 50px;
}

/* Add specific width for Total no of manpower column */
table td:nth-child(4) {
    max-width: 50px;
    width: 50px;
}

/* Add transition for smooth expansion */
td {
    transition: all 0.3s ease;
}

/* Add to your existing styles */
.gap-2 {
    gap: 0.5rem;
}

.btn-outline-primary {
    border-color: #7366ff;
    color: #7366ff;
}

.btn-outline-primary:hover {
    background-color: #7366ff;
    color: white;
}

/* Add these styles */
.btn-outline-primary.active {
    background-color: #7366ff;
    color: white;
}

.btn-outline-primary:focus {
    box-shadow: none;
}
</style>
