import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "work-order-section" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_4 = { class: "list-group" }
const _hoisted_5 = ["onClick"]

import { reactive, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'work-order-section',
  props: {
    workOrders: {}
  },
  emits: ['update:workOrders'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const localWorkOrders = reactive<File[]>([...props.workOrders]);

const handleFileUpload = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        localWorkOrders.push(input.files[0]);
        emit('update:workOrders', [...localWorkOrders]);
    }
};

const removeWorkOrder = (index: number) => {
    localWorkOrders.splice(index, 1);
    emit('update:workOrders', [...localWorkOrders]);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("label", { class: "form-label" }, "Upload Work Order Documents", -1)),
      _createElementVNode("input", {
        type: "file",
        class: "form-control",
        onChange: handleFileUpload,
        accept: ".pdf,.doc,.docx"
      }, null, 32)
    ]),
    (localWorkOrders.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("h6", null, "Uploaded Documents:", -1)),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localWorkOrders, (file, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "list-group-item d-flex justify-content-between align-items-center"
              }, [
                _createTextVNode(_toDisplayString(file.name) + " ", 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-danger btn-sm",
                  onClick: ($event: any) => (removeWorkOrder(index))
                }, " Remove ", 8, _hoisted_5)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})