<template>
    <div class="add-machine">
        <div class="card mb-4">
            <div class="card-header">
                <h3>Add Machine</h3>
            </div>
            <div class="card-body">
                <form @submit.prevent="handleSubmit">
                    <div class="mb-3">
                        <label class="form-label">Machine Name</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="machineName"
                            required
                            placeholder="Enter machine name"
                        >
                    </div>
                    <div class="d-flex justify-content-end gap-2">
                        <button 
                            type="button" 
                            class="btn btn-secondary"
                            @click="router.back()"
                        >
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-primary">
                            Add Machine
                        </button>
                    </div>
                </form>

                <!-- Display existing machines -->
                <div class="mt-4">
                    <h4>Existing Machines</h4>
                    <div v-if="isLoading" class="text-center my-4">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <div v-else-if="machines.length === 0" class="text-center my-4">
                        <p class="text-muted">No machines found</p>
                    </div>

                    <div v-else class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Machine Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="machine in machines" :key="machine._id">
                                    <td>{{ machine.registerName }}</td>
                                    <td>
                                        <button 
                                            class="btn btn-danger btn-sm"
                                            @click="removeMachine(machine._id)"
                                            :disabled="removingMachineId === machine._id"
                                        >
                                            <span v-if="removingMachineId === machine._id">
                                                Removing...
                                            </span>
                                            <span v-else>
                                                Remove
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';

const router = useRouter();
const route = useRoute();
const siteId = route.params.siteId as string;
const baseUrl = process.env.VUE_APP_BASE_URL;

const machineName = ref('');
const machines = ref<any[]>([]);
const isLoading = ref(false);
const removingMachineId = ref<string | null>(null);

// Handle form submission
const handleSubmit = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/physical/register/machine/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                siteId: siteId,
                registerData: [{
                    registerName: machineName.value
                }]
            })
        });

        const result = await response.json();
        
        // Check if response status is in 200-299 range
        if (response.ok) {
            // Reset form first
            machineName.value = '';
            // Then fetch updated machines
            await fetchMachines();
            // Show success message
            await Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Machine added successfully',
                timer: 1500,
                showConfirmButton: false
            });
        } else {
            throw new Error(result.message || 'Failed to add machine');
        }
    } catch (error: any) {
        console.error('Error adding machine:', error);
        await Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message || 'Failed to add machine',
        });
    }
};

// Fetch machines list
const fetchMachines = async () => {
    isLoading.value = true;
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/physical/register/machine/get/${siteId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        
        const result = await response.json();
        
        if (response.ok && result.physicalRegister) {
            // Transform the data to only include necessary machine information
            machines.value = result.physicalRegister.registerData.map((machine: any) => ({
                _id: machine._id,
                registerName: machine.registerName
            }));
        } else {
            console.error('Invalid API response format:', result);
            machines.value = [];
        }
    } catch (error) {
        console.error('Error fetching machines:', error);
        machines.value = [];
    } finally {
        isLoading.value = false;
    }
};

// Remove machine
const removeMachine = async (machineId: string) => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You want to remove this machine?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
        cancelButtonText: 'Cancel'
    });

    if (!result.isConfirmed) return;
    
    removingMachineId.value = machineId;
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/physical/register/machine/${siteId}/${machineId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        
        const apiResult = await response.json();
        
        if (response.ok) {
            await fetchMachines();
            await Swal.fire({
                icon: 'success',
                title: 'Removed!',
                text: 'Machine has been removed successfully',
                timer: 1500,
                showConfirmButton: false
            });
        } else {
            throw new Error(apiResult.message || 'Failed to remove machine');
        }
    } catch (error: any) {
        console.error('Error removing machine:', error);
        await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.message || 'Failed to remove machine'
        });
    } finally {
        removingMachineId.value = null;
    }
};

onMounted(() => {
    fetchMachines();
});
</script>

<style scoped>
.table th {
    background-color: #f8f9fa;
}

.btn-danger {
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
}
</style>
