<script setup lang="ts">
import { ref, onMounted, reactive } from 'vue';
import { fetchGetApi } from "@/util/api";
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';

interface Particular {
    particular: string;
    percentage: string;
    houseKeeping: number;
    securityGuards: number;
    _id: string;
}

interface Params {
    serviceName: string;
    selectedHours: string;
    selectedStay: string;
    selectedEpf: string;
    selectedWeeklyOff: string;
    selectedFood: string;
    selectedGender: string;
    selectedGst: string;
    selectedManpower: number;
    _id: string;
}

interface Quote {
    minSalary: number;
    maxSalary: number;
    particularData: Particular[];
    parms: Params;
    inHandSalary: number;
    margin: number;
    _id: string;
}

interface QuotationData {
    _id: string;
    empId: {
        _id: string;
        employeeName: string;
    };
    areaId: {
        _id: string;
        areaName: string;
    };
    siteId: {
        _id: string;
        siteName: string;
    };
    minSalary: number;
    maxSalary: number;
    particularData: Particular[];
    parms: {
        serviceName: string;
        selectedHours: string;
        selectedStay: string;
        selectedEpf: string;
        selectedWeeklyOff: string;
        selectedFood: string;
        selectedGender: string;
        selectedGst: string;
        selectedManpower: number;
        _id: string;
    };
    inHandSalary: number;
    margin: number;
    createdAt: string;
    updatedAt: string;
    quotes: Quote[];
}

interface QuotationWithSelectedQuote extends QuotationData {
    selectedQuote?: Quote;
}

const quotations = ref<QuotationData[]>([]);
const loading = ref(false);
const selectedQuotation = ref<QuotationData | null>(null);
const router = useRouter();

const fetchQuotations = async () => {
    loading.value = true;
    try {
        const response = await fetchGetApi('api/quote/get/all/app');
        console.log(response);
        if (response.data) {
            quotations.value = response.data.data;

        }
    } catch (error) {
        console.error('Error fetching quotations:', error);
    } finally {
        loading.value = false;
    }
};

const showQuotationDetails = (quotation: QuotationData) => {
    selectedQuotation.value = quotation;

    if (!quotation.quotes?.length) {
        Swal.fire({
            title: 'Error',
            text: 'No quote data found',
            icon: 'error'
        });
        return;
    }

    Swal.fire({
        title: 'Quotation Details',
        html: `
            <div class="text-start">
                <p><strong>Employee:</strong> ${quotation.empId?.employeeName || 'N/A'}</p>
                <p><strong>Area:</strong> ${quotation.areaId?.areaName || 'N/A'}</p>
                <p><strong>Site:</strong> ${quotation.siteId?.siteName || 'N/A'}</p>
                <hr>
                ${quotation.quotes.map((quote, index) => `
                    <div class="mb-4">
                        <h6>Service ${index + 1}: ${quote.parms?.serviceName || 'N/A'}</h6>
                        <p><strong>Manpower:</strong> ${quote.parms?.selectedManpower || 'N/A'}</p>
                        <p><strong>Hours:</strong> ${quote.parms?.selectedHours || 'N/A'}</p>
                        <p><strong>Salary Range:</strong> ₹${(quote.minSalary || 0).toLocaleString()} - ₹${(quote.maxSalary || 0).toLocaleString()}</p>
                        <p><strong>In Hand Salary:</strong> ₹${(quote.inHandSalary || 0).toLocaleString()}</p>
                        <p><strong>Margin:</strong> ₹${(quote.margin || 0).toLocaleString()}</p>
                        <p><strong>Parameters:</strong></p>
                        <ul>
                            <li>Stay: ${quote.parms?.selectedStay || 'N/A'}</li>
                            <li>EPF: ${quote.parms?.selectedEpf || 'N/A'}</li>
                            <li>Weekly Off: ${quote.parms?.selectedWeeklyOff || 'N/A'}</li>
                            <li>Food: ${quote.parms?.selectedFood || 'N/A'}</li>
                            <li>Gender: ${quote.parms?.selectedGender || 'N/A'}</li>
                            <li>GST: ${quote.parms?.selectedGst || 'N/A'}</li>
                        </ul>
                    </div>
                `).join('')}
            </div>
        `,
        width: '600px',
        showCloseButton: true,
        showConfirmButton: false
    });
};

const handleProceed = (quotation: QuotationData) => {
    if (!quotation.quotes?.length) {
        Swal.fire({
            title: 'Error',
            text: 'No quote data found',
            icon: 'error'
        });
        return;
    }

    Swal.fire({
        title: 'Proceed with Quotation?',
        text: `Are you sure you want to proceed with all services?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, Proceed',
        cancelButtonText: 'Cancel'
    }).then((result) => {
        if (result.isConfirmed) {
            router.push({
                path: '/quotation/new',
                query: {
                    empId: quotation.empId._id,
                    siteId: quotation.siteId._id,
                    appQuoteId: quotation._id,
                    projectName: quotation.siteId.siteName,
                    areaId: quotation.areaId._id,

                    quotes: JSON.stringify(quotation.quotes)
                }
            });
            console.log("quotation", quotation);
        }
    });
};

onMounted(() => {
    fetchQuotations();
});
</script>

<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>App Quotations</h5>
                    </div>
                    <div class="card-body">
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                        <div v-else class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Employee</th>
                                        <th>Area</th>
                                        <th>Site</th>
                                        <th>Service Type</th>
                                        <th>Manpower</th>
                                        <th>Hours</th>
                                        <th>Salary Range</th>
                                        <th>Created At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="quotation in quotations" :key="quotation._id">
                                        <td>{{ quotation.empId?.employeeName || 'N/A' }}</td>
                                        <td>{{ quotation.areaId?.areaName || 'N/A' }}</td>
                                        <td>{{ quotation.siteId?.siteName || 'N/A' }}</td>
                                        <td>
                                            <div v-for="quote in quotation.quotes" :key="quote._id">
                                                {{ quote.parms?.serviceName || 'N/A' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div v-for="quote in quotation.quotes" :key="quote._id">
                                                {{ quote.parms?.selectedManpower || 'N/A' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div v-for="quote in quotation.quotes" :key="quote._id">
                                                {{ quote.parms?.selectedHours || 'N/A' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div v-for="quote in quotation.quotes" :key="quote._id">
                                                ₹{{ quote.minSalary || 0 }} - ₹{{ quote.maxSalary || 0 }}
                                            </div>
                                        </td>
                                        <td>{{ new Date(quotation.createdAt).toLocaleDateString() }}</td>
                                        <td>
                                            <div class="btn-group">
                                                <button class="btn btn-primary btn-sm me-2"
                                                    @click="showQuotationDetails(quotation)">
                                                    <i class="fa fa-eye"></i> View
                                                </button>
                                                <button class="btn btn-success btn-sm" @click="handleProceed(quotation)"
                                                    :disabled="!quotation.quotes?.length">
                                                    <i class="fa fa-check"></i> Proceed
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.table {
    margin-bottom: 0;
}

.table th {
    font-weight: 600;
    background-color: #f8f9fa;
}

.table td,
.table th {
    padding: 0.75rem;
    vertical-align: middle;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
    margin: 2rem auto;
}

.btn-group {
    display: flex;
    gap: 0.5rem;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.text-start {
    text-align: left;
}

.mb-3 {
    margin-bottom: 1rem;
}

.dropdown-menu {
    min-width: 200px;
}

.dropdown-item {
    padding: 0.5rem 1rem;
}

td>div {
    padding: 2px 0;
}

td>div:not(:last-child) {
    border-bottom: 1px solid #eee;
}
</style>