import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "panel-container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row mb-4 justify-content-center" }
const _hoisted_5 = { class: "col-md-3 mx-2" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "col-md-3 mx-2" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "chart-wrapper" }
const _hoisted_10 = { class: "chart-container" }
const _hoisted_11 = { class: "table-responsive mt-4" }
const _hoisted_12 = { class: "table table-bordered" }

import { ref, onMounted, computed, watch } from 'vue';
import { Chart, ChartData, ChartOptions } from 'chart.js/auto';
import { format } from 'date-fns';

interface PriceRecord {
    date: string;
    item: string;
    vendor: string;
    costPrice: number;
    sellingPrice: number;
    criticalLevel: number;
}

interface Vendor {
    _id: string;
    name: string;
    category: string;
    // ... other vendor fields
}

// Remove static priceData and replace with empty array

export default /*@__PURE__*/_defineComponent({
  __name: 'variation-chart',
  setup(__props) {

const baseUrl = process.env.VUE_APP_BASE_URL;
const priceData = ref<PriceRecord[]>([]);

// Add loading state
const loading = ref(true);

const selectedVendor = ref('');
const selectedItem = ref('');
const chartRef = ref<HTMLCanvasElement | null>(null);
const chart = ref<Chart | null>(null);

const vendors = ref<Vendor[]>([]);
const vendorNames = computed(() => vendors.value.map(vendor => vendor.name));

const items = computed(() => [...new Set(priceData.value.map(record => record.item))]);

const filteredData = computed(() => {
    return priceData.value.filter(record => {
        if (selectedVendor.value && record.vendor !== selectedVendor.value) return false;
        if (selectedItem.value && record.item !== selectedItem.value) return false;
        return true;
    });
});

const formatDate = (date: string) => {
    return format(new Date(date), 'dd/MM/yyyy');
};

const updateChart = () => {
    if (!chartRef.value || !filteredData.value.length) return;

    const dates = filteredData.value.map(record => formatDate(record.date));
    const costPrices = filteredData.value.map(record => record.costPrice);
    const sellingPrices = filteredData.value.map(record => record.sellingPrice);
    const criticalLevels = filteredData.value.map(record => record.criticalLevel);

    const data: ChartData = {
        labels: dates,
        datasets: [
            {
                label: 'Cost Price',
                data: costPrices,
                borderColor: '#ff6384',
                backgroundColor: 'rgba(255, 99, 132, 0.1)',
                borderWidth: 2,
                fill: true,
                tension: 0.4
            },
            {
                label: 'Selling Price',
                data: sellingPrices,
                borderColor: '#36a2eb',
                backgroundColor: 'rgba(54, 162, 235, 0.1)',
                borderWidth: 2,
                fill: true,
                tension: 0.4
            },
            {
                label: 'Critical Level',
                data: criticalLevels,
                borderColor: '#ffce56',
                backgroundColor: 'rgba(255, 206, 86, 0.1)',
                borderWidth: 2,
                fill: true,
                tension: 0.4
            }
        ]
    };

    const options: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    padding: 20,
                    font: {
                        size: 14
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: 'Price (₹)',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Date',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            }
        }
    };

    if (chart.value) {
        chart.value.destroy();
    }

    chart.value = new Chart(chartRef.value, {
        type: 'line',
        data,
        options
    });
};

watch([selectedVendor, selectedItem], () => {
    updateChart();
});

// Update fetchPriceRecords with loading state
const fetchPriceRecords = async () => {
    loading.value = true;
    try {
        const response = await fetch(`${baseUrl}api/price-variation/get`);
        const result = await response.json();
        if (result.success) {
            priceData.value = result.data;
        } else {
            error.value = 'Failed to fetch price records';
        }
    } catch (error) {
        console.error('Error fetching price records:', error);
        error.value = 'Error loading price data';
    } finally {
        loading.value = false;
    }
};

// Update onMounted to handle loading states properly
onMounted(async () => {
    try {
        await Promise.all([fetchVendors(), fetchPriceRecords()]);
        updateChart();
    } catch (error) {
        console.error('Error initializing component:', error);
    } finally {
        loading.value = false;
    }
});

// Add refresh functionality
const refreshData = async () => {
    await Promise.all([fetchVendors(), fetchPriceRecords()]);
    updateChart();
};

const fetchVendors = async () => {
    try {
        const response = await fetch('http://localhost:3000/api/vendor/get');
        const result = await response.json();
        if (result.success) {
            vendors.value = result.data;
        }
    } catch (error) {
        console.error('Error fetching vendors:', error);
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("h5", null, "Price Variation Analysis")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, "Select Vendor", -1)),
            _withDirectives(_createElementVNode("select", {
              class: "form-select",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedVendor).value = $event))
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("option", { value: "" }, "All Vendors", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vendors.value, (vendor) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: vendor._id,
                  value: vendor.name
                }, _toDisplayString(vendor.name), 9, _hoisted_6))
              }), 128))
            ], 512), [
              [_vModelSelect, selectedVendor.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "Select Item", -1)),
            _withDirectives(_createElementVNode("select", {
              class: "form-select",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedItem).value = $event))
            }, [
              _cache[4] || (_cache[4] = _createElementVNode("option", { value: "" }, "All Items", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: item,
                  value: item
                }, _toDisplayString(item), 9, _hoisted_8))
              }), 128))
            ], 512), [
              [_vModelSelect, selectedItem.value]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("canvas", {
              ref_key: "chartRef",
              ref: chartRef
            }, null, 512)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("table", _hoisted_12, [
            _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Date"),
                _createElementVNode("th", null, "Item"),
                _createElementVNode("th", null, "Vendor"),
                _createElementVNode("th", null, "Cost Price"),
                _createElementVNode("th", null, "Selling Price"),
                _createElementVNode("th", null, "Critical Level")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredData.value, (record, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, _toDisplayString(formatDate(record.date)), 1),
                  _createElementVNode("td", null, _toDisplayString(record.item), 1),
                  _createElementVNode("td", null, _toDisplayString(record.vendor), 1),
                  _createElementVNode("td", null, "₹" + _toDisplayString(record.costPrice), 1),
                  _createElementVNode("td", null, "₹" + _toDisplayString(record.sellingPrice), 1),
                  _createElementVNode("td", null, _toDisplayString(record.criticalLevel), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})