<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                    <h5>Employee Follow-up Details</h5>
                    <span>View detailed follow-ups for each site</span>
                </div>
                <div class="d-flex gap-3 align-items-center">
                    <div class="form-group mb-0">
                        <input 
                            type="date" 
                            class="form-control" 
                            v-model="selectedDate"
                            :max="maxDate"
                        >
                    </div>
                    <button class="btn btn-primary" @click="downloadExcel">
                        <i class="fas fa-download me-1"></i>
                        Download Excel
                    </button>
                </div>
            </div>
            <div class="card-body">
                <!-- Loading State -->
                <div v-if="isLoading" class="text-center py-5">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <!-- Error State -->
                <div v-else-if="error" class="alert alert-danger">
                    {{ error }}
                </div>

                <!-- Data Display -->
                <div v-else>
                    <!-- Summary Cards -->
                    <div class="row mb-4">
                        <div class="col-md-3">
                            <div class="card bg-primary text-white">
                                <div class="card-body">
                                    <h6 class="card-title">Total Sites</h6>
                                    <h2 class="mb-0">{{ filteredData.totalSites }}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card bg-success text-white">
                                <div class="card-body">
                                    <h6 class="card-title">Total Follow-ups</h6>
                                    <h2 class="mb-0">{{ filteredData.totalFollowUps }}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card bg-info text-white">
                                <div class="card-body">
                                    <h6 class="card-title">Average Rating</h6>
                                    <h2 class="mb-0">{{ averageRating }}%</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card bg-warning text-white">
                                <div class="card-body">
                                    <h6 class="card-title">Followups Date</h6>
                                    <h5 class="mb-0">{{ formatDateDisplay(selectedDate) }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Sites Table -->
                    <div class="table-responsive">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Site Name</th>
                                    <th>Site UID</th>
                                    <th>Total Follow-ups</th>
                                    <th>Latest Rating</th>
                                    <th>NFD</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!filteredData.followUps.length">
                                    <td colspan="6" class="text-center">No followups found for selected date</td>
                                </tr>
                                <tr v-for="site in filteredData.followUps" :key="site.siteId">
                                    <td>{{ site.siteName }}</td>
                                    <td>{{ site.siteUID }}</td>
                                    <td>{{ site.totalFollowUps }}</td>
                                    <td>
                                        <div class="progress" style="height: 20px;">
                                            <div 
                                                class="progress-bar" 
                                                :class="getRatingClass(site.followUps[0]?.rating)"
                                                :style="{ width: site.followUps[0]?.rating + '%' }"
                                            >
                                                {{ site.followUps[0]?.rating }}%
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ formatDate(site.followUps[0]?.NFD) }}
                                        <button 
                                            class="btn btn-warning btn-sm ms-2" 
                                            @click="showEditNFD(site)"
                                            title="Edit NFD"
                                        >
                                            <i class="fa fa-edit"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button 
                                            class="btn btn-info btn-sm" 
                                            @click="showFollowupDetails(site)"
                                            title="View Details"
                                        >
                                            <i class="fa fa-eye"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Follow-up Details Modal -->
                    <div class="modal fade" id="followupModal" tabindex="-1">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">
                                        {{ selectedSite?.siteName }} - Follow-up History
                                    </h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                </div>
                                <div class="modal-body">
                                    <div v-if="selectedSite">
                                        <div class="timeline">
                                            <div 
                                                v-for="(followup, index) in selectedSite.followUps" 
                                                :key="index"
                                                class="timeline-item"
                                            >
                                                <div class="timeline-date">
                                                    {{ formatDate(followup.createdAt) }}
                                                </div>
                                                <div class="timeline-content">
                                                    <div class="d-flex justify-content-between">
                                                        <h6>
                                                            <span class="badge" :class="followup.isCall ? 'bg-info' : 'bg-success'">
                                                                {{ followup.isCall ? 'Call' : 'Visit' }}
                                                            </span>
                                                        </h6>
                                                        <div class="rating">
                                                            Rating: {{ followup.rating }}%
                                                        </div>
                                                    </div>
                                                    <p><strong>Next Follow-up Date:</strong> {{ formatDate(followup.NFD) }}</p>
                                                    <div v-if="followup.meetingReport && followup.meetingReport.length">
                                                        <strong>Meeting Report:</strong>
                                                        <ul class="mb-0">
                                                            <li v-for="(report, idx) in followup.meetingReport" :key="idx">
                                                                {{ report }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Add this new modal for editing NFD -->
                    <div class="modal fade" id="editNFDModal" tabindex="-1">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Edit Next Follow-up Date</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                </div>
                                <div class="modal-body">
                                    <div v-if="selectedSiteForNFD">
                                        <div class="mb-3">
                                            <h6>{{ selectedSiteForNFD.siteName }}</h6>
                                            <p class="text-muted">{{ selectedSiteForNFD.siteUID }}</p>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Current NFD</label>
                                            <p>{{ formatDate(selectedSiteForNFD.followUps[0]?.NFD) }}</p>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">New NFD</label>
                                            <input 
                                                type="datetime-local" 
                                                class="form-control" 
                                                v-model="newNFD"
                                                :min="currentDate"
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                    <button 
                                        type="button" 
                                        class="btn btn-primary" 
                                        @click="updateNFD"
                                        :disabled="isUpdating"
                                    >
                                        <span v-if="isUpdating" class="spinner-border spinner-border-sm me-1"></span>
                                        Update NFD
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import { Modal } from 'bootstrap';

export default {
    name: 'EmployeeFollowupDetails',
    data() {
        return {
            isLoading: true,
            error: null,
            data: {
                totalSites: 0,
                totalFollowUps: 0,
                followUps: []
            },
            selectedSite: null,
            modal: null,
            selectedDate: new Date().toISOString().split('T')[0], // Today's date in YYYY-MM-DD format
            selectedSiteForNFD: null,
            newNFD: '',
            isUpdating: false,
            editNFDModal: null
        };
    },
    computed: {
        maxDate() {
            return new Date().toISOString().split('T')[0];
        },
        filteredData() {
            const selectedDateStart = new Date(this.selectedDate);
            selectedDateStart.setHours(0, 0, 0, 0);
            
            const selectedDateEnd = new Date(this.selectedDate);
            selectedDateEnd.setHours(23, 59, 59, 999);

            // Filter followups for the selected date
            const filteredFollowUps = this.data.followUps.map(site => {
                const filteredSite = { ...site };
                filteredSite.followUps = site.followUps.filter(followup => {
                    const followupDate = new Date(followup.NFD);
                    return followupDate >= selectedDateStart && followupDate <= selectedDateEnd;
                });
                return {
                    ...filteredSite,
                    totalFollowUps: filteredSite.followUps.length
                };
            }).filter(site => site.followUps.length > 0);

            return {
                totalSites: filteredFollowUps.length,
                totalFollowUps: filteredFollowUps.reduce((sum, site) => sum + site.totalFollowUps, 0),
                followUps: filteredFollowUps
            };
        },
        averageRating() {
            if (!this.filteredData.followUps.length) return 0;
            const totalRating = this.filteredData.followUps.reduce((sum, site) => {
                return sum + (site.followUps[0]?.rating || 0);
            }, 0);
            return Math.round(totalRating / this.filteredData.followUps.length);
        },
        currentDate() {
            return new Date().toISOString().slice(0, 16);
        }
    },
    methods: {
        async fetchData() {
            try {
                this.isLoading = true;
                const employeeId = this.$route.params.id;
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}api/employee/followups/${employeeId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                
                if (response.data.success) {
                    this.data = response.data.data;
                } else {
                    this.error = 'Failed to fetch follow-up data';
                }
            } catch (error) {
                this.error = error.message || 'An error occurred while fetching data';
            } finally {
                this.isLoading = false;
            }
        },
        formatDate(dateString) {
            if (!dateString) return 'N/A';
            const date = new Date(dateString);
            return date.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
        },
        getRatingClass(rating) {
            if (!rating) return 'bg-secondary';
            if (rating >= 70) return 'bg-success';
            if (rating >= 40) return 'bg-warning';
            return 'bg-danger';
        },
        showFollowupDetails(site) {
            this.selectedSite = site;
            if (!this.modal) {
                const modalEl = document.getElementById('followupModal');
                if (modalEl) {
                    this.modal = new Modal(modalEl, {
                        backdrop: true,
                        keyboard: true
                    });
                }
            }
            this.modal?.show();
        },
        formatDateDisplay(dateString) {
            if (!dateString) return 'N/A';
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        },
        downloadExcel() {
            const data = this.filteredData.followUps.map(site => {
                const latestFollowup = site.followUps[0] || {};
                return {
                    'Site Name': site.siteName,
                    'Site UID': site.siteUID,
                    'Total Follow-ups': site.totalFollowUps,
                    'Latest Rating': latestFollowup.rating + '%',
                    'Latest NFD': this.formatDate(latestFollowup.NFD),
                    'Latest Meeting Type': latestFollowup.isCall ? 'Call' : 'Visit',
                    'Meeting Report': latestFollowup.meetingReport?.join(', ') || ''
                };
            });

            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Follow-ups');
            XLSX.writeFile(wb, `employee-followups-${this.selectedDate}.xlsx`);
        },
        showEditNFD(site) {
            this.selectedSiteForNFD = site;
            this.newNFD = site.followUps[0]?.NFD ? new Date(site.followUps[0].NFD).toISOString().slice(0, 16) : '';
            
            if (!this.editNFDModal) {
                const modalEl = document.getElementById('editNFDModal');
                if (modalEl) {
                    this.editNFDModal = new Modal(modalEl, {
                        backdrop: true,
                        keyboard: true
                    });
                }
            }
            this.editNFDModal?.show();
        },
        async updateNFD() {
            if (!this.selectedSiteForNFD || !this.newNFD) return;

            try {
                this.isUpdating = true;
                const token = localStorage.getItem('token');
                const response = await axios.put(
                    `${process.env.VUE_APP_BASE_URL}api/employee/followups/${this.selectedSiteForNFD.followUps[0]._id}/nfd`,
                    {
                        NFD: this.newNFD
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );

                if (response.data.success) {
                    // Update the NFD in the local data
                    const siteIndex = this.data.followUps.findIndex(s => s.siteId === this.selectedSiteForNFD.siteId);
                    if (siteIndex !== -1 && this.data.followUps[siteIndex].followUps.length > 0) {
                        this.data.followUps[siteIndex].followUps[0].NFD = this.newNFD;
                    }

                    // Close the modal
                    this.editNFDModal?.hide();
                    this.selectedSiteForNFD = null;
                    this.newNFD = '';

                    // Show success message
                    this.$swal({
                        icon: 'success',
                        title: 'Success',
                        text: 'NFD updated successfully',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Error',
                    text: error.response?.data?.message || 'Failed to update NFD'
                });
            } finally {
                this.isUpdating = false;
            }
        }
    },
    mounted() {
        this.fetchData();
    },
    beforeUnmount() {
        if (this.modal) {
            this.modal.dispose();
            this.modal = null;
        }
        if (this.editNFDModal) {
            this.editNFDModal.dispose();
            this.editNFDModal = null;
        }
    }
};
</script>

<style scoped>
.timeline {
    position: relative;
    padding: 20px 0;
}

.timeline-item {
    position: relative;
    padding: 20px 0;
    border-left: 2px solid #e9ecef;
    margin-left: 20px;
}

.timeline-date {
    font-weight: bold;
    margin-bottom: 10px;
    color: #6c757d;
}

.timeline-content {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    margin-left: 20px;
}

.timeline-item::before {
    content: '';
    position: absolute;
    left: -8px;
    top: 24px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #7366ff;
}

.progress {
    background-color: #e9ecef;
    border-radius: 10px;
}

.progress-bar {
    border-radius: 10px;
    min-width: 2rem;
    text-align: center;
}

.form-control {
    min-width: 200px;
}

.gap-3 {
    gap: 1rem;
}
</style>
