<template>
    <!-- <Breadcrumbs main="Docs" title="Docs" /> -->
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <docs-table />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const DocsTable = defineAsyncComponent(() => import("@/components/docs/docs-table.vue"))
</script>
