<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import * as XLSX from 'xlsx';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { fetchGetApi } from '@/util/api';

const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterSite = ref("");
const filterEmployee = ref("");
const startDate = ref("");
const endDate = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/operation/get/all/exchanges').then((response) => {
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data');
        }
    });
});

function downloadExcel() {
    const dataToExport = filteredData.value.map(item => ({
        'Date': new Date(item.createdAt).toLocaleDateString(),
        'Employee Name': item.employeeName,
        'From Site': item.fromSiteName,
        'From Department': item.fromDepartmentName,
        'From Designation': item.fromDesignationName,
        'Working Hour': item.workingHour,
        'Salary': item.salary,
        'Under SIDDAR': item.isUnderSiddar ? 'Yes' : 'No',
        'To Site': item.toSiteName,
        'To Employee': item.toEmployeeName,
        'To Department': item.toDepartmentName,
        'To Designation': item.toDesignationName,
        'To Working Hour': item.toWorkingHour,
        'To Salary': item.toSalary,
        'Reason': item.reasonForExchange,
        'Status': getStatus(item),
        'Action By': getActionBy(item),
        'Action Date': getActionDate(item)
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Exchange Report");
    XLSX.writeFile(workbook, 'exchange-report.xlsx');
}

function getStatus(item) {
    if (item.isRevoked) return 'Revoked';
    if (item.isRejected) return 'Rejected';
    if (item.isApproved) return 'Approved';
    return 'Pending';
}

function getActionBy(item) {
    if (item.isRevoked) return item.revokedBy || 'N/A';
    if (item.isRejected) return item.isRejectedBy || 'N/A';
    if (item.isApproved) return item.approvedBy || 'N/A';
    return 'N/A';
}

function getActionDate(item) {
    if (item.isRevoked) return new Date(item.revokedAt).toLocaleString();
    if (item.isRejected) return new Date(item.rejectedAt).toLocaleString();
    if (item.isApproved) return new Date(item.approvedAt).toLocaleString();
    return 'N/A';
}

// Pagination functions
function num_pages() {
    return Math.ceil(filteredData.value.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) currentPage.value--;
}

function nextPage() {
    if (currentPage.value < num_pages()) currentPage.value++;
}

const filteredData = computed(() => {
    const data = Array.isArray(allData.value) ? allData.value : [];
    
    return data.filter(item => {
        // Site name filtering - check both from and to sites
        const siteSearchTerm = filterSite.value.toLowerCase().trim();
        const matchesSite = !filterSite.value || 
            item.fromSiteName?.toLowerCase().includes(siteSearchTerm) ||
            item.toSiteName?.toLowerCase().includes(siteSearchTerm);
            
        // Employee name filtering - check both employees
        const employeeSearchTerm = filterEmployee.value.toLowerCase().trim();
        const matchesEmployee = !filterEmployee.value || 
            item.employeeName?.toLowerCase().includes(employeeSearchTerm) ||
            item.toEmployeeName?.toLowerCase().includes(employeeSearchTerm);
            
        // Date filtering
        const itemDate = new Date(item.createdAt);
        let matchesDate = true;
        
        if (startDate.value && endDate.value) {
            const start = new Date(startDate.value);
            const end = new Date(endDate.value);
            
            if (startDate.value === endDate.value) {
                const itemDateString = itemDate.toISOString().split('T')[0];
                matchesDate = itemDateString === startDate.value;
            } else {
                end.setDate(end.getDate() + 1);
                matchesDate = itemDate >= start && itemDate < end;
            }
        } else if (startDate.value) {
            matchesDate = itemDate >= new Date(startDate.value);
        } else if (endDate.value) {
            matchesDate = itemDate <= new Date(endDate.value);
        }
        
        return matchesSite && matchesEmployee && matchesDate;
    });
});

const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return filteredData.value.slice(start, end);
});
</script>

<template>
    <Breadcrumbs main="Reports" title="Exchange Report" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive">
                    <div class="mb-3 row justify-content-between align-items-end">
                        <div class="col-md-2">
                            <label class="form-label">Site Name</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="filterSite"
                                placeholder="Search by site name"
                            >
                        </div>
                        <div class="col-md-2">
                            <label class="form-label">Employee Name</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="filterEmployee"
                                placeholder="Search by employee name"
                            >
                        </div>
                        <div class="col-md-2">
                            <label class="form-label">Start Date</label>
                            <input 
                                type="date" 
                                class="form-control" 
                                v-model="startDate"
                            >
                        </div>
                        <div class="col-md-2">
                            <label class="form-label">End Date</label>
                            <input 
                                type="date" 
                                class="form-control" 
                                v-model="endDate"
                                :min="startDate"
                            >
                        </div>
                        <div class="col-md-2 text-end">
                            <button type="button" class="btn btn-primary" @click="downloadExcel">
                                Download Report
                            </button>
                        </div>
                    </div>

                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th class="text-danger">SL</th>
                                <th class="text-danger">Date</th>
                                <th class="text-danger">Employee Name</th>
                                <th class="text-danger">From Site</th>
                                <th class="text-danger">From Department</th>
                                <th class="text-danger">From Designation</th>
                                <th class="text-danger">Working Hour</th>
                                <th class="text-danger">Salary</th>
                                <th class="text-danger">Under SIDDAR</th>
                                <th class="text-danger">To Site</th>
                                <th class="text-danger">To Employee</th>
                                <th class="text-danger">To Department</th>
                                <th class="text-danger">To Designation</th>
                                <th class="text-danger">To Working Hour</th>
                                <th class="text-danger">To Salary</th>
                                <th class="text-danger">Reason</th>
                                <th class="text-danger">Status</th>
                                <th class="text-danger">Action By</th>
                                <th class="text-danger">Action Date</th>
                            </tr>
                        </thead>
                        <tbody v-if="!paginatedData.length">
                            <tr>
                                <td colspan="18" class="text-center">No records found</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(item, index) in paginatedData" :key="item._id">
                                <td>{{ (currentPage - 1) * elementsPerPage + index + 1 }}</td>
                                <td>{{ new Date(item.createdAt).toLocaleDateString() }}</td>
                                <td>{{ item.employeeName }}</td>
                                <td>{{ item.fromSiteName }}</td>
                                <td>{{ item.fromDepartmentName }}</td>
                                <td>{{ item.fromDesignationName }}</td>
                                <td>{{ item.workingHour }}</td>
                                <td>{{ item.salary }}</td>
                                <td>
                                    <span 
                                        class="badge"
                                        :class="item.isUnderSiddar ? 'bg-success' : 'bg-danger'"
                                    >
                                        {{ item.isUnderSiddar ? 'Yes' : 'No' }}
                                    </span>
                                </td>
                                <td>{{ item.toSiteName }}</td>
                                <td>{{ item.toEmployeeName }}</td>
                                <td>{{ item.toDepartmentName }}</td>
                                <td>{{ item.toDesignationName }}</td>
                                <td>{{ item.toWorkingHour }}</td>
                                <td>{{ item.toSalary }}</td>
                                <td>{{ item.reasonForExchange }}</td>
                                <td>
                                    <span 
                                        class="badge"
                                        :class="{
                                            'bg-success': item.isApproved,
                                            'bg-danger': item.isRejected,
                                            'bg-secondary': item.isRevoked,
                                            'bg-warning': !item.isApproved && !item.isRejected && !item.isRevoked
                                        }"
                                    >
                                        {{ getStatus(item) }}
                                    </span>
                                </td>
                                <td>{{ getActionBy(item) }}</td>
                                <td>{{ getActionDate(item) }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-end mt-3">
                        <ul class="pagination">
                            <li class="page-item" :class="{ disabled: currentPage === 1 }" @click="previousPage">
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li 
                                v-for="page in num_pages()" 
                                :key="page" 
                                class="page-item"
                                :class="{ active: currentPage === page }"
                                @click="change_page(page)"
                            >
                                <a class="page-link" href="#">{{ page }}</a>
                            </li>
                            <li class="page-item" :class="{ disabled: currentPage === num_pages() }" @click="nextPage">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
