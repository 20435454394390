<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";

const allData = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const selectedSite = ref('');

// Updated computed property for unique sites
const uniqueSites = computed(() => {
    const sites = new Set();
    allData.value.forEach(item => {
        sites.add(item.siteName);
    });
    return Array.from(sites);
});

// Updated computed property for filtered data
const filteredData = computed(() => {
    let filtered = [...allData.value];
    if (selectedSite.value) {
        filtered = filtered.filter(item =>
            item.siteName === selectedSite.value
        );
    }
    return filtered;
});

onMounted(async () => {
    fetchData();
});

// Pagination methods
const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        fetchData();
    }
};

const previousPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        fetchData();
    }
};

const fetchData = () => {
    fetchGetApi(`api/requirement/escalation/get/all`).then((response) => {
        if (response?.data) {
            allData.value = response.data.data;
            if (response.data.pagination) {
                totalPages.value = response.data.pagination.totalPages;
            }
        }
    });
};

</script>

<template>
    <Breadcrumbs main="Apps" title="Escalation List" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="site-select" class="col-form-label">Select Site:</label>
                                <select id="site-select" class="form-select" v-model="selectedSite">
                                    <option value="">All Sites</option>
                                    <option v-for="site in uniqueSites" :key="site" :value="site">
                                        {{ site }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50px;">SL</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Site Name</th>
                                    <th scope="col">Survey Status</th>
                                    <th scope="col">Negotiation Status</th>
                                    <th scope="col">Desired Person</th>
                                    <th scope="col">Manager Name</th>
                                </tr>
                            </thead>
                            <tbody v-if="!filteredData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="7" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="odd" v-for="(row, index) in filteredData" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ row.date }}</td>
                                    <td>{{ row.siteName }}</td>
                                    <td>{{ row.isSurvey ? 'Yes' : 'No' }}</td>
                                    <td>{{ row.isNegotiation ? 'Yes' : 'No' }}</td>
                                    <td>{{ row.desiredPersonName }}</td>
                                    <td>{{ row.employeeName }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div>
                                Showing page {{ currentPage }} of {{ totalPages }}
                            </div>
                            <ul class="pagination justify-content-end pagination-primary">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" @click="previousPage" style="cursor: pointer;">Previous</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                    <a class="page-link" @click="nextPage" style="cursor: pointer;">Next</a>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal {
    display: none;
}

.modal.d-block {
    display: block !important;
}
</style>
