<template>
    <div class="header-wrapper row m-0">
        <div class="header-logo-wrapper col-auto p-0">
            <sidebar />
        </div>
        <div class="left-header col horizontal-wrapper ps-0">
            <searchView />
        </div>
        <div class="nav-right col-8 pull-right right-header p-0">
            <ul class="nav-menus">
                <!-- <li class="onhover-dropdown">
                    <bookMark />
                </li>
                <li class="language-nav">
                    <languageView />
                </li>

                <li class="onhover-dropdown">
                    <notificationView />
                </li>
                <li class="onhover-dropdown">
                    <messagesView />
                </li>
                <li>
                    <modeView />
                </li>
                <li class="maximize">
                    <maximizeView />
                </li> -->
                <li class="profile-nav onhover-dropdown p-0 me-0">
                    <profileView />
                </li>
            </ul>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const languageView = defineAsyncComponent(() => import("./languageView.vue"))
const bookMark = defineAsyncComponent(() => import("./bookmarkSearch.vue"))
const sidebar = defineAsyncComponent(() => import("./sidebarView.vue"))
const searchView = defineAsyncComponent(() => import("./searchView.vue"))
const notificationView = defineAsyncComponent(() => import("./notificationView.vue"))
const messagesView = defineAsyncComponent(() => import("./messagesView.vue"))
const modeView = defineAsyncComponent(() => import("./modeView.vue"))
const maximizeView = defineAsyncComponent(() => import('./maximizeView.vue'))
const profileView = defineAsyncComponent(() => import('./profileView.vue'))

</script>
