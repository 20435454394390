import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  class: "nav nav-tabs border-tab nav-primary",
  role: "tablist"
}
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = {
  class: "tab-content",
  id: "tab-content"
}

import { ref } from 'vue'
import LogoSettings from './logo/logo.vue'
import SiteId from './siteId/siteId.vue'
import GST from './gst/gst.vue'
import PercentageSettings from './percentage/percentage.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'general-settings',
  setup(__props) {

const activeTab = ref('logo')

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "card-header pb-0" }, [
            _createElementVNode("h5", null, "General Settings")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("a", {
                  class: _normalizeClass(["nav-link", { active: activeTab.value === 'logo' }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (activeTab.value = 'logo')),
                  href: "javascript:void(0)"
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("i", { class: "fa fa-image" }, null, -1),
                  _createTextVNode("Logo ")
                ]), 2)
              ]),
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("a", {
                  class: _normalizeClass(["nav-link", { active: activeTab.value === 'site' }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (activeTab.value = 'site')),
                  href: "javascript:void(0)"
                }, _cache[6] || (_cache[6] = [
                  _createElementVNode("i", { class: "fa fa-cog" }, null, -1),
                  _createTextVNode("Site Settings ")
                ]), 2)
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createElementVNode("a", {
                  class: _normalizeClass(["nav-link", { active: activeTab.value === 'gst' }]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (activeTab.value = 'gst')),
                  href: "javascript:void(0)"
                }, _cache[7] || (_cache[7] = [
                  _createElementVNode("i", { class: "fa fa-cog" }, null, -1),
                  _createTextVNode("GST Settings ")
                ]), 2)
              ]),
              _createElementVNode("li", _hoisted_10, [
                _createElementVNode("a", {
                  class: _normalizeClass(["nav-link", { active: activeTab.value === 'percentage' }]),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (activeTab.value = 'percentage')),
                  href: "javascript:void(0)"
                }, _cache[8] || (_cache[8] = [
                  _createElementVNode("i", { class: "fa fa-cog" }, null, -1),
                  _createTextVNode("Percentage Settings ")
                ]), 2)
              ]),
              _createElementVNode("li", _hoisted_11, [
                _createElementVNode("a", {
                  class: _normalizeClass(["nav-link", { active: activeTab.value === 'contact' }]),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (activeTab.value = 'contact')),
                  href: "javascript:void(0)"
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("i", { class: "fa fa-phone" }, null, -1),
                  _createTextVNode("Contact Info ")
                ]), 2)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", {
                class: _normalizeClass(["tab-pane fade", { 'active show': activeTab.value === 'logo' }]),
                role: "tabpanel"
              }, [
                _createVNode(LogoSettings)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["tab-pane fade", { 'active show': activeTab.value === 'site' }]),
                role: "tabpanel"
              }, [
                _createVNode(SiteId)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["tab-pane fade", { 'active show': activeTab.value === 'gst' }]),
                role: "tabpanel"
              }, [
                _createVNode(GST)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["tab-pane fade", { 'active show': activeTab.value === 'percentage' }]),
                role: "tabpanel"
              }, [
                _createVNode(PercentageSettings)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["tab-pane fade", { 'active show': activeTab.value === 'contact' }]),
                role: "tabpanel"
              }, _cache[10] || (_cache[10] = [
                _createElementVNode("h6", null, "Contact Info Content", -1)
              ]), 2)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})