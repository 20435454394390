<template>
    <Breadcrumbs main="Apps" title="Image Processing" />
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <!-- Add tabs -->
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: activeTab === 'bulk' }"
                                    @click="activeTab = 'bulk'" href="#bulk" data-bs-toggle="tab" role="tab">
                                    Bulk Processing
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: activeTab === 'single' }"
                                    @click="activeTab = 'single'" href="#single" data-bs-toggle="tab" role="tab">
                                    Single Image
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <!-- Bulk Processing Tab -->
                            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'bulk' }" id="bulk"
                                role="tabpanel">
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Upload ZIP File</label>
                                            <input type="file" class="form-control" @change="onFileSelected"
                                                accept=".zip">
                                        </div>
                                        <div v-if="fileName" class="mt-3">
                                            Selected file: {{ fileName }}
                                        </div>
                                        <div class="mt-3">
                                            <button class="btn btn-primary" @click="uploadFile"
                                                :disabled="!fileName || isUploading">
                                                {{ isUploading ? 'Uploading...' : 'Upload File' }}
                                            </button>
                                        </div>
                                        <div v-if="uploadError" class="alert alert-danger mt-3">
                                            {{ uploadError }}
                                        </div>
                                        <div v-if="downloadUrl" class="mt-3">
                                            <a :href="downloadUrl" class="btn btn-success" :download="fileName">
                                                Download Processed File
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Single Image Tab -->
                            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'single' }" id="single"
                                role="tabpanel">
                                <SingleImageProcessing />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import axios from 'axios';
import SingleImageProcessing from '@/components/image-processing/single-image-processing.vue';

const activeTab = ref('bulk');
const fileName = ref<string>('');
const selectedFile = ref<File | null>(null);
const baseUrl = process.env.VUE_APP_BASE_URL;
const isUploading = ref<boolean>(false);
const uploadError = ref<string>('');
const downloadUrl = ref<string>('');

const onFileSelected = (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
        fileName.value = file.name;
        selectedFile.value = file;
    }
};

const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result as string;
            // Remove the data URL prefix (e.g., "data:application/zip;base64,")
            resolve(base64String.split(',')[1]);
        };
        reader.onerror = (error) => reject(error);
    });
};

const uploadFile = async () => {
    if (!selectedFile.value) return;
    const originalFileName = selectedFile.value.name; // Store original filename

    try {
        isUploading.value = true;
        uploadError.value = '';
        downloadUrl.value = '';

        // Convert file to base64
        const base64Content = await convertToBase64(selectedFile.value);

        // Prepare payload according to backend requirements
        const payload = {
            fileName: originalFileName,
            fileContent: base64Content,
            fileContentLength: base64Content.length
        };

        const response = await axios.post(`${baseUrl}api/util/upload/zip/file`, payload, {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });

        // Create a URL for the downloaded file
        const blob = new Blob([response.data], { type: 'application/zip' });
        downloadUrl.value = URL.createObjectURL(blob);

        // Keep the fileName for download
        fileName.value = originalFileName;
        selectedFile.value = null;
        const input = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (input) input.value = '';

    } catch (error: any) {
        uploadError.value = error.response?.data?.message || 'Error uploading file';
    } finally {
        isUploading.value = false;
    }
};
</script>