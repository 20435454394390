<!-- src/components/site-management/sections/WorkOrderSection.vue -->
<template>
    <div class="work-order-section">
        <div class="mb-3">
            <label class="form-label">Upload Work Order Documents</label>
            <input type="file" class="form-control" @change="handleFileUpload" accept=".pdf,.doc,.docx" />
        </div>
        <div v-if="localWorkOrders.length > 0" class="mt-3">
            <h6>Uploaded Documents:</h6>
            <ul class="list-group">
                <li v-for="(file, index) in localWorkOrders" 
                    :key="index" 
                    class="list-group-item d-flex justify-content-between align-items-center">
                    {{ file.name }}
                    <button type="button" class="btn btn-danger btn-sm" @click="removeWorkOrder(index)">
                        Remove
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue';

const props = defineProps<{
    workOrders: File[];
}>();

const emit = defineEmits(['update:workOrders']);

const localWorkOrders = reactive<File[]>([...props.workOrders]);

const handleFileUpload = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        localWorkOrders.push(input.files[0]);
        emit('update:workOrders', [...localWorkOrders]);
    }
};

const removeWorkOrder = (index: number) => {
    localWorkOrders.splice(index, 1);
    emit('update:workOrders', [...localWorkOrders]);
};
</script>

<style scoped>
.file-item {
    transition: all 0.3s ease;
}

.file-item:hover {
    background-color: #f8f9fa;
}
</style>