<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";

const allData = ref([]);
const elementsPerPage = ref(30);
const currentPage = ref(1);
const totalPages = ref(1);
const selectedEmployee = ref('');
const showPhotoModal = ref(false);
const selectedPhoto = ref('');
const fromDate = ref('');
const toDate = ref('');
const showDeleteModal = ref(false);
const selectedDeleteId = ref(null);
const showSuccessModal = ref(false);
const showErrorModal = ref(false);
const errorMessage = ref('');

const baseUrl = process.env.VUE_APP_BASE_URL;

const uniqueEmployees = computed(() => {
    const employees = new Set();
    allData.value.forEach(item => {
        employees.add(item.empName);
    });
    return Array.from(employees);
});

const filteredData = computed(() => {
    let filtered = [...allData.value];
    
    // Employee filter
    if (selectedEmployee.value) {
        filtered = filtered.filter(item =>
            item.empName === selectedEmployee.value
        );
    }

    // Date range filter
    if (fromDate.value) {
        const fromDateTime = new Date(fromDate.value);
        fromDateTime.setHours(0, 0, 0, 0);
        filtered = filtered.filter(item => {
            const itemDate = new Date(item.entryDate);
            return itemDate >= fromDateTime;
        });
    }
    
    if (toDate.value) {
        const toDateTime = new Date(toDate.value);
        toDateTime.setHours(23, 59, 59, 999);
        filtered = filtered.filter(item => {
            const itemDate = new Date(item.entryDate);
            return itemDate <= toDateTime;
        });
    }
    
    return filtered.sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate));
});

const openPhotoModal = (photoUrl) => {
    selectedPhoto.value = photoUrl;
    showPhotoModal.value = true;
};

onMounted(async () => {
    fetchGetApi('api/attendance/getLateAttendanceReport').then((response) => {
        if (response?.data) {
            allData.value = response.data.data;
            if (response.data.pagination) {
                currentPage.value = response.data.pagination.currentPage;
                totalPages.value = response.data.pagination.totalPages;
                elementsPerPage.value = response.data.pagination.recordsPerPage;
            }
        }
    });
});

const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        fetchData();
    }
};

const previousPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        fetchData();
    }
};

const fetchData = () => {
    let url = `api/attendance/getLateAttendanceReport?page=${currentPage.value}`;
    
    fetchGetApi(url).then((response) => {
        if (response?.data) {
            allData.value = response.data.data;
            if (response.data.pagination) {
                totalPages.value = response.data.pagination.totalPages;
            }
        }
    });
};

const convertTo12HourFormat = (time) => {
    try {
        // Split the time string into hours, minutes, seconds
        const [hours, minutes, seconds] = time.split(':').map(Number);
        
        // Convert to 12-hour format
        let period = hours >= 12 ? 'PM' : 'AM';
        let twelveHour = hours % 12;
        twelveHour = twelveHour ? twelveHour : 12; // Convert 0 to 12
        
        // Format the time components to ensure 2 digits
        const formatNumber = (num) => num.toString().padStart(2, '0');
        
        return `${formatNumber(twelveHour)}:${formatNumber(minutes)}:${formatNumber(seconds)} ${period}`;
    } catch (error) {
        return time; // Return original time if conversion fails
    }
};

const openDeleteModal = (event, id) => {
    event.preventDefault();
    selectedDeleteId.value = id;
    showDeleteModal.value = true;
};

const confirmDelete = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/attendance/delete/${selectedDeleteId.value}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (response.ok) {
            // Remove the deleted item from the local data
            allData.value = allData.value.filter(item => item._id !== selectedDeleteId.value);
            // Close the delete modal
            showDeleteModal.value = false;
            selectedDeleteId.value = null;
            // Show success modal
            showSuccessModal.value = true;
        } else {
            // Close the delete modal
            showDeleteModal.value = false;
            // Show error modal
            errorMessage.value = 'Failed to delete attendance record';
            showErrorModal.value = true;
        }
    } catch (error) {
        console.error('Error deleting attendance:', error);
        // Close the delete modal
        showDeleteModal.value = false;
        // Show error modal
        errorMessage.value = 'Error occurred while deleting attendance record';
        showErrorModal.value = true;
    }
};
</script>

<template>
    <Breadcrumbs main="Apps" title="Late Attendance List" />

    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="employee-select" class="col-form-label">Select Employee:</label>
                                <select id="employee-select" class="form-select" v-model="selectedEmployee">
                                    <option value="">All Employees</option>
                                    <option v-for="employee in uniqueEmployees" :key="employee" :value="employee">
                                        {{ employee }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="from-date" class="col-form-label">From Date:</label>
                                <input 
                                    type="date" 
                                    id="from-date" 
                                    class="form-control" 
                                    v-model="fromDate"
                                >
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="to-date" class="col-form-label">To Date:</label>
                                <input 
                                    type="date" 
                                    id="to-date" 
                                    class="form-control" 
                                    v-model="toDate"
                                >
                            </div>
                            <div class="col-xs-3 col-sm-auto d-flex align-items-end">
                                <button 
                                    type="button" 
                                    class="btn btn-light" 
                                    @click="fromDate = ''; toDate = ''"
                                >
                                    Clear Dates
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50px;">SL</th>
                                    <th scope="col">Employee Name</th>
                                    <th scope="col">Employee Code</th>
                                    <th scope="col">Site Name</th>
                                    <th scope="col">Entry Date</th>
                                    <th scope="col">Entry Time</th>
                                    <th scope="col">Photo</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="!filteredData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="7" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="odd" v-for="(row, index) in filteredData" :key="row._id">
                                    <td>{{ ((currentPage - 1) * elementsPerPage) + index + 1 }}</td>
                                    <td>{{ row.empName }}</td>
                                    <td>{{ row.empId?.employeeCode || '-' }}</td>
                                    <td>{{ row.siteId?.siteName || '-' }}</td>
                                    <td>{{ new Date(row.entryDate).toLocaleString('en-IN', {
                                        timeZone: 'Asia/Kolkata',
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric'
                                    }) }}</td>
                                    <td>{{ convertTo12HourFormat(row.entryTime) }}</td>
                                    <td>
                                        <img v-if="row.photo" :src="row.photo" alt="Entry Photo"
                                            style="width: 50px; height: 50px; object-fit: cover; cursor: pointer;"
                                            @click="openPhotoModal(row.photo)" />
                                        <span v-else>No photo</span>
                                    </td>
                                    <td>
                                        <button 
                                            type="button"
                                            class="btn btn-danger btn-sm"
                                            @click="(event) => openDeleteModal(event, row._id)"
                                            title="Delete Attendance"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div>
                                Showing page {{ currentPage }} of {{ totalPages }}
                            </div>
                            <ul class="pagination justify-content-end pagination-primary">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" @click="previousPage" style="cursor: pointer;">Previous</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                    <a class="page-link" @click="nextPage" style="cursor: pointer;">Next</a>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- Photo Modal -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showPhotoModal }" style="background-color: rgba(0,0,0,0.5);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Attendance Photo</h5>
                    <button type="button" class="btn-close" @click="showPhotoModal = false"></button>
                </div>
                <div class="modal-body text-center">
                    <img :src="selectedPhoto" alt="Attendance Photo" style="max-width: 100%; max-height: 70vh;" />
                </div>
            </div>
        </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showDeleteModal }" style="background-color: rgba(0,0,0,0.5);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm Delete</h5>
                    <button type="button" class="btn-close" @click="showDeleteModal = false"></button>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete this attendance record?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showDeleteModal = false">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="confirmDelete">Delete</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Success Modal -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showSuccessModal }" style="background-color: rgba(0,0,0,0.5);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-success text-white">
                    <h5 class="modal-title">Success</h5>
                    <button type="button" class="btn-close" @click="showSuccessModal = false"></button>
                </div>
                <div class="modal-body">
                    <p>Attendance record deleted successfully!</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" @click="showSuccessModal = false">OK</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Error Modal -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showErrorModal }" style="background-color: rgba(0,0,0,0.5);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-danger text-white">
                    <h5 class="modal-title">Error</h5>
                    <button type="button" class="btn-close" @click="showErrorModal = false"></button>
                </div>
                <div class="modal-body">
                    <p>{{ errorMessage }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" @click="showErrorModal = false">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal {
    display: none;
}

.modal.d-block {
    display: block !important;
}
</style>
