<!-- src/components/site-management/sections/WorkforceDetails.vue -->
<template>
    <div class="workforce-details">
        <!-- Common Inputs -->
        <div class="row mb-4">
            <div class="col-md-6">
                <label class="form-label">Total Manpower</label>
                <input type="number" class="form-control" :value="calculateTotalManpower" readonly />
            </div>
            <div class="col-md-6">
                <label class="form-label">Working Place*</label>
                <input type="text" class="form-control" v-model="localFormData.workingPlace" required />
            </div>
        </div>

        <!-- Designation Selection -->
        <div class="designation-selection mb-4" v-if="designationList.length">
            <label class="form-label">Select Services*</label>
            <div class="multiselect-wrapper">
                <div class="selected-options" @click="showServiceDropdown = !showServiceDropdown">
                    <div class="selected-tags">
                        <span v-if="selectedServices.length === 0" class="placeholder">
                            Choose Services
                        </span>
                        <span v-for="service in selectedServices" :key="service" class="selected-tag">
                            {{ service }}
                            <button type="button" class="remove-tag" @click.stop="removeService(service)">
                                ×
                            </button>
                        </span>
                    </div>
                    <span class="dropdown-arrow">▼</span>
                </div>
                <div v-if="showServiceDropdown" class="options-dropdown">
                    <div v-for="service in designationList" :key="service._id" class="option"
                        @click="toggleService(service.name)">
                        <input type="checkbox" :checked="selectedServices.includes(service.name)"
                            @click.stop>
                        <span>{{ service.name }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sub-Designation Details -->
        <template v-if="selectedServices.length">
            <div v-for="service in selectedServices" :key="service" class="service-section mb-4">
                <h6>{{ service }}</h6>
                <!-- Designation Selection for each Service -->
                <div class="designation-selection mb-4" v-if="subDesignationList[service]?.length">
                    <label class="form-label">Select Designations for {{ service }}*</label>
                    <div class="multiselect-wrapper">
                        <div class="selected-options" @click="toggleDesignationDropdown(service)">
                            <div class="selected-tags">
                                <span v-if="!selectedSubDesignations[service]?.length" class="placeholder">
                                    Choose Designations
                                </span>
                                <span v-for="designation in selectedSubDesignations[service] || []" :key="designation" class="selected-tag">
                                    {{ designation }}
                                    <button type="button" class="remove-tag" @click.stop="removeDesignation(service, designation)">
                                        ×
                                    </button>
                                </span>
                            </div>
                            <span class="dropdown-arrow">▼</span>
                        </div>
                        <div v-if="showDesignationDropdown[service]" class="options-dropdown">
                            <div v-for="designation in subDesignationList[service]" :key="designation._id" class="option"
                                @click="toggleDesignation(service, designation.name)">
                                <input type="checkbox" :checked="selectedSubDesignations[service]?.includes(designation.name)"
                                    @click.stop>
                                <span>{{ designation.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Designation Details -->
                <template v-if="selectedSubDesignations[service]?.length">
                    <div v-for="designation in selectedSubDesignations[service]" :key="designation"
                        class="designation-details-section mb-4">
                        <h6>{{ designation }} Details</h6>
                        <SubDesignationDetails :designation="designation"
                            v-model:details="localFormData.subDesignationDetails[`${service}-${designation}`]" />
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch, ref, onMounted, computed } from 'vue';
import SubDesignationDetails from './sub-designation.vue';
import { fetchPostApi, fetchGetApi } from '@/util/api';
import axios from 'axios';



const props = defineProps<{
    formData: {
        workingPlace: string;
        services: Array<{
            serviceName: string;
            designations: Array<{
                designationName: string;
                details: {
                    manpower: { male: number; female: number };
                    weeklyOff: { male: boolean; female: boolean };
                    workingHours: { male: number; female: number };
                    esiEpf: { male: boolean; female: boolean };
                    food: { male: boolean; female: boolean };
                    accommodation: { male: boolean; female: boolean };
                    deadline: { male: string; female: string };
                    inHandSalary: { male: number; female: number };
                    quotedRate: { male: number; female: number };
                    serviceStartDate: { male: string; female: string };
                };
            }>;
        }>;
        subDesignationDetails: Record<string, any>;
    };
}>();

const emit = defineEmits(['update:formData']);

const localFormData = reactive({
    workingPlace: props.formData.workingPlace || '',
    services: [...(props.formData.services || [])],
    subDesignationDetails: { ...props.formData.subDesignationDetails }
});

interface Designation {
    _id: string;
    name: string;
    image: string;
}

interface SubDesignation {
    _id: string;
    serviceId: string;
    name: string;
    description: string;
}

interface Service {
    _id: string;
    name: string;
    description?: string;
    // Add other service fields as needed
}

const designationList = ref<Designation[]>([]);
const selectedDesignation = ref('');
const subDesignationList = ref<Record<string, SubDesignation[]>>({});
const selectedSubDesignations = ref<Record<string, string[]>>({});
const servicesList = ref<Service[]>([]);
const selectedServices = ref<string[]>([]);

const showCustomPosition = ref(false);
const customPositionName = ref('');

const showServiceDropdown = ref(false);
const showDesignationDropdown = ref<Record<string, boolean>>({});

const baseUrl = process.env.VUE_APP_BASE_URL;
const fetchDesignation = async () => {
    try {
        const response = await axios.get(`${baseUrl}api/designation/getAllServices`);
        const designations: Designation[] = response.data;
        designationList.value = designations;

        // Initialize empty records for each service
        const emptySubDesignations: Record<string, string[]> = {};
        const emptyDesignationList: Record<string, SubDesignation[]> = {};
        const emptyDropdownState: Record<string, boolean> = {};
        
        designations.forEach(d => {
            emptySubDesignations[d.name] = [];
            emptyDesignationList[d.name] = [];
            emptyDropdownState[d.name] = false;
        });
        
        selectedSubDesignations.value = emptySubDesignations;
        subDesignationList.value = emptyDesignationList;
        showDesignationDropdown.value = emptyDropdownState;
        
        // Restore previous selection if it exists
        if (props.formData.services && props.formData.services.length > 0) {
            selectedServices.value = props.formData.services.map(s => s.serviceName);
            // Fetch designations for each service
            for (const service of selectedServices.value) {
                const serviceObj = designations.find(d => d.name === service);
                if (serviceObj) {
                    await fetchSubDesignationByDesignationId(serviceObj._id, service);
                }
            }
        }
    } catch (error) {
        console.error("Error fetching designations:", error);
    }
};

const fetchSubDesignationByDesignationId = async (serviceId: string, serviceName: string) => {
    try {
        const response = await axios.get(`${baseUrl}api/designation/get/by/service/id/${serviceId}`);
        if (response.data) {
            subDesignationList.value[serviceName] = response.data;
        } else {
            console.error("Error in fetching designations");
            subDesignationList.value[serviceName] = [];
        }
    } catch (error) {
        console.error("Error fetching designations:", error);
        subDesignationList.value[serviceName] = [];
    }
};

const addCustomPosition = () => {
    if (customPositionName.value.trim()) {
        localFormData.subDesignationDetails[customPositionName.value] = {
            manpower: { male: 0, female: 0 },
            weeklyOff: { male: false, female: false },
            workingHours: { male: 0, female: 0 },
            esiEpf: { male: false, female: false },
            food: { male: false, female: false },
            accommodation: { male: false, female: false },
            deadline: { male: '', female: '' },
            inHandSalary: { male: 0, female: 0 },
            quotedRate: { male: 0, female: 0 },
            serviceStartDate: { male: '', female: '' }
        };
        customPositionName.value = '';
    }
};

const initializeSubDesignationDetails = (designations: string[]) => {
    const details: Record<string, any> = {};
    designations.forEach(designation => {
        if (!localFormData.subDesignationDetails[designation]) {
            details[designation] = {
                manpower: { male: 0, female: 0 },
                weeklyOff: { male: false, female: false },
                workingHours: { male: 0, female: 0 },
                esiEpf: { male: false, female: false },
                food: { male: false, female: false },
                accommodation: { male: false, female: false },
                deadline: { male: '', female: '' },
                inHandSalary: { male: 0, female: 0 },
                quotedRate: { male: 0, female: 0 },
                serviceStartDate: { male: '', female: '' }
            };
        } else {
            details[designation] = localFormData.subDesignationDetails[designation];
        }
    });
    return details;
};

const fetchServicesBySubDesignation = async (subDesignationId: string) => {
    try {
        const response = await axios.get(`${baseUrl}api/services/by-sub-designation/${subDesignationId}`);
        if (response.data.success) {
            return response.data.data;
        }
        return [];
    } catch (error) {
        console.error("Error fetching services:", error);
        return [];
    }
};

const toggleService = async (name: string) => {
    const index = selectedServices.value.indexOf(name);
    if (index === -1) {
        selectedServices.value.push(name);
        // Fetch designations for this service
        const selectedServiceObj = designationList.value.find(d => d.name === name);
        if (selectedServiceObj) {
            await fetchSubDesignationByDesignationId(selectedServiceObj._id, name);
        }
    } else {
        selectedServices.value.splice(index, 1);
        // Clean up related data
        delete selectedSubDesignations.value[name];
        delete subDesignationList.value[name];
    }
};

const removeService = (name: string) => {
    const index = selectedServices.value.indexOf(name);
    if (index !== -1) {
        // Remove service from selected services
        selectedServices.value.splice(index, 1);
        
        // Clean up related designations
        if (selectedSubDesignations.value[name]) {
            // Remove all subDesignationDetails for this service
            selectedSubDesignations.value[name].forEach(designation => {
                const detailsKey = `${name}-${designation}`;
                delete localFormData.subDesignationDetails[detailsKey];
            });
            delete selectedSubDesignations.value[name];
        }
        
        delete subDesignationList.value[name];
        
        // Update services array
        localFormData.services = localFormData.services.filter(s => s.serviceName !== name);
    }
};

const toggleDesignationDropdown = (service: string) => {
    showDesignationDropdown.value[service] = !showDesignationDropdown.value[service];
};

const toggleDesignation = (service: string, designation: string) => {
    if (!selectedSubDesignations.value[service]) {
        selectedSubDesignations.value[service] = [];
    }

    const index = selectedSubDesignations.value[service].indexOf(designation);
    if (index === -1) {
        selectedSubDesignations.value[service].push(designation);
        // Initialize the subDesignationDetails for this service-designation pair
        const detailsKey = `${service}-${designation}`;
        if (!localFormData.subDesignationDetails[detailsKey]) {
            localFormData.subDesignationDetails[detailsKey] = {
                manpower: { male: 0, female: 0 },
                weeklyOff: { male: false, female: false },
                workingHours: { male: 0, female: 0 },
                esiEpf: { male: false, female: false },
                food: { male: false, female: false },
                accommodation: { male: false, female: false },
                deadline: { male: '', female: '' },
                inHandSalary: { male: 0, female: 0 },
                quotedRate: { male: 0, female: 0 },
                serviceStartDate: { male: '', female: '' }
            };
        }
    } else {
        selectedSubDesignations.value[service].splice(index, 1);
        // Clean up the subDesignationDetails when removing a designation
        const detailsKey = `${service}-${designation}`;
        delete localFormData.subDesignationDetails[detailsKey];
    }
};

const removeDesignation = (service: string, designation: string) => {
    const index = selectedSubDesignations.value[service]?.indexOf(designation);
    if (index !== -1) {
        // Remove designation from selected designations
        selectedSubDesignations.value[service].splice(index, 1);
        
        // Clean up subDesignationDetails
        const detailsKey = `${service}-${designation}`;
        delete localFormData.subDesignationDetails[detailsKey];
        
        // Update services array
        const serviceIndex = localFormData.services.findIndex(s => s.serviceName === service);
        if (serviceIndex !== -1) {
            localFormData.services[serviceIndex].designations = 
                localFormData.services[serviceIndex].designations.filter(d => d.designationName !== designation);
        }
    }
};

watch(selectedSubDesignations, (newVal) => {
    const services: Array<{
        serviceName: string;
        designations: Array<{
            designationName: string;
            details: any;
        }>;
    }> = [];

    Object.entries(newVal).forEach(([service, designations]) => {
        if (designations && designations.length > 0) {
            const serviceDesignations = designations.map(designation => {
                const detailsKey = `${service}-${designation}`;
                const details = localFormData.subDesignationDetails[detailsKey];
                
                if (!details) {
                    // Initialize details if they don't exist
                    localFormData.subDesignationDetails[detailsKey] = {
                        manpower: { male: 0, female: 0 },
                        weeklyOff: { male: false, female: false },
                        workingHours: { male: 0, female: 0 },
                        esiEpf: { male: false, female: false },
                        food: { male: false, female: false },
                        accommodation: { male: false, female: false },
                        deadline: { male: '', female: '' },
                        inHandSalary: { male: 0, female: 0 },
                        quotedRate: { male: 0, female: 0 },
                        serviceStartDate: { male: '', female: '' }
                    };
                }

                return {
                    designationName: designation,
                    details: localFormData.subDesignationDetails[detailsKey]
                };
            });

            services.push({
                serviceName: service,
                designations: serviceDesignations
            });
        }
    });

    localFormData.services = services;
}, { deep: true });

const calculateTotalManpower = computed(() => {
    let total = 0;
    try {
        localFormData.services.forEach(service => {
            service.designations.forEach(designation => {
                const detailsKey = `${service.serviceName}-${designation.designationName}`;
                const details = localFormData.subDesignationDetails[detailsKey];
                if (details?.manpower) {
                    const male = Number(details.manpower.male) || 0;
                    const female = Number(details.manpower.female) || 0;
                    total += male + female;
                }
            });
        });
    } catch (error) {
        console.error('Error calculating total manpower:', error);
    }
    return total;
});

watch(localFormData, (newVal) => {
    const formattedData = {
        workingPlace: newVal.workingPlace,
        services: newVal.services,
        subDesignationDetails: newVal.subDesignationDetails,
        totalManpower: calculateTotalManpower.value
    };
    
    emit('update:formData', formattedData);
}, { deep: true });

onMounted(() => {
    fetchDesignation();

    document.addEventListener('click', (e) => {
        const target = e.target as HTMLElement;
        if (!target.closest('.multiselect-wrapper')) {
            showServiceDropdown.value = false;
            Object.keys(showDesignationDropdown.value).forEach(service => {
                showDesignationDropdown.value[service] = false;
            });
        }
    });
});
</script>

<style scoped>
.form-select {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.sub-designation-section {
    border: 1px solid #dee2e6;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.custom-position {
    border-top: 1px solid #dee2e6;
    padding-top: 1rem;
}

select[multiple] {
    min-height: 120px;
}

.text-muted {
    display: block;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #6c757d;
}

.services-section {
    margin-top: 2rem;
}

.service-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.service-item .card {
    height: 100%;
    transition: box-shadow 0.3s ease;
}

.service-item .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-details {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #dee2e6;
}

.multiselect-wrapper {
    position: relative;
    width: 100%;
}

.selected-options {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    min-height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: #fff;
}

.selected-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    flex: 1;
}

.selected-tag {
    background-color: #e9ecef;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.remove-tag {
    background: none;
    border: none;
    padding: 0 0.25rem;
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 1;
    color: #6c757d;
}

.remove-tag:hover {
    color: #dc3545;
}

.dropdown-arrow {
    color: #6c757d;
    font-size: 0.875rem;
}

.options-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-top: 0.25rem;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.option {
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.option:hover {
    background-color: #f8f9fa;
}

.placeholder {
    color: #6c757d;
}
</style>