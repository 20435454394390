<template>
    <Breadcrumbs main="Docs" title="Pending Docs" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <pending-docs-main />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const PendingDocsMain = defineAsyncComponent(() => import("@/components/pending-docs/pending-docs-main.vue"))
</script>