<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi, fetchPatchApi } from "@/util/api";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterSite = ref("");
const filterEmployee = ref("");
const startDate = ref("");
const endDate = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/operation/get/all/transfers').then((response) => {
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
            console.log('Initial data:', allData.value);
        } else {
            toast.error('Failed to fetch data');
            console.log(response);
        }
    });
});

function downloadExcel() {
    const dataToExport = allData.value.map(item => ({
        'Date': new Date(item.createdAt).toLocaleDateString(),
        'Employee Name': item.employeeName,
        'From Site': item.fromSiteName,
        'From Department': item.fromDepartmentName,
        'From Designation': item.fromDesignationName,
        'Working Hour': item.workingHour,
        'Salary': item.salary,
        'Under SIDDAR': item.isUnderSiddar ? 'Yes' : 'No',
        'To Site': item.toSiteName,
        'To Working Hour': item.toWorkingHour,
        'To Salary': item.toSalary,
        'To Designation': item.toDesignationName,
        'Status': item.isRevoked ? 'Revoked' : (item.isRejected ? 'Rejected' : (item.isApproved ? 'Approved' : 'Pending')),
        'Action Date': item.revokedAt ? new Date(item.revokedAt).toLocaleString() : 
                      (item.rejectedAt ? new Date(item.rejectedAt).toLocaleString() : 
                      (item.approvedAt ? new Date(item.approvedAt).toLocaleString() : 'N/A'))
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transfers");
    XLSX.writeFile(workbook, 'transfers.xlsx');
}

function num_pages() {
    const data = Array.isArray(allData.value) ? allData.value : [];
    
    const filteredLength = data.filter(item => {
        // Site name filtering
        const siteSearchTerm = filterSite.value.toLowerCase().trim();
        const matchesSite = !filterSite.value || 
            item.fromSiteName?.toLowerCase().includes(siteSearchTerm) ||
            item.toSiteName?.toLowerCase().includes(siteSearchTerm);
            
        // Employee name filtering
        const employeeSearchTerm = filterEmployee.value.toLowerCase().trim();
        const matchesEmployee = !filterEmployee.value || 
            item.employeeName?.toLowerCase().includes(employeeSearchTerm);
            
        // Date filtering
        const itemDate = new Date(item.createdAt);
        let matchesDate = true;
        
        if (startDate.value && endDate.value) {
            const start = new Date(startDate.value);
            const end = new Date(endDate.value);
            
            if (startDate.value === endDate.value) {
                const itemDateString = itemDate.toISOString().split('T')[0];
                matchesDate = itemDateString === startDate.value;
            } else {
                end.setDate(end.getDate() + 1);
                matchesDate = itemDate >= start && itemDate < end;
            }
        } else if (startDate.value) {
            matchesDate = itemDate >= new Date(startDate.value);
        } else if (endDate.value) {
            matchesDate = itemDate <= new Date(endDate.value);
        }
        
        return matchesSite && matchesEmployee && matchesDate;
    }).length;
    
    return Math.ceil(filteredLength / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const filteredData = computed(() => {
    const data = Array.isArray(allData.value) ? allData.value : [];
    
    return data.filter(item => {
        // Site name filtering
        const siteSearchTerm = filterSite.value.toLowerCase().trim();
        const matchesSite = !filterSite.value || 
            item.fromSiteName?.toLowerCase().includes(siteSearchTerm) ||
            item.toSiteName?.toLowerCase().includes(siteSearchTerm);
            
        // Employee name filtering
        const employeeSearchTerm = filterEmployee.value.toLowerCase().trim();
        const matchesEmployee = !filterEmployee.value || 
            item.employeeName?.toLowerCase().includes(employeeSearchTerm);
            
        // Date filtering
        const itemDate = new Date(item.createdAt);
        let matchesDate = true;
        
        if (startDate.value && endDate.value) {
            const start = new Date(startDate.value);
            const end = new Date(endDate.value);
            
            if (startDate.value === endDate.value) {
                const itemDateString = itemDate.toISOString().split('T')[0];
                matchesDate = itemDateString === startDate.value;
            } else {
                end.setDate(end.getDate() + 1);
                matchesDate = itemDate >= start && itemDate < end;
            }
        } else if (startDate.value) {
            matchesDate = itemDate >= new Date(startDate.value);
        } else if (endDate.value) {
            matchesDate = itemDate <= new Date(endDate.value);
        }
        
        return matchesSite && matchesEmployee && matchesDate;
    }).slice((currentPage.value - 1) * elementsPerPage.value, currentPage.value * elementsPerPage.value);
});

async function approveTransfer(transferId) {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to approve this transfer? This will update the employee\'s position.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Approve',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const response = await fetchPatchApi('api/operation/transfer/approve', {
                transferId: transferId
            });

            if (response && response.data && response.data.transfer) {
                const index = allData.value.findIndex(item => item._id === transferId);
                if (index !== -1) {
                    allData.value[index] = {
                        ...allData.value[index],
                        ...response.data.transfer
                    };
                }
                
                toast.success('Transfer approved and employee details updated successfully');
            }
        }
    } catch (error) {
        toast.error('Failed to approve transfer: ' + (error.message || 'Unknown error'));
        console.error(error);
    }
}

async function rejectTransfer(transferId) {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to reject this transfer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Reject',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const response = await fetchPatchApi('api/operation/transfer/reject', {
                transferId: transferId
            });

            if (response && response.data && response.data.transfer) {
                const index = allData.value.findIndex(item => item._id === transferId);
                if (index !== -1) {
                    allData.value[index] = {
                        ...allData.value[index],
                        ...response.data.transfer
                    };
                }
                
                toast.success('Transfer rejected successfully');
            }
        }
    } catch (error) {
        toast.error('Failed to reject transfer: ' + (error.message || 'Unknown error'));
        console.error(error);
    }
}

async function revokeTransfer(transferId) {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to revoke this transfer? This will revert the employee to their original position.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Revoke',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const response = await fetchPatchApi('api/operation/transfer/revoke', {
                transferId: transferId
            });

            if (response && response.data && response.data.transfer) {
                const index = allData.value.findIndex(item => item._id === transferId);
                if (index !== -1) {
                    allData.value[index] = {
                        ...allData.value[index],
                        ...response.data.transfer
                    };
                }
                
                toast.success('Transfer revoked successfully');
            }
        }
    } catch (error) {
        toast.error('Failed to revoke transfer: ' + (error.message || 'Unknown error'));
        console.error(error);
    }
}

function getStatus(item) {
    if (item.isRevoked) return 'Revoked';
    if (item.isRejected) return 'Rejected';
    if (item.isApproved) return 'Approved';
    return 'Pending';
}

function getActionBy(item) {
    if (item.isRevoked) return item.revokedBy || 'N/A';
    if (item.isRejected) return item.rejectedBy || 'N/A';
    if (item.isApproved) return item.approvedBy || 'N/A';
    return 'N/A';
}

function getActionDate(item) {
    if (item.isRevoked) return new Date(item.revokedAt).toLocaleString();
    if (item.isRejected) return new Date(item.rejectedAt).toLocaleString();
    if (item.isApproved) return new Date(item.approvedAt).toLocaleString();
    return 'N/A';
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Transfer" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer" @submit.prevent>
                        <div class="mb-3 row justify-content-between align-items-end">
                            <div class="col-md-2">
                                <label class="form-label">Site Name</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="filterSite"
                                    placeholder="Search by site name"
                                >
                            </div>
                            <div class="col-md-2">
                                <label class="form-label">Employee Name</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="filterEmployee"
                                    placeholder="Search by employee name"
                                >
                            </div>
                            <div class="col-md-2">
                                <label class="form-label">Start Date</label>
                                <input 
                                    type="date" 
                                    class="form-control" 
                                    v-model="startDate"
                                >
                            </div>
                            <div class="col-md-2">
                                <label class="form-label">End Date</label>
                                <input 
                                    type="date" 
                                    class="form-control" 
                                    v-model="endDate"
                                    :min="startDate"
                                >
                            </div>
                            <div class="col-md-2 text-end">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">
                                    Download Excel
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Employee Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">From Site</th>
                                    <th class="sorting_asc text-nowrap" scope="col">From Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">From Designation</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Working Hour</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Salary</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Under SIDDAR</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Site</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Working Hour</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Salary</th>
                                    <th class="sorting_asc text-nowrap" scope="col">To Designation</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="!filteredData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="13" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, index) in filteredData" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.employeeName }}</td>
                                    <td>{{ item.fromSiteName }}</td>
                                    <td>{{ item.fromDepartmentName }}</td>
                                    <td>{{ item.fromDesignationName }}</td>
                                    <td>{{ item.workingHour }}</td>
                                    <td>{{ item.salary }}</td>
                                    <td>
                                        <span 
                                            class="badge"
                                            :class="item.isUnderSiddar ? 'bg-success' : 'bg-danger'"
                                        >
                                            {{ item.isUnderSiddar ? 'Yes' : 'No' }}
                                        </span>
                                    </td>
                                    <td>{{ item.toSiteName }}</td>
                                    <td>{{ item.toWorkingHour }}</td>
                                    <td>{{ item.toSalary }}</td>
                                    <td>{{ item.toDesignationName }}</td>
                                    <td>
                                        <div class="d-flex gap-2">
                                            <template v-if="!item.isApproved && !item.isRejected && !item.isRevoked">
                                                <button 
                                                    class="btn btn-sm btn-success"
                                                    @click="approveTransfer(item._id)"
                                                >
                                                    Approve
                                                </button>
                                                <button 
                                                    class="btn btn-sm btn-danger"
                                                    @click="rejectTransfer(item._id)"
                                                >
                                                    Reject
                                                </button>
                                            </template>

                                            <button 
                                                v-if="item.isApproved && !item.isRevoked && !item.isRejected"
                                                class="btn btn-sm btn-warning"
                                                @click="revokeTransfer(item._id)"
                                            >
                                                Revoke
                                            </button>

                                            <span 
                                                v-if="item.isRevoked" 
                                                class="badge bg-secondary"
                                            >
                                                Revoked at {{ new Date(item.revokedAt).toLocaleString() }}
                                            </span>
                                            <span 
                                                v-else-if="item.isRejected" 
                                                class="badge bg-danger"
                                            >
                                                Rejected by {{ item.rejectedBy }} at {{ new Date(item.rejectedAt).toLocaleString() }}
                                            </span>
                                            <span 
                                                v-else-if="item.isApproved" 
                                                class="badge bg-success"
                                            >
                                                Approved at {{ new Date(item.approvedAt).toLocaleString() }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>