import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notification-permission"
}

import { computed } from 'vue'
import { useWorkApprovalStore } from '@/store/workApproval'
import { initializeAudio } from '@/utils/audio'


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationPermission',
  setup(__props) {

const store = useWorkApprovalStore()

const alertClass = computed(() => {
  switch (store.notificationStatus) {
    case 'unsupported':
      return 'alert-warning'
    case 'denied':
      return 'alert-danger'
    default:
      return 'alert-info'
  }
})

async function enableNotifications() {
  try {
    // Initialize audio first since we have user interaction
    await initializeAudio()
    console.log('Audio initialized successfully')
    
    // Then enable notifications
    const granted = await store.initializeNotifications()
    console.log('Notifications initialized:', granted)
    
    if (!granted) {
      console.warn('Failed to get notification permission')
    }
  } catch (error) {
    console.error('Error enabling notifications:', error)
  }
}

// Try to initialize audio on component mount
initializeAudio().catch(error => {
  console.warn('Initial audio initialization failed:', error)
})

return (_ctx: any,_cache: any) => {
  return (_unref(store).notificationStatus !== 'granted')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["alert", alertClass.value]),
          role: "alert"
        }, [
          (_unref(store).notificationStatus === 'unsupported')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa fa-exclamation-triangle" }, null, -1)),
                _cache[1] || (_cache[1] = _createTextVNode(" Your browser doesn't support notifications "))
              ], 64))
            : (_unref(store).notificationStatus === 'denied')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa fa-bell-slash" }, null, -1)),
                  _cache[3] || (_cache[3] = _createTextVNode(" Notifications are blocked. Please enable them in your browser settings to receive updates. "))
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa fa-bell" }, null, -1)),
                  _cache[5] || (_cache[5] = _createTextVNode(" Enable notifications to stay updated about new pending approvals ")),
                  _createElementVNode("button", {
                    class: "btn btn-sm btn-primary ms-2",
                    onClick: enableNotifications
                  }, " Enable Notifications ")
                ], 64))
        ], 2)
      ]))
    : _createCommentVNode("", true)
}
}

})