import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row mt-4" }
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["href", "download"]
const _hoisted_8 = {
  key: 1,
  class: "alert alert-danger mt-3"
}
const _hoisted_9 = {
  key: 2,
  class: "mt-3"
}
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = ["src"]

import { ref } from 'vue';
import axios from 'axios';


export default /*@__PURE__*/_defineComponent({
  __name: 'single-image-processing',
  setup(__props) {

const fileName = ref<string>('');
const processedFileName = ref<string>('');
const selectedFile = ref<File | null>(null);
const baseUrl = process.env.VUE_APP_BASE_URL;
const isUploading = ref<boolean>(false);
const uploadError = ref<string>('');
const processedImageUrl = ref<string>('');

const onFileSelected = (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
        fileName.value = file.name;
        const time = new Date().getTime();
        const nameParts = file.name.split('.');
        processedFileName.value = `${nameParts[0]}_processed_${time}.${nameParts[nameParts.length - 1]}`;
        selectedFile.value = file;
        processedImageUrl.value = '';
    }
};

const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result as string;
            resolve(base64String.split(',')[1]);
        };
        reader.onerror = (error) => reject(error);
    });
};

const token = localStorage.getItem('token');

const uploadFile = async () => {
    if (!selectedFile.value) return;

    try {
        isUploading.value = true;
        uploadError.value = '';
        processedImageUrl.value = '';

        const base64Content = await convertToBase64(selectedFile.value);

        const payload = {
            fileName: selectedFile.value.name,
            fileContent: base64Content,
            fileContentLength: base64Content.length
        };

        const response = await axios.post(`${baseUrl}api/util/upload/single/image`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: 'image/jpeg' });
        processedImageUrl.value = URL.createObjectURL(blob);

        fileName.value = '';
        selectedFile.value = null;
        const input = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (input) input.value = '';

    } catch (error: any) {
        uploadError.value = error.response?.data?.message || 'Error processing image';
    } finally {
        isUploading.value = false;
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("label", null, "Upload Image", -1)),
        _createElementVNode("input", {
          type: "file",
          class: "form-control",
          onChange: onFileSelected,
          accept: "image/*"
        }, null, 32)
      ]),
      (fileName.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Selected file: " + _toDisplayString(fileName.value), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn btn-primary me-2",
          onClick: uploadFile,
          disabled: !fileName.value || isUploading.value
        }, _toDisplayString(isUploading.value ? 'Processing...' : 'Process Image'), 9, _hoisted_6),
        (processedImageUrl.value)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: processedImageUrl.value,
              class: "btn btn-success",
              download: processedFileName.value
            }, " Download Processed Image ", 8, _hoisted_7))
          : _createCommentVNode("", true)
      ]),
      (uploadError.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(uploadError.value), 1))
        : _createCommentVNode("", true),
      (processedImageUrl.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("img", {
                src: processedImageUrl.value,
                alt: "Processed Image",
                class: "img-fluid",
                style: {"max-width":"500px"}
              }, null, 8, _hoisted_11)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})