import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "table-responsive product-table custom-scrollbar" }
const _hoisted_5 = { class: "row align-items-center mb-4" }
const _hoisted_6 = { class: "col-md-3" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "col-md-4" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "d-flex gap-2" }
const _hoisted_11 = { class: "col-md-3" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "table-wrapper mt-4" }
const _hoisted_14 = {
  class: "display table table-striped table-hover table-bordered",
  id: "docs-table"
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }

import { ref, onMounted, watch } from 'vue';
import { format } from 'date-fns';
import { fetchGetApi } from '@/util/api';

interface Document {
    _id: string;
    docketNumber: string;
    employeeCode: string;
    candidateName: string;
    siteName: string;
    designation: string;
    documentsInCustody: Array<{
        _id: string;
        documentType: string;
        dateReceived: string;
        returnDate: string;
        status: string;
        returnProof: {
            photo: { url: string };
            signature: { url: string };
        };
        remarks: string;
    }>;
}

// Filters state

export default /*@__PURE__*/_defineComponent({
  __name: 'return-documents',
  setup(__props) {

const filters = ref({
    siteName: '',
    dateFrom: '',
    dateTo: '',
    employeeName: ''
});

const documents = ref<Document[]>([]);
const loading = ref(false);
const baseUrl = process.env.VUE_APP_BASE_URL;

const formatDate = (dateString: string) => {
    if (!dateString) return '-';
    return format(new Date(dateString), 'dd/MM/yyyy');
};

const fetchDocuments = async () => {
    try {
        loading.value = true;
        const response = await fetchGetApi(`api/document-custody/returned/all`);
        console.log('API Response:', response.data); // Debug log
        if (response.data && response.data.data) {
            documents.value = response.data.data;
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
    } finally {
        loading.value = false;
    }
};

const getStatusClass = (status: string) => {
    return {
        'badge rounded-pill bg-success': status === 'Returned',
        'badge rounded-pill bg-warning': status === 'Pending',
        'badge rounded-pill bg-info': status === 'In Progress'
    };
};

const viewDetails = (doc: Document) => {
    // Implement view details functionality
    console.log('Viewing details for:', doc);
};

onMounted(() => {
    fetchDocuments();
});

const handleSearch = () => {
    // Add any additional search logic here if needed
    console.log('Searching with filters:', filters.value);
};

// Add a debug watcher to see when documents change
watch(documents, (newVal) => {
    console.log('Documents updated:', newVal);
}, { deep: true });

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Reports",
      title: "Return Documents Report"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h5", null, "Return Documents Report"),
          _createElementVNode("span", null, "View and manage document returns across all sites")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("form", {
              class: "dataTables_wrapper no-footer",
              onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "Search by Site Name", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value.siteName) = $event)),
                      placeholder: "Enter site name..."
                    }, null, 512), [
                      [_vModelText, filters.value.siteName]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label" }, "Search by Date Range", -1)),
                    _createElementVNode("div", _hoisted_10, [
                      _withDirectives(_createElementVNode("input", {
                        type: "date",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filters.value.dateFrom) = $event)),
                        placeholder: "From"
                      }, null, 512), [
                        [_vModelText, filters.value.dateFrom]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        type: "date",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filters.value.dateTo) = $event)),
                        placeholder: "To"
                      }, null, 512), [
                        [_vModelText, filters.value.dateTo]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label" }, "Search by Employee Name", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filters.value.employeeName) = $event)),
                      placeholder: "Enter employee name..."
                    }, null, 512), [
                      [_vModelText, filters.value.employeeName]
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "col-md-2" }, [
                  _createElementVNode("div", { class: "form-group mt-4" }, [
                    _createElementVNode("button", {
                      class: "btn btn-primary w-100",
                      onClick: handleSearch
                    }, _cache[8] || (_cache[8] = [
                      _createElementVNode("i", { class: "fa fa-search me-2" }, null, -1),
                      _createTextVNode("Search ")
                    ]))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("table", _hoisted_14, [
                  _cache[10] || (_cache[10] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "Docket No."),
                      _createElementVNode("th", null, "Employee Code"),
                      _createElementVNode("th", null, "Candidate Name"),
                      _createElementVNode("th", null, "Site Name"),
                      _createElementVNode("th", null, "Designation"),
                      _createElementVNode("th", null, "Document Type"),
                      _createElementVNode("th", null, "Date Received"),
                      _createElementVNode("th", null, "Return Date"),
                      _createElementVNode("th", null, "Status")
                    ])
                  ], -1)),
                  (!documents.value || documents.value.length === 0)
                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_15, _cache[9] || (_cache[9] = [
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", {
                            colspan: "10",
                            class: "text-center"
                          }, "No matching records found")
                        ], -1)
                      ])))
                    : (_openBlock(), _createElementBlock("tbody", _hoisted_16, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(documents.value, (doc) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: doc._id
                          }, [
                            _createElementVNode("td", null, _toDisplayString(doc?.docketNumber || '-'), 1),
                            _createElementVNode("td", null, _toDisplayString(doc?.employeeCode || '-'), 1),
                            _createElementVNode("td", null, _toDisplayString(doc?.candidateName || '-'), 1),
                            _createElementVNode("td", null, _toDisplayString(doc?.siteName || '-'), 1),
                            _createElementVNode("td", null, _toDisplayString(doc?.designation || '-'), 1),
                            _createElementVNode("td", null, [
                              (doc.documentsInCustody && doc.documentsInCustody.length)
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(doc.documentsInCustody, (document) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: document._id
                                    }, _toDisplayString(document.documentType || '-'), 1))
                                  }), 128))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode("-")
                                  ], 64))
                            ]),
                            _createElementVNode("td", null, [
                              (doc.documentsInCustody && doc.documentsInCustody.length)
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(doc.documentsInCustody, (document) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: document._id
                                    }, _toDisplayString(formatDate(document.dateReceived)), 1))
                                  }), 128))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode("-")
                                  ], 64))
                            ]),
                            _createElementVNode("td", null, [
                              (doc.documentsInCustody && doc.documentsInCustody.length)
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(doc.documentsInCustody, (document) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: document._id
                                    }, _toDisplayString(formatDate(document.returnDate)), 1))
                                  }), 128))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode("-")
                                  ], 64))
                            ]),
                            _createElementVNode("td", null, [
                              (doc.documentsInCustody && doc.documentsInCustody.length)
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(doc.documentsInCustody, (document) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: document._id
                                    }, [
                                      _createElementVNode("span", {
                                        class: _normalizeClass(getStatusClass(document.status))
                                      }, _toDisplayString(document.status || '-'), 3)
                                    ]))
                                  }), 128))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode("-")
                                  ], 64))
                            ])
                          ]))
                        }), 128))
                      ]))
                ])
              ])
            ], 32)
          ])
        ])
      ])
    ])
  ], 64))
}
}

})