<template>
    <div class="add-register">
        <div class="card mb-4">
            <div class="card-header">
                <h3>Add Register</h3>
            </div>
            <div class="card-body">
                <form @submit.prevent="handleSubmit">
                    <div class="mb-3">
                        <label class="form-label">Register Name</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="registerName"
                            required
                            placeholder="Enter register name"
                        >
                    </div>
                    <div class="d-flex justify-content-end gap-2">
                        <button 
                            type="button" 
                            class="btn btn-secondary"
                            @click="router.back()"
                        >
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-primary">
                            Add Register
                        </button>
                    </div>
                </form>

                <!-- Display existing registers -->
                <div class="mt-4">
                    <h4>Existing Registers</h4>
                    <div v-if="isLoading" class="text-center my-4">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <div v-else-if="registers.length === 0" class="text-center my-4">
                        <p class="text-muted">No registers found</p>
                    </div>

                    <div v-else class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Register Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="register in registers" :key="register._id">
                                    <td>{{ register.registerName }}</td>
                                    <td>
                                        <button 
                                            class="btn btn-danger btn-sm"
                                            @click="removeRegister(register._id)"
                                            :disabled="removingRegisterId === register._id"
                                        >
                                            <span v-if="removingRegisterId === register._id">
                                                Removing...
                                            </span>
                                            <span v-else>
                                                Remove
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';

const router = useRouter();
const route = useRoute();
const siteId = route.params.siteId as string;
const baseUrl = process.env.VUE_APP_BASE_URL;

const registerName = ref('');
const registers = ref<any[]>([]);
const isLoading = ref(false);
const removingRegisterId = ref<string | null>(null);

// Handle form submission
const handleSubmit = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/physical/register/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                siteId: siteId,
                registerData: [{
                    registerName: registerName.value
                }]
            })
        });

        const result = await response.json();
        
        if (response.ok) {
            await fetchRegisters();
            // Reset form
            registerName.value = '';
            Swal.fire('Success', 'Register added successfully', 'success');
        } else {
            throw new Error(result.message || 'Failed to add register');
        }
    } catch (error: any) {
        console.error('Error adding register:', error);
        Swal.fire('Error', error.message || 'Failed to add register', 'error');
    }
};

// Fetch registers list
const fetchRegisters = async () => {
    isLoading.value = true;
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/physical/register/get/${siteId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        
        const result = await response.json();
        
        if (response.ok && result.physicalRegister) {
            // Transform the data to only include necessary register information
            registers.value = result.physicalRegister.registerData.map((register: any) => ({
                _id: register._id,
                registerName: register.registerName
            }));
        } else {
            console.error('Invalid API response format:', result);
            registers.value = [];
        }
    } catch (error) {
        console.error('Error fetching registers:', error);
        registers.value = [];
    } finally {
        isLoading.value = false;
    }
};

// Remove register
const removeRegister = async (registerId: string) => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You want to remove this register?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
        cancelButtonText: 'Cancel'
    });

    if (!result.isConfirmed) return;
    
    removingRegisterId.value = registerId;
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}api/physical/register/${siteId}/${registerId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        const apiResult = await response.json();
        
        if (response.ok) {
            await fetchRegisters();
            Swal.fire(
                'Removed!',
                'Register has been removed.',
                'success'
            );
        } else {
            throw new Error(apiResult.message || 'Failed to remove register');
        }
    } catch (error: any) {
        console.error('Error removing register:', error);
        Swal.fire(
            'Error!',
            error.message || 'Failed to remove register',
            'error'
        );
    } finally {
        removingRegisterId.value = null;
    }
};

onMounted(() => {
    fetchRegisters();
});
</script>

<style scoped>
.table th {
    background-color: #f8f9fa;
}

.btn-danger {
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
}
</style>
