import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-container" }
const _hoisted_2 = { class: "error-content" }
const _hoisted_3 = { class: "error-actions" }


export default /*@__PURE__*/_defineComponent({
  __name: '403',
  setup(__props) {

// No additional logic needed

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "error-icon" }, [
        _createElementVNode("i", { class: "fa fa-lock" })
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, "403", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Access Denied", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("p", null, "Sorry, you don't have permission to access this page.", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "btn btn-primary"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "fa fa-home me-2" }, null, -1),
            _createTextVNode(" Back to Dashboard ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})