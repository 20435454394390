import { defineStore } from "pinia";
import { ref, watch } from "vue";
import Menu from "@/data/menu";
import sidebarlist from "@/types/sidebarlist"
import menu from "@/types/menu"
interface searchdatas {
  icon: string,
  path: string,
  title: string
}
interface search {
  icon: string,
  path: string,
  title: string,
  bookmark: string
}
export const useMenuStore = defineStore("menu", () => {
  const data = ref<sidebarlist[]>([]);
console.log(data.value)
  const data1: menu[] = (JSON.parse(JSON.stringify(Menu.data)))
console.log(data1)
  const togglesidebar = ref<boolean>(true);
  const activeoverlay = ref<boolean>(true);
  const customizer = ref<string>("");
  const searchData = ref<searchdatas[]>([]);
  const searchDatas = ref<search[]>([]);
  const searchOpen = ref<boolean>(false);
  const hideRightArrowRTL = ref<boolean>(false)
  const hideLeftArrowRTL = ref<boolean>(true)
  const hideRightArrow = ref<boolean>(true)
  const hideLeftArrow = ref<boolean>(true)
  const width = ref<number>(0)
  const height = ref<number>(0)
  const margin = ref<number>(0)
  const menuWidth = ref<number>(0)
  const searchKey = ref('')
  interface term {
    [key: string]: any;
  }
  interface terms {
    [key: string]: any;
  }
  function toggle_sidebar() {
    togglesidebar.value = !togglesidebar.value;
    if (window.innerWidth < 991) {
      activeoverlay.value = true;
    } else {
      activeoverlay.value = false;
    }
    activeoverlay.value = false;
  }

  function searchTerm({ term }: term) {
    const searchKey = term;
    searchKey.toLowerCase();

    if (searchKey !== "") {
      let icon: string;
      let suggestionArray: any[] = [];
      let updatedList = {};
      const deepSearchFun = (menuItem: any, searchKeyValue: any, mainTitle: any) => {
        if (menuItem.title.toLowerCase().includes(searchKeyValue) && menuItem.path) {
          updatedList = { ...menuItem, mainTitle, icon };
          suggestionArray.push(menuItem);
        }

        if (!menuItem.children) return;
        menuItem.children.map((mainSubItem: any) => {
          if (menuItem.icon) {
            icon = menuItem.icon;
          }

          deepSearchFun(mainSubItem, searchKeyValue, mainTitle);
        });
      };

      data.value.map((mainItem) => {
        const mainTitle = mainItem?.title;
        deepSearchFun(mainItem, searchKey, mainTitle);
      });

      searchData.value = [];
      searchData.value.push(...suggestionArray);
      suggestionArray = [];
    }



  }




  function searchterm({ terms }: terms) {
    const searchKey = terms;
    searchKey.toLowerCase();
    if (searchKey !== "") {
      let icon: string;
      let suggestionArray: any[] = [];
      let updatedList = {};
      const deepSearchFun = (menuItems: any, searchKeyValue: any, mainTitle: any) => {
        if (menuItems.title.toLowerCase().includes(searchKeyValue) && menuItems.path) {
          updatedList = { ...menuItems, mainTitle, icon };
          suggestionArray.push(menuItems);
        }

        if (!menuItems.children) return;
        menuItems.children.map((mainSubItem: any) => {
          if (menuItems.icon) {
            icon = menuItems.icon;
          }

          deepSearchFun(mainSubItem, searchKeyValue, mainTitle);
        });
      };

      data.value.map((mainItem) => {
        const mainTitle = mainItem?.title;
        deepSearchFun(mainItem, searchKey, mainTitle);
      });

      searchDatas.value = [];
      searchDatas.value.push(...suggestionArray);
      suggestionArray = [];
    }


  }

  function setNavActive(item: any) {
    if (!item.active) {
      data.value.forEach((a: any) => {
        if (data.value.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b: any) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }
  function setActiveRoute(item: any) {
    data.value.filter(menuItem => {
      menuItem.children?.filter(menu => {
        if (menu! == item)
          item.active = false;
        if (menu.children && menu.children.includes(item))

          item.active = true;
        if (menu.children) {
          menu.children.filter(submenuItems => {
          });
        }
      })

    });
  }

  async function initializeMenu() {
    try {
        // Clear existing data
        data.value = [];
        
        // Get fresh token
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found');
            return;
        }

        // Parse menu data with new token
        data.value = JSON.parse(JSON.stringify(Menu.data));
        
        return true;
    } catch (error) {
        console.error('Error initializing menu:', error);
        return false;
    }
  }

  function resetMenu() {
    data.value = [];
  }

  // Add a watch to handle token changes
  watch(() => localStorage.getItem('token'), () => {
    initializeMenu();
  }, { immediate: true });

  return {
    data,
    togglesidebar,
    activeoverlay,
    toggle_sidebar,
    setNavActive,
    customizer,
    searchTerm,
    searchterm,
    searchData,
    searchOpen,
    hideRightArrowRTL,
    hideLeftArrowRTL,
    hideRightArrow,
    hideLeftArrow,
    width,
    height,
    margin,
    menuWidth,
    searchDatas,
    setActiveRoute,
    data1,
    initializeMenu,
    resetMenu,
  };
});