// Utility for handling browser notifications
// import { startContinuousSound, stopContinuousSound, isNotificationActive, initializeAudio } from './audio';

// Check if notifications are supported
export function isNotificationsSupported(): boolean {
    return 'Notification' in window;
}

// Get current permission status
export function getNotificationPermission(): NotificationPermission {
    if (!isNotificationsSupported()) {
        console.warn('Notifications not supported in this browser');
        return 'denied';
    }
    return Notification.permission;
}

// Request notification permission
export async function requestNotificationPermission(): Promise<boolean> {
    try {
        if (!isNotificationsSupported()) {
            console.warn('This browser does not support notifications');
            return false;
        }

        // If already granted, return true
        if (Notification.permission === 'granted') {
            console.log('Notification permission already granted');
            return true;
        }

        // If already denied, return false
        if (Notification.permission === 'denied') {
            console.warn('Notification permission previously denied');
            return false;
        }

        // Comment out audio initialization
        // try {
        //     await initializeAudio();
        // } catch (error) {
        //     console.warn('Audio initialization failed:', error);
        // }

        // Request permission
        console.log('Requesting notification permission...');
        const permission = await Notification.requestPermission();
        console.log('Notification permission response:', permission);
        return permission === 'granted';
    } catch (error) {
        console.error('Error requesting notification permission:', error);
        return false;
    }
}

interface ExtendedNotificationOptions extends NotificationOptions {
    soundUntilClick?: boolean;
}

// Show notification
export function showNotification(title: string, options: ExtendedNotificationOptions = {}): boolean {
    try {
        if (!isNotificationsSupported()) {
            console.warn('This browser does not support notifications');
            return false;
        }

        if (Notification.permission !== 'granted') {
            console.warn('Notification permission not granted');
            return false;
        }

        const defaultOptions: ExtendedNotificationOptions = {
            icon: '/favicon.ico',
            badge: '/favicon.ico',
            requireInteraction: true, // Keep notification visible until user interacts
            silent: true, // We're handling sound ourselves
            soundUntilClick: false, // Disabled sound
            tag: 'pending-approval-' + Date.now(), // Ensure unique tag if not provided
            ...options
        };

        // Don't create duplicate notifications with the same tag
        // if (defaultOptions.tag && isNotificationActive(defaultOptions.tag)) {
        //     console.log('Notification already active:', defaultOptions.tag);
        //     return true;
        // }

        console.log('Creating notification:', title, defaultOptions);
        const notification = new Notification(title, defaultOptions);

        // Comment out audio initialization and sound
        // if (defaultOptions.soundUntilClick && defaultOptions.tag) {
        //     initializeAudio().then(() => {
        //         startContinuousSound(defaultOptions.tag);
        //     }).catch(error => {
        //         console.error('Failed to initialize audio for notification:', error);
        //     });
        // }

        // Add click handler
        notification.onclick = function() {
            console.log('Notification clicked:', defaultOptions.tag);
            window.focus();
            // if (defaultOptions.tag) {
            //     stopContinuousSound(defaultOptions.tag);
            // }
            notification.close();
        };

        // Add error handler
        notification.onerror = function(error) {
            console.error('Notification error:', error);
            // if (defaultOptions.tag) {
            //     stopContinuousSound(defaultOptions.tag);
            // }
        };

        // Add close handler
        notification.onclose = function() {
            console.log('Notification closed:', defaultOptions.tag);
            // if (defaultOptions.tag) {
            //     stopContinuousSound(defaultOptions.tag);
            // }
        };

        return true;
    } catch (error) {
        console.error('Error showing notification:', error);
        return false;
    }
} 