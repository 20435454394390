import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap" }
const _hoisted_3 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'out-of-stocks',
  setup(__props) {

const OutOfStocksTable = defineAsyncComponent(() => import('@/components/stocks/out-of-stocks/out-of-stocks-table.vue'));

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Front Office",
      title: "Joining"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(OutOfStocksTable))
        ])
      ])
    ])
  ], 64))
}
}

})