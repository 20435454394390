<template>
  <div class="assign-work-approval">
    <Breadcrumbs title="Assign Work Approval" />

    <!-- Add search filters -->
    <div class="search-filters">
      <div class="search-input">
        <label>From Date:</label>
        <input type="date" v-model="filters.fromDate" @input="handleSearch">
      </div>
      <div class="search-input">
        <label>To Date:</label>
        <input type="date" v-model="filters.toDate" @input="handleSearch">
      </div>
      <div class="search-input">
        <label>Site Name:</label>
        <input type="text" v-model="filters.siteName" placeholder="Search by site name..." @input="handleSearch">
      </div>
      <div class="search-input">
        <label>From Employee:</label>
        <input type="text" v-model="filters.fromEmployee" placeholder="Search by from employee..."
          @input="handleSearch">
      </div>
      <div class="search-input">
        <label>To Employee:</label>
        <input type="text" v-model="filters.toEmployee" placeholder="Search by to employee..." @input="handleSearch">
      </div>
    </div>

    <div class="table-container">
      <div class="work-list">
        <div class="list-header">
          <div class="header-item">Work Title</div>
          <div class="header-item">Site Name</div>
          <div class="header-item">From Employee</div>
          <div class="header-item">To Employee</div>
          <div class="header-item">Description</div>
          <div class="header-item">Priority</div>
          <div class="header-item">Type</div>
          <div class="header-item">Assigned Date</div>
          <div class="header-item">Reason</div>
          <div class="header-item">Status</div>
          <div class="header-item">Actions</div>
        </div>

        <div class="list-body">
          <div v-for="item in displayedWorkItems" :key="item._id" class="list-row">
            <div class="list-cell">{{ item.workId?.workTitle || '-' }}</div>
            <div class="list-cell">{{ item.workId?.siteName || '-' }}</div>
            <div class="list-cell">{{ item.workId?.employeeName || '-' }}</div>
            <div class="list-cell">
              {{ item.employeeId?.employeeName || '-' }}
            </div>
            <div class="list-cell description">{{ item.workId?.description || '-' }}</div>
            <div class="list-cell">
              <span v-if="item.workId?.priority" :class="['priority-badge', item.workId.priority.toLowerCase()]">
                {{ item.workId.priority }}
              </span>
              <span v-else>-</span>
            </div>
            <div class="list-cell">
              <span :class="['type-badge', formatType(item.type).toLowerCase()]">
                {{ formatType(item.type) }}
              </span>
            </div>
            <div class="list-cell">
              <span v-if="item.assignedDate" class="date-text">{{ formatDate(item.assignedDate) }}</span>
              <span v-else>-</span>
            </div>
            <div class="list-cell reason">
              <span v-if="item.reason" class="reason-text">{{ item.reason }}</span>
              <span v-else>-</span>
            </div>
            <div class="list-cell">
              <span :class="['status-badge', item.status.toLowerCase()]">
                {{ item.status }}
              </span>
            </div>
            <div class="list-cell action-buttons">
              <template v-if="item.status === 'pending'">
                <button class="btn approve" @click="showConfirmation('approve', item)">
                  Approve
                </button>
                <button class="btn reject" @click="showConfirmation('reject', item)">
                  Reject
                </button>
              </template>
              <span v-else :class="['status-action', item.status.toLowerCase()]">
                {{ item.status }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add confirmation dialog -->
    <div v-if="showConfirmDialog" class="confirmation-overlay">
      <div class="confirmation-dialog">
        <h3>Confirm Action</h3>
        <p>Are you sure you want to {{ confirmAction }} this work item?</p>
        <div class="dialog-buttons">
          <button class="btn cancel" @click="closeConfirmation">
            Cancel
          </button>
          <button :class="['btn', confirmAction]" @click="confirmActionHandler">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useWorkApprovalStore } from '@/store/workApproval'

export default {
  name: 'AssignWorkApproval',
  setup() {
    const workApprovalStore = useWorkApprovalStore()
    return { workApprovalStore }
  },
  data() {
    return {
      workItems: [],
      filteredWorkItems: [],
      showConfirmDialog: false,
      confirmAction: null,
      selectedItem: null,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      filters: {
        fromDate: '',
        toDate: '',
        siteName: '',
        fromEmployee: '',
        toEmployee: ''
      }
    }
  },
  computed: {
    displayedWorkItems() {
      if (!this.filters.fromEmployee &&
        !this.filters.toEmployee &&
        !this.filters.siteName &&
        !this.filters.fromDate &&
        !this.filters.toDate) {
        return this.workItems;
      }

      return this.workItems.filter(item => {
        const siteName = (item.workId?.siteName || '').toLowerCase();
        const fromEmployeeName = (item.workId?.employeeName || '').toLowerCase();
        const toEmployeeName = (item.employeeId?.employeeName || '').toLowerCase();
        const assignedDate = new Date(item.assignedDate || item.workId?.assignedDate);

        const siteFilter = this.filters.siteName.toLowerCase();
        const fromFilter = this.filters.fromEmployee.toLowerCase();
        const toFilter = this.filters.toEmployee.toLowerCase();

        // Date filtering
        const fromDate = this.filters.fromDate ? new Date(this.filters.fromDate) : null;
        const toDate = this.filters.toDate ? new Date(this.filters.toDate) : null;

        // Set time to start of day for from date and end of day for to date
        if (fromDate) fromDate.setHours(0, 0, 0, 0);
        if (toDate) toDate.setHours(23, 59, 59, 999);

        const matchesSiteName = !siteFilter || siteName.includes(siteFilter);
        const matchesFromEmployee = !fromFilter || fromEmployeeName.includes(fromFilter);
        const matchesToEmployee = !toFilter || toEmployeeName.includes(toFilter);
        const matchesDateRange = (!fromDate || assignedDate >= fromDate) &&
          (!toDate || assignedDate <= toDate);

        return matchesSiteName &&
          matchesFromEmployee &&
          matchesToEmployee &&
          matchesDateRange;
      });
    }
  },
  async created() {
    await this.fetchWorkAssignments()
    await this.workApprovalStore.fetchPendingCount()
  },
  methods: {
    async fetchWorkAssignments() {
      try {
        this.isLoading = true
        const response = await axios.get(`${this.baseUrl}api/work/assignment/change/all`)
        if (response.data.success) {
          this.workItems = response.data.data
          await this.workApprovalStore.fetchPendingCount()
        }
      } catch (error) {
        console.error('Error fetching work assignments:', error)
      } finally {
        this.isLoading = false
      }
    },
    showConfirmation(action, item) {
      this.confirmAction = action
      this.selectedItem = item
      this.showConfirmDialog = true
    },
    closeConfirmation() {
      this.showConfirmDialog = false
      this.confirmAction = null
      this.selectedItem = null
    },
    async confirmActionHandler() {
      try {
        if (this.confirmAction === 'approve') {
          await this.handleApprove(this.selectedItem)
        } else if (this.confirmAction === 'reject') {
          await this.handleReject(this.selectedItem)
        }
        await this.fetchWorkAssignments()
        await this.workApprovalStore.fetchPendingCount()
      } catch (error) {
        console.error('Error handling action:', error)
      } finally {
        this.closeConfirmation()
      }
    },
    async handleApprove(item) {
      try {
        const response = await axios.post(
          `${this.baseUrl}api/work/assignment/change/status/${item._id}`
        )
        if (response.data.success) {
          // Show success message
          this.$toast.success('Work assignment approved successfully')
        } else {
          // Show error message
          this.$toast.error(response.data.message || 'Failed to approve work assignment')
        }
      } catch (error) {
        console.error('Error approving work assignment:', error)
        this.$toast.error('Failed to approve work assignment')
      }
    },
    async handleReject(item) {
      try {
        const response = await axios.post(
          `${this.baseUrl}api/work/assignment/change/reject/${item._id}`
        )
        if (response.data.success) {
          // Show success message
          this.$toast.success('Work assignment rejected successfully')
        } else {
          // Show error message
          this.$toast.error(response.data.message || 'Failed to reject work assignment')
        }
      } catch (error) {
        console.error('Error rejecting work assignment:', error)
        this.$toast.error('Failed to reject work assignment')
      }
    },
    formatDate(dateString) {
      if (!dateString) return ''
      const date = new Date(dateString)
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },
    formatType(type) {
      if (!type) return '-'
      // Convert camelCase to Title Case with spaces
      return type
        .replace(/([A-Z])/g, ' $1') // Add space before capital letters
        .replace(/^./, str => str.toUpperCase()) // Capitalize first letter
        .trim() // Remove any extra spaces
    },
    handleSearch() {
      // Debounce the search if needed
      clearTimeout(this._searchTimeout);
      this._searchTimeout = setTimeout(() => {
        // The filtering is handled by the computed property
      }, 300);
    },
    formatDateForInput(date) {
      if (!date) return '';
      const d = new Date(date);
      return d.toISOString().split('T')[0];
    }
  }
}
</script>

<style scoped>
.page-header {
  margin-bottom: 24px;
}

.page-header h1 {
  font-size: 24px;
  color: #2c3e50;
  margin: 0 0 8px 0;
}

.subtitle {
  color: #666;
  margin: 0;
}

.stats-summary {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
}

.stat-card {
  background: white;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
}

.stat-label {
  color: #666;
  font-size: 14px;
}

.assign-work-approval {
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  /* Add smooth scrolling */
  scroll-behavior: smooth;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: thin;
}

/* Style the scrollbar for Webkit browsers */
.table-container::-webkit-scrollbar {
  height: 8px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.work-list {
  min-width: 1200px;
  /* Set a minimum width to ensure proper display */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.list-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 0.8fr 1fr 1fr 1fr 0.8fr 1fr;
  background-color: #f5f5f5;
  padding: 12px;
  font-weight: bold;
}

.list-body {
  background-color: white;
}

.list-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 0.8fr 1fr 1fr 1fr 0.8fr 1fr;
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
}

.list-row:last-child {
  border-bottom: none;
}

.list-row:hover {
  background-color: #f8f8f8;
}

.header-item,
.list-cell {
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Add specific styles for description and reason cells */
.list-cell.description,
.list-cell.reason {
  white-space: pre-wrap;
  word-break: break-word;
  min-height: 40px;
  max-height: 80px;
  overflow-y: auto;
}

.list-cell.description::-webkit-scrollbar,
.list-cell.reason::-webkit-scrollbar {
  width: 4px;
}

.list-cell.description::-webkit-scrollbar-thumb,
.list-cell.reason::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
}

.status-badge.pending {
  background-color: #fff3cd;
  color: #856404;
}

.status-badge.approved {
  background-color: #d4edda;
  color: #155724;
}

.status-badge.rejected {
  background-color: #f8d7da;
  color: #721c24;
}

.action-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85em;
  font-weight: 500;
  transition: opacity 0.2s;
  white-space: nowrap;
  min-width: 70px;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn.approve {
  background-color: #28a745;
  color: white;
}

.btn.approve:hover:not(:disabled) {
  background-color: #218838;
}

.btn.reject {
  background-color: #dc3545;
  color: white;
}

.btn.reject:hover:not(:disabled) {
  background-color: #c82333;
}

.status-action {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-action.approved {
  background-color: #d4edda;
  color: #155724;
}

.status-action.rejected {
  background-color: #f8d7da;
  color: #721c24;
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-dialog {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.confirmation-dialog h3 {
  margin: 0 0 16px 0;
  color: #2c3e50;
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.btn.cancel {
  background-color: #6c757d;
  color: white;
}

.btn.cancel:hover {
  background-color: #5a6268;
}

/* Add loading state styles if needed */
.loading {
  opacity: 0.5;
  pointer-events: none;
}

.priority-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
}

.priority-badge.low {
  background-color: #e9ecef;
  color: #495057;
}

.priority-badge.medium {
  background-color: #fff3cd;
  color: #856404;
}

.priority-badge.high {
  background-color: #f8d7da;
  color: #721c24;
}

.reason-text {
  color: #666;
  font-style: italic;
  font-size: 0.9em;
}

.date-text {
  color: #2c3e50;
  font-size: 0.9em;
}

.type-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
  background-color: #e3f2fd;
  color: #0d47a1;
}

.type-badge.changedate {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.type-badge.transferwork {
  background-color: #ede7f6;
  color: #4527a0;
}

.search-filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.search-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.search-input label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.search-input input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.search-input input:focus {
  outline: none;
  border-color: #4a90e2;
}

.search-input input::placeholder {
  color: #999;
}

/* Add these styles for date inputs */
.search-input input[type="date"] {
  padding: 7px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s;
  width: 100%;
}

.search-input input[type="date"]:focus {
  outline: none;
  border-color: #4a90e2;
}
</style>
