<template>
    <Breadcrumbs main="Content" title="Gallery Management" />
    <div class="container-fluid">
        <!-- Category Filter -->
        <div class="row mb-4">
            <div class="col">
                <div class="btn-group">
                    <button v-for="category in categories" :key="category" class="btn"
                        :class="selectedCategory === category ? 'btn-primary' : 'btn-outline-primary'"
                        @click="selectedCategory = category">
                        {{ category }}
                    </button>
                </div>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary" @click="handleAddImage">
                    <i class="fa fa-plus"></i> Add Image
                </button>
            </div>
        </div>

        <!-- Gallery Grid -->
        <div class="row g-3">
            <TransitionGroup name="gallery-list">
                <div v-for="image in filteredImages" :key="image.key" class="col-md-4 col-lg-3">
                    <div class="card h-100">
                        <img :src="image.url" class="card-img-top" :alt="image.title">
                        <div class="card-body">
                            <h5 class="card-title">{{ image.title }}</h5>
                            <p class="card-text">{{ image.description }}</p>
                            <div class="btn-group">
                                <button class="btn btn-sm btn-primary" @click="editImage(image)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-sm btn-danger" @click="deleteImage(image)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionGroup>
        </div>

        <!-- Add/Edit Modal -->
        <div class="modal fade" id="galleryModal" tabindex="-1" role="dialog" aria-labelledby="galleryModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ isEditing ? 'Edit Image' : 'Add Image' }}</h5>
                        <button class="btn-close" type="button" @click="hideModal()" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveImage">
                            <div class="mb-3">
                                <label class="form-label">Image</label>
                                <input 
                                    type="file" 
                                    class="form-control" 
                                    @change="handleImageUpload" 
                                    accept="image/*"
                                    :required="!isEditing"
                                >
                                <div class="form-text" v-if="!isEditing">
                                    Please select an image file (JPEG, PNG, or WebP, max 5MB)
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Title</label>
                                <input v-model="formData.title" type="text" class="form-control" required>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Category</label>
                                <select v-model="formData.category" class="form-select" required>
                                    <option v-for="category in selectableCategories" :key="category" :value="category">
                                        {{ category }}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Description</label>
                                <textarea v-model="formData.description" class="form-control" rows="3"></textarea>
                            </div>
                            <div class="text-end">
                                <button type="button" class="btn btn-secondary me-2"
                                    @click="hideModal()">Cancel</button>
                                <button type="submit" class="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { useToast } from 'vue-toast-notification'
import { fetchGetApi, fetchPostApi } from '@/util/api'
import { Modal } from 'bootstrap'

interface GalleryImage {
    _id: string
    url: string
    key: string
    category: 'Events' | 'Office' | 'Projects' | 'Team'
    title: string
    description?: string
    order: number
}

interface FormData {
    _id?: string
    url: string
    key: string
    category: GalleryImage['category'] | ''
    title: string
    description: string
    order: number
    file?: File
}

const baseUrl = process.env.VUE_APP_BASE_URL;
const toast = useToast()
const categories = ['All', 'Events', 'Office', 'Projects', 'Team'] as const
const selectableCategories = categories.filter(c => c !== 'All') as GalleryImage['category'][]
const selectedCategory = ref<typeof categories[number]>('All')
const isEditing = ref(false)
const images = ref<GalleryImage[]>([])
//@ts-ignore
const modal = ref<Modal | null>(null)

const formData = ref<FormData>({
    url: '',
    key: '',
    category: '',
    title: '',
    description: '',
    order: 0
})

const filteredImages = computed(() => {
    if (selectedCategory.value === 'All') return images.value
    return images.value.filter(img => img.category === selectedCategory.value)
})

function openModal() {
    if (!modal.value) {
        modal.value = new Modal(document.getElementById('galleryModal'))
    }
    modal.value.show()
}

function hideModal() {
    if (modal.value) {
        modal.value.hide()
        resetForm()
    }
}

async function fetchImages() {
    try {
        const params = selectedCategory.value !== 'All' ? `?category=${selectedCategory.value}` : '';
        const response = await fetchGetApi(`api/gallery${params}`)
        if (response.data.success) {
            images.value = response.data.data
        } else {
            throw new Error('Failed to fetch images')
        }
    } catch (error) {
        toast.error('Failed to load images')
        console.error('Error fetching images:', error)
    }
}

async function saveImage() {
    try {
        if (isEditing.value && formData.value._id) {
            // Update existing image
            const response = await fetch(`${baseUrl}api/gallery/${formData.value._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...(localStorage.getItem('token') ? {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    } : {})
                },
                body: JSON.stringify({
                    title: formData.value.title,
                    description: formData.value.description,
                    category: formData.value.category,
                    order: formData.value.order
                })
            });

            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.message || 'Failed to update image');
            }

            if (!data.success) {
                throw new Error(data.message || 'Failed to update image');
            }
        } else {
            // Create new image
            // Validate that file exists for new image upload
            if (!formData.value.file) {
                toast.error('Please select an image file')
                return
            }

            const imageFormData = new FormData()
            imageFormData.append('image', formData.value.file)
            imageFormData.append('title', formData.value.title)
            imageFormData.append('description', formData.value.description || '')
            imageFormData.append('category', formData.value.category)
            imageFormData.append('order', formData.value.order.toString())

            const response = await fetch(`${baseUrl}api/gallery/upload`, {
                method: 'POST',
                body: imageFormData,
                headers: {
                    ...(localStorage.getItem('token') ? {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    } : {})
                }
            });

            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.message || 'Failed to upload image');
            }

            if (!data.success) {
                throw new Error(data.message || 'Failed to create image');
            }
        }

        hideModal()
        await fetchImages()
        toast.success(`Image ${isEditing.value ? 'updated' : 'added'} successfully`)
    } catch (error: any) {
        toast.error(error.message || 'Failed to save image')
        console.error('Error saving image:', error)
    }
}

async function deleteImage(image: GalleryImage) {
    if (!confirm('Are you sure you want to delete this image?')) return

    try {
        // Use fetch directly for delete request
        const response = await fetch(`${baseUrl}api/gallery/${image._id}`, {
            method: 'DELETE',
            headers: {
                ...(localStorage.getItem('token') ? {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                } : {})
            }
        });

        const data = await response.json();
        
        if (!response.ok) {
            throw new Error(data.message || 'Failed to delete image');
        }

        if (data.success) {
            await fetchImages();
            toast.success(data.message || 'Image deleted successfully');
        } else {
            throw new Error(data.message || 'Failed to delete image');
        }
    } catch (error: any) {
        toast.error(error.message || 'Failed to delete image');
        console.error('Error deleting image:', error);
    }
}

async function updateOrder(evt: any) {
    try {
        const updates = filteredImages.value.map((img, index) => ({
            key: img.key,
            order: index
        }))

        await fetchPutApi(`api/gallery/reorder`, updates)
        toast.success('Order updated successfully')
    } catch (error) {
        toast.error('Failed to update order')
        console.error('Error updating order:', error)
    }
}

function editImage(image: GalleryImage) {
    isEditing.value = true
    formData.value = {
        _id: image._id,
        url: image.url,
        key: image.key,
        category: image.category,
        title: image.title,
        description: image.description || '',
        order: image.order
    }
    openModal()
}

async function handleImageUpload(event: Event) {
    const target = event.target as HTMLInputElement
    const file = target.files?.[0]
    if (!file) {
        toast.error('Please select a file')
        return
    }

    // Add file type validation
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp']
    if (!allowedTypes.includes(file.type)) {
        toast.error('Invalid file type. Only JPEG, PNG and WebP are allowed.')
        return
    }

    // Add file size validation (e.g., 5MB limit)
    const maxSize = 5 * 1024 * 1024 // 5MB
    if (file.size > maxSize) {
        toast.error('File size too large. Maximum size is 5MB.')
        return
    }

    // Store the file in formData for later upload
    formData.value.file = file
}

function resetForm() {
    formData.value = {
        url: '',
        key: '',
        category: '',
        title: '',
        description: '',
        order: images.value.length, // Set order to last position for new images
        file: undefined // Clear the file
    }
    isEditing.value = false
    
    // Reset file input
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement
    if (fileInput) {
        fileInput.value = ''
    }
}

function handleAddImage() {
    isEditing.value = false
    resetForm()
    openModal()
}

// Initialize
onMounted(() => {
    fetchImages()
})
</script>

<style scoped>
.gallery-list-move {
    transition: transform 0.5s;
}

.gallery-list-enter-active,
.gallery-list-leave-active {
    transition: all 0.5s ease;
}

.gallery-list-enter-from,
.gallery-list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.card-img-top {
    height: 200px;
    object-fit: cover;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
</style>