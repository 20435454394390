import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "client-requirements" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "col-md-6" }

import { reactive, watch } from 'vue';

interface ClientRequirementsProps {
    requirements: {
        specificRequirements: string;
        serviceStartDate: {
            male: string;
            female: string;
        };
    };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'client-requirements',
  props: {
    requirements: {}
  },
  emits: ['update:requirements'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const localRequirements = reactive({
    specificRequirements: props.requirements.specificRequirements || '',
    serviceStartDate: {
        male: props.requirements.serviceStartDate.male || '',
        female: props.requirements.serviceStartDate.female || ''
    }
});

watch(localRequirements, (newVal) => {
    emit('update:requirements', { ...newVal });
}, { deep: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, "Specific Requirements", -1)),
      _withDirectives(_createElementVNode("textarea", {
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localRequirements.specificRequirements) = $event)),
        rows: "4"
      }, null, 512), [
        [_vModelText, localRequirements.specificRequirements]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label" }, "Service Start Date (Male)", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localRequirements.serviceStartDate.male) = $event))
        }, null, 512), [
          [_vModelText, localRequirements.serviceStartDate.male]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "Service Start Date (Female)", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          class: "form-control",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localRequirements.serviceStartDate.female) = $event))
        }, null, 512), [
          [_vModelText, localRequirements.serviceStartDate.female]
        ])
      ])
    ])
  ]))
}
}

})