<script setup>
import { ref, onMounted, computed } from 'vue';
import { fetchGetApi } from "@/util/api";
import { useToast } from 'vue-toast-notification';
import { useWorkApprovalStore } from '@/store/workApproval';

const $toast = useToast();
const workApprovalStore = useWorkApprovalStore();
const pendingKyts = ref([]);
const loading = ref(false);
const selectedKyt = ref(null);
const showApprovalModal = ref(false);
const selectedProjectFilter = ref('all');

const uniqueProjectNames = computed(() => {
    const projects = new Set(pendingKyts.value.map(kyt => kyt.projectName));
    return Array.from(projects).sort();
});

const filteredKyts = computed(() => {
    if (selectedProjectFilter.value === 'all') {
        return pendingKyts.value;
    }
    return pendingKyts.value.filter(kyt => kyt.projectName === selectedProjectFilter.value);
});

async function fetchPendingKyts() {
    loading.value = true;
    try {
        // Fetch pending transfer KYTs
        const transferResponse = await fetchGetApi('api/kyt/transfer/get/all');

        const transferKyts = [];
        const seenTransferIds = new Set();

        (transferResponse?.data || []).forEach(item => {
            const itemId = item._id || item.id;
            if (item.status === 'Pending' && !seenTransferIds.has(itemId)) {
                seenTransferIds.add(itemId);
                transferKyts.push({
                    ...item,
                    id: itemId,
                    managerName: item.requestedBy || 'N/A',
                    date: formatDate(item.createdAt),
                    kytDate: formatDate(item.kytDate),
                    type: 'Transfer',
                    projectName: item.siteName || 'N/A',
                    workDetails: `KYT Transfer Request`,
                    transferredTo: item.empId || 'N/A',
                    status: 'Pending'
                });
            }
        });

        // Fetch pending change KYTs
        const changeResponse = await fetchGetApi('api/kyt/getChangeKyts');

        const changeKyts = [];
        const seenChangeIds = new Set();

        (changeResponse?.data || []).forEach(item => {
            const itemId = item._id || item.id;
            if (item.status === 'Pending' && !seenChangeIds.has(itemId)) {
                seenChangeIds.add(itemId);
                changeKyts.push({
                    ...item,
                    id: itemId,
                    managerName: item.empName || 'N/A',
                    date: formatDate(item.createdAt),
                    kytDate: formatDate(item.kytDate),
                    type: 'Change',
                    projectName: item.siteName || 'N/A',
                    workDetails: Array.isArray(item.workDetails) ? item.workDetails.join(',') : (item.workDetails || 'N/A'),
                    transferredTo: '-',
                    status: 'Pending',
                    previousNFD: item.previousNFD || 'N/A',
                    newNFD: item.date || 'N/A'
                });
            }
        });

        // Final deduplication before combining arrays
        const allKyts = [...transferKyts, ...changeKyts];
        const uniqueKyts = Array.from(
            new Map(allKyts.map(item => [item.id, item])).values()
        );

        // Add original index to each KYT
        pendingKyts.value = uniqueKyts.map((kyt, index) => ({
            ...kyt,
            originalIndex: index + 1
        }));
    } catch (error) {
        console.error('Error fetching pending KYTs:', error);
        $toast.error('Failed to fetch pending KYTs');
    } finally {
        loading.value = false;
    }
}

function openApprovalModal(kyt) {
    selectedKyt.value = kyt;
    showApprovalModal.value = true;
}

async function handleApproval(approved) {
    if (!selectedKyt.value) return;

    try {
        let endpoint;
        let response;

        if (selectedKyt.value.type === 'Transfer') {
            endpoint = approved
                ? `api/kyt/transfer/approve/${selectedKyt.value.id}`
                : `api/kyt/transfer/reject/${selectedKyt.value.id}`;

            try {
                response = await fetchGetApi(endpoint);
                response = { success: true, message: response.message };
            } catch (error) {
                throw new Error(error.message || 'Failed to process transfer approval');
            }
        } else {
            endpoint = approved
                ? `api/kyt/approveChangeKyt/${selectedKyt.value.id}`
                : `api/kyt/rejectChangeKyt/${selectedKyt.value.id}`;

            try {
                response = await fetchGetApi(endpoint);
                response = { success: true, message: response.message };
            } catch (error) {
                throw new Error(error.message || 'Failed to process approval');
            }
        }

        if (response && (response.success || response.message)) {
            $toast.success(response.message || `KYT ${approved ? 'approved' : 'rejected'} successfully`);
            await fetchPendingKyts();
            await workApprovalStore.fetchPendingCount();
            showApprovalModal.value = false;
            selectedKyt.value = null;
        } else {
            throw new Error('Failed to process approval');
        }
    } catch (error) {
        console.error('Error processing approval:', error);
        $toast.error(error.message || 'Failed to process approval');
    }
}

function formatDate(dateString) {
    if (!dateString) return 'N/A';

    try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        // Convert to IST by adding 5 hours and 30 minutes
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        return 'Invalid Date';
    }
}

onMounted(async () => {
    await fetchPendingKyts();
    await workApprovalStore.fetchPendingCount();
});
</script>

<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <h5 class="card-title">Marketing KYT Approvals</h5>
                    <router-link to="/reports/marketing-kyt-change-transfer" class="btn btn-secondary">
                        View All KYTs
                    </router-link>
                </div>

                <!-- Filter Section -->
                <div class="row mb-4">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="projectFilter" class="form-label">Filter by Project:</label>
                            <select 
                                id="projectFilter" 
                                v-model="selectedProjectFilter" 
                                class="form-select"
                            >
                                <option value="all">All Projects</option>
                                <option 
                                    v-for="project in uniqueProjectNames" 
                                    :key="project" 
                                    :value="project"
                                >
                                    {{ project }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- Loading State -->
                <div v-if="loading" class="text-center my-4">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <!-- No Pending KYTs Message -->
                <div v-else-if="!pendingKyts.length" class="text-center my-4">
                    <p class="text-muted">No pending KYTs for approval</p>
                </div>

                <!-- Pending KYTs Table -->
                <div v-else class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Manager Name</th>
                                <th>Date</th>
                                <th>KYT Date</th>
                                <th>Previous NFD</th>
                                <th>New NFD</th>
                                <th>Type</th>
                                <th>Project Name</th>
                                <th>Work Details</th>
                                <th>Reason</th>
                                <th>Transferred To</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="kyt in filteredKyts" :key="kyt.id">
                                <td>{{ kyt.originalIndex }}</td>
                                <td>{{ kyt.managerName }}</td>
                                <td>{{ kyt.date }}</td>
                                <td>{{ kyt.kytDate }}</td>
                                <td>{{ kyt.previousNFD }}</td>
                                <td>{{ kyt.newNFD }}</td>
                                <td>
                                    <span :class="`badge bg-${kyt.type === 'Transfer' ? 'primary' : 'success'}`">
                                        {{ kyt.type }}
                                    </span>
                                </td>
                                <td>{{ kyt.projectName }}</td>
                                <td>{{ kyt.workDetails }}</td>
                                <td>{{ kyt.reason }}</td>
                                <td>{{ kyt.transferredTo }}</td>
                                <td>
                                    <span class="badge bg-warning">
                                        {{ kyt.status }}
                                    </span>
                                </td>
                                <td>
                                    <button class="btn btn-primary btn-sm" @click="openApprovalModal(kyt)">
                                        Review
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Approval Modal -->
                <div class="modal" :class="{ 'd-block': showApprovalModal }" tabindex="-1">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Review KYT Request</h5>
                                <button type="button" class="btn-close" @click="showApprovalModal = false">
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="alert alert-info">
                                    <strong>Details:</strong><br>
                                    Type: {{ selectedKyt?.type }}<br>
                                    Project: {{ selectedKyt?.projectName }}<br>
                                    Reason: {{ selectedKyt?.reason }}
                                    <br>
                                    Previous NFD: {{ selectedKyt?.previousNFD }}
                                    <br>
                                    New NFD: {{ selectedKyt?.newNFD }}
                                </div>

                                <!-- Images Section -->
                                <div v-if="selectedKyt?.image?.length" class="mt-3">
                                    <h6>Attached Images:</h6>
                                    <div class="d-flex flex-wrap gap-2">
                                        <div v-for="(img, index) in selectedKyt.image" :key="index"
                                            class="position-relative">
                                            <img :src="img" class="img-thumbnail"
                                                style="max-width: 200px; height: auto; cursor: pointer"
                                                @click="window.open(img, '_blank')" alt="KYT Image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" @click="showApprovalModal = false">
                                    Cancel
                                </button>
                                <button type="button" class="btn btn-danger" @click="handleApproval(false)">
                                    Reject
                                </button>
                                <button type="button" class="btn btn-success" @click="handleApproval(true)">
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal Backdrop -->
                <div v-if="showApprovalModal" class="modal-backdrop fade show">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.badge {
    padding: 0.5em 0.8em;
    font-weight: 500;
}

.card-title {
    font-size: 1.1rem;
    font-weight: 500;
}

.table th,
.table td {
    vertical-align: middle;
    font-size: 0.875rem;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}
</style>