import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sub-designation-details mb-4" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6 mb-3" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "col-md-6 mb-3" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "form-check" }
const _hoisted_12 = ["id"]
const _hoisted_13 = ["for"]
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = { class: "form-check" }
const _hoisted_16 = ["id"]
const _hoisted_17 = ["for"]
const _hoisted_18 = { class: "col-md-6 mb-3" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-6" }
const _hoisted_21 = { class: "col-6" }
const _hoisted_22 = { class: "col-md-6 mb-3" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-6" }
const _hoisted_25 = { class: "col-6" }
const _hoisted_26 = { class: "col-md-6 mb-3" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-6" }
const _hoisted_29 = { class: "form-check" }
const _hoisted_30 = ["id"]
const _hoisted_31 = ["for"]
const _hoisted_32 = { class: "col-6" }
const _hoisted_33 = { class: "form-check" }
const _hoisted_34 = ["id"]
const _hoisted_35 = ["for"]
const _hoisted_36 = { class: "col-md-6 mb-3" }
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "col-6" }
const _hoisted_39 = { class: "form-check" }
const _hoisted_40 = ["id"]
const _hoisted_41 = ["for"]
const _hoisted_42 = { class: "col-6" }
const _hoisted_43 = { class: "form-check" }
const _hoisted_44 = ["id"]
const _hoisted_45 = ["for"]
const _hoisted_46 = { class: "col-md-6 mb-3" }
const _hoisted_47 = { class: "row" }
const _hoisted_48 = { class: "col-6" }
const _hoisted_49 = { class: "form-check" }
const _hoisted_50 = ["id"]
const _hoisted_51 = ["for"]
const _hoisted_52 = { class: "col-6" }
const _hoisted_53 = { class: "form-check" }
const _hoisted_54 = ["id"]
const _hoisted_55 = ["for"]
const _hoisted_56 = { class: "col-md-6 mb-3" }
const _hoisted_57 = { class: "row" }
const _hoisted_58 = { class: "col-6" }
const _hoisted_59 = { class: "col-6" }
const _hoisted_60 = { class: "col-md-6 mb-3" }
const _hoisted_61 = { class: "row" }
const _hoisted_62 = { class: "col-6" }
const _hoisted_63 = { class: "col-6" }
const _hoisted_64 = { class: "col-md-6 mb-3" }
const _hoisted_65 = { class: "row" }
const _hoisted_66 = { class: "col-6" }
const _hoisted_67 = { class: "col-6" }

import { reactive, watch, onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'sub-designation',
  props: {
    designation: {},
    details: {}
  },
  emits: ['update:details'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Initialize with default values
const defaultDetails = {
  manpower: { male: 0, female: 0 },
  weeklyOff: { male: false, female: false },
  workingHours: { male: 0, female: 0 },
  esiEpf: { male: false, female: false },
  food: { male: false, female: false },
  accommodation: { male: false, female: false },
  deadline: { male: '', female: '' },
  inHandSalary: { male: 0, female: 0 },
  quotedRate: { male: 0, female: 0 },
  serviceStartDate: { male: '', female: '' }
};

// Merge provided details with default values
const localDetails = reactive({
  ...defaultDetails,
  ...props.details
});

watch(localDetails, (newVal) => {
  emit('update:details', newVal);
}, { deep: true });

// Emit initial values
onMounted(() => {
  emit('update:details', localDetails);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.designation), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Number of Manpower", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Male", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localDetails.manpower.male) = $event)),
              min: "0"
            }, null, 512), [
              [_vModelText, localDetails.manpower.male]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Female", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "form-control",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localDetails.manpower.female) = $event)),
              min: "0"
            }, null, 512), [
              [_vModelText, localDetails.manpower.female]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label" }, "Weekly Offs", -1)),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localDetails.weeklyOff.male) = $event)),
                id: `weeklyOff-male-${_ctx.designation}`
              }, null, 8, _hoisted_12), [
                [_vModelCheckbox, localDetails.weeklyOff.male]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: `weeklyOff-male-${_ctx.designation}`
              }, "Male", 8, _hoisted_13)
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((localDetails.weeklyOff.female) = $event)),
                id: `weeklyOff-female-${_ctx.designation}`
              }, null, 8, _hoisted_16), [
                [_vModelCheckbox, localDetails.weeklyOff.female]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: `weeklyOff-female-${_ctx.designation}`
              }, "Female", 8, _hoisted_17)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _cache[26] || (_cache[26] = _createElementVNode("label", { class: "form-label" }, "Working Hours", -1)),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _cache[24] || (_cache[24] = _createElementVNode("label", { class: "form-label" }, "Male", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "form-control",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((localDetails.workingHours.male) = $event)),
              min: "0"
            }, null, 512), [
              [_vModelText, localDetails.workingHours.male]
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _cache[25] || (_cache[25] = _createElementVNode("label", { class: "form-label" }, "Female", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "form-control",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((localDetails.workingHours.female) = $event)),
              min: "0"
            }, null, 512), [
              [_vModelText, localDetails.workingHours.female]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _cache[29] || (_cache[29] = _createElementVNode("label", { class: "form-label" }, "Service Start Date", -1)),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _cache[27] || (_cache[27] = _createElementVNode("label", { class: "form-label" }, "Male", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "date",
              class: "form-control",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((localDetails.serviceStartDate.male) = $event))
            }, null, 512), [
              [_vModelText, localDetails.serviceStartDate.male]
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _cache[28] || (_cache[28] = _createElementVNode("label", { class: "form-label" }, "Female", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "date",
              class: "form-control",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((localDetails.serviceStartDate.female) = $event))
            }, null, 512), [
              [_vModelText, localDetails.serviceStartDate.female]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _cache[30] || (_cache[30] = _createElementVNode("label", { class: "form-label" }, "ESI/EPF", -1)),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((localDetails.esiEpf.male) = $event)),
                id: `esiEpf-male-${_ctx.designation}`
              }, null, 8, _hoisted_30), [
                [_vModelCheckbox, localDetails.esiEpf.male]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: `esiEpf-male-${_ctx.designation}`
              }, "Male", 8, _hoisted_31)
            ])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((localDetails.esiEpf.female) = $event)),
                id: `esiEpf-female-${_ctx.designation}`
              }, null, 8, _hoisted_34), [
                [_vModelCheckbox, localDetails.esiEpf.female]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: `esiEpf-female-${_ctx.designation}`
              }, "Female", 8, _hoisted_35)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_36, [
        _cache[31] || (_cache[31] = _createElementVNode("label", { class: "form-label" }, "Food", -1)),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((localDetails.food.male) = $event)),
                id: `food-male-${_ctx.designation}`
              }, null, 8, _hoisted_40), [
                [_vModelCheckbox, localDetails.food.male]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: `food-male-${_ctx.designation}`
              }, "Male", 8, _hoisted_41)
            ])
          ]),
          _createElementVNode("div", _hoisted_42, [
            _createElementVNode("div", _hoisted_43, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((localDetails.food.female) = $event)),
                id: `food-female-${_ctx.designation}`
              }, null, 8, _hoisted_44), [
                [_vModelCheckbox, localDetails.food.female]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: `food-female-${_ctx.designation}`
              }, "Female", 8, _hoisted_45)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_46, [
        _cache[32] || (_cache[32] = _createElementVNode("label", { class: "form-label" }, "Accommodation", -1)),
        _createElementVNode("div", _hoisted_47, [
          _createElementVNode("div", _hoisted_48, [
            _createElementVNode("div", _hoisted_49, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((localDetails.accommodation.male) = $event)),
                id: `accommodation-male-${_ctx.designation}`
              }, null, 8, _hoisted_50), [
                [_vModelCheckbox, localDetails.accommodation.male]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: `accommodation-male-${_ctx.designation}`
              }, "Male", 8, _hoisted_51)
            ])
          ]),
          _createElementVNode("div", _hoisted_52, [
            _createElementVNode("div", _hoisted_53, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((localDetails.accommodation.female) = $event)),
                id: `accommodation-female-${_ctx.designation}`
              }, null, 8, _hoisted_54), [
                [_vModelCheckbox, localDetails.accommodation.female]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: `accommodation-female-${_ctx.designation}`
              }, "Female", 8, _hoisted_55)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_56, [
        _cache[35] || (_cache[35] = _createElementVNode("label", { class: "form-label" }, "Deadline", -1)),
        _createElementVNode("div", _hoisted_57, [
          _createElementVNode("div", _hoisted_58, [
            _cache[33] || (_cache[33] = _createElementVNode("label", { class: "form-label" }, "Male", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "date",
              class: "form-control",
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((localDetails.deadline.male) = $event))
            }, null, 512), [
              [_vModelText, localDetails.deadline.male]
            ])
          ]),
          _createElementVNode("div", _hoisted_59, [
            _cache[34] || (_cache[34] = _createElementVNode("label", { class: "form-label" }, "Female", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "date",
              class: "form-control",
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((localDetails.deadline.female) = $event))
            }, null, 512), [
              [_vModelText, localDetails.deadline.female]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_60, [
        _cache[38] || (_cache[38] = _createElementVNode("label", { class: "form-label" }, "In-Hand Salary", -1)),
        _createElementVNode("div", _hoisted_61, [
          _createElementVNode("div", _hoisted_62, [
            _cache[36] || (_cache[36] = _createElementVNode("label", { class: "form-label" }, "Male", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "form-control",
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((localDetails.inHandSalary.male) = $event)),
              min: "0"
            }, null, 512), [
              [_vModelText, localDetails.inHandSalary.male]
            ])
          ]),
          _createElementVNode("div", _hoisted_63, [
            _cache[37] || (_cache[37] = _createElementVNode("label", { class: "form-label" }, "Female", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "form-control",
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((localDetails.inHandSalary.female) = $event)),
              min: "0"
            }, null, 512), [
              [_vModelText, localDetails.inHandSalary.female]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_64, [
        _cache[41] || (_cache[41] = _createElementVNode("label", { class: "form-label" }, "Quoted Rate", -1)),
        _createElementVNode("div", _hoisted_65, [
          _createElementVNode("div", _hoisted_66, [
            _cache[39] || (_cache[39] = _createElementVNode("label", { class: "form-label" }, "Male", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "form-control",
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((localDetails.quotedRate.male) = $event)),
              min: "0"
            }, null, 512), [
              [_vModelText, localDetails.quotedRate.male]
            ])
          ]),
          _createElementVNode("div", _hoisted_67, [
            _cache[40] || (_cache[40] = _createElementVNode("label", { class: "form-label" }, "Female", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "form-control",
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((localDetails.quotedRate.female) = $event)),
              min: "0"
            }, null, 512), [
              [_vModelText, localDetails.quotedRate.female]
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})