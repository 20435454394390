import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  key: 0,
  class: "text-center py-4"
}
const _hoisted_4 = {
  key: 1,
  class: "col-md-12 w-full"
}
const _hoisted_5 = { class: "col-md-12" }
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = { class: "employee-photo mb-3" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "mt-2" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "employee-details" }
const _hoisted_12 = { class: "mb-2" }
const _hoisted_13 = { class: "mb-2" }
const _hoisted_14 = { class: "mb-2" }
const _hoisted_15 = { class: "mb-2" }
const _hoisted_16 = { class: "mb-2" }
const _hoisted_17 = { class: "mb-2" }
const _hoisted_18 = { class: "mb-2" }
const _hoisted_19 = { class: "mb-2" }
const _hoisted_20 = { class: "mb-2" }
const _hoisted_21 = { class: "mb-2" }
const _hoisted_22 = { class: "mb-2" }
const _hoisted_23 = { class: "mt-4" }
const _hoisted_24 = { class: "ms-3" }
const _hoisted_25 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_26 = { class: "table-responsive" }
const _hoisted_27 = { class: "table table-bordered" }
const _hoisted_28 = { class: "document-downloads mt-4" }
const _hoisted_29 = { class: "list-group" }
const _hoisted_30 = { class: "list-group-item" }
const _hoisted_31 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_32 = ["disabled"]
const _hoisted_33 = { class: "list-group-item" }
const _hoisted_34 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_35 = ["disabled"]
const _hoisted_36 = { class: "list-group-item" }
const _hoisted_37 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_38 = ["disabled"]
const _hoisted_39 = { class: "list-group-item" }
const _hoisted_40 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_41 = ["disabled"]
const _hoisted_42 = { class: "mt-4" }
const _hoisted_43 = { class: "mb-3" }
const _hoisted_44 = { class: "table-responsive" }
const _hoisted_45 = { class: "table table-bordered" }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = { class: "mt-4" }
const _hoisted_48 = { class: "table-responsive" }
const _hoisted_49 = { class: "table table-bordered" }
const _hoisted_50 = { key: 0 }
const _hoisted_51 = { class: "mt-4" }
const _hoisted_52 = { class: "table-responsive" }
const _hoisted_53 = { class: "table table-bordered" }
const _hoisted_54 = { key: 0 }
const _hoisted_55 = { class: "mt-4" }
const _hoisted_56 = { class: "mb-2" }
const _hoisted_57 = { class: "table-responsive" }
const _hoisted_58 = { class: "table table-bordered" }
const _hoisted_59 = { key: 0 }
const _hoisted_60 = { class: "d-flex align-items-center" }
const _hoisted_61 = { class: "ms-2 text-muted" }
const _hoisted_62 = { key: 0 }
const _hoisted_63 = { key: 1 }
const _hoisted_64 = { key: 0 }
const _hoisted_65 = { key: 1 }
const _hoisted_66 = { class: "mt-4" }
const _hoisted_67 = { class: "table-responsive" }
const _hoisted_68 = { class: "table table-bordered" }
const _hoisted_69 = { key: 0 }
const _hoisted_70 = { class: "position-details" }
const _hoisted_71 = { class: "position-details" }
const _hoisted_72 = { class: "approver-details" }
const _hoisted_73 = { class: "text-muted" }
const _hoisted_74 = { class: "remarks-details" }
const _hoisted_75 = { class: "mt-4" }
const _hoisted_76 = { class: "table-responsive" }
const _hoisted_77 = { class: "table table-bordered" }
const _hoisted_78 = { key: 0 }
const _hoisted_79 = { class: "d-flex align-items-center" }
const _hoisted_80 = {
  key: 0,
  class: "ms-2 badge bg-secondary",
  title: "Confidential"
}
const _hoisted_81 = { class: "badge bg-info" }
const _hoisted_82 = {
  key: 0,
  class: "attachment-list"
}
const _hoisted_83 = ["href"]
const _hoisted_84 = { key: 1 }
const _hoisted_85 = { class: "mt-4" }
const _hoisted_86 = { class: "table-responsive" }
const _hoisted_87 = { class: "table table-bordered" }
const _hoisted_88 = { key: 0 }
const _hoisted_89 = { class: "badge bg-info" }
const _hoisted_90 = { key: 0 }
const _hoisted_91 = { class: "d-flex flex-column gap-1" }
const _hoisted_92 = {
  key: 0,
  class: "attachment-list"
}
const _hoisted_93 = ["href"]
const _hoisted_94 = { key: 1 }

import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { format, addMonths as dateAddMonths } from 'date-fns';
import { fetchGetApi } from '@/util/api';
import { useRoute } from 'vue-router';

interface Document {
    type: string;
    status: 'pending' | 'uploaded' | 'verified';
    url?: string;
}

interface PenaltySlip {
    date: string;
    siteName: string;
    penaltySlip: string;
    amount: number;
    penaltyReason: string;
    warningSlip: string;
    warningReason: string;
    good: boolean;
}

interface Employee {
    id: number;
    photoUrl: string;
    name: string;
    doj: string;
    dob: string;
    siddarDate: string;
    reason: string;
    jobLeft: string;
    blacklisted: boolean;
    blacklistReason?: string;
    documents: {
        aadharCard?: { url: string };
        panCard?: { url: string };
        voterCard?: { url: string };
        bankPassbook?: { url: string };
    };
    documentsList: Document[];
    penaltySlips: PenaltySlip[];
    workingRecords: WorkingRecord[];
    reinstateRecords: ReinstateRecord[];
    reInstantDate: string;
    reInstantReason: string;
    complaints: Complaint[];
    uniformRecords: UniformRecord[];
    promotionRecords: PromotionRecord[];
    reinstateInfo: ReinstateInfo;
}

interface WorkingRecord {
    id: number;
    startDate: string;
    siteName: string;
    department: string;
    transferTo: string;
    relieverDate: string;
    relieverSiteName: string;
}

interface ReinstateRecord {
    id: number;
    doj: string;
    site: string;
    department: string;
    post: string;
    siddarTypeAndDate: string;
    reason: string;
    leftOn: string;
    siddarBy: string;
    reinstateBy: string;
}

interface Complaint {
    id: number;
    date: string;
    offenceCategory: string;
    subCategory: string;
    warningType: string;
    hasSlip: boolean;
    by: string;
}

interface UniformDeductions {
    month1: number;
    month2: number;
    month3: number;
}

interface UniformRecord {
    id: number;
    date: string;
    itemsIssued: string;
    amountTotal: number;
    deductions: UniformDeductions;
    badDebt: number;
    reason: string;
    uniformReturn: boolean;
    originalType: string;
    docReturnDate: string;
}

interface PromotionRecord {
    id: number;
    doj: string;
    siteName: string;
    department: string;
    post: string;
}

interface ReinstateInfo {
    by: string;
    date: string;
    siteName: string;
    reason: string;
}

// Add these interfaces for the API response
interface ApiResponse {
    success: boolean;
    data: EmployeeApiData;
}

interface EmployeeApiData {
    experience: {
        details: string;
        documents: any[];
    };
    legalInformation: {
        criminalOffence: {
            exists: boolean;
            cases: any[];
        };
        pendingProceedings: {
            exists: boolean;
            cases: any[];
        };
    };
    addressVerification: {
        familyVerification: Array<{
            memberName: string;
            relationship: string;
            contactNo: string;
            verifiedBy: any[];
            _id: string;
        }>;
        friendsVerification: Array<{
            memberName: string;
            relationship: string;
            contactNo: string;
            verifiedBy: any[];
            _id: string;
        }>;
    };
    documents: {
        signature?: DocumentFile;
        passportPhoto?: DocumentFile;
        panCard?: DocumentFile;
        aadharCard?: DocumentFile;
        voterCard?: DocumentFile;
        bankPassbook?: DocumentFile;
    };
    _id: string;
    employeePrejoinToken: string;
    employeeName: string;
    employeeCode: string;
    fathersName: string;
    dateOfBirth: string;
    education: string;
    character: string;
    medicalCategory: string;
    languagesKnown: string[];
    sex: string;
    permanentAddress: Address;
    localAddress: Address;
    currentPlace: string;
    familyDetails: FamilyMember[];
    previousNames: PreviousName[];
    employeeType: string;
    email: string;
    isUniformGiven: boolean;
    status: string;
    blacklisted: boolean;
    createdAt: string;
    updatedAt: string;
}

interface Address {
    village: string;
    postOffice: string;
    policeStation: string;
    district: string;
    state: string;
    pin: string;
    phoneNumber: string;
    alternatePhoneNumber: string;
    alternatePhoneRelation: string;
    _id: string;
}

interface FamilyMember {
    name: string;
    relationship: string;
    age: number;
    occupation: string;
    contactNo: string;
    _id: string;
}

interface PreviousName {
    name: string;
    changedDate: string;
    reason: string;
    _id: string;
}

interface DocumentFile {
    key: string;
    url: string;
    _id: string;
}

// Add interface for uniform data
interface UniformItem {
    itemName: string;
    size: string;
    quantity: number;
    issueDate: string;
    condition: string;
    status: string;
    _id: string;
}

interface UniformData {
    _id: string;
    employeeId: {
        _id: string;
        employeeName: string;
        employeeCode: string;
    };
    employeeName: string;
    departmentId: {
        _id: string;
        name: string;
    };
    siteId: {
        _id: string;
        siteName: string;
    };
    uniformItems: UniformItem[];
    status: string;
    lastIssueDate: string;
    nextRenewalDate: string;
    createdAt: string;
    updatedAt: string;
}

// Add this interface
interface SiddarRecord {
    _id: string;
    dateOfSiddar: string;
    type: string;
    vacentType: string;
    reason: string;
    daysWorked: number;
    status: string;
    statusOfSiddar: string;
    dressNotReturnedReason: string;
    deadlineToFillUp: string;
}

// Add this interface
interface ReinstateRecord {
    _id: string;
    empId: string;
    empName: string;
    lastSite: string;
    Department: string;
    Designation: string;
    ReasonOfleaving: string;
    DateOfSiddar: string;
    TimeOfSiddar: string;
    siddarByEmpId: string;
    siddarBy: string;
    lastJobTenure: string;
    numberOfReinstate: string;
    loseToCompany: string;
    reasonToReinstate: string;
    status: string;
    createdAt: string;
    updatedAt: string;
}

// Add these interfaces
interface TransferDetails {
    transferredTo: {
        siteId: string;
        siteName: string;
        transferDate: string;
        reason: string;
    };
}

interface RelieverDetails {
    relieverDate: string;
    relieverSiteId: string;
    relieverSiteName: string;
    reason: string;
}

// Add these interfaces
interface WorkingRecord {
    _id: string;
    startDate: string;
    endDate: string | null;
    siteId: string;
    siteName: string;
    departmentId: string;
    departmentName: string;
    salary: number;
    workingHours: string;
    performanceRating: number;
    remarks: string;
    transferDetails?: TransferDetails;
    relieverDetails?: RelieverDetails;
}

interface SiteWorkingData {
    _id: string;
    employeeId: string;
    employeeName: string;
    records: WorkingRecord[];
    totalExperience: string;
    createdAt: string;
    updatedAt: string;
}

// Add these interfaces
interface Position {
    title: string;
    department: string;
    salary: number;
}

interface Approver {
    name: string;
    date: string;
}

interface Promotion {
    _id: string;
    previousPosition: Position;
    newPosition: Position;
    approvedBy: Approver;
    promotionDate: string;
    effectiveDate: string;
    promotionType: string;
    reason: string;
    status: string;
    remarks: string;
}

interface PromotionData {
    _id: string;
    employeeId: string;
    employeeName: string;
    promotions: Promotion[];
    createdAt: string;
    updatedAt: string;
}

// Add these interfaces
interface Attachment {
    _id: string;
    fileName: string;
    fileUrl: string;
}

interface Complaint {
    _id: string;
    title: string;
    description: string;
    category: string;
    status: string;
    confidential: boolean;
    attachments: Attachment[];
    dateSubmitted: string
}

interface ComplaintData {
    _id: string;
    employeeId: string;
    employeeName: string;
    complaints: Complaint[];
    createdAt: string;
    updatedAt: string;
}

// Add these interfaces
interface PenaltyDetails {
    type: string;
    amount: number;
    details: string;
}

interface Acknowledgement {
    acknowledged: boolean;
    comments: string;
}

interface AppealDetails {
    date: string | null;
    description: string;
    decision: string;
    decidedBy: string | null;
}

interface PenaltyAttachment {
    _id: string;
    fileName: string;
    fileUrl: string;
    uploadDate: string;
}

interface PenaltySlip {
    _id: string;
    type: string;
    title: string;
    description: string;
    category: string;
    severity: string;
    dateIssued: string;
    issuedBy: string;
    penalty: PenaltyDetails;
    acknowledgement: Acknowledgement;
    appealDetails: AppealDetails;
    attachments: PenaltyAttachment[];
    appealStatus: string;
}

interface PenaltyData {
    status: string;
    data: {
        _id: string;
        employeeId: string;
        employeeName: string;
        slips: PenaltySlip[];
        createdAt: string;
        updatedAt: string;
    };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'employee-job-life',
  setup(__props) {

const toast = useToast();

const baseUrl = process.env.VUE_APP_BASE_URL
// Update the setup script
const route = useRoute();
const employeeId = route.params.id as string;

// Modify the employee ref to include API data
const employee = ref<Employee>({
    id: 1,
    photoUrl: '',
    name: 'John Doe',
    doj: '2024-01-15',
    dob: '1990-05-20',
    siddarDate: '2024-02-01',
    reason: 'Regular employment',
    jobLeft: 'No',
    blacklisted: false,
    documents: {
        aadharCard: { url: '' },
        panCard: { url: '' },
        voterCard: { url: '' },
        bankPassbook: { url: '' }
    },
    documentsList: [
        { type: 'joiningForm', status: 'verified' },
        { type: 'declaration', status: 'verified' },
        { type: 'scans', status: 'verified' },
        { type: 'pvSent', status: 'verified' },
        { type: 'pvReturn', status: 'pending' },
        { type: 'esicDoc', status: 'uploaded' },
        { type: 'esicSigned', status: 'pending' },
        { type: 'resignationSlip', status: 'pending' }
    ],
    penaltySlips: [
        {
            date: '2024-02-15',
            siteName: 'Site A',
            penaltySlip: 'PS001',
            amount: 500,
            penaltyReason: 'Late arrival',
            warningSlip: 'WS001',
            warningReason: 'Repeated late arrivals',
            good: false
        }
    ],
    workingRecords: [
        {
            id: 1,
            startDate: '2024-01-15',
            siteName: 'Site 1',
            department: 'Security',
            transferTo: 'Site 2',
            relieverDate: '2024-02-01',
            relieverSiteName: 'Site 3'
        }
    ],
    reinstateRecords: [
        {
            id: 1,
            doj: '2024-01-15',
            site: 'Site A',
            department: 'Security',
            post: 'Guard',
            siddarTypeAndDate: 'Type A - 2024-02-01',
            reason: 'Performance',
            leftOn: '2024-01-30',
            siddarBy: 'John Manager',
            reinstateBy: 'Jane Supervisor'
        }
    ],
    reInstantDate: '2024-02-15',
    reInstantReason: 'Good past performance',
    complaints: [
        {
            id: 1,
            date: '2024-02-10',
            offenceCategory: 'Attendance',
            subCategory: 'Late arrival',
            warningType: 'Verbal',
            hasSlip: true,
            by: 'Site Supervisor'
        }
    ],
    uniformRecords: [
        {
            id: 1,
            date: '2024-01-15',
            itemsIssued: 'Uniform Set, Shoes',
            amountTotal: 2500,
            deductions: {
                month1: 800,
                month2: 800,
                month3: 900
            },
            badDebt: 0,
            reason: 'Regular issue',
            uniformReturn: false,
            originalType: 'Standard Issue',
            docReturnDate: '2024-04-15'
        }
    ],
    promotionRecords: [
        {
            id: 1,
            doj: '2024-01-15',
            siteName: 'Site A',
            department: 'Security',
            post: 'Senior Guard'
        }
    ],
    reinstateInfo: {
        by: 'John Manager',
        date: '2024-02-15',
        siteName: 'Site B',
        reason: 'Performance improvement'
    }
});

// Add loading state
const loading = ref(true);

// Add fetch function
const fetchEmployeeData = async () => {
    try {
        loading.value = true;
        const response = await fetchGetApi<ApiResponse>(`api/joining/employee/${employeeId}`);
        const apiData = response.data.data;

        employee.value = {
            // Basic Info
            photoUrl: apiData.documents.passportPhoto?.url || '',
            name: apiData.employeeName,
            employeeCode: apiData.employeeCode,
            fathersName: apiData.fathersName,
            dob: apiData.dateOfBirth,
            education: apiData.education,
            character: apiData.character,
            medicalCategory: apiData.medicalCategory,
            languagesKnown: apiData.languagesKnown,
            sex: apiData.sex,
            blacklisted: apiData.blacklisted,
            doj: apiData.createdAt,

            // Documents
            documents: {
                aadharCard: apiData.documents.aadharCard,
                panCard: apiData.documents.panCard,
                voterCard: apiData.documents.voterCard,
                bankPassbook: apiData.documents.bankPassbook,
            },

            // Address
            permanentAddress: apiData.permanentAddress,

            // Family Details
            familyDetails: apiData.familyDetails,

            // Previous Names
            previousNames: apiData.previousNames,

            // Initialize empty arrays for other sections that aren't in the API
            penaltySlips: [],
            workingRecords: [],
            reinstateRecords: [],
            complaints: [],
            uniformRecords: [],
            promotionRecords: []
        };

    } catch (error) {
        toast.error('Failed to fetch employee data');
        console.error('Error fetching employee data:', error);
    } finally {
        loading.value = false;
    }
};

// Add ref for uniform data
const uniformData = ref<UniformData | null>(null);

// Add function to fetch uniform data
const fetchUniformData = async () => {
    try {
        const response = await fetchGetApi(`api/uniform/employee/${employeeId}`);
        uniformData.value = response.data;
    } catch (error) {
        console.error('Error fetching uniform data:', error);
        toast.error('Failed to fetch uniform data');
    }
};

// Add this ref
const siddarData = ref<SiddarRecord[]>([]);

// Add this function to fetch siddar data
const fetchSiddarData = async () => {
    try {
        const response = await fetchGetApi(`api/siddar/get/employeeId/${employeeId}`);
        siddarData.value = response.data;
    } catch (error) {
        console.error('Error fetching siddar data:', error);
        toast.error('Failed to fetch siddar data');
    }
};

// Add this ref
const reinstateData = ref<ReinstateRecord[]>([]);

// Add this function to fetch reinstate data
const fetchReinstateData = async () => {
    try {
        const response = await fetchGetApi(`api/reinstate/get/${employeeId}`);
        reinstateData.value = response.data;
    } catch (error) {
        console.error('Error fetching reinstate data:', error);
        toast.error('Failed to fetch reinstate data');
    }
};

// Add this ref
const siteWorkingData = ref<SiteWorkingData | null>(null);

// Add this function to fetch site working data
const fetchSiteWorkingData = async () => {
    try {
        const response = await fetchGetApi(`api/siteWorkingRecord/employee/${employeeId}`);
        siteWorkingData.value = response.data;
    } catch (error) {
        console.error('Error fetching site working data:', error);
        toast.error('Failed to fetch site working data');
    }
};

// Add this ref
const promotionData = ref<PromotionData | null>(null);

// Add this function to fetch promotion data
const fetchPromotionData = async () => {
    try {
        const response = await fetchGetApi(`api/promotion-record/employee/${employeeId}`);
        promotionData.value = response.data;
    } catch (error) {
        console.error('Error fetching promotion data:', error);
        toast.error('Failed to fetch promotion data');
    }
};

// Add this ref
const complaintData = ref<ComplaintData | null>(null);

// Add this function to fetch complaint data
const fetchComplaintData = async () => {
    try {
        const response = await fetchGetApi(`api/complaint-record/employee/${employeeId}`);
        complaintData.value = response.data;
    } catch (error) {
        console.error('Error fetching complaint data:', error);
        toast.error('Failed to fetch complaint data');
    }
};

// Add this ref
const penaltyData = ref<PenaltyData | null>(null);

// Add this function to fetch penalty data
const fetchPenaltyData = async () => {
    try {
        const response = await fetchGetApi(`api/penalty-warning-slip/${employeeId}`);
        penaltyData.value = response.data;
    } catch (error) {
        console.error('Error fetching penalty data:', error);
        toast.error('Failed to fetch penalty and warning slip data');
    }
};

// Update onMounted to include the new fetch
onMounted(() => {
    fetchEmployeeData();
    fetchUniformData();
    fetchSiddarData();
    fetchReinstateData();
    fetchSiteWorkingData();
    fetchPromotionData();
    fetchComplaintData();
    fetchPenaltyData();
});

const formatDate = (date: string) => {
    if (!date) return '';
    return format(new Date(date), 'dd/MM/yyyy');
};

const getDocumentStatus = (docType: 'aadharCard' | 'panCard' | 'voterCard' | 'bankPassbook') => {
    return employee.value.documents[docType]?.url ? '(Available)' : '(Not Available)';
};

const downloadDocument = async (docType: 'aadharCard' | 'panCard' | 'voterCard' | 'bankPassbook') => {
    try {
        const docUrl = employee.value.documents[docType]?.url;
        if (docUrl) {
            window.open(docUrl, '_blank');
        } else {
            toast.error('Document not available');
        }
    } catch (error) {
        console.error(`Error downloading ${docType}:`, error);
        toast.error(`Failed to download ${docType}`);
    }
};

// Add this helper function
const addMonths = (date: string, months: number) => {
    if (!date) return '';
    return format(dateAddMonths(new Date(date), months), 'dd/MM/yyyy');
};

// Add this function in the script section
const downloadPhoto = async () => {
    try {
        if (employee.value.photoUrl) {
            window.open(employee.value.photoUrl, '_blank');
        } else {
            toast.error('Photo not available');
        }
    } catch (error) {
        console.error('Error downloading photo:', error);
        toast.error('Failed to download photo');
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[72] || (_cache[72] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h5", null, "Employee Job Life (EJL)")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (loading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[5] || (_cache[5] = [
            _createElementVNode("div", {
              class: "spinner-border",
              role: "status"
            }, [
              _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
            ], -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: employee.value.photoUrl || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsEJHmI0MlIGvH9CYkbsLEWQ5_ee8Qtl5V-Q&s',
                    alt: "Employee Photo",
                    class: "img-thumbnail",
                    style: {"max-width":"200px"}
                  }, null, 8, _hoisted_8),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("button", {
                      class: "btn btn-sm btn-outline-primary",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (downloadPhoto())),
                      disabled: !employee.value.photoUrl
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("i", { class: "bi bi-download" }, null, -1),
                      _createTextVNode(" Download Photo ")
                    ]), 8, _hoisted_10)
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Black listed:", -1)),
                    _createTextVNode(" " + _toDisplayString(employee.value.blacklisted ? 'Yes' : 'No'), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[8] || (_cache[8] = _createElementVNode("strong", null, "Name:", -1)),
                    _createTextVNode(" " + _toDisplayString(employee.value.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _cache[9] || (_cache[9] = _createElementVNode("strong", null, "DOJ:", -1)),
                    _createTextVNode(" " + _toDisplayString(formatDate(employee.value.doj)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[10] || (_cache[10] = _createElementVNode("strong", null, "DOB:", -1)),
                    _createTextVNode(" " + _toDisplayString(formatDate(employee.value.dob)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _cache[11] || (_cache[11] = _createElementVNode("strong", null, "Employee Code:", -1)),
                    _createTextVNode(" " + _toDisplayString(employee.value.employeeCode), 1)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Father's Name:", -1)),
                    _createTextVNode(" " + _toDisplayString(employee.value.fathersName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Education:", -1)),
                    _createTextVNode(" " + _toDisplayString(employee.value.education), 1)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Character:", -1)),
                    _createTextVNode(" " + _toDisplayString(employee.value.character), 1)
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Medical Category:", -1)),
                    _createTextVNode(" " + _toDisplayString(employee.value.medicalCategory), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Languages:", -1)),
                    _createTextVNode(" " + _toDisplayString(employee.value.languagesKnown?.join(', ')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _cache[17] || (_cache[17] = _createElementVNode("strong", null, "Gender:", -1)),
                    _createTextVNode(" " + _toDisplayString(employee.value.sex), 1)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _cache[18] || (_cache[18] = _createElementVNode("h6", null, "Permanent Address", -1)),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", null, _toDisplayString(employee.value.permanentAddress?.village), 1),
                      _createElementVNode("div", null, _toDisplayString(employee.value.permanentAddress?.postOffice), 1),
                      _createElementVNode("div", null, _toDisplayString(employee.value.permanentAddress?.district) + ", " + _toDisplayString(employee.value.permanentAddress?.state), 1),
                      _createElementVNode("div", null, "PIN: " + _toDisplayString(employee.value.permanentAddress?.pin), 1),
                      _createElementVNode("div", null, "Phone: " + _toDisplayString(employee.value.permanentAddress?.phoneNumber), 1)
                    ])
                  ]),
                  (employee.value.previousNames?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _cache[20] || (_cache[20] = _createElementVNode("h6", null, "Previous Names", -1)),
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("table", _hoisted_27, [
                            _cache[19] || (_cache[19] = _createElementVNode("thead", null, [
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", null, "Previous Name"),
                                _createElementVNode("th", null, "Changed Date"),
                                _createElementVNode("th", null, "Reason")
                              ])
                            ], -1)),
                            _createElementVNode("tbody", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(employee.value.previousNames, (name) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: name._id
                                }, [
                                  _createElementVNode("td", null, _toDisplayString(name.name), 1),
                                  _createElementVNode("td", null, _toDisplayString(formatDate(name.changedDate)), 1),
                                  _createElementVNode("td", null, _toDisplayString(name.reason), 1)
                                ]))
                              }), 128))
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_28, [
                    _cache[25] || (_cache[25] = _createElementVNode("h6", null, "Documents", -1)),
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("span", null, "Aadhar Card " + _toDisplayString(getDocumentStatus('aadharCard')), 1),
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-outline-primary",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (downloadDocument('aadharCard'))),
                            disabled: !employee.value.documents.aadharCard?.url
                          }, _cache[21] || (_cache[21] = [
                            _createElementVNode("i", { class: "bi bi-download" }, null, -1),
                            _createTextVNode(" Download ")
                          ]), 8, _hoisted_32)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("span", null, "Pan Card " + _toDisplayString(getDocumentStatus('panCard')), 1),
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-outline-primary",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (downloadDocument('panCard'))),
                            disabled: !employee.value.documents.panCard?.url
                          }, _cache[22] || (_cache[22] = [
                            _createElementVNode("i", { class: "bi bi-download" }, null, -1),
                            _createTextVNode(" Download ")
                          ]), 8, _hoisted_35)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("span", null, "Voter Card " + _toDisplayString(getDocumentStatus('voterCard')), 1),
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-outline-primary",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (downloadDocument('voterCard'))),
                            disabled: !employee.value.documents.voterCard?.url
                          }, _cache[23] || (_cache[23] = [
                            _createElementVNode("i", { class: "bi bi-download" }, null, -1),
                            _createTextVNode(" Download ")
                          ]), 8, _hoisted_38)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_39, [
                        _createElementVNode("div", _hoisted_40, [
                          _createElementVNode("span", null, "Bank Passbook " + _toDisplayString(getDocumentStatus('bankPassbook')), 1),
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-outline-primary",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (downloadDocument('bankPassbook'))),
                            disabled: !employee.value.documents.bankPassbook?.url
                          }, _cache[24] || (_cache[24] = [
                            _createElementVNode("i", { class: "bi bi-download" }, null, -1),
                            _createTextVNode(" Download ")
                          ]), 8, _hoisted_41)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_42, [
                    _cache[31] || (_cache[31] = _createElementVNode("h6", null, "Uniform Details", -1)),
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("div", null, [
                        _cache[26] || (_cache[26] = _createElementVNode("strong", null, "Last Issue Date:", -1)),
                        _createTextVNode(" " + _toDisplayString(formatDate(uniformData.value?.lastIssueDate)), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _cache[27] || (_cache[27] = _createElementVNode("strong", null, "Next Renewal Date:", -1)),
                        _createTextVNode(" " + _toDisplayString(formatDate(uniformData.value?.nextRenewalDate)), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_44, [
                      _createElementVNode("table", _hoisted_45, [
                        _cache[30] || (_cache[30] = _createElementVNode("thead", { class: "table-light" }, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Date"),
                            _createElementVNode("th", null, "Items"),
                            _createElementVNode("th", null, "Quantity"),
                            _createElementVNode("th", null, "Monthly Deduction"),
                            _createElementVNode("th", null, "Condition"),
                            _createElementVNode("th", null, "Status")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (!uniformData.value?.uniformItems?.length)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_46, _cache[28] || (_cache[28] = [
                                _createElementVNode("td", {
                                  colspan: "6",
                                  class: "text-center"
                                }, "No uniform items found", -1)
                              ])))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uniformData.value?.uniformItems, (item) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: item._id
                            }, [
                              _createElementVNode("td", null, _toDisplayString(formatDate(item.issueDate)), 1),
                              _createElementVNode("td", null, _toDisplayString(item.itemName) + " (" + _toDisplayString(item.size) + ")", 1),
                              _createElementVNode("td", null, _toDisplayString(item.quantity), 1),
                              _cache[29] || (_cache[29] = _createElementVNode("td", null, "-", -1)),
                              _createElementVNode("td", null, _toDisplayString(item.condition), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", {
                                  class: _normalizeClass({
                                                        'badge bg-success': item.status === 'Issued',
                                                        'badge bg-warning': item.status === 'Pending',
                                                        'badge bg-danger': item.status === 'Returned'
                                                    })
                                }, _toDisplayString(item.status), 3)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _cache[34] || (_cache[34] = _createElementVNode("h6", null, "Siddar Details", -1)),
                    _createElementVNode("div", _hoisted_48, [
                      _createElementVNode("table", _hoisted_49, [
                        _cache[33] || (_cache[33] = _createElementVNode("thead", { class: "table-light" }, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Date of Siddar"),
                            _createElementVNode("th", null, "Type"),
                            _createElementVNode("th", null, "Vacant Type"),
                            _createElementVNode("th", null, "Reason"),
                            _createElementVNode("th", null, "Days Worked"),
                            _createElementVNode("th", null, "Status")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (!siddarData.value?.length)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_50, _cache[32] || (_cache[32] = [
                                _createElementVNode("td", {
                                  colspan: "6",
                                  class: "text-center"
                                }, "No siddar records found", -1)
                              ])))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(siddarData.value, (record) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: record._id
                            }, [
                              _createElementVNode("td", null, _toDisplayString(formatDate(record.dateOfSiddar)), 1),
                              _createElementVNode("td", null, _toDisplayString(record.type), 1),
                              _createElementVNode("td", null, _toDisplayString(record.vacentType), 1),
                              _createElementVNode("td", null, _toDisplayString(record.reason), 1),
                              _createElementVNode("td", null, _toDisplayString(record.daysWorked), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", {
                                  class: _normalizeClass({
                                                        'badge bg-warning': record.status === 'Pending',
                                                        'badge bg-success': record.status === 'Approved',
                                                        'badge bg-danger': record.status === 'Rejected'
                                                    })
                                }, _toDisplayString(record.status), 3)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_51, [
                    _cache[37] || (_cache[37] = _createElementVNode("h6", null, "Reinstate Details", -1)),
                    _createElementVNode("div", _hoisted_52, [
                      _createElementVNode("table", _hoisted_53, [
                        _cache[36] || (_cache[36] = _createElementVNode("thead", { class: "table-light" }, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Date of Siddar"),
                            _createElementVNode("th", null, "Time of Siddar"),
                            _createElementVNode("th", null, "Siddar By"),
                            _createElementVNode("th", null, "Last Job Tenure"),
                            _createElementVNode("th", null, "Reason of Leaving"),
                            _createElementVNode("th", null, "Reason to Reinstate"),
                            _createElementVNode("th", null, "Date of Reinstate"),
                            _createElementVNode("th", null, "Status")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (!reinstateData.value?.length)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_54, _cache[35] || (_cache[35] = [
                                _createElementVNode("td", {
                                  colspan: "8",
                                  class: "text-center"
                                }, "No reinstate records found", -1)
                              ])))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reinstateData.value, (record) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: record._id
                            }, [
                              _createElementVNode("td", null, _toDisplayString(record.DateOfSiddar), 1),
                              _createElementVNode("td", null, _toDisplayString(record.TimeOfSiddar), 1),
                              _createElementVNode("td", null, _toDisplayString(record.siddarBy), 1),
                              _createElementVNode("td", null, _toDisplayString(record.lastJobTenure) + " days", 1),
                              _createElementVNode("td", null, _toDisplayString(record.ReasonOfleaving), 1),
                              _createElementVNode("td", null, _toDisplayString(record.reasonToReinstate), 1),
                              _createElementVNode("td", null, _toDisplayString(formatDate(record.createdAt)), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", {
                                  class: _normalizeClass({
                                                        'badge bg-warning': record.status === 'Pending',
                                                        'badge bg-success': record.status === 'Approved',
                                                        'badge bg-danger': record.status === 'Rejected'
                                                    })
                                }, _toDisplayString(record.status), 3)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_55, [
                    _cache[45] || (_cache[45] = _createElementVNode("h6", null, "Site Working Details", -1)),
                    _createElementVNode("div", _hoisted_56, [
                      _cache[38] || (_cache[38] = _createElementVNode("strong", null, "Total Experience:", -1)),
                      _createTextVNode(" " + _toDisplayString(siteWorkingData.value?.totalExperience), 1)
                    ]),
                    _createElementVNode("div", _hoisted_57, [
                      _createElementVNode("table", _hoisted_58, [
                        _cache[44] || (_cache[44] = _createElementVNode("thead", { class: "table-light" }, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Duration"),
                            _createElementVNode("th", null, "Site"),
                            _createElementVNode("th", null, "Department"),
                            _createElementVNode("th", null, "Working Hours"),
                            _createElementVNode("th", null, "Performance"),
                            _createElementVNode("th", null, "Transfer Details"),
                            _createElementVNode("th", null, "Reliever Details")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (!siteWorkingData.value?.records?.length)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_59, _cache[39] || (_cache[39] = [
                                _createElementVNode("td", {
                                  colspan: "7",
                                  class: "text-center"
                                }, "No working records found", -1)
                              ])))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(siteWorkingData.value?.records, (record) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: record._id
                            }, [
                              _createElementVNode("td", null, _toDisplayString(formatDate(record.startDate)) + " - " + _toDisplayString(record.endDate ? formatDate(record.endDate) : 'Present'), 1),
                              _createElementVNode("td", null, _toDisplayString(record.siteName), 1),
                              _createElementVNode("td", null, _toDisplayString(record.departmentName), 1),
                              _createElementVNode("td", null, _toDisplayString(record.workingHours), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_60, [
                                  _createTextVNode(_toDisplayString(record.performanceRating) + "/5 ", 1),
                                  _createElementVNode("small", _hoisted_61, _toDisplayString(record.remarks), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                (record.transferDetails?.transferredTo)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                                      _createElementVNode("small", null, [
                                        _createTextVNode(" To: " + _toDisplayString(record.transferDetails.transferredTo.siteName), 1),
                                        _cache[40] || (_cache[40] = _createElementVNode("br", null, null, -1)),
                                        _createTextVNode(" Date: " + _toDisplayString(formatDate(record.transferDetails.transferredTo.transferDate)), 1),
                                        _cache[41] || (_cache[41] = _createElementVNode("br", null, null, -1)),
                                        _createTextVNode(" Reason: " + _toDisplayString(record.transferDetails.transferredTo.reason), 1)
                                      ])
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_63, "-"))
                              ]),
                              _createElementVNode("td", null, [
                                (record.relieverDetails)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                                      _createElementVNode("small", null, [
                                        _createTextVNode(" Site: " + _toDisplayString(record.relieverDetails.relieverSiteName), 1),
                                        _cache[42] || (_cache[42] = _createElementVNode("br", null, null, -1)),
                                        _createTextVNode(" Date: " + _toDisplayString(formatDate(record.relieverDetails.relieverDate)), 1),
                                        _cache[43] || (_cache[43] = _createElementVNode("br", null, null, -1)),
                                        _createTextVNode(" Reason: " + _toDisplayString(record.relieverDetails.reason), 1)
                                      ])
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_65, "-"))
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_66, [
                    _cache[55] || (_cache[55] = _createElementVNode("h6", null, "Promotion Records", -1)),
                    _createElementVNode("div", _hoisted_67, [
                      _createElementVNode("table", _hoisted_68, [
                        _cache[54] || (_cache[54] = _createElementVNode("thead", { class: "table-light" }, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Previous Position"),
                            _createElementVNode("th", null, "New Position"),
                            _createElementVNode("th", null, "Promotion Date"),
                            _createElementVNode("th", null, "Effective Date"),
                            _createElementVNode("th", null, "Type"),
                            _createElementVNode("th", null, "Approved By"),
                            _createElementVNode("th", null, "Status"),
                            _createElementVNode("th", null, "Remarks")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (!promotionData.value?.promotions?.length)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_69, _cache[46] || (_cache[46] = [
                                _createElementVNode("td", {
                                  colspan: "8",
                                  class: "text-center"
                                }, "No promotion records found", -1)
                              ])))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(promotionData.value?.promotions, (record) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: record._id
                            }, [
                              _createElementVNode("td", _hoisted_70, [
                                _createElementVNode("div", null, [
                                  _cache[47] || (_cache[47] = _createElementVNode("strong", null, "Title:", -1)),
                                  _createTextVNode(" " + _toDisplayString(record.previousPosition.title), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _cache[48] || (_cache[48] = _createElementVNode("strong", null, "Dept:", -1)),
                                  _createTextVNode(" " + _toDisplayString(record.previousPosition.department), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _cache[49] || (_cache[49] = _createElementVNode("strong", null, "Salary:", -1)),
                                  _createTextVNode(" ₹" + _toDisplayString(record.previousPosition.salary), 1)
                                ])
                              ]),
                              _createElementVNode("td", _hoisted_71, [
                                _createElementVNode("div", null, [
                                  _cache[50] || (_cache[50] = _createElementVNode("strong", null, "Title:", -1)),
                                  _createTextVNode(" " + _toDisplayString(record.newPosition.title), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _cache[51] || (_cache[51] = _createElementVNode("strong", null, "Dept:", -1)),
                                  _createTextVNode(" " + _toDisplayString(record.newPosition.department), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _cache[52] || (_cache[52] = _createElementVNode("strong", null, "Salary:", -1)),
                                  _createTextVNode(" ₹" + _toDisplayString(record.newPosition.salary), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, _toDisplayString(formatDate(record.promotionDate)), 1),
                              _createElementVNode("td", null, _toDisplayString(formatDate(record.effectiveDate)), 1),
                              _createElementVNode("td", null, _toDisplayString(record.promotionType), 1),
                              _createElementVNode("td", _hoisted_72, [
                                _createElementVNode("div", null, _toDisplayString(record.approvedBy.name), 1),
                                _createElementVNode("div", _hoisted_73, _toDisplayString(formatDate(record.approvedBy.date)), 1)
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", {
                                  class: _normalizeClass({
                                                        'badge bg-success': record.status === 'APPROVED',
                                                        'badge bg-warning': record.status === 'PENDING',
                                                        'badge bg-danger': record.status === 'REJECTED'
                                                    })
                                }, _toDisplayString(record.status), 3)
                              ]),
                              _createElementVNode("td", _hoisted_74, [
                                _createElementVNode("div", null, [
                                  _cache[53] || (_cache[53] = _createElementVNode("strong", null, "Reason:", -1)),
                                  _createTextVNode(" " + _toDisplayString(record.reason), 1)
                                ]),
                                _createElementVNode("div", null, _toDisplayString(record.remarks), 1)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_75, [
                    _cache[60] || (_cache[60] = _createElementVNode("h6", null, "Complaint Records", -1)),
                    _createElementVNode("div", _hoisted_76, [
                      _createElementVNode("table", _hoisted_77, [
                        _cache[59] || (_cache[59] = _createElementVNode("thead", { class: "table-light" }, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Date"),
                            _createElementVNode("th", null, "Title"),
                            _createElementVNode("th", null, "Category"),
                            _createElementVNode("th", null, "Description"),
                            _createElementVNode("th", null, "Status"),
                            _createElementVNode("th", null, "Attachments")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (!complaintData.value?.complaints?.length)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_78, _cache[56] || (_cache[56] = [
                                _createElementVNode("td", {
                                  colspan: "6",
                                  class: "text-center"
                                }, "No complaint records found", -1)
                              ])))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(complaintData.value?.complaints, (complaint) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: complaint._id
                            }, [
                              _createElementVNode("td", null, _toDisplayString(formatDate(complaint.dateSubmitted)), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_79, [
                                  _createTextVNode(_toDisplayString(complaint.title) + " ", 1),
                                  (complaint.confidential)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_80, _cache[57] || (_cache[57] = [
                                        _createElementVNode("i", { class: "bi bi-lock-fill" }, null, -1)
                                      ])))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", _hoisted_81, _toDisplayString(complaint.category), 1)
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("small", null, _toDisplayString(complaint.description), 1)
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", {
                                  class: _normalizeClass({
                                                        'badge bg-warning': complaint.status === 'PENDING',
                                                        'badge bg-success': complaint.status === 'RESOLVED',
                                                        'badge bg-danger': complaint.status === 'REJECTED'
                                                    })
                                }, _toDisplayString(complaint.status), 3)
                              ]),
                              _createElementVNode("td", null, [
                                (complaint.attachments?.length)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(complaint.attachments, (file) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: file._id,
                                          class: "attachment-item"
                                        }, [
                                          _createElementVNode("a", {
                                            href: file.fileUrl,
                                            target: "_blank",
                                            class: "btn btn-sm btn-outline-secondary"
                                          }, [
                                            _cache[58] || (_cache[58] = _createElementVNode("i", { class: "bi bi-file-earmark" }, null, -1)),
                                            _createTextVNode(" " + _toDisplayString(file.fileName), 1)
                                          ], 8, _hoisted_83)
                                        ]))
                                      }), 128))
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_84, "-"))
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_85, [
                    _cache[71] || (_cache[71] = _createElementVNode("h6", null, "Penalty and Warning Slips", -1)),
                    _createElementVNode("div", _hoisted_86, [
                      _createElementVNode("table", _hoisted_87, [
                        _cache[70] || (_cache[70] = _createElementVNode("thead", { class: "table-light" }, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Date"),
                            _createElementVNode("th", null, "Type"),
                            _createElementVNode("th", null, "Title"),
                            _createElementVNode("th", null, "Category"),
                            _createElementVNode("th", null, "Severity"),
                            _createElementVNode("th", null, "Penalty Details"),
                            _createElementVNode("th", null, "Status"),
                            _createElementVNode("th", null, "Attachments")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (!penaltyData.value?.data?.slips?.length)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_88, _cache[61] || (_cache[61] = [
                                _createElementVNode("td", {
                                  colspan: "8",
                                  class: "text-center"
                                }, "No penalty or warning records found", -1)
                              ])))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(penaltyData.value?.data?.slips, (slip) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: slip._id
                            }, [
                              _createElementVNode("td", null, _toDisplayString(formatDate(slip.dateIssued)), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", {
                                  class: _normalizeClass({
                                                        'badge bg-danger': slip.type === 'PENALTY',
                                                        'badge bg-warning': slip.type === 'WARNING'
                                                    })
                                }, _toDisplayString(slip.type), 3)
                              ]),
                              _createElementVNode("td", null, _toDisplayString(slip.title), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", _hoisted_89, _toDisplayString(slip.category), 1)
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", {
                                  class: _normalizeClass({
                                                        'badge bg-danger': slip.severity === 'HIGH',
                                                        'badge bg-warning': slip.severity === 'MODERATE',
                                                        'badge bg-info': slip.severity === 'LOW'
                                                    })
                                }, _toDisplayString(slip.severity), 3)
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("small", null, [
                                  (slip.penalty)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_90, [
                                        _cache[62] || (_cache[62] = _createElementVNode("strong", null, "Type:", -1)),
                                        _createTextVNode(" " + _toDisplayString(slip.penalty.type), 1),
                                        _cache[63] || (_cache[63] = _createElementVNode("br", null, null, -1)),
                                        _cache[64] || (_cache[64] = _createElementVNode("strong", null, "Amount:", -1)),
                                        _createTextVNode(" ₹" + _toDisplayString(slip.penalty.amount), 1),
                                        _cache[65] || (_cache[65] = _createElementVNode("br", null, null, -1)),
                                        _cache[66] || (_cache[66] = _createElementVNode("strong", null, "Details:", -1)),
                                        _createTextVNode(" " + _toDisplayString(slip.penalty.details), 1)
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_91, [
                                  _createElementVNode("small", null, [
                                    _cache[67] || (_cache[67] = _createElementVNode("strong", null, "Acknowledged:", -1)),
                                    _createTextVNode(" " + _toDisplayString(slip.acknowledgement.acknowledged ? 'Yes' : 'No'), 1)
                                  ]),
                                  _createElementVNode("small", null, [
                                    _cache[68] || (_cache[68] = _createElementVNode("strong", null, "Appeal:", -1)),
                                    _createElementVNode("span", {
                                      class: _normalizeClass({
                                                                'badge bg-secondary': slip.appealStatus === 'NONE',
                                                                'badge bg-warning': slip.appealStatus === 'PENDING',
                                                                'badge bg-success': slip.appealStatus === 'APPROVED',
                                                                'badge bg-danger': slip.appealStatus === 'REJECTED'
                                                            })
                                    }, _toDisplayString(slip.appealStatus), 3)
                                  ])
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                (slip.attachments?.length)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_92, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slip.attachments, (file) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: file._id,
                                          class: "attachment-item"
                                        }, [
                                          _createElementVNode("a", {
                                            href: file.fileUrl,
                                            target: "_blank",
                                            class: "btn btn-sm btn-outline-secondary"
                                          }, [
                                            _cache[69] || (_cache[69] = _createElementVNode("i", { class: "bi bi-file-earmark" }, null, -1)),
                                            _createTextVNode(" " + _toDisplayString(file.fileName), 1)
                                          ], 8, _hoisted_93)
                                        ]))
                                      }), 128))
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_94, "-"))
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]))
    ])
  ]))
}
}

})